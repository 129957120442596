import React, { useState, useEffect } from "react";
import { Outlet, Route, Navigate, useLocation } from "react-router-dom";
import { PublicRoutes } from "src/common";
import {
  Login, LoginWeb, Logout, GetStarted, ChooseAccount, AddAccount, CreateAccount, CreateExpressAccount, ConnectAccount,
  CopyAccount, ClaimAccount, RecoverAccount, ApprovalGroup, BackupRecoverAccount, UpgradeAccount, Wrapper, UpgradeInformation,
  DeviceLocked, UpdateRequired, SystemError, ExpiredKey,
  ForgotPassword
} from "src/components";

const darkBgPage = ["/", `/${PublicRoutes.login_route}`, `/${PublicRoutes.connect_account_route}`,
  `/${PublicRoutes.create_account_route}`, `/${PublicRoutes.upgrade_account_route}`];

function PublicPage() {
  const location = useLocation();
  // Description: Add pages with darker white background
  const [customClass, setCustomClass] = useState(darkBgPage.includes(location.pathname) ? " bg" : "");
  useEffect(() => {
    setCustomClass(darkBgPage.includes(location.pathname) ? " bg" : "");
  }, [location]);

  return <Wrapper className={`public-page${customClass}`}>
    <Outlet />
  </Wrapper>;
}

// Description: Handle Public pages Local App routing
export function PublicPagesRoutes() {
  return <>
    <Route path={"/"} element={<Login />} />
    <Route path={PublicRoutes.login_route} element={<Login />} />
    <Route path={PublicRoutes.logout_route} element={<Logout />} />
    <Route path={PublicRoutes.get_started_route} element={<GetStarted />} />
    <Route path={PublicRoutes.choose_account_route} element={<ChooseAccount />} />
    <Route path={PublicRoutes.create_account_route} element={<CreateAccount />} />
    <Route path={PublicRoutes.claim_account_route} element={<ClaimAccount />} />
    <Route path={PublicRoutes.copy_account_route} element={<CopyAccount />} />
    <Route path={PublicRoutes.add_account_route} element={<AddAccount />} />
    <Route path={PublicRoutes.account_recovery_route} element={<RecoverAccount />} />
    <Route path={PublicRoutes.account_backup_recovery_route} element={<BackupRecoverAccount />} />
    <Route path={PublicRoutes.approval_group_route} element={<ApprovalGroup />} />
    <Route path={PublicRoutes.upgrade_account_route} element={<UpgradeAccount />} />
    <Route path={PublicRoutes.upgrade_information_route} element={<UpgradeInformation />} />
    <Route path={PublicRoutes.device_locked_route} element={<DeviceLocked />} />
    <Route path={PublicRoutes.update_required_route} element={<UpdateRequired />} />
    <Route path={PublicRoutes.system_error_route} element={<SystemError />} />
    <Route path={PublicRoutes.expired_key_route} element={<ExpiredKey />} />
    {/* Pages not available in full app - redirects */}
    <Route path={PublicRoutes.connect_account_route} element={<Navigate replace to={`/${PublicRoutes.copy_account_route}`} />} />
    <Route path={PublicRoutes.upgrade_route} element={<Navigate replace to={`/${PublicRoutes.upgrade_information_route}`} />} />
    <Route path="/" element={<Navigate replace to={PublicRoutes.login_route} />} />
  </>;
}

// Description: Handle Public pages Web routing
export function PublicPagesWebRoutes() {
  return <>
    <Route path={"/"} element={<LoginWeb />} />
    <Route path={PublicRoutes.login_route} element={<LoginWeb />} />
    <Route path={PublicRoutes.forgot_password_route} element={<ForgotPassword />} />
    <Route path={PublicRoutes.logout_route} element={<Logout />} />
    <Route path={PublicRoutes.get_started_route} element={<GetStarted />} />
    <Route path={PublicRoutes.connect_account_route} element={<ConnectAccount />} />
    <Route path={PublicRoutes.create_account_route} element={<CreateExpressAccount />} />
    <Route path={PublicRoutes.upgrade_information_route} element={<UpgradeInformation />} />
    <Route path={PublicRoutes.upgrade_account_route} element={<UpgradeInformation />} />
    <Route path={PublicRoutes.device_locked_route} element={<DeviceLocked />} />
    <Route path={PublicRoutes.expired_key_route} element={<ExpiredKey />} />
    {/* Pages not available in web build - redirects */}
    <Route path={PublicRoutes.upgrade_route} element={<Navigate replace to={`/${PublicRoutes.upgrade_information_route}`} />} />
    <Route path="*" element={<Navigate replace to={PublicRoutes.login_route} />} />
  </>;
}

export default React.memo(PublicPage); 
