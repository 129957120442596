import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { IActionHandler, MessageDisplayType, WebsocketCryptoMessage } from "@preveil-api";
import { useAppDispatch, useAppSelector, SettingsErrorMessages, MessageHandlerDisplayType, Message, AccounWStSteps, GlobalErrorMessages, MessageAnchors } from "src/common";
import { uiActions, WebsocketStatus, websocketActions } from "src/store";
import { Loading, PageHeader } from "src/components";
import {
	DefaultPanel,
	AwaitConnection,
	ConnectionEstablished,
	SuccessPanel,
	ErrorPanel
} from ".";

// Constants.ALERT_ERROR, "Cannot approve recovery for self - use Add Device instead", "", 5));
function ApproveRecoveryComponent() {
	const dispatch = useAppDispatch();
	const [step, setStep] = useState<number>(AccounWStSteps.DISCONNECTED);
	const [request_user_id, setRequestUserID] = useState<string>();

	// selectors:
	const message = useAppSelector((state) => state.websocket.message as WebsocketCryptoMessage);
	const status = useAppSelector((state) => state.websocket.status);
	const errors = useAppSelector((state) => state.websocket.errors);
	const current_account = useAppSelector((state) => state.account.current_account);
	const user_id = current_account?.user_id;

	// Description: First onload and unload events - destroy ws on leave
	useEffect(() => {
		return () => {  // Destroy websocket State onleave
			dispatch(websocketActions.destroyWebsocket());
		};
	}, []);

	// Description: Responds to changes in message and websocket status
	useEffect(() => {
		const is_error = status === WebsocketStatus.error || message?.status === WebsocketStatus.error;
		let next = is_error ? AccounWStSteps.ERROR : !!message ? step + 1 : step;
		if (!!message?.status && message.status === WebsocketStatus.key_transfer_complete) {
			next = AccounWStSteps.GET_KEY_TRANSFER_COMPLETE;
		}
		setStep(next);
	}, [message, status]);

	useEffect(() => {
		!!errors &&
			ApproveRecoveryRequests.handlePageErrorMessage({ message: SettingsErrorMessages.default, stack: errors });
	}, [errors]);

	const ApproveRecoveryRequests = {
		// Description: Acknowledge information and begin ws process
		handleSubmitAcknowledgeInformation: () => {
			// setAcknowledged(true);
			setStep(AccounWStSteps.INITIALIZE_CONNECTION);
		},
		// Description: Initialize the connection
		handleInitWebsocketConnection: (_request_user_id: string) => {
			if (!!user_id && !!_request_user_id) {
				const host = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}/get/account/${encodeURIComponent(user_id)
					}/shard/${encodeURIComponent(_request_user_id)}/send`;
				setRequestUserID(_request_user_id);
				dispatch(websocketActions.wsConnect({ host, init: { action: "init" } }));
			} else {
				ApproveRecoveryRequests.handlePageError({ message: SettingsErrorMessages.missing_user_id });
			}
		},
		// Description: Send Pin to server to request adding a device
		handleTransferKeyRequest: (params: { pin: string }) => {
			if (!!params.pin && params.pin.length > 0) {
				const verify_message: string = JSON.stringify({
					user_id,
					action: "verify",
					pin: params.pin
				});
				dispatch(websocketActions.wsOnSendMessage(verify_message));
			} else {
				ApproveRecoveryRequests.handlePageError({ message: SettingsErrorMessages.bad_pin });
			}
		},
		// Description: Reset message state and ws connection
		handleResetMessage: () => {
			dispatch(websocketActions.wsOnResetMessage());
		},
		// Description: Reset   ws connection
		handleDisconnect: () => {
			dispatch(websocketActions.wsDisconnect());
		},
		// Description: Reset page state and ws connection
		handleDestroy: () => {
			dispatch(websocketActions.wsDisconnect());
			dispatch(websocketActions.destroyWebsocket());
			setStep(AccounWStSteps.DISCONNECTED);
		},
		handlePageError: (params: { message: string, stack?: any, displayType?: MessageDisplayType }) => {
			const display_type = params.displayType || MessageHandlerDisplayType.toastr;
			dispatch(uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack));
		},
		handlePageErrorMessage: (params: { message: string, stack?: any, displayType?: MessageDisplayType }) => {
			const display_type = params.displayType || MessageHandlerDisplayType.logger;
			dispatch(uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack));
		}
	};

	//  Description: Handle all actions from Children forms
	function handlePageActions(actionObj: IActionHandler) {
		const callback = `handle${actionObj.actionType}`;
		if ((ApproveRecoveryRequests as any)[callback] instanceof Function) {
			(ApproveRecoveryRequests as any)[callback](actionObj.params);
		} else {
			const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
			ApproveRecoveryRequests.handlePageErrorMessage({ message, stack: actionObj });
		}
	};

	// Description: Allow user to reset form if ws gets stuck or connection is disrupted
	function InPlaceLoading() {
		return <>
			<Loading className="in-place" />
			<Button onClick={() => ApproveRecoveryRequests.handleDestroy()} >Try again</Button>
		</>;
	}

	const ApproveRecoveryPanels = () => {
		switch (step) {
			case AccounWStSteps.ERROR:
				return <ErrorPanel error={SettingsErrorMessages.default} handleAction={handlePageActions} />;
			case AccounWStSteps.DISCONNECTED:
				return <DefaultPanel handleAction={handlePageActions} />;
			case AccounWStSteps.INITIALIZE_CONNECTION:
			case AccounWStSteps.GET_ACCEPTED_INIT_OK:
				return <AwaitConnection handleAction={handlePageActions} />;
			case AccounWStSteps.GET_CONNECTION_ESTABLISHED:
				return !!request_user_id ?
					<ConnectionEstablished handleAction={handlePageActions} request_user_id={request_user_id} /> : <InPlaceLoading />;
			case AccounWStSteps.GET_KEY_TRANSFER_COMPLETE:
				return <SuccessPanel request_user_id={request_user_id} handleAction={handlePageActions} />;
			default:
				return <InPlaceLoading />;
		};
	};

	return <>
		<PageHeader>
			<h1>Approve Recovery</h1>
		</PageHeader>
		<Card className="pv-card">
			{ApproveRecoveryPanels()}
		</Card>
	</>;
}

export default React.memo(ApproveRecoveryComponent);
