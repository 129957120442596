import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button, Col, ProgressBar, Row, Toast, Card, CloseButton } from "react-bootstrap";
import { IActionHandlerTyped, UserProfile, ActionHandlerFunction, ContactsData, UserListData, ErrorStackDataType, MessageDisplayType } from "@preveil-api";
import {
  Account, AppConfiguration, useAppDispatch, DriveUIActionTypes, CollectionEntity, NodePermissionTypes, NodePermissionType, useWebShare, EntryItem, SettingsUIActionTypes,
  DriveMessages, isSameUser, MessageAnchors, PanelState, Message, useFSProcessNotification, AsyncJobTypeMap, UUID, AsyncJobStatusMap, DriveErrorMessages,
  MessageHandlerDisplayType, ShareDataBase, COLLECTION_PROTOCOL_VERSIONS
} from "src/common";
import { uiActions } from "src/store";
import { UserList, UserListNotification } from "src/components";
import { PermissionsMenu } from "../../../action-menus";
import { EditExpiration } from ".";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  collection_info: CollectionEntity;
  entry: EntryItem;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setEditReadonly: Dispatch<SetStateAction<boolean>>;
  handleAction: ActionHandlerFunction;
};

const is_app = AppConfiguration.buildForApp();
function NewShareComponent(props: AllProps) {
  const { current_account, collection_info, entry, setEditMode, setEditReadonly, handleAction } = props;
  const [exclude_users, setExcludeUsers] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [success_message, setSuccessMessage] = useState<string | undefined>();
  const [recipients, setRecipients] = useState<UserProfile[]>([]);
  const [contacts, setContacts] = useState<ContactsData>();
  const [percent, setPercent] = useState<number>(0);
  const [expiration, setExpiration] = useState<string>("");
  const [user_list_warning, setUserListWarning] = useState<string>();
  const [permission, setPermission] = useState<NodePermissionTypes>(NodePermissionType.edit_and_share);
  const [watch_id, setWatchRequestId] = useState<string | undefined>();
  const { new_notification } = useFSProcessNotification(AsyncJobTypeMap.SHARE, watch_id);
  const { save, reset, data, error, progress } = useWebShare(current_account, collection_info, entry, contacts);
  const dispatch = useAppDispatch();
  // Description: build users to exclude from New Share User List
  // NOTE: Do not render UserList until this is resolved (emtpy array if none)
  useEffect(() => {
    // NOTE: clean up UI after full update
    if (!!collection_info) {
      const _exclude_users = _.map(collection_info.shared_with, node_permissions => node_permissions.user_id.toLowerCase());
      // NOTE: Reset on notification
      (!_.isEqual(_exclude_users, exclude_users) && refreshing) && handleCompleteShareRefresh(success_message);
      setExcludeUsers(_exclude_users);
    }
  }, [collection_info.shared_with]);

  // Description: Update UI when FILESYNC Notifications come in
  useEffect(() => {
    if (!!new_notification && new_notification.request_id === watch_id) {
      ((new_notification.status === AsyncJobStatusMap.COMPLETED) && !!data) && handleFSOnComplete(data);

      // NOTE: Handle Errors:
      (new_notification.status === AsyncJobStatusMap.FAILED || new_notification.status === AsyncJobStatusMap.HARD_FAILED) &&
        handleShareError({ stack_message: "FilesyncLocalNotification returned an error", stack_error: new_notification }, MessageHandlerDisplayType.toastr);
    };
  }, [new_notification]);

  // Description: Reset type value when 
  useEffect(() => {
    // NOTE: if is app and a promote or v2 collection then wait for Filesync notifications
    ((!!data) && !(is_app && (!!data.is_promote || collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2))) ?
      handleShareComplete(data) : (!!data && data.grantees.length <= 0 && handleFSOnComplete(data));

    // NOTE: Handle errors from Webshare Close panel to restart process cleaner
    !!error && handleShareError({ stack_message: "useWebshare threw an error" });
  }, [data, error]);

  // Description: Reset type value when 
  useEffect(() => {
    refreshing ? setPercent(99) : setPercent(progress);
  }, [progress]);

  // Description:  Step 1 - Handle first step for refreshing UI after share (web)
  // This step runs for web and V1 collections (app and web)
  function handleShareComplete(_data: ShareDataBase) {
    // NOTE: Only setRefreshing if there are actual grantees added to the node
    setRefreshing(true);
    setSuccessMessage(_data.success_message);
    handleAction({ actionType: DriveUIActionTypes.SharedComplete, params: _data });
    reset();
    cancelSharing(true);
    (_data.grantees.length <= 0) && handleCompleteShareRefresh(data?.success_message);
  }

  // Description: Step 2 - Finish cleaning up UI after Share is completed and the UI refreshed (web)
  function handleCompleteShareRefresh(_success_message?: string) {
    setRefreshing(false);
    setPercent(0);
    if (!!_success_message) {
      dispatch(uiActions.handleSetMessage(new Message(_success_message)));
      setSuccessMessage(undefined);
    }
  }

  // Description: Handle App OnComplete on FS notifications
  function handleFSOnComplete(_data: ShareDataBase) {
    handleAction({ actionType: DriveUIActionTypes.SharedComplete, params: _data });
    reset();
    cancelSharing(true);
    setWatchRequestId(undefined);
    !!_data.success_message && dispatch(uiActions.handleSetMessage(new Message(_data.success_message)));
  }

  // Description: Handle share error
  function handleShareError(stack?: ErrorStackDataType, display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    reset();
    cancelSharing(true);
    setRefreshing(false);
    setWatchRequestId(undefined);
    handleAction({ actionType: DriveUIActionTypes.ToggleSidePanel, params: PanelState.off });
    !!stack && dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, display_type), stack));
  }

  // Description: Update users list with user or error message
  function handleUpdateUsers(actionObj: IActionHandlerTyped<UserListData>) {
    const params = actionObj.params;
    // NOTE: Handle excluded users (self and already shared with)
    if (actionObj.actionType === SettingsUIActionTypes.UpdateContactsExcludeUserWarning && !!params.exclude_users) {
      let warning = params.exclude_users.includes(current_account.user_id) ? DriveMessages.unable_to_add_self : "";
      const exists: string[] = [];
      const shared: string[] = [];
      _.forEach(params.exclude_users, (address: string) => {
        const _exists = _.find(params.current_contacts, (_contact: UserProfile) => isSameUser(address, _contact.address));
        !!_exists ? exists.push(address) : (!isSameUser(address, current_account.user_id) && shared.push(address));
      });
      const exist_msg = exists.length ? `<p>${DriveMessages.already_selected_user.replace(MessageAnchors.user_name, `<b>${exists.join(", ")}</b>`)}</p>` : "";
      const shared_msg = shared.length ? `<p>${DriveMessages.already_shared_with_user.replace(MessageAnchors.user_name,
        `<b>${shared.join(", ")}</b>`)}</p>` : "";
      warning = `${warning}${exist_msg}${shared_msg}`;
      setUserListWarning(warning);
    } else {
      setContacts(params.contacts_data);
      setRecipients(params.current_contacts);
      // Need to reset setUserListWarning if none
      (!params.exclude_users || params.exclude_users?.length <= 0) && setUserListWarning(undefined);
    }
  }

  // Description: Trigger the share hook 
  function share() {
    setLoading(true);
    setEditReadonly(true);
    const request_id = is_app ? new UUID().String() : undefined;
    setWatchRequestId(request_id);
    save(recipients, permission, expiration, request_id);
  }

  // Description: Trigger cancel and reset component
  function cancelSharing(edit_read_only: boolean = false) {
    setLoading(false);
    setPermission(NodePermissionType.edit_and_share);
    setEditReadonly(false);
    setContacts(undefined);
    setRecipients([]);
    setUserListWarning(undefined);
    setEditMode(edit_read_only);
    handleAction({ actionType: DriveUIActionTypes.ResetAction });
  }

  return <>
    <Card className="new-share py-2">
      {
        !loading && !refreshing ?
          <>
            {
              <Toast
                show={!!user_list_warning}
                bg="warning" className="my-3">
                <Toast.Body>
                  <CloseButton className="ms-auto me-2" onClick={() => setUserListWarning(undefined)} />
                  <div dangerouslySetInnerHTML={{ __html: user_list_warning || "" }} />
                </Toast.Body>
              </Toast>
            }
            <div className="tos">
              <label>Invite:</label>
              {
                exclude_users &&
                <UserList
                  id="new_share"
                  current_account={current_account}
                  focus
                  exclude_self
                  exclude_users={exclude_users}
                  handleAction={handleUpdateUsers} />
              }
            </div>
            {
              !!recipients &&
              <UserListNotification recipients={recipients} />
            }
            <div>
              <label>Permissions:</label>
              <PermissionsMenu
                permission_type={permission}
                unshare_disabled
                handleAction={setPermission} />
            </div>
            {
              (!!permission && permission !== NodePermissionType.edit_and_share) &&
              <div className="expiration">
                <label>
                  Expiration Date:
                  <small>(optional)</small>
                </label>
                <EditExpiration
                  disabled={false}
                  type={permission}
                  expiration={""}
                  handleReset={() => setExpiration("")}
                  handleAction={setExpiration} />
              </div>
            }
            <div className="btn-panel">
              <Button size="sm" onClick={share} disabled={recipients.length <= 0}>
                Share
              </Button>
              <Button size="sm" variant="no-outline-primary" onClick={() => cancelSharing()}>
                Cancel
              </Button>
            </div>
          </> :
          <>
            <Row className="align-items-center">
              <Col sm={8}>
                <ProgressBar animated now={percent} label={`${percent}%`} visuallyHidden variant="secondary" />
              </Col>
              <Col >
                {`${percent}%`}
              </Col>
              <Col xs="auto">
                <Button size="sm" variant="no-outline-primary" className="mb-2" onClick={() => cancelSharing()}>
                  close
                </Button>
              </Col>
            </Row>
            <Toast bg="warning" className="my-3">
              <Toast.Body>
                <span>Encrypting and Sharing in Progress.</span>
              </Toast.Body>
            </Toast>
          </>
      }
    </Card>
  </>;
}

export default React.memo(NewShareComponent);