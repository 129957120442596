import { AccountType } from "@preveil-api";
import _ from "lodash";

export type AdminUserInvite = {
  email: string;
  firstName: string;
  lastName: string;
  department: string;
}

export type AdminUserExternalInvite = {
  id?: string;
  accountType?: AccountType;
  accountVersion?: number;
  user_id: string;
  display_name: string | null | undefined;
  status?: string;
  role: string;
  department: string;
  recoveryGroup?: string | null;
}

export type AdminUserSubsumeInvite = {
  subsume_user_id: string;
  department: string;
}

export type AdminRecoveryGroup = {
  groupId: string;
  name: string;
  requiredApprovers: number;
  isDeleted: boolean;
  groupVersion: string;
  approvers: Array<{
    name: string | null;
    address: string;
    role?: string | undefined;
  }>;
};

export type UpdateUser = {
  accountType: string;
  department: string | null;
  recoveryGroup: AdminRecoveryGroup | null;
  devices: any;
  role: string;
  status: string;
  userEmail: string;
  userName: string;
};

export type PendingGroupUpdates = {
  [key: string]: {
    requestsIds: string[];
    userName: string;
    userEmail: string;
  };
}


export class AdminUserEntity {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  department: string;
  constructor(email: string, firstName: string, lastName: string, department: string) {
    this.id = _.uniqueId(email);
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.department = department; 
  }

  // Note: this is for external users
  // Todo: pick a better name.
  public api_payload_format() {
    return {
      user_id: this.email,
      display_name: `${this.firstName} ${this.lastName}`,
      role: "standard", // NOTE/Question: Are all the roles standard when sending invites to external users?
      department: this.department
    };
  }

  public subsume_account_format() {
    return {
      subsume_user_id: this.email,
      department: this.department
    };
  }

  public ui_format() {
    // Sharing Group Members Card component to display the user info
    // so data is formatted in the same way.
    return {
      id: this.id,
      user_id: this.email,
      display_name: `${this.firstName} ${this.lastName}`,
      org_info: {
        dept_name: this.department
      }
    };
  }
}
