import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { IActionHandler, WebsocketCryptoMessage } from "@preveil-api";
import {
  useAppDispatch, useAppSelector, AccounWStSteps, SettingsErrorMessages, MessageHandlerDisplayType, Message, GlobalErrorMessages, MessageAnchors
} from "src/common";
import { uiActions, WebsocketStatus, websocketActions } from "src/store";
import { PageHeader } from "src/components";
import {
  DefaultPanel,
  AwaitConnection,
  ConnectionEstablished,
  SuccessPanel,
  ErrorPanel
} from ".";

function AddDeviceComponent() {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState<number>(AccounWStSteps.DISCONNECTED);
  const message = useAppSelector((state) => state.websocket.message as WebsocketCryptoMessage);
  const status = useAppSelector((state) => state.websocket.status);
  const errors = useAppSelector((state) => state.websocket.errors);
  const current_account = useAppSelector((state) => state.account.current_account);
  const user_id = current_account?.user_id;

  useEffect(() => {
    return () => {  // Destroy websocket State onleave
      dispatch(websocketActions.destroyWebsocket());
    };
  }, []);

  // Description: Responds to changes in message and websocket status
  useEffect(() => {
    const is_error = status === WebsocketStatus.error || message?.status === WebsocketStatus.error;
    let next = is_error ? AccounWStSteps.ERROR : !!message ? step + 1 : step;
    if (!!message?.status) {
      next = message.status === WebsocketStatus.key_transfer_complete ?
        AccounWStSteps.GET_KEY_TRANSFER_COMPLETE :
        message.status === WebsocketStatus.connection_established ?
          AccounWStSteps.GET_CONNECTION_ESTABLISHED : next;
    }
    setStep(next);
  }, [message, status]);

  // Description: load accounts and urrent account from crypto (First onload event)
  useEffect(() => {
    !!errors &&
      DeviceRequests.handlePageErrorMessage({ message: SettingsErrorMessages.default, stack: errors });

  }, [errors]);

  const DeviceRequests = {
    // Description: Initialize the connection
    handleInitWebsocketConnection: () => {
      if (!!user_id) {
        const host = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}/get/account/${encodeURIComponent(user_id)}/key/send`;
        dispatch(websocketActions.wsConnect({ host, init: { action: "init" } }));
      } else {
        DeviceRequests.handlePageError(SettingsErrorMessages.missing_user_id);
      }
    },
    // Description: Send Pin to server to request adding a device
    handleTransferKeyRequest: (params: { pin: string }) => {
      if (!!params.pin && params.pin.length > 0) {
        const verify_message: string = JSON.stringify({
          user_id,
          action: "verify",
          pin: params.pin
        });
        dispatch(websocketActions.wsOnSendMessage(verify_message));
      } else {
        DeviceRequests.handlePageError(SettingsErrorMessages.bad_pin);
      }
    },
    // Description: Reset message state and ws connection
    handleResetMessage: () => {
      dispatch(websocketActions.wsOnResetMessage());
    },
    // Description: Reset   ws connection
    handleDisconnect: () => {
      dispatch(websocketActions.wsDisconnect());
    },
    // Description: Reset page state and ws connection
    handleDestroy: () => {
      dispatch(websocketActions.destroyWebsocket());
      setStep(AccounWStSteps.DISCONNECTED);
    },
    handlePageError: (message: string) => {
      // dispatch(websocketActions.wsOnError(new Error(message)));
      dispatch(uiActions.handleRequestErrors(new Message(message)));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DeviceRequests as any)[callback] instanceof Function) {
      (DeviceRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      DeviceRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Action Handler
  const AddDevicePanels = () => {
    switch (step) {
      case AccounWStSteps.ERROR:
        return <ErrorPanel error={SettingsErrorMessages.default} handleAction={handlePageActions} />;
      case AccounWStSteps.DISCONNECTED:
        return <DefaultPanel handleAction={handlePageActions} />;
      case AccounWStSteps.INITIALIZE_CONNECTION:
      case AccounWStSteps.GET_ACCEPTED_INIT_OK:
        return <AwaitConnection handleAction={handlePageActions} />;
      case AccounWStSteps.GET_CONNECTION_ESTABLISHED:
        return <ConnectionEstablished handleAction={handlePageActions} />;
      case AccounWStSteps.GET_KEY_TRANSFER_COMPLETE:
        return <SuccessPanel handleAction={handlePageActions} />;
      default:
        return <DefaultPanel handleAction={handlePageActions} />;
    };
  };

  return <>
    <PageHeader>
      <h1>Add Device</h1>
    </PageHeader>
    <Card className="pv-card">
      {AddDevicePanels()}
    </Card>
  </>;
}

export default React.memo(AddDeviceComponent);
