import React, { useState, useEffect, useRef } from "react";
import { ActionHandlerFunction, IActionHandler, CollectionServerUser } from "@preveil-api";
import { InfoPopover, UserList } from "src/components";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Account, AdminUIActionTypes, UserListActionTypes } from "src/common";
import { GatewayConnectivityStatus } from "./helpers.class";

type AllProps = {
  org_users: CollectionServerUser[];
  current_account: Account;
  handleActions: ActionHandlerFunction;
  gateway_user_id: string[];
  gateway_connectivity_status: GatewayConnectivityStatus | null;
  is_invalid_gateway_id: boolean;
}


function EmailGatewayTopPanel({ current_account, handleActions, gateway_user_id, gateway_connectivity_status, is_invalid_gateway_id, org_users }: AllProps) {
  // Local state
  const [show_input, setShowInput] = useState<boolean>(false);
  const [current_gateway_user, setCurrentGatewayUser] = useState<string[]>(gateway_user_id);
  const [is_assign_btn_disabled, setIsAssignBtnDisabled] = useState<boolean>(true);

  const userListRef = useRef<{ handlePageActions: ActionHandlerFunction }>();
  const gatewayUserIdRef = useRef<string>();
  const is_healthy = !!gateway_connectivity_status?.healthy;
  const status = is_healthy ? "Ok" : gateway_connectivity_status?.details || "Connectivity Error.";

  useEffect(() => {
    if (gateway_user_id && gateway_user_id.length === 1) {
      setCurrentGatewayUser(gateway_user_id);
      setShowInput(false);
    }
  }, [gateway_user_id]);

  useEffect(() => {
    if (is_invalid_gateway_id && gateway_user_id.length === 1 && gatewayUserIdRef.current !== gateway_user_id[0]) {
      gatewayUserIdRef.current = gateway_user_id[0];
      handleCancel();
    }
  }, [current_gateway_user]);
  // Description: This function will handle the gateway user action.
  function handleGatewayUser(actionObj: IActionHandler) {
    const { params } = actionObj;
    const { current_contacts } = params;
    if (current_contacts.length === 0) {
      if (show_input) {
        // if show_input is already true, then not need to set/change state.
        return;
      }
      setCurrentGatewayUser([]);
      setIsAssignBtnDisabled(true);
      setShowInput(true);
    } else if (current_contacts.length !== 0) {
      const { address } = params.current_contacts[0];
      gatewayUserIdRef.current = address;

      setCurrentGatewayUser([address]);
      if (show_input && gateway_user_id[0] !== address) {
        setIsAssignBtnDisabled(false);
        setShowInput(false);
      } else {
        setShowInput(false);
      }
    }
  }
  // Description: Cancel button will reset the gatewayUserId to the default value.
  function handleCancel() {
    const userId = gateway_user_id[0];
    if (!!userListRef && !!userListRef.current) {
      userListRef.current.handlePageActions({
        actionType: UserListActionTypes.Cancel,
        params: {
          user_id: userId
        }
      });
    }
  }

  const isCancelButtonDisabled = gateway_user_id.length === 0;
  // Cancel button acts more like a reset to default gatewayId.
  // if there is no gatewayUserId assigned coming from the backend, then it won't show the cancel button.
  const showCancelButton = show_input && gateway_user_id.length !== 0;
  return <Card.Body>
    <Row>
      <Col sm={12} md={6}>
        <p>Gateway User ID
          <InfoPopover
            message="Gateway User ID is a new email address in your Organization that will be used as the service account inside your Gateway setup." />
        </p>
        <div className="top-panel-area">
          <div className={`gateway-user-id-section ${show_input ? "show" : "hide"}`}>
            <UserList
              ref={userListRef}
              single_user
              org_users={org_users}
              current_account={current_account}
              handleAction={handleGatewayUser}
              user_ids={current_gateway_user}
            />
          </div>
          {showCancelButton ? (
            <Button variant="no-outline-secondary" size="sm" onClick={handleCancel}>
              Cancel
            </Button>
          ) : null}
          <Button
            disabled={is_assign_btn_disabled}
            variant="outline-secondary"
            size="sm"
            className="action-btn"
            onClick={() =>
              handleActions({
                actionType: AdminUIActionTypes.ChangeGatewayUserId,
                params: current_gateway_user[0],
              })
            }>Assign</Button>
        </div>
      </Col>
      <Col sm={12} md={6} className="mt-4 mt-md-0">
        <p>
          Connectivity Test
          <InfoPopover
            placement="bottom-end"
            message="Connectivity test will check that the Gateway can send and receive mail to and from the PreVeil service in your Org. If errors are shown, they must be resolved before Gateway will operate correctly." />
        </p>
        <div className="gateway-status">
          <p className={`fs-5 ${is_healthy ? "success-green" : "error-red"}`}>{status}</p>
          <Button variant="outline-secondary" className="btn-sm action-btn" disabled={isCancelButtonDisabled} onClick={() => handleActions({ actionType: AdminUIActionTypes.TestGatewayConnectivity })}>
            Test Now
          </Button>
        </div>
      </Col>
    </Row>
  </Card.Body>;
}

export default React.memo(EmailGatewayTopPanel);