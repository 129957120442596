import React from "react";
import { ActionHandlerFunction } from "@preveil-api";
import { AdminUIActionTypes } from "src/common";
import { Button } from "react-bootstrap";
import keyMirror from "keymirror";

type AllProps = {
  handleActions: ActionHandlerFunction;
  step: string;
  approved_users: number;
  required_approvers?: number;
};

const dataExportStatus = keyMirror({
  not_init: null, // not approval groups assigned yet.
  not_started: null, // no data export request has been made.
  init: null, // data export request has been made and is pending approval.
  pending: null, // data export request is pending approval.
  started: null, // data export request has been approved and started.
  running: null, // data export download is running.
  complete: null, // data export download is complete.
  cancelled: null // data export download is cancelled.
});

const buttonState = keyMirror({
  complete: null, // data export download is complete.
  started: null, // data export request has been approved and started.
  running: null // data export download is running.
});


function DataExportHeader({ handleActions, step, approved_users, required_approvers }: AllProps) {
  const disableStartExport = (approved_users !== required_approvers || !!buttonState[step as keyof typeof buttonState]) && step !== dataExportStatus.cancelled;
  const showCancel = !buttonState[step as keyof typeof buttonState];
  const showStopExport = step === dataExportStatus.started || step === dataExportStatus.running;
  return (
    <div className="d-flex align-items-center justify-content-between">
      <h1 className="m-0">Data Export</h1>
      <div>
        {step !== dataExportStatus.not_init && step !== dataExportStatus.not_started && 
          <>
            <Button disabled={disableStartExport} onClick={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}>Start Export</Button>
            {showCancel && (
              <Button
                variant="no-outline-primary"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.Cancel })}
              >Cancel</Button>
            )}
            {showStopExport && (
              <Button
                variant="no-outline-primary"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.StopDataExportRunning })}
              >Stop Export</Button>
            )}
            {step === dataExportStatus.complete &&
              <Button
                variant="no-outline-primary"
                onClick={() => handleActions({ actionType: AdminUIActionTypes.Finish })}
              >Finish</Button>
            }
          </>
        }
      </div>
    </div>
  );
}

export default React.memo(DataExportHeader);