import React, { useState, useEffect, FocusEvent, useRef } from "react";
import { OrgInfo, IActionHandler, AdminUserEntity, RequestMetadataObject, Sort, } from "@preveil-api";
import {
  Account, AdminMessages, useGetOrgInfo, useAppDispatch, GlobalErrorMessages, MessageAnchors, Message, MessageHandlerDisplayType,
  useDeleteOrgUserMutation, MessageToastTypes, AdminErrorMessages, AdminSuccessMessages, useAddOrganizationMembersMutation, useAddOrgApprovalGroupMembersMutation,
  useOrgSubsumeMemberMutation, useAppSelector, useUpdateUserOrgRoleMutation, useUpdateUserOrgDeptMutation, CheckboxStates, OrgUserStatus,
  CheckboxStatesTypes, getOppositeSortDirection, sortBy, SORT_DIRECTION, dayjs, UserRequest, account_types
} from "src/common";
import { CoverTemplate, AdminToolbar, PageHeader, Loading } from "src/components";
import { AdminUserExternalInvite, AdminRecoveryGroup, UpdateUser, AdminUserSubsumeInvite, PendingGroupUpdates } from "./helpers.class";
import { AdminUsersListPanel, AddUsersModal, ResendInvitesModal, SetRecoveryGroupModal } from ".";
import { uiActions } from "src/store";
import { saveAs } from "file-saver";
import _ from "lodash";

// Users List headers for CSV File.
const HEADERS: string[] = [
  "Display Name",
  "User Email",
  "Account Type",
  "Status",
  "Department",
  "Role",
  "Recovery Group"
];

// Byte Order Mark (BOM)
// it may be required by some apps to be present in UTF-8 encoded files.
const BOM = "\ufeff";

type AllProps = {
  account: Account;
  org_info: OrgInfo;
}

type SelectedUser = {
  userName: string;
  userEmail: string;
}

type GroupRequestPayload = {
  [key: string]: {
    groupVersion: string;
    currentGroupId: string | null;
    currentGroupVersion: string | undefined | null;
    users: string[];
  };
}

type UsersOrgRequest = RequestMetadataObject & { department: string };

function AdminUsersComponent({ account, org_info }: AllProps) {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector((state) => state.account.accounts);
  const REQUEST_SUBSUME = "subsume_account";
  const REQUEST_PENDING_APPROVAL_GROUP = "member_rekey_and_set_approval_group";

  // Local State
  const [show_add_users_modal, setShowAddUsersModal] = useState<boolean>(false);
  const [show_resend_invites_modal, setShowResendInvitesModal] = useState<boolean>(false);
  const [show_set_recovery_group_modal, setShowSetRecoveryGroupModal] = useState<boolean>(false);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [users_list, setUsersList] = useState<AdminUserEntity[]>([]);
  const [recovery_groups, setRecoveryGroups] = useState<AdminRecoveryGroup[]>([]);
  const [filter_param, setFilterParam] = useState<string>("");
  const [selected_users, setSelectedUsers] = useState<SelectedUser[]>([]);
  const [local_users_accounts_map, setLocalUsersAccountsMap] = useState<Map<string, Account>>(); // All accounts from localusers.
  const [is_resend_invite_button_disable, setIsResendInviteButtonDisable] = useState<boolean>(true);
  const [allow_add_devices, setAllowAddDevices] = useState<boolean>();
  const [add_device_overrides, setAddDeviceOverrides] = useState<string[]>([]);
  const [users_with_pending_invites, setUsersWithPendingInvites] = useState<AdminUserExternalInvite[]>([]);
  const [submit_success, setSubmitSuccess] = useState<boolean>(false);
  const [sort, setSort] = useState<Sort<keyof AdminUserEntity>>({ field: "userName", direction: "asc" });

  const usersOrgHash = useRef(new Map<string, AdminUserExternalInvite>());

  // Hooks
  const { data, error, refresh } = useGetOrgInfo(account, org_info);
  const [deleteOrgUser] = useDeleteOrgUserMutation();
  const [addOrganizationMembers] = useAddOrganizationMembersMutation();
  const [orgSubsumeMember] = useOrgSubsumeMemberMutation();
  const [addOrgApprovalGroupMembers] = useAddOrgApprovalGroupMembersMutation();
  const [updateUserOrgRole] = useUpdateUserOrgRoleMutation();
  const [updateUserOrgDept] = useUpdateUserOrgDeptMutation();

  const filteredUsers = _.isEmpty(filter_param) ? users_list : filterUsersList(filter_param);

  function fetchOrgData() {
    setIsLoading(true);
    return refresh({
      history: false,
      status: "pending",
      hideExpired: true,
      limit: 200,
    });
  };

  /* Description: On initial load - call the use get org info hook to get the approval groups and requests. */
  useEffect(() => {
    fetchOrgData();
  }, []);

  useEffect(() => {
    if (accounts && accounts.length >= 1) {
      const allAccountsMap = new Map(accounts.map((account: Account) => {
        const key = account.user_id;
        return [key, account];
      }));

      setLocalUsersAccountsMap(allAccountsMap);
    }
  }, [accounts]);

  function verifyPendingInvites(user: SelectedUser, selectedState: boolean, users: SelectedUser[]) {
    const { userEmail } = user;
    const userWithPendingInvite = usersOrgHash.current.get(userEmail);

    // Description: if the user is selected.
    if (selectedState) {
      if (userWithPendingInvite && userWithPendingInvite.status === "pending") {
        setUsersWithPendingInvites([...users_with_pending_invites, userWithPendingInvite]);
        const filtered_selected_users = users.filter((user) => !!usersOrgHash.current.get(user.userEmail) && usersOrgHash.current.get(user.userEmail)?.status !== "pending");
        filtered_selected_users.length === 0 && is_resend_invite_button_disable && setIsResendInviteButtonDisable(false);
      }
      if (!userWithPendingInvite || userWithPendingInvite.status !== "pending") {
        !is_resend_invite_button_disable && setIsResendInviteButtonDisable(true);
      }
    }

    // Description: if the user is unselected.
    if (!selectedState) {
      // For every user that is unselected, check if there is still an user with a pending invite selected.
      // We also need to verify if there are any selected users with other statuses (active, invited).
      if (userWithPendingInvite && userWithPendingInvite.status === "pending" && users_with_pending_invites.length >= 1) {
        const filteredPendingInvites = users_with_pending_invites.filter((user) => user.user_id !== userEmail); // Remove the unselected user from the pending invites list.
        setUsersWithPendingInvites(filteredPendingInvites);
        filteredPendingInvites.length === 0 && !is_resend_invite_button_disable && setIsResendInviteButtonDisable(true); // if there are no more users with pending invites selected, disable the resend invite button.
      }
      if (users_with_pending_invites.length >= 1 && (!userWithPendingInvite || userWithPendingInvite.status !== "pending")) {
        // If the unselected user is not a pending invite, check if there are any other users without pending invites selected.
        // If there are no more users with (active, invite) status, enable the resend invite button.
        const filtered_selected_users = users.filter((user) => usersOrgHash.current.get(user.userEmail) && usersOrgHash.current.get(user.userEmail)?.status !== "pending");
        filtered_selected_users.length === 0 && setIsResendInviteButtonDisable(false);
      }
    }
  }

  // Description: When select all is checked, we need to verify if there are any pending invites selected.
  // Javascript.some will only iterate through the array until it finds a match.
  function verifySelectAllPendingInvites(users: AdminUserEntity[]) {
    if (users.length === 0) return false;
    return users.some((user) => user?.status === "pending");
  }

  // Description: parses through the org requests
  // and returns the pending group updates for each user if there is any.
  // and the subsume requests.
  function parseOrgRequests(requests: UserRequest[]) {
    const pending_group_updates: PendingGroupUpdates = {};
    const usersOrgRequests: AdminUserEntity[] = [];
    if (requests.length >= 1) {
      requests.forEach((request) => {
        const { type } = request;
        if (type === REQUEST_PENDING_APPROVAL_GROUP) {
          // each request can have multiple users.
          const { for_users } = request;
          for_users.forEach((user) => {
            const { address, name } = user;
            // we save the request id for each user in the pendingGroupRequestsApprovals object.
            // so we can access it later when we need to check which user has pending requests and how many (updates pending for recovery group).
            if (!pending_group_updates[address]) {
              const requestsIds = [];
              requestsIds.push(request.request_id);
              pending_group_updates[address] = {
                requestsIds,
                userName: name as string,
                userEmail: address
              };
            } else if (pending_group_updates[address]) {
              pending_group_updates[address].requestsIds.push(request.request_id);
            }
          });
        } else if (type === REQUEST_SUBSUME) {
          // Iterate over the subsume request users since those are not part of the org users (not present in the org_users array).
          const { for_users: userData, is_admin, metadata, request_id } = request;
          const { department } = metadata as UsersOrgRequest;
          const accountType = undefined;
          const role = is_admin ? "admin" : "standard";
          const status = request.status === OrgUserStatus.pending ? OrgUserStatus.invited : request.status;
          const userName = userData[0].name as string;
          const userEmail = userData[0].address;
          const subsumeRequestUser = {
            user_id: userEmail,
            display_name: userName,
            status,
            role,
            department,
            recoveryGroup: null,
            subsume_request_id: request_id
          };

          usersOrgHash.current.set(userEmail, subsumeRequestUser); // This includes all users in the org (active, pending, invited).
          usersOrgRequests.push({
            accountType,
            status,
            userName,
            userEmail,
            department,
            role,
            devices: [],
            recoveryGroup: null,
            subsume_request_id: request_id
          });
        }
      });
    }
    return { pending_group_updates, usersOrgRequests };
  }

  useEffect(() => {
    if (data && data?.org_users) {
      const { org_users, approval_groups, org_requests, org_info } = data;
      setAllowAddDevices(org_info.allow_add_devices);
      !!org_info.add_device_overrides && setAddDeviceOverrides(org_info.add_device_overrides);
      const recovery_groups = approval_groups.map((group) => {
        const { approval_group_id, name, approvers, group_version: groupVersion, required_approvers: requiredApprovers, is_deleted: isDeleted } = group;
        return { groupId: approval_group_id, name, approvers, groupVersion, requiredApprovers, isDeleted };
      });
      const { pending_group_updates, usersOrgRequests } = parseOrgRequests(org_requests);
      const users = org_users.map((user) => {
        const { account_type: accountType, display_name: userName, user_id: userEmail, entity_metadata, devices, claimed } = user;
        const { department, recovery_group_id: recoveryGroup, role } = entity_metadata;
        const status = !!claimed ? "active" : "pending"; // Claimed is a boolean value so we need to add a label to it.
        const updatesPending = pending_group_updates[userEmail] ? pending_group_updates[userEmail].requestsIds.length : 0; // Recovery group updates pending for this specific user.
        const orgUser = {
          accountType: accountType ?? null,
          accountVersion: user.account_version,
          user_id: userEmail,
          display_name: userName,
          status,
          role,
          department,
          recoveryGroup
        };
        usersOrgHash.current.set(userEmail, orgUser); // This includes all users in the org (active, pending, invited).
        return {
          accountType,
          status,
          userName,
          userEmail,
          department,
          role,
          devices,
          recoveryGroup,
          updatesPending
        };
      });
      const users_list = [...users, ...usersOrgRequests];
      const sorted_users_list = sortBy(users_list, { field: "userName", direction: SORT_DIRECTION.ascending });
      setUsersList(sorted_users_list);
      setRecoveryGroups(recovery_groups.filter((group) => !group.isDeleted));
      setIsLoading(false);
    }

    if (error) {
      const params = {
        message: AdminErrorMessages.error_fetching_org_info,
        stack: error
      };
      dispatchPageError(params);
      setIsLoading(false);
    }
  }, [data, error]);

  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  // Description: Filter the users list based on the search param. Toolbar
  function filterUsersList(param: string) {
    const _param = param.toLowerCase();
    return users_list.filter((user) => {
      return Object.values(user).filter((field) => field !== "full" && !_.isArray(field) && _.isString(field) && field?.toLowerCase().includes(_param)).length >= 1;
    });
  }

  // Description: CSV Export for the users list.
  function handleExportUsersList() {
    // We need to get the recovery group name for each user. (if user has an assigned recovery group)
    const recoveryGroupsHash = Object.fromEntries(recovery_groups.map(group => [group.groupId, group]));
    try {
      const csvString = [
        [...HEADERS],
        ...users_list.map((user: AdminUserEntity) => [
          user.userName,
          user.userEmail,
          user.accountType === account_types.full ? "Full Account User" : user.accountType === account_types.express ? "Express User" : "Pending",
          user.status,
          user.department,
          user.role,
          !!user.recoveryGroup ? recoveryGroupsHash[user.recoveryGroup].name : "Not Assigned"
        ])
      ]
        .map(row => row.join(","))
        .join("\n");

      const csvData = `${BOM}${csvString}`;
      const blob = new Blob([csvData], { type: "text/csv; charset=utf-8" });
      saveAs(blob, `admin-users-${dayjs(new Date()).format("MM.DD.YYYY-HH_mm_ss")}.csv`);
    } catch (error) {
      const params = {
        message: AdminErrorMessages.error_admin_users_export_csv,
        stack: error
      };
      dispatchPageError(params);
    }
  };

  // Description: Prepare the data for the request to assign the recovery group to the selected users.
  function prepareDataForRecoveryGroupRequest(recoveryGroup: AdminRecoveryGroup, userId?: string) {
    const { groupVersion, approvers, requiredApprovers, groupId } = recoveryGroup;
    let groupRequestWithUsersPayload = null;

    if (selected_users.length === 0 && !!userId) {
      // if action to update group is coming from selecting a single user - sidebar/offcanvas action, this is where we'll prepare the payload.
      if (!!usersOrgHash.current.get(userId)?.recoveryGroup) {
        const foundGroup = recovery_groups.find((group) => group.groupId === usersOrgHash.current.get(userId)?.recoveryGroup);
        const id = foundGroup?.groupId || usersOrgHash.current.get(userId)?.recoveryGroup as string;
        groupRequestWithUsersPayload = {
          [id]: {
            groupVersion,
            currentGroupId: foundGroup?.groupId ? foundGroup.groupId : null,
            currentGroupVersion: foundGroup?.groupVersion ? foundGroup.groupVersion : null as any,
            users: [usersOrgHash.current.get(userId)?.user_id]
          }
        };
      } else {
        // if the user doesn't have a recovery group assigned already, we'll use the "noGroup" key.
        groupRequestWithUsersPayload = {
          noGroup: {
            groupVersion,
            currentGroupId: null,
            currentGroupVersion: null,
            users: [usersOrgHash.current.get(userId)?.user_id]
          }
        };
      }
    } else if (selected_users.length >= 1 && !userId) {
      // if action to update group is coming from users list panel (one or more users selected), this is where we'll prepare the payload.
      // We'll use a reduce method to create the payload for the request.
      // Each selected user could have a different recovery group assigned, so we need to group them by their recovery group id.
      // the groupRequest object will store each group with the groupId as the key.
      // if it's a user without a recovery group, we'll use the "noGroup" key.
      // And we'll Promise.all through the groupRequest object to make the requests.
      groupRequestWithUsersPayload = selected_users.reduce((groupRequest: GroupRequestPayload, user) => {
        const currentGroupId = usersOrgHash.current.get(user.userEmail)?.recoveryGroup;
        if (currentGroupId && !groupRequest[currentGroupId]) {
          // if the currentGroupId is not in the groupRequest object, we'll create a new key for it.
          const foundGroup = recovery_groups.find((group) => group.groupId === usersOrgHash.current.get(user.userEmail)?.recoveryGroup);
          groupRequest[currentGroupId] = {
            groupVersion,
            currentGroupId,
            currentGroupVersion: foundGroup?.groupVersion,
            users: []
          };
          groupRequest[currentGroupId].users.push(usersOrgHash.current.get(user.userEmail)?.user_id ?? "");
        } else if (currentGroupId && groupRequest[currentGroupId]) {
          // if the currentGroupId is already in the groupRequest object, we'll just push the user to the users array.
          groupRequest[currentGroupId].users.push(usersOrgHash.current.get(user.userEmail)?.user_id ?? "");
        } else if (!currentGroupId && groupRequest.noGroup) {
          groupRequest.noGroup.users.push(usersOrgHash.current.get(user.userEmail)?.user_id ?? "");
        } else {
          // if this is the first time we encounter a user without a recovery group, we'll create a noGroup object key.
          groupRequest.noGroup = {
            groupVersion,
            currentGroupId: null,
            currentGroupVersion: null,
            users: []
          };
          groupRequest.noGroup.users.push(usersOrgHash.current.get(user.userEmail)?.user_id ?? "");
        }
        return groupRequest;
      }, {});
    };
    // Approvers from new assigned recovery group.
    const formattedApprovers = approvers.map((approver) => { // Payload needs to be formatted to match the API.
      const user_id = usersOrgHash.current.get(approver.address)?.user_id;
      let account_version;
      if (local_users_accounts_map?.get(approver.address)) {
        account_version = local_users_accounts_map?.get(approver.address)?.account_version as number;
      } else if (usersOrgHash.current.get(approver.address)) {
        account_version = usersOrgHash.current.get(approver.address)?.accountVersion as number;
      }
      return { user_id, account_version, required: false };
    });
    return { groupRequestWithUsersPayload, formattedApprovers, requiredApprovers, groupId };
  }

  // Description: API for assigning the recovery group to the selected users.
  async function assignSelectedRecoveryGroup(recoveryGroup: AdminRecoveryGroup, userAddress?: string, skipDispatch?: boolean) {
    // User Address is the user_id of the user that is being updated.
    if (account && org_info && !_.isEmpty(recoveryGroup)) {
      let promises;

      const userId = account.user_id;
      const orgId = org_info.org_id;
      const users = selected_users.length > 1 ? selected_users : selected_users.length === 1 ? [selected_users[0].userEmail] : [userAddress];
      const usersMessage = users.length === 1 ? users[0] : `${users.length} users`;

      const { groupRequestWithUsersPayload, formattedApprovers, requiredApprovers, groupId } = prepareDataForRecoveryGroupRequest(recoveryGroup, userAddress);
      if (!!groupRequestWithUsersPayload && !_.isEmpty(groupRequestWithUsersPayload)) {
        try {
          // await for all the requests to be completed.
          // it will go to catch block if any of the requests fails.
          promises = await Promise.all(Object.entries(groupRequestWithUsersPayload).map(([_, groupRequest]) => {
            const { groupVersion, currentGroupId, currentGroupVersion, users } = groupRequest;
            return addOrgApprovalGroupMembers({
              orgId,
              userId,
              groupVersion,
              approvalGroupUid: groupId,
              currentGroupId,
              currentGroupVersion,
              approvers: formattedApprovers,
              optionalsRequired: requiredApprovers,
              users
            });
          }));
          if (!skipDispatch) {
            // if skipDispatch is true, it means we are not dispatching the message or updating the state inside this function, see updateUserInfo function.
            const users = selected_users.length > 1 ? selected_users : selected_users.length === 1 ? [selected_users[0].userEmail] : [userAddress];
            const usersMessage = users.length === 1 ? users[0] : `${users.length} users`;
            const successMessage = AdminSuccessMessages.success_set_recovery_group.replace(MessageAnchors.message_content, `${usersMessage}`);
            setSelectedUsers([]);
            dispatch(uiActions.handleSetMessage(new Message(successMessage)));
            fetchOrgData();
            setShowSetRecoveryGroupModal(false);
          } else {
            return promises; // returning the promises to updateUserInfo function so we can handle the success message there.
          }
        } catch (error) {
          const params = {
            message: AdminErrorMessages.error_set_recovery_group.replace(MessageAnchors.message_content, `${usersMessage}`),
            stack: error
          };
          dispatchPageError(params);
        }
      } else {
        if (skipDispatch) {
          // skipDispatch means we are calling this function from the updateUserInfo function, 
          // so we are returning a promise to handle the reject message there.
          // and notify the user about the error, and let them know that the recovery group was not assigned/updated 
          promises = Promise.reject(new Error("Error updating recovery group for selected users."));
          return await promises;
        }
        else {
          const params = {
            message: AdminErrorMessages.error_set_recovery_group.replace(MessageAnchors.message_content, `${usersMessage}`),
            stack: {
              status: "Selected users payload is empty.",
              payload: groupRequestWithUsersPayload
            }
          };
          dispatchPageError(params);
        }
      }
    }
  }

  // Description: API for sending invites to external users.
  async function sendUsersInvite(users: AdminUserExternalInvite[], resendInvite?: boolean, skipDispatch?: boolean) {
    if (account && account?.org_info) {
      const userId = account.user_id;
      const orgId = org_info.org_id;
      const isSingleUser = users.length === 1;
      const usersMessage = isSingleUser ? `${users[0].display_name}` : `${users.length} users`;
      await addOrganizationMembers({
        orgId,
        userId,
        users
      })
        .unwrap()
        .then(() => {
          // If skipDispatch is true, it means we are not dispatching the message or updating the state inside this function, see sendInvitesFromCsv function.
          if (!skipDispatch) {
            let successMessage = "";
            if (resendInvite) {
              successMessage = AdminSuccessMessages.success_resend_invite_users.replace(MessageAnchors.message_content, `${usersMessage}`);
            } else if (!resendInvite) {
              const message = users.length === 1 ? `${users[0].display_name}` : `${users.length} users`;
              successMessage = AdminSuccessMessages.success_invite_user.replace(MessageAnchors.message_content, `${message}`);
            }
            fetchOrgData();
            dispatch(uiActions.handleSetMessage(new Message(successMessage)));
            setSelectedUsers([]);
            setUsersWithPendingInvites([]);
            setShowResendInvitesModal(false);
          }
        })
        .catch((error) => {
          let errorMessage = "";
          if (resendInvite) {
            // if we are resending invites, we need to verify if there are multiple users selected or just a single one.
            errorMessage = AdminErrorMessages.error_resend_invite_users.replace(MessageAnchors.message_content, `${usersMessage}`);
          } else if (!resendInvite) {
            errorMessage = AdminErrorMessages.error_invite_user_to_org.replace(MessageAnchors.user_id, `${users[0].display_name}`);
          }
          const params = {
            message: errorMessage,
            stack: error
          };
          dispatchPageError(params);
        });
    }
  }

  // Description: API for sending invites to registered users.
  // Note: You can only resend invites to users that have a "pending" status.
  // And subsume accounts only get "invited" status.
  async function sendSubsumeInvite(params: { subsume_user_id: string, department: string }, skipDispatch?: boolean) {
    if (account && account?.org_info) {
      const userId = account.user_id;
      const orgId = org_info.org_id;
      const { subsume_user_id, department } = params;

      await orgSubsumeMember({
        orgId,
        userId,
        subsumeUserId: subsume_user_id,
        department
      })
        .unwrap()
        .then((result) => {
          if (!skipDispatch) {
            const successMessage = AdminSuccessMessages.success_invite_user.replace(MessageAnchors.message_content, `${subsume_user_id}`);
            fetchOrgData();
            dispatch(uiActions.handleSetMessage(new Message(successMessage)));
          }
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_invite_user_to_org.replace(MessageAnchors.user_id, `${subsume_user_id}`),
            stack: error
          };
          dispatchPageError(params);
        });
    }
  }

  // Description: Handles invites request from CSV file data, for both subsume and external accounts.
  async function sendInvitesFromCsv(params: { subsumeAccounts: AdminUserSubsumeInvite[], externalAccounts: AdminUserExternalInvite[] }) {
    const { subsumeAccounts, externalAccounts } = params;
    if (subsumeAccounts.length >= 1 || externalAccounts.length >= 1) {
      try {
        const resendInvite = false;
        const skipDispatch = true; // we skip the dispatch message so we can handle it in the subsume accounts requests.
        // if there are any external accounts in the csv file, we need to call a different API.
        if (externalAccounts.length >= 1 && subsumeAccounts.length >= 1) {
          await sendUsersInvite(externalAccounts, resendInvite, skipDispatch);
        } else if (externalAccounts.length >= 1 && subsumeAccounts.length === 0) {
          await sendUsersInvite(externalAccounts, resendInvite, skipDispatch).then(() => {
            const successMessage = AdminSuccessMessages.success_invite_user.replace(MessageAnchors.message_content, `${externalAccounts.length} users`);
            dispatch(uiActions.handleSetMessage(new Message(successMessage)));
            setSubmitSuccess(true);
            fetchOrgData();
          });
        }

        if (subsumeAccounts.length >= 1) {
          await Promise.all(subsumeAccounts.map((subsumeAccount) => sendSubsumeInvite(subsumeAccount, skipDispatch))).then(() => {
            const totalInvites = subsumeAccounts.length + externalAccounts.length;
            const successMessage = AdminSuccessMessages.success_invite_user.replace(MessageAnchors.message_content, `${totalInvites} users`);
            fetchOrgData();
            dispatch(uiActions.handleSetMessage(new Message(successMessage)));
            setSubmitSuccess(true);
          });
        }

      } catch (error) {
        const params = {
          message: AdminErrorMessages.error_invite_request_csv_users,
          stack: error
        };
        dispatchPageError(params);
      }
    }
  }

  // Description: Handles the request to remove selected users from the organization.
  async function removeSelectedUsersFromOrg(users: SelectedUser[]) {
    if (account && org_info && _.isArray(users)) {
      const params = {
        userId: account.user_id,
        orgId: org_info.org_id,
      };
      const failed_users_request = [];
      // If there are multiple users selected, we need to make a request for each user.
      await Promise.all(users.map((user) => {
        return deleteOrgUser({ ...params, userToBeDeletedId: user.userEmail }).unwrap().then(() => {
          usersOrgHash.current.delete(user.userEmail);
        }
        ).catch(() => {
          failed_users_request.push(user.userEmail);
        });
      }));

      const success_deleted = users.length - failed_users_request.length;
      if (success_deleted > 0) {
        const users_message_template = success_deleted === users.length ? `${users.length} users` : `${success_deleted} out of ${users.length} users`;
        const successMessage = AdminSuccessMessages.success_request_delete_users.replace(MessageAnchors.message_content, `${users_message_template}`);
        const display_type = success_deleted === users.length ? MessageToastTypes.success : MessageToastTypes.warning;
        dispatch(uiActions.handleSetMessage(new Message(successMessage, MessageHandlerDisplayType.toastr, display_type)));
      }
      else {
        const message = AdminErrorMessages.error_request_delete_user.replace(MessageAnchors.message_content, `${users.length} user${users.length > 1 ? "s" : ""} from organization.`);
        dispatchPageError({ message });
      }

      setSelectedUsers([]);
      fetchOrgData();
    }
  }

  // Description: Handles the request to update the user's role, department or recovery group in the organization.
  async function updateUserInfo(params: UpdateUser) {
    if (account && org_info && params) {
      const { userName } = params;
      const userId = account.user_id;
      const orgId = org_info.org_id;
      const userAddress = params.userEmail;
      const memberId = params.userEmail;
      const stack = [];
      const skipDispatch = true;
      try {
        params?.role && stack.push(updateUserOrgRole({ orgId, userId, memberId, role: params.role, userAddress }));
        params?.department && stack.push(updateUserOrgDept({ orgId, userId, memberId, department: params.department }));
        params?.recoveryGroup && stack.push(assignSelectedRecoveryGroup(params.recoveryGroup, memberId, skipDispatch));

        if (stack.length >= 1) {
          const results = await Promise.allSettled(stack);
          results.forEach((result) => {
            // we'd like to log the errors in case a single or more request fails.
            if (result.status === "rejected") {
              const message = AdminErrorMessages.error_update_user_account.replace(MessageAnchors.user_id, `${userName}`);
              const stack = result.reason;
              dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
            }
          });
          if (results.every((result) => result.status === "fulfilled")) {
            // check if all the requests were successful. If so, dispatch the success message and refresh the users list.
            const successMessage = AdminSuccessMessages.success_update_user_account.replace(MessageAnchors.user_id, `${userName}`);
            dispatch(uiActions.handleSetMessage(new Message(successMessage)));
            fetchOrgData();
          } else {
            // if there are any failed requests, we'll dispatch an error message. But that doesn't mean the other requests failed.
            // we are just going to bring into attention to the user the first request that failed, if there are more errors, they will be logged in the forEach above.
            // we don't want to overwhelm the user with multiple error messages.
            const errorMessage = AdminErrorMessages.error_update_user_account.replace(MessageAnchors.user_id, `${userName}`);
            const params = {
              message: errorMessage,
              stack: results.find((result) => result.status === "rejected"),
            };
            dispatchPageError(params);
          }
        }
      } catch (error) {
        const message = AdminErrorMessages.error_update_user_account.replace(MessageAnchors.user_id, `${userName}`);
        const params = {
          message,
          stack: error
        };
        dispatchPageError(params);
      }
    }
  }

  // Description: when a single user is selected, it will add the user to the selected users list.
  function singleSelectUser(params: { e: FocusEvent<HTMLInputElement>, user: SelectedUser }) {
    const { e, user } = params;
    const { checked } = e.target;
    if (checked) {
      const users = [...selected_users, user];
      verifyPendingInvites(user, checked, users);
      setSelectedUsers(users);
    } else if (!checked) {
      const filteredUsers = selected_users.filter((_user) => _user.userEmail !== user.userEmail);
      verifyPendingInvites(user, checked, filteredUsers);
      setSelectedUsers(filteredUsers);
    }
  }

  // Description: when the select all checkbox is checked, it will add all users to the selected users list.
  function multipleSelectUsers(e: FocusEvent<HTMLInputElement>) {
    const { checked } = e.target;
    if (checked) {
      setSelectedUsers([...filteredUsers]);
    } else if (!checked) {
      setSelectedUsers([]);
    }
  }

  // Description: Handling multiple modals state, if not selection/param is passed
  // it will default to the add users modal.
  function handleModals(selection?: string) {
    if (selection && selection === "resend-invite") {
      if (!show_resend_invites_modal) {
        setShowResendInvitesModal(true);
      } else if (show_resend_invites_modal) {
        setShowResendInvitesModal(false);
      }
    } else if (selection && selection === "set-recovery-group") {
      setShowSetRecoveryGroupModal(!show_set_recovery_group_modal);
    } else {
      setShowAddUsersModal(!show_add_users_modal);
    }
  }

  const AdminUsersRequests = {
    handleSetSort: (params: "userName" | "userEmail" | "department" | "recoveryGroup") => {
      // TODO: New feature, we'll sort the users by status in the future.
      const direction = sort.field === params ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending;
      const sorted_requests = sortBy(users_list, { field: params, direction });
      setSort({ field: params, direction });
      setUsersList(sorted_requests);
    },
    handleGetAddressesRequest: () => {
      fetchOrgData();
    },
    handleAddressSearchParam: (param: string) => {
      setFilterParam(param);
    },
    handleShowModal: (params?: string) => {
      handleModals(params);
    },
    handleSubmitUserInvite: (user: AdminUserExternalInvite[]) => {
      sendUsersInvite(user);
    },
    handleSubmitSubsumeUserInvite: (params: any) => {
      sendSubsumeInvite(params);
    },
    handleUpdate: (params: UpdateUser) => {
      updateUserInfo(params);
    },
    handleSubmitCsvData: (params: { subsumeAccounts: AdminUserSubsumeInvite[], externalAccounts: AdminUserExternalInvite[] }) => {
      sendInvitesFromCsv(params);
    },
    handleSetRecoveryGroup: (params: { recoveryGroup: AdminRecoveryGroup }) => {
      const { recoveryGroup } = params;
      // Function that will handle the request to assign the recovery group to the selected users.
      assignSelectedRecoveryGroup(recoveryGroup);
    },
    handleResendInvite: () => {
      const resendInvite = true;
      sendUsersInvite(users_with_pending_invites, resendInvite);
    },
    handleExportCSV: () => {
      handleExportUsersList();
    },
    handleSingleSelect: (params: { e: FocusEvent<HTMLInputElement>, user: SelectedUser }) => {
      singleSelectUser(params);
    },
    handleMultiSelect: (e: FocusEvent<HTMLInputElement>) => {
      multipleSelectUsers(e);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      const { message, stack } = params;
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    },
    handleDelete: () => {
      confirmDeleteSelectedUsersDialog();
    },
    handleReset: () => {
      // Reset Filter Param if user click (x) input field.
      setFilterParam("");
    }
  };

  // Description: Confirm dialog for deleting selected users.
  function confirmDeleteSelectedUsersDialog() {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_delete_admin_users.body.replace(
        MessageAnchors.message_content,
        selected_users.length > 1
          ? `these <b>${selected_users.length}</b> accounts?`
          : `<b>${selected_users[0].userName}'s</b> account (<b>${selected_users[0].userEmail}</b>)?`
      ),

      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_delete_admin_users.title,
      {
        label: "Yes",
        data: true,
        action: () => removeSelectedUsersFromOrg(selected_users)
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AdminUsersRequests as any)[callback] instanceof Function) {
      (AdminUsersRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      AdminUsersRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  let selectAllCheckboxState = CheckboxStates.empty as CheckboxStatesTypes;
  if (users_list.length === selected_users.length && selected_users.length > 0) {
    selectAllCheckboxState = CheckboxStates.checked;
  } else if (selected_users.length < users_list.length && selected_users.length !== 0) {
    selectAllCheckboxState = CheckboxStates.indeterminate;
  }


  const isSelectedUsersListEmpty = selected_users.length === 0;
  const isSelectedAllWithPendingInvites = verifySelectAllPendingInvites(selected_users as AdminUserEntity[]);
  // We want to disable the set recovery group button if there are not active selected users.
  // And if there are any pending users selected, we want to disable the recovery group button.
  // And set a tooltip message for the recovery button telling the user why they can't apply the recovery group to the selected users.
  const setRecoveryGroupMessage = users_with_pending_invites.length >= 1 || isSelectedAllWithPendingInvites ? "Recovery Groups cannot be set on Pending Acounts" : "Set Recovery Group For Selected Account";
  const isSetRecoveryGroupButtonDisabled = isSelectedUsersListEmpty || users_with_pending_invites.length >= 1 || isSelectedAllWithPendingInvites;
  const total_users_label = users_list.length === 1 ? "User" : "Users";
  return (
    <CoverTemplate className="admin-wrapper">
      <PageHeader>
        <h1>Users</h1>
      </PageHeader>
      <AdminToolbar
        is_loading={is_loading}
        is_recovery_group_button_disable={isSetRecoveryGroupButtonDisabled}
        is_resend_invite_button_disable={is_resend_invite_button_disable}
        is_delete_disable={isSelectedUsersListEmpty}
        handleActions={handlePageActions}
        total_items_filtered={filteredUsers.length}
        total_items={users_list.length}
        filter_param={filter_param}
        label={total_users_label}
        button_title="Add Users"
        set_recovery_group_message={setRecoveryGroupMessage}
        is_admin_user_page={true}
      />

      <AddUsersModal
        current_account={account}
        submit_success={submit_success}
        orgUsers={usersOrgHash.current}
        show_modal={show_add_users_modal}
        local_users_accounts_map={local_users_accounts_map}
        handleActions={handlePageActions}
      />

      <ResendInvitesModal
        show_modal={show_resend_invites_modal}
        handleActions={handlePageActions}
        users={users_with_pending_invites}
      />

      <SetRecoveryGroupModal
        show_modal={show_set_recovery_group_modal}
        recovery_groups={recovery_groups}
        handleActions={handlePageActions}
        users={selected_users}
      />

      {is_loading ? (
        <div className="cover-content">
          <div className="admin-panel-center">
            <Loading className="in-place" />
          </div>
        </div>
      ) : (
        <AdminUsersListPanel
          sort={sort}
          allSelectState={selectAllCheckboxState}
          filtered_users_list={filteredUsers}
          selected_users_list={selected_users}
          recovery_groups={recovery_groups}
          handleActions={handlePageActions}
          allow_add_devices={allow_add_devices}
          add_device_overrides={add_device_overrides}
        />
      )}
    </CoverTemplate>
  );
}

export default React.memo(AdminUsersComponent);
