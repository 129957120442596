import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import keyMirror from "keymirror";
import { action } from "typesafe-actions";
import { MessageItem, ErrorMessageItem } from "@preveil-api";
import { Message, MessageToastTypes, PanelState, PanelStateType, MobileMenuDisplay } from "src/common";

export interface UIManagerState {
  loading: boolean;
  progress?: number; // Will need more data (id or a form that will allow for multiple instances)
  is_dirty: boolean;
  message_item?: MessageItem;
  errors?: ErrorMessageItem;
  active_status_code?: string, // TEMPORARY
  redirect_url?: string;
  document_title: string;
  right_sidebar_state: PanelStateType;
  left_nav_state: PanelStateType;
  upload_progress_hidden: boolean;
  upload_folder_ids: string[];
}

export const UIManagerActionTypes = keyMirror({
  FETCH_REQUEST: null, // before request
  FETCH_SUCCESS: null, // request is successful
  FETCH_ERROR: null, // request failed
  FETCH_ERROR_DISMISS: null,
  FETCH_COMPLETE: null, // after request completes
  TOGGLE_LEFT_NAVIGATION: null,
  SET_REDIRECT_URL: null,
});

// ----------------------------------------------------------------------
// Initial State and Slices
// ----------------------------------------------------------------------
const initialState: UIManagerState = {
  loading: false,
  is_dirty: false,
  errors: undefined,
  message_item: undefined,
  redirect_url: undefined,
  document_title: "",
  right_sidebar_state: PanelState.off,
  left_nav_state: MobileMenuDisplay ? PanelState.off : PanelState.on,
  upload_progress_hidden: true,
  upload_folder_ids: [],
};

export const uiManagerSlice = createSlice({
  name: "ui",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    handleSetLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    handleSetDocumentTitle: (state, action: PayloadAction<string>) => {
      state.document_title = action.payload;
    },
    handleSetRedirectUrl: (state, action: PayloadAction<string | undefined>) => {
      state.redirect_url = action.payload;
    },
    handleToggleRightSidebar: (state, action: PayloadAction<PanelStateType>) => {
      state.right_sidebar_state = action.payload;
    },
    handleToggleLeftNavigation: (state, action: PayloadAction<PanelStateType>) => {
      state.left_nav_state = action.payload;
    },
    setProgress: (state, action: PayloadAction<number | undefined>) => {
      state.progress = action.payload;
    },
    setUploadProgressHidden: (state, action: PayloadAction<boolean>) => {
      state.upload_progress_hidden = action.payload;
    },
    setUploadFolderIds: (state, action: PayloadAction<string[]>) => {
      state.upload_folder_ids = action.payload;
    },
    handleSetMessage: {
      reducer: (state, action: PayloadAction<MessageItem>) => {
        return { ...state, ...{ message_item: action.payload, errors: initialState.errors } };
      },
      prepare: (message: Message) => {  // Parse Message to MessageItem:
        return { payload: message.messageItem };
      }
    },
    handleRequestErrors: {
      reducer: (state, action: PayloadAction<ErrorMessageItem>) => {
        return { ...state, ...{ errors: action.payload, message_item: initialState.message_item } };
      },
      // Description and Notes: If stack is passed then add to model
      // If type is NOT SPECIFIED then set to danger / error - Remove default (light) and set to danger
      prepare: (message: Message, stack?: any) => {
        message.set_type(MessageToastTypes.error);
        const error_item = Object.assign(message.messageItem, { stack });
        return { payload: error_item };
      },
    },
    handleSetActiveStatusCode: (state, action: PayloadAction<string | undefined>) => {
      state.active_status_code = action.payload;
    },
    handleMessageDismiss: (state) => {
      return {
        ...state,
        ...{ errors: initialState.errors, message_item: initialState.message_item },
      };
    },
    handleSetDirtyMessage: (state, action: PayloadAction<boolean>) => {
      state.is_dirty = action.payload;
    },
    destroyUIManager: () => {
      return initialState;
    },
  },
});

//  Description: Actions for UI Manager: example:
const uiOnBeforeRequest = () => action(UIManagerActionTypes.FETCH_REQUEST);
const uiOnCompleteRequest = () => action(UIManagerActionTypes.FETCH_COMPLETE);
const onLeftNavToggle = (isOpened: boolean) =>
  action(UIManagerActionTypes.TOGGLE_LEFT_NAVIGATION, isOpened);
const uiSagaActions = {
  uiOnBeforeRequest,
  uiOnCompleteRequest,
  onLeftNavToggle,
};

// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
// export const uiActions = uiManagerSlice.actions;
export const uiActions = { ...uiManagerSlice.actions, ...uiSagaActions };
export default uiManagerSlice.reducer;
