import { ActionHandlerFunction, IActionHandler } from "@preveil-api";
import React, { useEffect, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import { ComposeModalSize, ComposeModalSizeType, ProgressTracker, UploadUIActionTypes, useAppDispatch, useAppSelector } from "src/common";
import { Icon } from "src/components";
import { ProgressRow } from "../..";
import _ from "lodash";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";

type AllProps = {
  progress: ProgressTracker[];
  handleAction: ActionHandlerFunction;
}

function UploadProgressComponent(props: AllProps) {
  const { progress, handleAction } = props;
  const [show, setShow] = useState<boolean>(false);
  const [modal_size, setModalSize] = useState<ComposeModalSizeType>(ComposeModalSize.lg);
  const [completed_uploads, setCompletedUploads] = useState<number>(0);
  const [total_uploads, setTotalUploads] = useState<number>(0);
  const [renaming, setRenaming] = useState(new Map<string, string>());
  const upload_folder_ids = useAppSelector((state: RootState) => state.ui.upload_folder_ids);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (completed_uploads > 0) {
      const group = _.groupBy(progress, "destination.id");
      _.each(_.toArray(group), function (v) {
        const completed_files = v.filter(t => t.done === t.total).length;
        if (completed_files === v.length) {
          dispatch(uiActions.setUploadFolderIds(upload_folder_ids.filter(id => id !== v[0].destination.id)));
        }
      });
    }
  }, [completed_uploads]);

  useEffect(() => {
    if (progress.length > 0) {
      setTotalUploads(progress.length);
      setCompletedUploads(progress.filter(t => t.done === t.total).length);
      setShow(true);
    } else {
      show && setShow(false);
    }
  }, [progress]);

  useEffect(() => {
    show && setModalSize(ComposeModalSize.lg);
  }, [show]);

  const allowSubmit = (file_id: string, entries?: string[]) => {
    const target_directory = progress.find(t => t.file_id === file_id)?.destination.directory;
    const existing_files = entries || target_directory?.entries.map(e => e.name);
    const new_name = renaming.get(file_id);
    return !!new_name && new_name !== "" && !existing_files?.includes(new_name);
  };

  function deleteRename(renaming: Map<string, string>, file_id: string): Map<string, string> {
    const updated_renaming = new Map(renaming);
    updated_renaming.delete(file_id);
    return updated_renaming;
  }

  const UploadProgressRequests = {
    handleSubmitRename: (tracker: ProgressTracker) => {
      const file_id = tracker.file_id;
      if (!allowSubmit(file_id, tracker.entries)) return;
      const name = renaming.get(file_id);
      setRenaming(renaming => deleteRename(renaming, file_id));
      !!name && handleAction({ actionType: UploadUIActionTypes.Rename, params: { file_id, name } });
    },
    handleUpdateRename: (params: { tracker: ProgressTracker, name: string }) => {
      const { tracker, name } = params;
      setRenaming(renaming => {
        const updated_renaming = new Map(renaming);
        updated_renaming.set(tracker.file_id, name);
        return updated_renaming;
      });
    },
    handleCancelUpload: (file_id: string) => {
      setRenaming(renaming => deleteRename(renaming, file_id));
      handleAction({ actionType: UploadUIActionTypes.Cancel, params: file_id });
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((UploadProgressRequests as any)[callback] instanceof Function) {
      (UploadProgressRequests as any)[callback](actionObj.params);
    } else {
      // NOTE: If not found here propagate to parent
      handleAction(actionObj);
    }
  }

  return (
    <Offcanvas
      className={`upload-progress ${modal_size}`}
      backdrop={false}
      show={show}
      enforceFocus={false}
      placement="bottom">
      <Offcanvas.Header>
        <Offcanvas.Title>
          {`Encrypted and uploaded ${completed_uploads} out of ${total_uploads}`}
        </Offcanvas.Title>
        <div className="btns">
          <Button variant="transparent" onClick={() => (
            modal_size === ComposeModalSize.minimized ? setModalSize(ComposeModalSize.lg) : setModalSize(ComposeModalSize.minimized)
          )}>
            {modal_size === ComposeModalSize.minimized ? <Icon className="ficon-maximize-2" /> : <Icon className="pv-icon-minimize" />}
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {modal_size === ComposeModalSize.lg && (
          <div className="py-1">
            {progress.map((tracker, index) => (
              <div key={index} className="align-items-center">
                <ProgressRow
                  tracker={tracker}
                  renaming={renaming}
                  allowSubmit={allowSubmit}
                  handleAction={handlePageActions}
                />
                {index !== progress.length - 1 && (
                  <hr className="my-1" />
                )}
              </div>
            ))}
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(UploadProgressComponent);
