import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AdminUIActionTypes, AdminErrorMessages } from "src/common";
import { ActionHandlerFunction, OSInfo } from "@preveil-api";
import { preVerifyStartExport } from "../utility";

type AllProps = {
  os_info: OSInfo | null | undefined;
  show: boolean;
  handleActions: ActionHandlerFunction;
}

function DataExportLocationModal({ os_info, show, handleActions }: AllProps) {
  const [drop_dir, setDropDir] = useState("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    setDropDir(value);
  };

  useEffect(() => {
    // Making sure the state of drop_dir is empty when the modal is closed
    if (!show && !!drop_dir) {
      setDropDir("");
    }
  }, [show]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file_location = preVerifyStartExport(drop_dir, os_info);
    if (!file_location) {
      const message = AdminErrorMessages.error_data_export_illegal_file;
      const stack = drop_dir;
      handleActions({ actionType: AdminUIActionTypes.PageError, params: {message, stack } });
    } else {
      handleActions({ actionType: AdminUIActionTypes.StartDataExport, params: drop_dir });
    }
  };

  function handleCancel() {
    setDropDir("");
    handleActions({ actionType: AdminUIActionTypes.ShowModal });
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => handleActions({ actionType: AdminUIActionTypes.ShowModal })}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="export-location">
          <p>Enter a file path to any folder to which you have write permissions. The file path needs to end with a new folder that will be created to hold the data. Your exported data will be copied to that location.</p>
          <p>(eg): {os_info?.data_export_example_path} </p>
        </div>
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>File Path</Form.Label>
            <Form.Control
              value={drop_dir}
              onChange={handleChange}
              type="text"
              placeholder="Enter file path"
              required
            />
          </Form.Group>
          <footer className="mt-3">
            <Button
              type="submit"
              variant="primary"
              disabled={!drop_dir}
            >
              Start Export
            </Button>
            <Button variant="no-outline-primary" onClick={handleCancel}>Cancel</Button>
          </footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(DataExportLocationModal);