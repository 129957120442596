/*
* Description: Crypto API endpoints for sagas
* Author:      PreVeil, LLC
*/
import { CreateUserData, IFetchResponse, LocalUser, Signature, GetThreadsParams } from "@preveil-api";
import {
  DeleteEmailsApiArg, DeleteEmailsApiResponse, SetEmailFlagApiArg, GetPaginatedThreadsRemoteApiResponse, SetEmailFlagApiResponse,
  MoveEmailsToMailboxApiArg
} from "./crypto-rtk-api";
import { Account, AccountIdentifiers, Helpers, BaseAPI, parseGetAppThreads } from "src/common";
const base_url = `${process.env.REACT_APP_CRYPTO_SERVER}`;
// --------------------------------------------------------------------------------------------
// Saga calls
// --------------------------------------------------------------------------------------------
/*
 * Description: set App Config from env variables ***** Working *****
 * Request: GET; no params
 * Response: { ... }
 */
const getAppConfiguration = (): Promise<IFetchResponse> => {
  return BaseAPI.get(`${base_url}/get/buildversion`);
}

/*
* Description: Verify Crypto Status (legacy: verifyCryptoStatus)
* Request: GET no params
* Response: {"cs_connection": boolean}
*/
const getCryptoStatus = (): Promise<IFetchResponse> => {
  return BaseAPI.get(`${base_url}/ping`);
}

/*
 * Description: Gets a list of local accounts from crypto
 * Request: Optional param user_id
 * Response: if !!user_id = Sends user_id to crypto for misc and gets a list of local accounts / users from crypto
 *           else returns an array of users - users: Array<LocalUser>
 * Notes: Parse LocalUser data to create a local user - return type IFetchResponse
 *        previously getLocalUsers = gets a list of local user accounts
 *        optional call to {crypto}/updateAndListLocalUsers
 */
const getLocalAccounts = (user_id?: string): Promise<IFetchResponse> => {
  const url = !!user_id ? `${base_url}/get/users/${encodeURIComponent(user_id)}/localusers` :
    `${base_url}/get/account/list/local`;
  return BaseAPI.put(url)
    .then(async (response: IFetchResponse) => {
      if (response.isError) { return response; }
      const users: LocalUser[] = response.data.users;
      return (!!users && Array.isArray(users) && users.length > 0) ?
        {
          ...response, data: {
            users: await Promise.all(users.map(async (user) => {
              return await Account.initAccount(user);
            }))
          }
        } : response;
    });
}

/*
 * Description: Sets up a new local account in crypto
 * Request: user_id: string; secret: string; key_version: string; display_name?: string
 * Response: returns an array of users - users: Array<LocalUser>
 * Notes: Parse LocalUser (JSONLocalUser) data to create a local user - return type IFetchResponse
 *        previously getLocalUsers = gets a list of local user accounts
 *        optional call to {crypto}/updateAndListLocalUsers
 */
const setupNewAccount = (createUserData: CreateUserData): Promise<IFetchResponse> => {
  const url = `${base_url}/put/account/${encodeURIComponent(createUserData.user_id)}`;
  return BaseAPI.put(url, {
    secret: createUserData.secret,
    key_version: createUserData.key_version,
    display_name: createUserData.display_name
  })
    .then(async (response: IFetchResponse) => {
      if (response.isError) { return response; }
      const user: LocalUser = response.data;
      return !!user ? { ...response, data: { current_account: await Account.initAccount(user) } } : response;
    });
}

/*
 * Description: Upgrade an existing user account to crypto from new express Accounts
 * Request: UpgradeExpressAccountApiArg
 * Response: UpgradeExpressAccountApiResponse
 */
const upgradeExpressAccount = (account_ids: AccountIdentifiers): Promise<IFetchResponse> => {
  const url = `${base_url}/account/${encodeURIComponent(account_ids.user_id)}/upgrade`;
  return BaseAPI.put(url, {
    device_id: account_ids.device_id,
    user_key: account_ids.user_key_serialized,
    device_key: account_ids.device_key_serialized
  });
}

/*
* Description: Sign with User Key in Crypto
* Request: message to sign 
* Response: SignWithUserKeyApiResponse =>  {key_version: number; signature: string;}
*/
const signWithUserKey = (message: Uint8Array, user_id: string, key_version: number = -1): Promise<Signature> => {
  return BaseAPI.put(`${base_url}/post/${user_id}/sign`, { plaintext: Helpers.b64Encode(message) })
    .then(response => {
      return { signature: Helpers.b64Decode(response.data.signature) } as Signature
    });
}

/*
* Description: Sign with User Key in Crypto
* Request: message to sign 
* Response: SignWithUserKeyApiResponse =>  {key_version: number; signature: string;}
*/
const signWithDeviceKey = (message: Uint8Array, user_id: string): Promise<Signature> => {
  return BaseAPI.put(`${base_url}/post/${user_id}/device/sign`, { plaintext: Helpers.b64Encode(message) })
    .then(response => {
      return { signature: Helpers.b64Decode(response.data.signature) } as Signature
    });
}

/*
* Description: decrypt with Crypto
* Request: cipher to decrypt
* Response:  {plaintext: Uint8Array;}
*/
const decrypt = (cipher: Uint8Array, user_id: string, key_version: number = -1): Promise<Uint8Array> => {
  return BaseAPI.put(`${base_url}/post/${user_id}/decrypt`, { ciphertext: Helpers.b64Encode(cipher), decrypt_key_version: key_version })
    .then(response => { // NOTE: need to return Promise<Uint8Array>
      return (typeof response.data.plaintext === "string") ? Helpers.b64Decode(response.data.plaintext) :
        response.data.plaintext as Uint8Array;
    });
}

/*
* Description: encrypt with Crypto
* Request:  
    encrypt_for_user_id = j.get("encrypt_for"),
    encrypt_for_key_version = j.get("encrypt_for_key_version", -1)
    plaintext = j.get("plaintext")
* Response:  { 
    "ciphertext" : b64enc(ciphertext),
    "encrypt_key_version" : encrypt_for.public_user_key.key_version,: string;
*/
const encrypt = (plaintext: Uint8Array, user_id: string, key_version: number = -1): Promise<Uint8Array> => {
  return BaseAPI.put(`${base_url}/post/${user_id}/encrypt`, {
    encrypt_for: user_id,
    encrypt_for_key_version: key_version,
    plaintext: Helpers.b64Encode(plaintext)
  })
    .then(response => { return response.data.ciphertext as Uint8Array });
}

/*
* Description:  For Developers to test device key rotation
*/
const triggerDeviceKeyRotation = (user_id: string): Promise<IFetchResponse> => {
  return BaseAPI.put(`${base_url}/user/${user_id}/device/refresh`);
}

/*
* Description:   GET Mail Paginated threads by collID and MailboxId
* Returns:       Process returned data int UI models
*/
const getPaginatedThreadsRemote = (params: GetThreadsParams): Promise<IFetchResponse> => {
  const url = `/get/mail/${encodeURIComponent(params.user_id)}/mailboxes/${params.mailbox_id}/threads`;
  return BaseAPI.put(`${base_url}${url}`, { limit: params.limit, offset: params.offset })
    .then((response: IFetchResponse) => {
      return (!response.isError && !!response.data) ? {
        ...response,
        data: parseGetAppThreads(response.data as GetPaginatedThreadsRemoteApiResponse, params.mailbox_id)
      } : response;
    })
}

/*
* Description: GET Mail Paginated threads by collID and MailboxId (deprecated only used as backup)
* Returns:       Process returned data int UI models
*/
const getPaginatedThreads = (params: GetThreadsParams): Promise<IFetchResponse> => {
  const mailboxData = [{
    id: params.mailbox_id,
    limit: params.limit,
    offset: params.offset
  }];
  const url = `/mail/${encodeURIComponent(params.user_id)}`;
  return BaseAPI.put(`${base_url}${url}`, mailboxData)
    .then((response: IFetchResponse) => {
      return (!response.isError && !!response.data) ? {
        ...response,
        data: parseGetAppThreads(response.data as GetPaginatedThreadsRemoteApiResponse, params.mailbox_id)
      } : response;
    });
}

/*
* Description: Update Flags through crypto
* Returns:      SetEmailFlagApiResponse
*/
const updateFlags = (user_id: string, updates: SetEmailFlagApiArg, remove: boolean = false): Promise<IFetchResponse> => {
  const url = remove ? `${base_url}/delete/account/${encodeURIComponent(user_id)}/emails/flag` :
    `${base_url}/put/account/${encodeURIComponent(user_id)}/emails/flag`;
  return BaseAPI.put(url, updates)
    .then((response: IFetchResponse) => ((!response.isError && !!response.data) ? { ...response, data: response.data as SetEmailFlagApiResponse } : response))
}

/*
* Description:  Delete mail threads
* Returns:      DeleteEmailsApiResponse
*/
const deleteMailThreads = (params: DeleteEmailsApiArg): Promise<IFetchResponse> => {
  const url = `${base_url}/delete/account/${encodeURIComponent(params.userId)}/emails/`;
  return BaseAPI.put(url, params)
    .then((response: IFetchResponse) => ((!response.isError && !!response.data) ?
      { ...response, data: response.data as DeleteEmailsApiResponse } : response))
}

/*
* Description:  Move mail threads
* Returns:      DeleteEmailsApiResponse
*/
const moveMailThreads = (params: MoveEmailsToMailboxApiArg): Promise<IFetchResponse> => {
  const url = `${base_url}/post/account/${encodeURIComponent(params.userId)}/copy`;
  return BaseAPI.put(url, {
    email_ids: params.email_ids,
    dest_mailbox_id: params.dest_mailbox_id,
    src_mailbox_id: params.src_mailbox_id,
    trash_source: true
  })
    .then((response: IFetchResponse) => ((!response.isError && !!response.data) ?
      { ...response, data: response.data as DeleteEmailsApiResponse } : response))
}

export const CryptoAPI = {
  getCryptoStatus,
  getLocalAccounts,
  getAppConfiguration,
  setupNewAccount,
  upgradeExpressAccount,
  signWithUserKey,
  signWithDeviceKey,
  decrypt,
  encrypt,
  triggerDeviceKeyRotation,
  getPaginatedThreadsRemote,
  getPaginatedThreads,
  updateFlags,
  deleteMailThreads,
  moveMailThreads
}