import React, { useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler, TreeItem } from "@preveil-api";
import {
  Account, buildCustomDriveTree, COLLECTION_PROTOCOL_VERSIONS, DirectoryEntity, DriveConstants, DriveErrorMessages,
  DriveSuccessMessages, DriveUIActionTypes, EntryItem, GlobalErrorMessages, Message, MessageAnchors, MessageHandlerDisplayType,
  TreeItemType, useAppDispatch, useAppSelector, useFetchDirectory, useFetchLinkNodeId, useMove
} from "src/common";
import { Icon, Loading, Tree } from "src/components";
import { RootState } from "src/store/configureStore";
import { uiActions } from "src/store";

type AllProps = {
  current_directory: DirectoryEntity;
  count: number;
  handleAction: ActionHandlerFunction;
  current_account: Account;
  move_info?: { items: string[]; destination: EntryItem };
};

// Description: Component for Moving selected items within Drive.
// Current Move Restrictions: 
// - We CANNOT move any item INTO or OUT OF a shared folder (link - v1 or v2) OR acl node (for permissions reasons) 
// - The only destination type possible is a NORMAL FOLDER
// - we can move ANY ITEM into a NORMAL FOLDER (links and acl nodes can also be moved)
function MoveComponent(props: AllProps) {
  const { current_directory, count, handleAction, current_account, move_info } = props;
  const [fetching_children, setFetchingChildren] = useState<boolean>(false);
  const [active, setActive] = useState<string | undefined>();
  const [is_moving, setIsMoving] = useState<boolean>(false);
  const [root_tree, setRootTree] = useState<TreeItem>();
  const [tree_item, setTreeItem] = useState<TreeItem>();
  const [tree, setTree] = useState<TreeItem[]>();
  const [destination, setDestination] = useState<TreeItem>();
  const breadcrumbs = useAppSelector((state: RootState) => state.drive.breadcrumbs);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const selected = useAppSelector((state: RootState) => state.drive.selected);
  const { directory, fetchDirectory, resetDirectory, error: dir_error } = useFetchDirectory(current_account);
  const { move, error: move_error, errors, successes, done, resetMove } = useMove(current_account);
  const { fetchLinkNodeId, link_node_id, resetLinkNodeId } = useFetchLinkNodeId(current_account);
  const dispatch = useAppDispatch();

  // for drag and drop
  useEffect(() => {
    if (!!move_info) {
      sendMoveAction(move_info);
    }
  }, [move_info]);

  // Description: On load - build tree for current directory as well as root tree (if user is within a collection)
  // Instead of displaying "My PreVeil", if the user is within a collection, the root tree will be the collection tree.
  useEffect(() => {
    if (!move_info) {
      const _tree = buildCustomDriveTree(current_directory, selected);
      !!_tree && setTree(_tree);
      if (!!root_info && root_info.id !== current_directory.id && current_directory.collection_id === root_info.collection_id) { // if we are not under a shared folder
        fetchDirectory(root_info.collection_id, root_info.id);
      } else if (!!root_info && root_info.id !== current_directory.id && current_directory.collection_id !== root_info.collection_id) {
        const protocol_version =
          !!current_directory.maintainer_id && current_directory.maintainer_id.length > 0
            ? COLLECTION_PROTOCOL_VERSIONS.V2
            : COLLECTION_PROTOCOL_VERSIONS.V1;
        fetchLinkNodeId(current_directory.collection_id, protocol_version); // hook to fetch the link node id instead of calling usefetchlink which has more calls than we need for this case
      }
    }
  }, []);

  // Description: handles the success of useFetchLinkNodeId hook and calls fetchDirectory with that node id.
  useEffect(() => {
    if (!!link_node_id) {
      fetchDirectory(current_directory.collection_id, link_node_id);
    }
    resetLinkNodeId();
  }, [link_node_id]);

  // Description: Build tree structure on fetch Directory success or error
  useEffect(() => {
    if (!!directory) {
      const under_my_preveil = !!root_info && current_directory.id === root_info.id;
      // If the custom root tree is not populated yet, it populates it here
      if (!root_tree && !under_my_preveil) {
        const is_root = !!root_info && current_directory.collection_id === root_info.collection_id; // if the user is not inside a collection 
        const parent_path = !!is_root ? DriveConstants.ROOT_DIRECTORY : !!breadcrumbs ? breadcrumbs[0].path : "";
        const children = buildCustomDriveTree(directory, selected, parent_path, current_directory.id);
        const root = {
          id: !!is_root && !!root_info ? root_info.id : directory.id,
          name: parent_path,
          type: TreeItemType.folder,
          path: parent_path,
          children: !!children && children.length > 0 ? children : undefined,
        };
        setRootTree(root);
      } else { // This is for setting the tree_items children when a node is opened (it fetches the children of the node)
        if (!!tree_item) {
          const children = buildCustomDriveTree(directory, selected, tree_item.path);
          if (!!children && children.length > 0) {
            tree_item.children = children;
          }
          tree_item.children_loading = false;
        }
        setFetchingChildren(false);
      }
      resetDirectory();
    }
  }, [directory, dir_error]);

  // Description: Handles the success and error messages from the useMove hook.
  useEffect(() => {
    const name = !!move_info ? move_info.destination.name : (!!destination ? destination.name : "");
    if (done) {
      if (errors.length === 0) {
        if (successes.length === 1) {
          dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_moving_one.replace(MessageAnchors.folder_name, name).replace(MessageAnchors.move_name, successes[0].name))));
        } else {
          dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_moving_many.replace(MessageAnchors.folder_name, name).replace(MessageAnchors.item_count, successes.length.toString()))));
        }
      } else if (successes.length === 0) {
        if (errors.length === 1) {
          const response = errors[0];
          const entry = response.entry;
          if (response.conflict && !!entry) {
            dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.conflict_while_moving.replace(MessageAnchors.folder_name, name).replace(MessageAnchors.move_name, entry.name))));
          } else {
            !!entry && dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_moving_one_item.replace(MessageAnchors.folder_name, name).replace(MessageAnchors.move_name, entry.name))));
            !entry && dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_moving_general)));
          }
        } else {
          dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_moving_all_items.replace(MessageAnchors.folder_name, name).replace(MessageAnchors.item_count, errors.length.toString()))));
        }
      } else {
        dispatch(
          uiActions.handleRequestErrors(
            new Message(
              DriveErrorMessages.error_moving_some_items
                .replace(MessageAnchors.folder_name, name)
                .replace(MessageAnchors.item_count, errors.length.toString())
                .replace(MessageAnchors.total_count, selected.length.toString()),
            ),
          ),
        );
      }
      handleAction({ actionType: DriveUIActionTypes.Refresh });
      handleHideModal();
    }
    if (!!move_error) {
      !!destination && dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_moving_all_items.replace(MessageAnchors.folder_name, destination.name).replace(MessageAnchors.item_count, "selected"))));
      handleHideModal();
    }
    setIsMoving(false);
  }, [done, move_error]);

  // Description: Set Dirty Warning based on is_moving
  useEffect(() => {
    dispatch(uiActions.handleSetDirtyMessage(is_moving));
  }, [is_moving]);

  // Description: gets the entry_items of the selected list and calls either the web hook or the app function 
  // based on web mode or app mode.
  function sendMoveAction(move_info?: { items: string[]; destination: EntryItem }) {
    resetMove();
    if (!!move_info) { // moving items through drag and drop
      move(current_directory.collection_id, current_directory, move_info.destination.id, move_info.items);
    } else {
      if (!!destination) {
        const _collection_id = destination.name === DriveConstants.ROOT_DIRECTORY && !!root_info ? root_info.collection_id : current_directory.collection_id;
        if (!!destination.id) {
          setIsMoving(true);
          move(_collection_id, current_directory, destination.id, selected);
        }
      }
    }
  }

  // Description: Handle states of modal.
  function handleHideModal() {
    handleAction({ actionType: DriveUIActionTypes.MoveModal, params: { show: false } });
    dispatch(uiActions.handleSetDirtyMessage(false)); // just in case ***** TODO REmove this when App on complete is handled
  }

  // Description: Function for the path list of the destination folder
  function prettyBreadcrumbs(destination_name: string) {
    return destination_name.replaceAll("/", "<i class='ficon-chevron-right'></i>");
  }

  const MoveDriveRequests = {
    // Description: Sets the active item as well as the destination (on click of a node)
    handleTreeNodeClick: (item: TreeItem) => {
      if (!!item.type) {
        !!item.id ? setActive(item.id) : setActive(undefined);
        setDestination(item);
      }
    },
    // Description: On click of the chevron, fetches the nodes children through fetch directory
    handleFetchTreeNodeChildren: (item: TreeItem) => {
      if (!!item.id) {
        setTreeItem(item);
        item.children_loading = true;
        setFetchingChildren(true);
        fetchDirectory(current_directory.collection_id, item.id);
      }
    },
    handlePageErrorMessage: (params: { message: string; stack?: any }) => {
      dispatch(
        uiActions.handleRequestErrors(
          new Message(params.message, MessageHandlerDisplayType.logger),
          params.stack,
        ),
      );
    },
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((MoveDriveRequests as any)[callback] instanceof Function) {
      (MoveDriveRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      MoveDriveRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <>
    {!move_info && <Modal
      size="lg"
      dialogClassName="move-modal"
      show={true}
      onHide={() => handleHideModal()}
      aria-labelledby="Move Modal Drive"
    >
      <Modal.Header className="align-items-start" closeButton>
        <p>
          Move <b>{`${count} Selected Item${count > 1 ? "s" : ""}`}</b>{" "}
          {!!destination?.name && (
            <>
              to:{" "}
              <span
                className="text-link"
                dangerouslySetInnerHTML={{ __html: prettyBreadcrumbs(destination.path) }}
              />
            </>
          )}
        </p>
      </Modal.Header>
      <Modal.Body>
        {(!!root_info && current_directory.id === root_info.id) && !root_tree && (!!tree && tree.length === 0) && <p>No eligible subfolders in this folder</p>}
        {is_moving && <Loading className="in-place" noLogo={true} />}
        {!is_moving &&
          <div className="move-folders ps-0">
            {!!root_tree && current_directory.id !== root_tree.id && (
              <Tree
                className="move-tree"
                data={[root_tree]}
                handleAction={handlePageActions}
                readonly
                active={active}
                fetching_children={fetching_children}
                drive={true}
              />
            )}
            {!!tree && (
              <Tree
                readonly
                className="move-tree"
                active={active}
                data={tree}
                handleAction={handlePageActions}
                fetching_children={fetching_children}
                drive={true}
              />
            )}
          </div>}
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Alert variant="dark" className="mb-3 text-muted">
          <Icon className="pv-orange pv-icon-key-small" />
          Note: To maintain security, PreVeil currently restricts moving items to within shared folders so only valid destination folders are shown above.
        </Alert>
        <Button onClick={() => sendMoveAction()} disabled={!destination || is_moving}>
          Move
        </Button>
        <Button variant="no-outline-primary" onClick={handleHideModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>}
  </>;
}

export default React.memo(MoveComponent);
