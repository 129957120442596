import * as React from "react";
import { Card, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { RecoverAccountUIActionTypes } from "src/common";
import img from "src/assets/images/recovery_group.svg";

type AllProps = {
  handleAction: ActionHandlerFunction
}

function DefaultPanelComponent(props: AllProps) {
  const { handleAction } = props;

  return <>
    <Card.Img variant="top" src={img} />
    <Card.Body>
      <Card.Title as="p" className="semi-bold fs-4">
        If you are a member of another user's recovery group, you may be asked to help recover
        their private key.
      </Card.Title>
      <Card.Text as="p">
        To help another user recover their private key now, click 'continue'
      </Card.Text>
      <Button variant="primary" onClick={() => handleAction({ actionType: RecoverAccountUIActionTypes.SubmitAcknowledgeInformation })}>Continue</Button>
    </Card.Body>
  </>;
}
export default React.memo(DefaultPanelComponent);
