import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, ListGroup } from "react-bootstrap";
import { PublicRoutes, useAppDispatch, AppConfiguration } from "src/common";
import { accountActions, mailActions, driveActions, uiActions, settingsActions, adminActions } from "src/store";

function GetStartedComponent() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    // NOTE: Log user out for a fresh start - needed for transitioning between System and public
    // Do not destroy websockets state here
    dispatch(accountActions.logout());
    dispatch(mailActions.destroyMail());
    dispatch(driveActions.destroyDrive());
    dispatch(uiActions.destroyUIManager());
    dispatch(settingsActions.destroySettings());
    dispatch(adminActions.destroyAdmin());
  }, []);

  function Instructions() {
    return AppConfiguration.buildForApp() ? <>
      Please create a new account or add an existing account.
      If you have already set up your account on this computer, <Link to={`/${PublicRoutes.choose_account_route}`}>click here to choose your account</Link>.</> :
      <>
        Please create a new account or <Link to={`/${PublicRoutes.connect_account_route}`}>connect</Link> to an existing account.
        If you have an existing PreVeil Express account, <Link to={`/${PublicRoutes.login_route}`}>click here to sign In</Link>.</>;
  };

  return <Row className="justify-content-center">
    <Col md={8}>
      <h1 className="main-header">Welcome to PreVeil</h1>
      <p className="mb-4 fs-3"><Instructions /></p>
      <ListGroup className="public-card" horizontal>
        <ListGroup.Item className="image">
          <div className="create-new-account-image"></div>
        </ListGroup.Item>
        <ListGroup.Item className="content">
          <p className="title">Create New Account.</p>
          <p>Use your email address as your PreVeil ID.</p>
          <Link className="btn btn-primary" to={`/${PublicRoutes.create_account_route}`}>Create Account</Link>
        </ListGroup.Item>
      </ListGroup>
      {
        AppConfiguration.buildForApp() ?
          <ListGroup className="public-card" horizontal>
            <ListGroup.Item className="image">
              <div className="copy-account-image"></div>
            </ListGroup.Item>
            <ListGroup.Item className="content">
              <p className="title">Add Existing Account.</p>
              <p>
                Enable this computer to access an existing PreVeil account.
              </p>
              <Link className="btn btn-primary" to={`/${PublicRoutes.add_account_route}`}>Add Account</Link>
            </ListGroup.Item>
          </ListGroup> :
          <ListGroup className="public-card" horizontal>
            <ListGroup.Item className="image">
              <div className="upgrade-account-image"></div>
            </ListGroup.Item>
            <ListGroup.Item className="content">
              <p className="title">Upgrade from PreVeil Express</p>
              <p>
                Transfer your current PreVeil Express Account into a full
                featured PreVeil account.
              </p>
              <Link className="btn btn-primary" to={`/${PublicRoutes.upgrade_account_route}`}>Upgrade Express PreVeil</Link>
            </ListGroup.Item>
          </ListGroup>
      }

    </Col>
  </Row>;
}

export default React.memo(GetStartedComponent);
