import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { CollectionServerUser } from "@preveil-api";
import { Icon } from "src/components";
import _ from "lodash";

type AllProps = {
  members: CollectionServerUser[];
  removeUser?: Function;
};

function EmailGroupMembersTableComponent(props: AllProps) {
  const { members, removeUser } = props;

  function RenderList() {
    return (
      <>
        {_.map(members, (member: CollectionServerUser, i: number) => {
          const { display_name, user_id, entity_metadata } = member;
          return <Row key={i} className="align-items-center">
            <Col xs={7}>
              <p className="semi-bold">{display_name}</p>
              <p>{user_id}</p>
            </Col>
            <Col xs={4} className="ps-1">
              <p className="m-0">{entity_metadata.department}</p>
            </Col>
            {!!removeUser && <Col xs={1}>
              <Button 
                variant="icon"
                size="sm"
                title="Delete User Group"
                data-tooltip-id="pv-tooltip"
                data-tooltip-content="Delete User Group"
                onClick={() => removeUser([user_id])}>
                <Icon className="ficon-x" />
              </Button>
            </Col>}
        </Row>;
        })}
      </>
    );
  }

  return <div className={`groups-card ${!!removeUser ? "mt-3" : ""}`}>
    <header>
      <Row className="header-card-row">
        <Col xs={7}>
          <p className="m-0">User</p>
        </Col>
        <Col xs={5} className="ps-2">
          <p className="m-0">Department</p>
        </Col>
      </Row>
    </header>
    {RenderList()}
  </div>;
}

export default React.memo(EmailGroupMembersTableComponent);
