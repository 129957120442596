import * as React from "react";
// import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, AdminUserEntity } from "@preveil-api";
import { CheckboxStates } from "src/common";
import { NotMatchesFoundView } from "src/components/shared/address-list";
import { UserListRow } from ".";
import _ from "lodash";

type SingleUserList = {
  userName: string;
  userEmail: string;
}

type RenderUsersListProps = {
  filtered_users_list: AdminUserEntity[];
  selected_users_list: SingleUserList[];
  recoveryGroupsHash: Record<string, { groupId: string, name: string }> | undefined;
  handleActions: ActionHandlerFunction;
}

// Description: This component is responsible for rendering the list of users in the admin panel.
function ListViewComponent({ filtered_users_list, selected_users_list, recoveryGroupsHash, handleActions }: RenderUsersListProps) {
  // Create an key-value object for easy retrieval/access.
  const selectedUsersHash = Object.fromEntries(selected_users_list.map(user => [user.userEmail, user]));

  return _.isEmpty(filtered_users_list) ?
    <NotMatchesFoundView /> :
    <div className="row-container">
      {filtered_users_list.map((user: AdminUserEntity) => {
        const key = _.uniqueId(user.userEmail);
        const isUserFound = selectedUsersHash[user.userEmail];
        const isChecked = isUserFound ? CheckboxStates.checked : CheckboxStates.empty;
        const { accountType, userName, userEmail, department, recoveryGroup: userAssignedRecoveryGroup, status, updatesPending } = user;
        const _recoveryGroup = !!userAssignedRecoveryGroup && !!recoveryGroupsHash && !!recoveryGroupsHash[userAssignedRecoveryGroup] ? recoveryGroupsHash[userAssignedRecoveryGroup] : null;
        return (
          <UserListRow
            key={key}
            isChecked={isChecked}
            accountType={accountType}
            status={status}
            userName={userName}
            userEmail={userEmail}
            department={department}
            updatesPending={updatesPending}
            recoveryGroup={_recoveryGroup}
            handleActions={handleActions}
          />
        );
      })}
    </div>;
}

export default React.memo(ListViewComponent);