import { useState, useCallback, useRef, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, LinkEntity, useFetchDirectory, useGetACLNodeDirectory, CollectionFilesyncAPI, DriveRequest, useSendRequestMutation, useAppSelector,
  PermissionSetType, DriveErrorMessages, useAppDispatch, COLLECTION_PROTOCOL_VERSIONS, MessageHandlerDisplayType, Message, useGetPermissions,
  getEnumKey, ErrorStackItem, DriveRouteType
} from "src/common";
import { FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import { RootState } from "src/store/configureStore";
import { uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

export function useFetchLink(current_account: Account, set_state: boolean = false, use_filesync: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const [acl_link_entity, setAclDirectoryLink] = useState<LinkEntity>();
  const [link_entity, setLinkEntity] = useState<FSMessage.Link>();
  const [directory_id, setDirectoryId] = useState<string>();
  const [linked_collection_id, setLinkedCollectionId] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const { permissions, getPermissions, destroyPermissions } = useGetPermissions(current_account);
  const { fetchDirectory, resetDirectory, error: dir_error } = useFetchDirectory(current_account, set_state, use_filesync);
  const { resetACLDirectory, error: acl_error } = useGetACLNodeDirectory(current_account, acl_link_entity);
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: Allow active permissions and default_permissions to be accessed
  const default_permissions_ref = useRef<PermissionSetType[]>(default_permissions);
  const permissionsRef = useRef(permissions);
  function setPermissionsRef(_permissions?: PermissionSetType) {
    permissionsRef.current = _permissions;
  }

  useEffect(() => {
    default_permissions_ref.current = default_permissions;
  }, [default_permissions]);

  useEffect(() => {
    if (!!permissions) {
      setPermissionsRef(permissions);
      // NOTE: Return the permission state to undefined to be able to detect future chages
      destroyPermissions();
      !!link_entity && getNodeId(link_entity);
    }
  }, [permissions]);

  // Description: Fetch link and save a Fetch Link Entity 
  async function handleFetchLink(linked_collection_id: string, id: string) {
    const request = await CollectionFilesyncAPI.fetchLinkRequest(linked_collection_id, id);
    const drive_request = !!permissionsRef.current ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const link = message.getLink();
        if (!!link) {
          const collection_id = link.getLinkedCollectionId_asB64();
          setLinkEntity(link);
          getPermissions(collection_id, default_permissions);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_link }, message, MessageHandlerDisplayType.toastr);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_link);
  }

  // Description: Get Node Id depending on COLLECTION_PROTOCOL_VERSIONS
  function getNodeId(link: FSMessage.Link) {
    const link_entity = {
      id: link.getId_asB64(),
      parent_id: link.getParentId_asB64(),
      collection_id: link.getLinkedCollectionId_asB64(),
      linked_collection_id,
      collection_protocol_version:
        link.getCollectionProtocolVersion() || COLLECTION_PROTOCOL_VERSIONS.V2,
      version: link.getVersion_asB64(),
      deleted: link.getIsDeleted(), // not needed ?
      is_expunged: link.getIsExpunged(),
      last_modified_device_id: link.getLastModifiedDeviceId_asB64(), // not needed ?
    };
    // NOTE: Two paths depending on collection_protocol_version
    link_entity.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V2
      ? getACLTree(link_entity)
      : getRootInfo(link_entity.collection_id);
  }

  // Description: Get V2 directory node_id
  async function getACLTree(link: LinkEntity) {
    const request = await CollectionFilesyncAPI.getACLTreeRequest(link.collection_id);
    const drive_request = !!permissionsRef.current ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const acl_tree = message.getAclTree();
        const dir_list = acl_tree?.getDirsList();
        const dir = _.find(dir_list, (dir: FSMessage.Dir) => dir.getIsRoot());
        const root_node_id = (!!dir && dir instanceof FSMessage.Dir) ? dir.getId_asB64() : null;
        if (!!root_node_id) {
          set_state ? fetchDirectory(link.collection_id, root_node_id) : setDirectoryId(root_node_id);
        } else if (!!link) {
          // NOTE: This collection does not have a true root, an Effective Root node which means that the immediate child / children nodes are directories with ACL  
          // and current user is not the owner => Share component should be disabled for this cases.
          setAclDirectoryLink({ ...link, ...{ acl_tree } });
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree });
        };
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
      }
    }

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree);
  }

  // Description: Get Direct Link ACLTree to find node_id
  async function getDirectLinkACLTree(collection_id: string) {
    const request = await CollectionFilesyncAPI.getACLTreeRequest(collection_id);
    const drive_request = !!permissionsRef.current ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const acl_tree = message.getAclTree();
        const dir_list = acl_tree?.getDirsList();
        const dir = _.find(dir_list, (dir: FSMessage.Dir) => dir.getIsRoot());
        const root_node_id = (!!dir && dir instanceof FSMessage.Dir) ? dir.getId_asB64() : null;
        if (!!root_node_id) {
          fetchDirectory(collection_id, root_node_id);
        } else if (!!acl_tree) {
          // NOTE: This collection does not have a true root, an Effective Root node which means that the immediate child / children nodes are directories with ACL  
          // and current user is not the owner => Share component should be disabled for this cases.
          const link: LinkEntity = {
            collection_id,
            id: DriveRouteType.DIRECT_LINK,
            linked_collection_id: collection_id,
            collection_protocol_version: COLLECTION_PROTOCOL_VERSIONS.V2,
            last_modified_device_id: "",
            version: ""
          };
          setAclDirectoryLink({ ...link, ...{ acl_tree } });
        } else {
          handlePageErrorMessage(DriveErrorMessages.error_finding_direct_link, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.error_finding_direct_link, { stack_message: DriveErrorMessages.error_fetching_acl_tree }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_tree, DriveErrorMessages.error_finding_direct_link);
  }

  // Description: Get V1 directory node_id
  async function getRootInfo(collection_id: string) {
    const request = await CollectionFilesyncAPI.getRootInfoRequest(collection_id);
    const drive_request = !!permissionsRef.current ? await DriveRequest.initializeRequest(request, current_account, permissionsRef.current) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const message_root_info = message.getRootInfo();
        if (!!message_root_info) {
          const root_info = {
            collection_id,
            id: message_root_info.getId_asB64()
          };
          set_state ? fetchDirectory(collection_id, root_info.id) : setDirectoryId(root_info.id);
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info }, null, MessageHandlerDisplayType.toastr);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_root_info }, message, MessageHandlerDisplayType.toastr);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_root_info);
  }

  // -----------------------------
  // HOOK General
  // -----------------------------
  // Description: Sends Request and handles error when the request object is null, pass it custom stack message
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>,
    stack_error: string = DriveErrorMessages.error_sending_request, user_message: string = DriveErrorMessages.default) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(user_message, { stack_error, stack_message: DriveErrorMessages.error_sending_request }, null, MessageHandlerDisplayType.toastr);
    }
  }


  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useFetchLink Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for create directory
  const fetchLink = useCallback((linked_collection_id: string, id: string, direct_link: boolean = false) => {
    const permissions = _.find(default_permissions_ref.current, (set: PermissionSetType) => linked_collection_id === set.collection_id.B64());
    setPermissionsRef(permissions);
    setLinkedCollectionId(linked_collection_id);
    direct_link ? getDirectLinkACLTree(linked_collection_id) : handleFetchLink(linked_collection_id, id);
  }, []);

  // Description: reset hook 
  const resetLink = useCallback(() => {
    setError(false);
    setDirectoryId(undefined);
    destroyPermissions();
    resetDirectory();
    resetACLDirectory();
  }, []);

  return {
    fetchLink,
    directory_id,
    resetLink,
    error: error || dir_error || acl_error
  };
}
