import React, { useEffect, useState } from "react";
import { IActionHandler, MessageDisplayType, Sort } from "@preveil-api";
import {
  Account, DriveErrorMessages, DriveSuccessMessages, DriveTrashItem, getOppositeSortDirection, GlobalErrorMessages, Message,
  MessageAnchors, MessageHandlerDisplayType, MessageToastTypes, sortBy, SORT_DIRECTION, useAppDispatch, useAppSelector, useDeleteItems,
  useDownloadFile, useEmptyTrash, useFetchTrash, DriveUrlParams, useRestoreItems, Pagination, useFetchDirectory, DirectoryEntity, usePathList, isNumber, EntryItem, AppConfiguration, DefaultRoutes, TrashErrorMessages, TrashMessageAnchors, TrashRestoreErrorMessages, TrashSuccessMessages, TrashConfirmationMessages, DriveEntryType
} from "src/common";
import { PageHeader } from "src/components";
import { driveActions, uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import { TrashListView, TrashToolbar } from ".";
import { Breadcrumbs } from "../drive-manager";
import saveAs from "file-saver";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  router_params: DriveUrlParams;
};

/* Description: This is the parent component for Drive Trash (works on web and app) 
Functionality includes: Restore, Permanently Delete, Empty Trash, Multi Select, Download File, Navigation, Filtering, Sorting, Pagination */
function DriveTrashComponent(props: AllProps) {
  const { current_account, router_params } = props;
  const is_app = AppConfiguration.buildForApp();
  const { collection_id, id, item_type } = router_params;
  const selected = useAppSelector((state: RootState) => state.drive.selected);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const pagination = useAppSelector((state: RootState) => state.drive.pagination);
  const breadcrumbs = useAppSelector((state: RootState) => state.drive.breadcrumbs);
  const [sort, setSort] = useState<Sort<keyof DriveTrashItem>>({ field: "name", direction: "asc" });
  const sort_children = useAppSelector((state: RootState) => state.drive.sort);
  const { restoreItems, resetRestoreItems, successes: restore_successes, errors: restore_errors, conflict_errors, done: restore_done } = useRestoreItems(current_account);
  const { fetchDirectory, directory, pagination_info, resetDirectory, error: dir_error } = useFetchDirectory(current_account, false, false, true);
  const { deleteItems, delete_complete, errors, successes, resetDeleteItems } = useDeleteItems(current_account, true);
  const { fetchTrashList, trash_items, error: error_fetching, resetFetchTrash } = useFetchTrash(current_account);
  const { file, downloadFile, error: download_error, resetDownload, file_name } = useDownloadFile(current_account, is_app, true);
  const { emptyTrash, success, error } = useEmptyTrash(current_account);
  const { setBreadcrumbs } = usePathList(current_account, undefined, true);
  const [child_entries, setChildEntries] = useState<EntryItem[]>();
  const [current_directory, setCurrentDirectory] = useState<DirectoryEntity>();
  const [_list, setList] = useState<DriveTrashItem[]>([]);
  const [trash_list, setTrashList] = useState<DriveTrashItem[]>([]);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>();
  const [page, setPage] = useState<string>("1");
  const dispatch = useAppDispatch();

  /* Description: Resets pagination and sort when exiting the component (so that it will be reset when navigating to my preveil) */
  useEffect(() => {
    return () => {
      ResetPagination();
      dispatch(driveActions.resetSort());
      dispatch(driveActions.setSelected([]));
      dispatch(driveActions.resetBreadcrumbs());
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  /* Description: Handles Navigation in trash - fetches the directory when navigating into a directory or
  calls fetchTrashList when in root view of trash */
  useEffect(() => {
    if (!!id) {
      setIsLoading(true);
      fetchDirectory(collection_id, id);
    } else {
      dispatch(driveActions.resetBreadcrumbs());
      setCurrentDirectory(undefined);
      setChildEntries(undefined); // Reset current directory and child entries when navigating back to trash root
      DriveTrashRequests.handleRefresh(true);
    }
  }, [collection_id, id, item_type]);

  /* Description:  Handles the Restore hook response */
  useEffect(() => {
    !!restore_done && restoreErrorHandling();
  }, [restore_done]);

  /* Description:  Handles the Delete hook response */
  useEffect(() => {
    !!delete_complete && deleteErrorHandling();
  }, [delete_complete]);

  /* Description:  Handles the Fetch Directory hook response */
  useEffect(() => {
    !!directory && setCurrentDirectoryInfo(directory);
  }, [directory, dir_error]);

  /* Description:  Handles Empty Trash hook response (display error or display success and refresh trash) */
  useEffect(() => {
    if (!!success) {
      DriveTrashRequests.handleRefresh(true);
      dispatch(uiActions.handleSetMessage(new Message(TrashSuccessMessages.success_emptying_trash)));
    }
    !!error && dispatch(uiActions.handleSetMessage(new Message(TrashErrorMessages.error_emptying_trash)));
  }, [success, error]);

  /* Description: Handles Navigation in trash - fetches the directory when navigating into a directory or
  calls fetchTrashList when in root view of trash */
  useEffect(() => {
    if (!!trash_items) {
      setTrashList(trash_items);
      DriveTrashRequests.handleSetSort({ field: "name", list: trash_items });
      resetFetchTrash();
    }
    if (error_fetching) {
      setIsLoading(false);
      resetFetchTrash();
    }
  }, [trash_items, error_fetching]);

  /* Description: handles what the downloadFileWeb hook returns (either an error or the file blob). 
  NOTE: for web - files are downloaded to the file explorer but for app they are downloaded to the PreVeil Downloads folder in My PreVeil. */
  useEffect(() => {
    if (download_error) {
      dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.error_downloading_file.replace(MessageAnchors.file_name, "file"))));
      setIsLoading(false);
      resetDownload();
    } else if (!!file && !!file_name) {
      if (is_app) {
        successfullyDownloadedForApp(file_name);
      } else {
        saveAs(file, file_name);
        dispatch(uiActions.handleSetMessage(new Message(DriveSuccessMessages.success_downloading.replace(MessageAnchors.file_name, file_name))));
      }
      setIsLoading(false);
      resetDownload();
    }
  }, [file, download_error, file_name]);

  /* Description: Handles the directory that the fetch directory hook returns (when navigating into a directory under trash) 
    This function changes the path urls for the breadcrumbs and handles sorting and pagination */
  function setCurrentDirectoryInfo(directory: DirectoryEntity) {
    if (!!directory.path_list) {
      const trash_path = directory.path_list.map(p => {
        let url = p.url;
        if (!p.deleted) {
          url = DefaultRoutes.drive_trash;
        } else if (!!p.url) {
          const prev_url = p.url.split("/");
          url = `${DefaultRoutes.drive_trash}/${prev_url[2]}/${prev_url[3]}/${prev_url[4]}`;
        }
        return { ...p, url };
      });
      setBreadcrumbs(trash_path, directory.collection_id);
    }
    directory.entries = directory.entries.filter(entry => entry.type !== DriveEntryType.LINK);
    setCurrentDirectory(directory);
    DriveTrashRequests.handleSetSortChildren({ field: "name", list: directory.entries });
    if (!!pagination_info) {
      const total_page_rows = directory.entries.length;
      const total_rows = pagination_info.total_count || directory.entries.length;
      const pagination_item = new Pagination(0, total_page_rows, total_rows, pagination.pageSize).pagination_item;
      !!pagination_item && dispatch(driveActions.setPagination(pagination_item));
    }
    setIsLoading(false);
    resetDirectory();
  }

  /* Description:  Handle delete successes and errors (display messages) */
  function deleteErrorHandling() {
    if (errors.length === 0) {
      if (successes.length === 1) {
        const item = trash_list.find(i => i.id === successes[0]);
        !!item && dispatch(uiActions.handleSetMessage(new Message(TrashSuccessMessages.success_deleting_one.replace(TrashMessageAnchors.name, item.name))));
      } else {
        dispatch(uiActions.handleSetMessage(new Message(TrashSuccessMessages.success_deleting_many.replace(TrashMessageAnchors.item_count, successes.length.toString()))));
      }
    } else if (successes.length === 0) {
      if (errors.length === 1) {
        const item = trash_list.find(i => i.id === errors[0]);
        !!item && dispatch(uiActions.handleRequestErrors(new Message(TrashErrorMessages.error_deleting_one_item.replace(TrashMessageAnchors.name, item.name))));
      } else {
        dispatch(uiActions.handleRequestErrors(new Message(TrashErrorMessages.error_deleting_all_items.replace(TrashMessageAnchors.item_count, errors.length.toString()))));
      }
    } else {
      dispatch(
        uiActions.handleRequestErrors(
          new Message(
            TrashErrorMessages.error_deleting_some_items
              .replace(MessageAnchors.item_count, errors.length.toString())
              .replace(MessageAnchors.total_count, selected.length.toString()),
          ),
        ),
      );
    }
    dispatch(driveActions.setSelected([]));
    resetDeleteItems();
    DriveTrashRequests.handleRefresh(true);
  }

  /* Description: Handle restore successes and errors (display messages) */
  function restoreErrorHandling() {
    if (restore_errors.length === 0 && conflict_errors.length === 0) {
      if (restore_successes.length === 1) {
        dispatch(uiActions.handleSetMessage(new Message(TrashSuccessMessages.success_restoring_one.replace(TrashMessageAnchors.name, restore_successes[0].name))));
      } else {
        dispatch(uiActions.handleSetMessage(new Message(TrashSuccessMessages.success_restoring_many.replace(TrashMessageAnchors.item_count, restore_successes.length.toString()))));
      }
    } else {
      // NOTE & TO DO: the below commented toasters will not be displayed since the popup will replace the toasters. 
      // We can uncomment this when we allow multiple messages to be displayed.
      /* const errors = restore_errors.length + conflict_errors.length;
      const total = errors + restore_successes.length;
      if (errors === total) {
        if (total === 1) {
          dispatch(uiActions.handleRequestErrors(new Message(TrashRestoreErrorMessages.error_restoring_all.replace(TrashMessageAnchors.item_count, total.toString()))));
        } else {
          dispatch(uiActions.handleRequestErrors(new Message(TrashRestoreErrorMessages.error_restoring_one.replace(TrashMessageAnchors.name, restore_errors[0].name))));
        }
      } else {
        dispatch(uiActions.handleRequestErrors(new Message(TrashRestoreErrorMessages.error_restoring_some.replace(TrashMessageAnchors.item_count, errors.toString()).replace(TrashMessageAnchors.total_count, total.toString()))));
      } */
      restoreErrorPopup(restore_errors, conflict_errors);
    }
    dispatch(driveActions.setSelected([]));
    DriveTrashRequests.handleRefresh(true);
    resetRestoreItems();
  }

  // Description: Reset state supporting client side pagination
  function ResetPagination() {
    setPage("1");
    dispatch(driveActions.resetPagination());
  }

  // -------------------------------------------------------------------------------
  // Description: Handle all children component actions and store it
  // -------------------------------------------------------------------------------
  const DriveTrashRequests = {
    handleSelect: (params: { entry?: DriveTrashItem, selected: boolean; }) => {
      let new_selected = selected.slice();
      const _id = !!params.entry ? params.entry.id : null;
      if (!!_id) { // Note: individual check
        if (params.selected) {
          !_.find(new_selected, (_selected: string) => (_selected === _id)) &&
            new_selected.push(_id);
        } else {
          const index = _.findIndex(new_selected, (_selected: string) => (_selected === _id));
          index >= 0 && (new_selected.splice(index, 1));
        }
      } else { // Note: bulk actions
        new_selected = params.selected ? _.map(trash_list, (entry: DriveTrashItem) => (entry.id)) : [];
      }
      dispatch(driveActions.setSelected(new_selected));
    },
    // Description: Handles sort for root trash
    handleSetSort: (params: { field: keyof DriveTrashItem, list?: DriveTrashItem[]; }) => {
      const { field, list } = params;
      const folders = !!list ? list : trash_list;
      const direction = sort.field === field ? !list ? getOppositeSortDirection(sort.direction) : sort.direction : SORT_DIRECTION.ascending;
      const sorted = sortBy(folders, { field, direction }, field === "deleted_at");
      setSort({ field, direction });
      setTrashList(sorted);
      folders.length > 0 ? updatePagination(sorted, pagination.pageIndex) : setList([]);
      setIsLoading(false);
    },
    // Description: Handles sort for folders under root trash
    handleSetSortChildren: (params: { field: keyof EntryItem, list?: EntryItem[]; }) => {
      const { field, list } = params;
      const folders = !!list ? list : !!current_directory ? current_directory.entries : [];
      const direction = sort_children.field === field ? !list ? getOppositeSortDirection(sort_children.direction) : sort_children.direction : SORT_DIRECTION.ascending;
      const sorted = sortBy(folders, { field, direction }, field === "deleted_at");
      dispatch(driveActions.setSort({ field, direction }));
      const page_index = !!list ? 0 : pagination.pageIndex;
      folders.length > 0 ? updateDirectoryPagination(sorted, page_index) : setChildEntries([]);
      setIsLoading(false);
    },
    // Description: if fetching root, use fetchTrash hook otherwise fetch directory of the current directory in trash
    handleRefresh: (refreshing_root: boolean) => {
      ResetPagination();
      setIsLoading(true);
      refreshing_root ? fetchTrashList() : !!current_directory && fetchDirectory(current_directory.collection_id, current_directory.id);
    },

    handleRestoreItems: (item?: DriveTrashItem) => {
      confirmRestore(item);
    },

    handleDelete: (item?: DriveTrashItem) => {
      confirmPermanentlyDelete(item);
    },

    handleDownload: (params: { collection_id: string, id: string, name: string }) => {
      const { collection_id, id, name } = params;
      setIsLoading(true);
      downloadFile(collection_id, id, name);
    },

    handleEmptyTrash: () => {
      !!root_info && emptyTrash(root_info.collection_id);
    },
    // Description: filters items in list and updates pagination
    handleFilter: (filter: string) => {
      setFilter(filter);
      if (!!current_directory) {
        const filtered = current_directory.entries.filter((item: EntryItem) => item.name.toLowerCase().includes(filter.toLowerCase()));
        updateDirectoryPagination(filtered, 0);
      } else {
        const filtered = trash_list.filter((item: DriveTrashItem) => item.name.toLowerCase().includes(filter.toLowerCase()));
        updatePagination(filtered, 0);
      }
    },

    handlePaging: (new_page: string) => {
      const new_page_index = !!new_page && isNumber(new_page) ? (Number(new_page) - 1) : pagination.pageIndex;
      (new_page !== page && isNumber(new_page)) ? setPage(new_page) :
        DriveTrashRequests.handlePageErrorMessage({
          message: GlobalErrorMessages.default, stack: {
            error: `There was a Problem paginating to Page: ${new_page}`
          }
        });
      if (!!current_directory) {
        const filtered = !!filter ? current_directory.entries.filter((item: EntryItem) => item.name.toLowerCase().includes(filter.toLowerCase())) : current_directory.entries;
        updateDirectoryPagination(filtered, new_page_index);
      } else {
        const filtered = !!filter ? trash_list.filter((item: DriveTrashItem) => item.name.toLowerCase().includes(filter.toLowerCase())) : trash_list;
        updatePagination(filtered, new_page_index);
      }
    },

    // Description: Handle all page / components top level errors
    handlePageErrorMessage: (params: { message: string; stack?: any; display_type?: MessageDisplayType; }) => {
      const display_type = !!params.display_type ? params.display_type : MessageHandlerDisplayType.logger;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack));
    },
  };

  function updatePagination(list: DriveTrashItem[], page: number) {
    const chunks = list.length > 0 ? _.chunk(list, pagination.pageSize) : undefined;
    const _items = !!chunks ? chunks[page] : list;
    setPagination(page, _items.length, list.length);
    setList(_items);
  }

  function updateDirectoryPagination(list: EntryItem[], page: number) {
    const chunks = list.length > 0 ? _.chunk(list, pagination.pageSize) : undefined;
    const _items = !!chunks ? chunks[page] : list;
    setPagination(page, _items.length, list.length);
    setChildEntries(_items);
  }

  function setPagination(page: number, page_rows: number, rows: number) {
    const paginationItem = new Pagination(page, page_rows, rows, pagination.pageSize).pagination_item;
    !!paginationItem && dispatch(driveActions.setPagination(paginationItem));
  }

  /* Description: Handle all actions from Children forms */
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DriveTrashRequests as any)[callback] instanceof Function) {
      (DriveTrashRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      DriveTrashRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  /* Description: Popup message telling user that downloaded file is in PreVeil downloads folder (only for app not web) */
  function successfullyDownloadedForApp(file_name: string) {
    const message = DriveSuccessMessages.success_downloading_app;
    const title = DriveSuccessMessages.success_downloading.replace(MessageAnchors.file_name, file_name);
    const confirmation_dialog = new Message(message, MessageHandlerDisplayType.dialog, MessageToastTypes.primary, title, undefined,
      { label: "Close" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  /* Description: Confirmation for permanently deleting items */
  function confirmPermanentlyDelete(item?: DriveTrashItem) {
    const items = !!item ? [item] : trash_list.filter(item => selected.includes(item.id));
    const delete_items = items.map(item => ({ id: item.id, collection_id: item.collection_id, type: item.type }));
    const message = TrashConfirmationMessages.confirm_permanently_delete_message.replace(TrashMessageAnchors.name, items.length === 1 ? `"${items[0].name}"` : "the selected items");
    const title = `Permanently Delete ${items.length === 1 ? `"${items[0].name}"` : "selected items"}?`;
    const confirmation_dialog = new Message(message, MessageHandlerDisplayType.confirm, MessageToastTypes.primary, title,
      {
        label: "Yes",
        data: true,
        action: () => { setIsLoading(true); deleteItems(delete_items); },
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  /* Description: Confirmation for restoring items */
  function confirmRestore(item?: DriveTrashItem) {
    const items = !!item ? [item] : trash_list.filter(item => selected.includes(item.id));
    const message = TrashConfirmationMessages.confirm_restore.replace(TrashMessageAnchors.name, items.length === 1 ? `"${items[0].name}"` : "the selected items");
    const title = `Restore ${items.length === 1 ? `"${items[0].name}"` : "selected items"}?`;
    const confirmation_dialog = new Message(message, MessageHandlerDisplayType.confirm, MessageToastTypes.primary, title,
      {
        label: "Yes",
        data: true,
        action: () => { setIsLoading(true); restoreItems(items); },
      },
      { label: "No" },
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  /* Description: Handles restore error messaging */
  function restoreErrorPopup(deleted_parents: DriveTrashItem[], conflict_acl: DriveTrashItem[]) {
    const total = deleted_parents.length + conflict_acl.length;
    let title: string = TrashRestoreErrorMessages.error_restoring_general_title.replace(TrashMessageAnchors.item_count, total.toString());
    let message: string;
    if (deleted_parents.length > 0 && conflict_acl.length < 1) {
      if (deleted_parents.length === 1) {
        title = TrashRestoreErrorMessages.shared_parent_title_single.replace(TrashMessageAnchors.name, deleted_parents[0].name);
        message = TrashRestoreErrorMessages.shared_parent_message_single;
      } else {
        title = TrashRestoreErrorMessages.error_restoring_general_title.replace(TrashMessageAnchors.item_count, deleted_parents.length.toString());
        message = TrashRestoreErrorMessages.shared_parent_message_multiple;
      }
    } else if (deleted_parents.length < 1 && conflict_acl.length > 0) {
      if (conflict_acl.length === 1) {
        title = TrashRestoreErrorMessages.acl_conflict_title_single.replace(TrashMessageAnchors.name, conflict_acl[0].name);
        message = TrashRestoreErrorMessages.acl_conflict_message_single;
      } else {
        title = TrashRestoreErrorMessages.acl_conflict_title_multiple.replace(TrashMessageAnchors.item_count, conflict_acl.length.toString());
        message = TrashRestoreErrorMessages.acl_conflict_message_multiple;
      }
    } else if (conflict_acl.length === 1 && deleted_parents.length === 1) {
      title = TrashRestoreErrorMessages.error_restoring_two_title.replace(TrashMessageAnchors.name, `${deleted_parents[0].name} and ${conflict_acl[0].name}`);
      title = `Error while restoring ${deleted_parents[0].name} and ${conflict_acl[0]}`;
      message = TrashRestoreErrorMessages.error_restoring_two_message.replace(TrashMessageAnchors.item_name, deleted_parents[0].name).replace(TrashMessageAnchors.name, conflict_acl[0].name);
    } else if (conflict_acl.length === 1 && deleted_parents.length > 1) {
      message = TrashRestoreErrorMessages.one_conflict_message.replace(TrashMessageAnchors.item_count, deleted_parents.length.toString()).replace(TrashMessageAnchors.name, conflict_acl[0].name);
    } else if (conflict_acl.length > 1 && deleted_parents.length === 1) {
      message = TrashRestoreErrorMessages.one_shared_parent_message.replace(TrashMessageAnchors.item_count, conflict_acl.length.toString()).replace(TrashMessageAnchors.name, deleted_parents[0].name);
    } else {
      message = TrashRestoreErrorMessages.error_restoring_general_message.replace(TrashMessageAnchors.item_count, conflict_acl.length.toString()).replace(TrashMessageAnchors.total_count, deleted_parents.length.toString());
    }
    const confirmation_dialog = new Message(message, MessageHandlerDisplayType.dialog, MessageToastTypes.primary, title, { label: "Close" });
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  return (
    <>
      <PageHeader>
        {
          <Breadcrumbs
            trash
            id={id}
            paths={breadcrumbs} />
        }
      </PageHeader>
      <TrashToolbar
        is_loading={is_loading}
        handleAction={handlePageActions}
        selected={selected}
        pagination={pagination}
        directory={current_directory}
        total={trash_list.length}
      ></TrashToolbar>
      <div className="cover-content">
        <TrashListView
          handleAction={handlePageActions}
          trash_list={_list}
          child_entries={child_entries}
          is_loading={is_loading}
          selected={selected}
          pagination={pagination}
          sort={sort}
        />
      </div>
    </>
  );
}

export default React.memo(DriveTrashComponent);
