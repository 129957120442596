import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { MailboxItem, IActionHandler, TreeItem, TreeModalActions, IFetchResponse } from "@preveil-api";
import {
  Account, useAppDispatch, useAppSelector, buildCustomMailboxTree, MessageHandlerDisplayType, MessageToastTypes, DefaultRoutes,
  UIManagerActionTypes, MessageAnchors, GlobalErrorMessages, Message, MailSuccessMessages, MailErrorMessages, generateURLIdentifier, renameFolderPath,
  usePutMailByCollectionIdMailboxesAndMbidMutation, useDeleteMailByCollectionIdMailboxesAndMbidMutation, usePostMailByCollectionIdMailboxesMutation,
  PostMailboxesApiResponse, PutMailboxesAndMbidApiResponse, DeleteMailboxesApiResponse, StatusCode, MailMessages, DragEventType
} from "src/common";
import { mailActions, uiActions } from "src/store";
import { Icon, Tree, MailboxFormModal } from "src/components";

type AllProps = {
  custom_mailboxes?: MailboxItem[];
}

function MailboxesTreeComponent(props: AllProps) {
  const { custom_mailboxes } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const current_account = useAppSelector((state) => state.account.current_account);
  const current_mailbox = useAppSelector((state) => state.mail.current_mailbox);
  const expanded = useAppSelector((state) => state.mail.expanded);
  const [tree, setTree] = useState<TreeItem[]>();
  const [show, setShow] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<TreeModalActions>();
  const [renameFolder] = usePutMailByCollectionIdMailboxesAndMbidMutation();
  const [deleteFolder] = useDeleteMailByCollectionIdMailboxesAndMbidMutation();
  const [createFolder] = usePostMailByCollectionIdMailboxesMutation();

  // Description: Handle all children component actions and store it
  const MailboxesTreeRequests = {
    // Description: Keep track of expanded nodes for navigation and refresh purposes
    handleTreeNodeActiveState: (params: { id: string; expanded: boolean }) => {
      const id = params.id;
      const isExpanded = params.expanded;
      if (!!id) {
        const _expanded = expanded.slice();
        const index = _expanded.indexOf(id);
        (isExpanded && index < 0) ? _expanded.push(id) : (index >= 0 && (_expanded.splice(index, 1)));
        dispatch(mailActions.setExpanded(_expanded));
      };
    },

    // Description: Handle click to custom mailbox links
    handleTreeNodeClick: (selected_item: TreeItem) => {
      if (!!selected_item && !!selected_item.url_identifier) {
        navigate(`/mail/${encodeURIComponent(selected_item.url_identifier)}/1`);
      }
    },

    // Description: Handle the add a new Mailbox folder
    //  Step 1: Opens the modal to get the @Param new_name === undefined
    //  Step 2: Saves name to collection @Param new_name === string 
    // NOTE: If selected is undefined => add folder to root or path = ""
    handleTreeNodeAddChild: (selected?: TreeItem, new_name?: string) => {
      if (!!new_name) {
        if (!!current_account) {
          // NOTE: new name must include complete path
          const request = {
            account_ids: Account.getAccountIdentifiers(current_account),
            body: {
              collectionId: current_account.mail_cid,
              name: !!selected ? `${selected.path}/${new_name}` : new_name
            }
          };
          createFolder(request)
            .unwrap()
            .then((params: PostMailboxesApiResponse) => {
              const msg = MailSuccessMessages.mailbox_created_success.replace(MessageAnchors.mailbox_name, new_name || "folder");
              dispatch(uiActions.handleSetMessage(new Message(msg)));
              !!selected && (current_mailbox?.mailbox_id !== selected.id && !!selected.url_identifier) &&
                navigate(`/mail/${encodeURIComponent(selected.url_identifier)}/1`);
            })
            .catch((stack: IFetchResponse) => {
              const msg = stack.status === StatusCode.CONFLICT_CODE ? MailErrorMessages.mailbox_already_exists :
                MailErrorMessages.error_creating_mailbox.replace(MessageAnchors.mailbox_name, new_name || "mailbox folder");
              dispatch(uiActions.handleRequestErrors(new Message(msg), stack));
            });
        } else {
          MailboxesTreeRequests.handlePageErrorMessage({
            message: GlobalErrorMessages.default,
            stack: { error: GlobalErrorMessages.no_current_account_set }
          });
        }
      } else {
        // Step 1: Default Open Modal and set State
        setShow(true);
        setModalAction({
          type: UIManagerActionTypes.TreeNodeAddChild, selected
        });
      }
    },

    // Description: Handle the rename of a Mailbox folder
    // NOTE: Only works well for top level folders or no children levels 
    // ***** https://preveil.atlassian.net/browse/BACK-794
    //  Step 1: Opens the modal to get the @Param new_name === undefined
    //  Step 2: Saves name to collection @Param new_name === string
    handleTreeNodeRename: (selected: TreeItem, new_name?: string) => {
      if (!!new_name) {
        const mbid = selected.id;
        if (!!current_account && !!mbid) {
          const request = {
            account_ids: Account.getAccountIdentifiers(current_account),
            body: {
              collectionId: current_account.mail_cid,
              mbid,
              name: renameFolderPath(selected.path, new_name)
            }
          };

          renameFolder(request)
            .unwrap()
            .then((params: PutMailboxesAndMbidApiResponse) => {
              const msg = MailSuccessMessages.mailbox_renamed_success.replace(MessageAnchors.mailbox_name, selected.name || "mailbox folder");
              dispatch(uiActions.handleSetMessage(new Message(msg)));
              // Note: if Current mailbox is this renamed one then redirect to new mailbox
              (current_mailbox?.mailbox_id === mbid) &&
                dispatch(uiActions.handleSetRedirectUrl(`/mail/${encodeURIComponent(generateURLIdentifier(new_name, mbid))}/1`));
            })
            .catch((stack: IFetchResponse) => {
              const msg = stack.status === StatusCode.CONFLICT_CODE ? MailErrorMessages.mailbox_already_exists :
                MailErrorMessages.error_renaming_mailbox.replace(MessageAnchors.mailbox_name, selected.name || "mailbox folder");
              dispatch(uiActions.handleRequestErrors(new Message(msg), stack));
            });
        } else {
          MailboxesTreeRequests.handlePageErrorMessage({
            message: GlobalErrorMessages.default,
            stack: { error: GlobalErrorMessages.no_current_account_set }
          });
        }
      } else {
        // Step 1: Default Open Modal and set State
        setShow(true);
        setModalAction({ type: UIManagerActionTypes.TreeNodeRename, selected });
      }
    },

    // Description: Set up the confirmation Dialog and send through action through the MessageHandler
    handleTreeNodeDelete: (selected_item: TreeItem) => {
      const mbid = selected_item.id;
      if (!!current_account && !!mbid) {
        const action = (selected_item: TreeItem) => {
          const request = {
            account_ids: Account.getAccountIdentifiers(current_account),
            body: {
              collectionId: current_account.mail_cid,
              mbid
            }
          };
          deleteFolder(request)
            .unwrap()
            .then((params: DeleteMailboxesApiResponse) => {
              const msg = MailSuccessMessages.mailbox_deleted_success.replace(MessageAnchors.mailbox_name, selected_item.name || "folder");
              dispatch(uiActions.handleSetMessage(new Message(msg)));
              !!params.rev_id && dispatch(mailActions.setMailboxRevId(params.rev_id));
              // Note: if Current mailbox is this deleted one then redirect to inbox:
              current_mailbox?.mailbox_id === mbid && navigate(DefaultRoutes.mail_default);
            })
            .catch((stack: IFetchResponse) => {
              const msg = MailErrorMessages.error_deleting_mailbox.replace(MessageAnchors.mailbox_name, selected_item.name || "mailbox folder");
              dispatch(uiActions.handleRequestErrors(new Message(msg), stack));
            });
        };

        const confirmation_dialog = new Message(
          MailMessages.confirm_folder_delete.replace(MessageAnchors.mailbox_name, selected_item.name),
          MessageHandlerDisplayType.confirm,
          MessageToastTypes.primary,
          MailMessages.confirm_folder_title,
          {
            label: "Yes",
            data: selected_item,
            action
          },
          { label: "No" }
        );
        dispatch(uiActions.handleSetMessage(confirmation_dialog));
      } else {
        MailboxesTreeRequests.handlePageErrorMessage({
          message: GlobalErrorMessages.default,
          stack: { error: GlobalErrorMessages.no_current_account_set }
        });
      }
    },

    // Description: Handle Modal Form submit and redirect to correct handler + clear state
    handleSubmitMailboxName: (params: TreeModalActions) => {
      (params.type === UIManagerActionTypes.TreeNodeAddChild) &&
        MailboxesTreeRequests.handleTreeNodeAddChild(params.selected, params.new_name);

      (params.type === UIManagerActionTypes.TreeNodeRename && !!params.selected)
        && MailboxesTreeRequests.handleTreeNodeRename(params.selected, params.new_name);

      MailboxesTreeRequests.handleCloseModal();
    },

    handleCloseModal: () => {
      setShow(false);
      setModalAction(undefined);
    },

    // NOTE: Tree drag events  OnDragOver, OnDrop, OnDragLeave
    handleOnDragOver: (params: { event: DragEvent, folder: TreeItem }) => {
      const event = params.event;
      event.preventDefault();
      const drop_target = event.currentTarget as HTMLElement;
      if (!!drop_target && !!current_mailbox && drop_target.id !== current_mailbox.mailbox_id) {
        drop_target.classList.add("drag-over");
      }
    },

    handleOnDrop: (params: { event: DragEvent, folder: TreeItem }) => {
      const event = params.event;
      event.preventDefault();
      const drop_target = event.currentTarget as HTMLElement;
      const destination = !!custom_mailboxes && !!current_mailbox && drop_target.id !== current_mailbox.mailbox_id
        ? custom_mailboxes.find((mailbox) => mailbox.mailbox_id === drop_target.id)
        : undefined;
      !!destination ? dispatch(mailActions.setMoveDestination(destination)) :
        dispatch(uiActions.handleSetMessage(new Message(MailErrorMessages.same_mailbox_target_warning, MessageHandlerDisplayType.toastr,
          MessageToastTypes.warning)));
      drop_target.classList.remove("drag-over");
    },

    handleOnDragLeave: (params: { event: DragEvent, folder: TreeItem }) => {
      const event = params.event;
      event.preventDefault();
      const drop_target = event.currentTarget as HTMLElement;
      if (!!drop_target) {
        drop_target.classList.remove("drag-over");
      }
    },

    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((MailboxesTreeRequests as any)[callback] instanceof Function) {
      (MailboxesTreeRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      MailboxesTreeRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Build tree structure
  useEffect(() => {
    if (!!custom_mailboxes) {
      const _tree = buildCustomMailboxTree(custom_mailboxes);
      !!_tree && setTree(_tree);
    };
  }, [custom_mailboxes]);

  return <>
    <Nav.Link title="Add New Folders"
      className="nav-link"
      onClick={() => {
        setShow(true);
        setModalAction({ type: UIManagerActionTypes.TreeNodeAddChild });
      }}>
      <Icon className="ficon-folder-plus" />
      <span>Folders</span>
    </Nav.Link>
    {
      !!tree && <Tree
        active={current_mailbox?.mailbox_id}
        className="mail-tree"
        data={tree}
        handleAction={handlePageActions}
        // drag_events={["OnDragOver", "OnDrop", "OnDragLeave"]} 
        drag_events={[DragEventType.DRAG_OVER, DragEventType.DROP, DragEventType.DRAG_LEAVE]}
      />
    }
    <MailboxFormModal
      handleAction={handlePageActions}
      show={show}
      modalAction={modalAction} />
  </>;
}

export default React.memo(MailboxesTreeComponent);
