import React, { useEffect, useState, useCallback, useRef, DragEvent } from "react";
import { useSearchParams, useParams, useNavigate, useLocation } from "react-router-dom";
import { IActionHandler, MessageDisplayType, ThreadMessage, Thread, GetThreadsParams, ThreadsPage, MailboxNotificationCounts, MailboxItem } from "@preveil-api";
import {
	Account, collectionApi, useAppSelector, useAppDispatch, QueryParamKeys, QueryParamMessages, Message, capitalize, MailErrorMessages,
	MessageAnchors, DefaultMailboxes, Pagination, GlobalConstants, MessageHandlerDisplayType, GlobalErrorMessages, isNumber, SimplePaginationActionType,
	SimplePaginationAction, isMailboxNotificationType, ComposeMailMessage, ComposeTypesType, ComposeMail, ClientDefaultMailboxesType, getVirtualMailboxId,
	VIRTUAL_MAILBOXES_SERVER_IDS, MailFlags, MoveMailApiArg, ComposeTypes, RegexHelper, isCustomMailbox, MailThread, AppConfiguration, DefaultRoutes
} from "src/common";
import { uiActions, mailActions } from "src/store";
import { CoverTemplate, Icon, Loading, PageHeader } from "src/components";
import {
	ListView, ThreadView, Toolbar, WelcomeModal, Mailboxes, MailSearchHeader, SearchResultsView, ComposeMailList, InviteNotifications, MoveModal,
	ExternalLinkModal, ExpressSubsumeModal
} from ".";
import _ from "lodash";
import { createRoot } from "react-dom/client";

const is_web = AppConfiguration.buildForWeb();
function MailWrapperComponent() {
	const { mailbox, page, thread_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const [active_mailbox_name, setActiveMailboxName] = useState<string | undefined>(!!mailbox ? capitalize(mailbox.toLowerCase()) : mailbox);
	const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
	const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
	const [externalLinkModal, setExternalLinkModal] = useState<string | null>(null);
	const [offset, setOffset] = useState<number>(0);
	const [subsumeRequestId, setSubsumeRequestId] = useState<string | null>(null);
	const current_account = useAppSelector((state) => state.account.current_account);
	const current_mailbox = useAppSelector((state) => state.mail.current_mailbox);
	const mailboxes_counts = useAppSelector((state) => state.mail.mailboxes_counts);
	const compose_messages = useAppSelector((state) => state.mail.compose);
	const current_thread = useAppSelector((state) => state.mail.current_thread);
	const isLoading = useAppSelector((state) => state.mail.is_loading);
	const selected = useAppSelector((state) => state.mail.selected);
	const pagination = useAppSelector((state) => state.mail.pagination);
	const threads_page = useAppSelector((state) => state.mail.threads_page);
	const new_mail = useAppSelector((state) => state.mail.new_mail);
	const refresh_counts = useAppSelector((state) => state.mail.refresh_counts);
	const [dragging_threads, setDraggingThreads] = useState<string[]>();
	const move_destination = useAppSelector((state) => state.mail.move_destination);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [getCounts] = collectionApi.endpoints.getSummaryCounts.useLazyQuery();
	// Description: Allow ongoing getThreads callback to have access to current mailbox state
	const currentMailboxRef = useRef<MailboxItem | undefined>(current_mailbox);
	function setCurrentMailbox(_current_mailbox?: MailboxItem) {
		currentMailboxRef.current = _current_mailbox;
	}

	// Description: load LoginEmailForm (First onload event)
	useEffect(() => {
		handleInitMail();
		return () => { // Destroy Mail on navigating out of module
			dispatch(uiActions.handleMessageDismiss());
		};
	}, []);

	// Description: detect change in move_destination (for drag and drop)
	useEffect(() => {
		!!move_destination && MailWrapperRequests.handleMove(move_destination);
	}, [move_destination]);

	// Description: Handle external link messages and warnings
	useEffect(() => {
		const hash = location.hash;
		(!!hash && hash.length > 0) && handleExternalLink(hash);
	}, [location.hash]);

	// Description: Set new offset for query on Listview
	useEffect(() => {
		setOffset(Pagination.getInitialOffset(page || "1"));
	}, [page]);

	// Description: on navigation to new mailbox or page
	useEffect(() => {
		// NOTE: Skip this updates on Search (i.e clientside pagination and filters)
		// 			- Reset selected on navigation -> mailbox_name !== active_mailbox_name 
		if (!!current_mailbox) {
			setCurrentMailbox(current_mailbox);
			const mailbox_name: string = current_mailbox?.title || capitalize(current_mailbox.name.toLowerCase());
			if (mailbox_name !== active_mailbox_name) {
				setActiveMailboxName(mailbox_name);
				dispatch(uiActions.handleSetDocumentTitle(setMailTitle(mailbox_name)));
			};

			handleLoadMail();
			// NOTE: Reset Select on navigations if not empty
			!!selected && dispatch(mailActions.setSelected([]));
		}
	}, [current_mailbox, offset]);


	// Description: after setting threads clear setLoading
	useEffect(() => {
		!!threads_page &&
			MailWrapperRequests.handleSetThreadsSuccess(threads_page);
	}, [threads_page]);

	// Description: On navigation to thread view threads_page will not change
	// NOTES: Debounce have to be set to 4000 so that it matches Collections debouncer timer for notify_ws ()
	useEffect(() => {
		!!thread_id && getCurrentThread();
	}, [thread_id]);

	// Description: Set new Refresh all on new mail notification from notify_ws
	// NOTES: need to debounce and skip new_mails when they are result from bulk updates or client actions = 
	//				otherwise it will trigger multiple refresh / call to server
	useEffect(() => {
		new_mail && onNewMailHandler(!isLoading);
	}, [new_mail]);

	// Description: Trigger mailbox counts from store/sagas on specific callbacks
	useEffect(() => {
		refresh_counts && getMailboxNotifications();
	}, [refresh_counts]);

	// Description: call refresh UI and toggle new_mail flag on a debouncer matching CS debouncer time
	const onNewMailHandler = useCallback(
		_.debounce((refresh: boolean) => {
			refresh && handleLoadMail();
			dispatch(mailActions.setNewMail(false));
		}, 4000), [current_mailbox, isLoading]);

	// -------------------------------------------------------------------------------
	// Description: Handle Main Wrapper onload and refresh functionalities
	// -------------------------------------------------------------------------------
	// Description: Handle Query Params on first load
	function handleInitMail() {
		if (!!searchParams.toString()) {
			const page_action_qs = searchParams.get(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
			if (!!page_action_qs) {
				initAccountMessaging(page_action_qs);
				searchParams.delete(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
			}
			const term_qs = searchParams.get(QueryParamKeys.SEARCH_RESULT_QUERY_KEY);
			if (!!term_qs) {
				dispatch(mailActions.setSearchTerm(term_qs));
				searchParams.delete(QueryParamKeys.SEARCH_RESULT_QUERY_KEY);
			}
			const feedback = searchParams.get(QueryParamKeys.PAGE_FEEDBACK_QUERY_KEY);
			if (!!feedback) {
				handleFeedbackRequest();
				searchParams.delete(QueryParamKeys.PAGE_FEEDBACK_QUERY_KEY);
			}

			// NOTE: public pages redirects 
			!!searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY) && searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
			// NOTE: happens when Clicking Login from marketing site, in the form /mail/inbox?nonce=[random_number]
			!!searchParams.get(QueryParamKeys.PAGE_NONCE_QUERY_KEY) && searchParams.delete(QueryParamKeys.PAGE_NONCE_QUERY_KEY);
			setSearchParams(searchParams);
		}
	}

	// Description: Get action message depending on QS type (ie. new, recovered...)
	function initAccountMessaging(page_action_qs: string) {
		if (Object.keys(QueryParamMessages).includes(page_action_qs)) {
			const msg = QueryParamMessages[page_action_qs];
			!!msg && dispatch(uiActions.handleSetMessage(new Message(msg)));
			if (page_action_qs !== QueryParamMessages.existing) {
				setShowWelcomeModal(true);
			}
		} else {
			dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.no_qs_action_message_found.replace(MessageAnchors.actionType, page_action_qs),
				MessageHandlerDisplayType.logger)));
		}
	}

	// Description: Handles users action to send feedback to feedback@preveil.com from left nav click on other modules
	function handleFeedbackRequest() {
		const compose_message = new ComposeMail(ComposeTypes.new);
		compose_message.setDefaults("feedback@preveil.com", "Feedback for PreVeil");
		dispatch(mailActions.addComposeMessage(compose_message));
	}

	// Description: set the dynamic title to store
	// NOTE: mock_notification_counts information will come from crypto/server call
	function setMailTitle(mailbox_name: string): string {
		let count = "";
		const _name = mailbox === DefaultMailboxes.inbox ? DefaultMailboxes.unread : mailbox;
		if (!!mailboxes_counts && _name && isMailboxNotificationType(_name)) {
			const n = mailboxes_counts[_name];
			count = !!n && !isNaN(n) && n > 0 ? ` (${n})` : "";
		}
		return `${mailbox_name}${count}`;
	}

	// Description: Onload and Refresh interfaces 
	// NOTES:  			will update thread view on getThreads success block 
	function handleLoadMail(): void {
		if (!isLoading && !!current_mailbox) {
			// NOTE: make sure !!current_mailbox and UI is not in the process of updating from other source
			getThreads();
			getMailboxNotifications();
			refreshMailboxes();
		}
	}

	// -------------------------------------------------------------------------------
	// Description: Handle all children component actions and store it
	// -------------------------------------------------------------------------------
	const MailWrapperRequests = {
		//  Description: Navigate to page passed - different behavior for search
		handlePaging: (new_page: string) => {
			if (new_page !== page && isNumber(new_page)) {
				navigate(`/mail/${encodeURIComponent(mailbox || DefaultMailboxes.inbox)}/${new_page}`);
			} else {
				MailWrapperRequests.handlePageErrorMessage({
					message: GlobalErrorMessages.default, stack: {
						error: `There was a Problem navigating to Page: ${new_page} in mailbox: ${mailbox} `
					}
				});
			};
		},

		//  TODO - Description: Handles paging within the individual threads (small pagination, only Prev and Next arrows)  ***** WORKING
		handleSimplePaging: (action: SimplePaginationActionType) => {
			if (!!action) {
				if (action === SimplePaginationAction.next) {
					// navigate(`/mail/${encodeURIComponent(mailbox)}/${new_page}/${thread}`);
					// LOOK UP NEXT THREAD AND NAVIGATE TO IT
					// Problem: if last thread in page need to navigate to next page then pick first thread
					// NOTE: It wont be triggered if this is the last thread (button should be disabled) 
				} else {
					// LOOK UP PREVIOUS THREAD AND NAVIGATE TO IT
					// Problem: if first thread in the page need to navigate to previous page then pick last thread
					// NOTE: It wont be triggered if this is the first (button should be disabled) 
				}
			} else {
				MailWrapperRequests.handlePageErrorMessage({
					message: GlobalErrorMessages.default, stack: {
						error: `There was a Problem navigating to ${action} thread in mailbox: ${mailbox} `
					}
				});
			}
		},

		// Description: Handles select all from toolbar
		handleSelect: (params: { thread: Thread, selected: boolean }) => {
			let new_selected = selected.slice();
			const thread_id = !!params.thread ? params.thread.thread_id : null;
			if (!!thread_id) { // Note: individual check
				if (params.selected) {
					!_.find(new_selected, (_selected: string) => (_selected === thread_id)) &&
						new_selected.push(thread_id);
				} else {
					const index = _.findIndex(new_selected, (_selected: string) => (_selected === thread_id));
					index >= 0 && (new_selected.splice(index, 1));
				}
			} else { // Note: bulk actions
				new_selected = params.selected ? _.map(threads_page?.threads, (thread: Thread) => (thread.thread_id)) : [];
			}
			dispatch(mailActions.setSelected(new_selected));
		},

		// Description: Mark Threads or messages unread
		// NOTE: if params === undefined -> use selected items - set default flag state to true
		// PARAM: Pass ThreadMessage[] and current star state - Collect selected if params is undefined
		handleStar: (params?: { messages: ThreadMessage[], is_starred: boolean }) => {
			const is_bulk_action = !params?.messages;
			const messages = params?.messages ? params?.messages : getSelectedThreadMessages();
			if (!!messages && messages.length > 0) {
				is_bulk_action && setBulkActionState();
				dispatch(mailActions.updateFlags({ messages, flag: MailFlags.STAR_FLAG, remove: params?.is_starred, offset }));
			}
		},

		// Description: Mark Threads or messages unread / read
		// NOTE: if params === undefined -> use selected items  - Collect selected if params is undefined
		handleUpdateSeenFlag: (params?: { messages: ThreadMessage[], remove: boolean }) => {
			const is_bulk_action = !params?.messages;
			const messages = params?.messages ? params?.messages : getSelectedThreadMessages();
			if (!!messages && messages.length > 0) {
				is_bulk_action && setBulkActionState();
				dispatch(mailActions.updateFlags({ messages, flag: MailFlags.SEEN_FLAG, remove: params?.remove, offset }));
			}
		},

		// Description: Delete mails from current mailbox
		// NOTE: Copy emails to trash. unless email is already in trash or draft. then set `DELETED_FLAG` on them.
		// NOTE: using Collection call for both build modes
		handleDelete: (params: { messages: ThreadMessage[] }) => {
			const is_bulk_action = !params?.messages;
			const messages = params?.messages ? params?.messages : getSelectedThreadMessages();
			const mailbox_id = current_mailbox?.mailbox_id;
			if (!!messages && messages.length > 0 && !!mailbox_id) {
				is_bulk_action && setBulkActionState();
				dispatch(mailActions.deleteMail({ messages, offset, mailbox_id }));
				if (!!current_thread && !(messages.length === 1 && messages[0].flags.includes(MailFlags.DRAFT_FLAG))) {
					const mailbox_name = !isCustomMailbox(current_mailbox) ? current_mailbox.role : (current_mailbox.url_identifier || "");
					navigate(`/mail/${encodeURIComponent(mailbox_name)}/${!!pagination ? pagination.pageIndex + 1 : 1}`);
				}
			} else {
				console.error("CANT DELETE");
			}
		},

		// Description: handle move modal from toolbar click
		handleMoveModal: (show: boolean) => {
			setShowMoveModal(show);
		},

		// Description: Reset External Link Modal
		handleResetExternalLink: () => {
			setExternalLinkModal(null);
		},

		handleResetSubsumeModal: () => {
			setSubsumeRequestId(null);
		},

		// Description: Move selected to another mailbox folder
		handleMove: (destination_mailbox: MailboxItem) => {
			const messages = !!dragging_threads ? getSelectedThreadMessages(dragging_threads) : current_thread?.messages || getSelectedThreadMessages();
			setDraggingThreads(undefined);
			dispatch(mailActions.setMoveDestination());
			if (!!current_account && !!current_mailbox && messages.length > 0 && !!destination_mailbox) {
				setBulkActionState(); // Defaults to 	is_bulk_action === true
				// NOTE: For Web need to pass message thread_id and 1 per item
				const params: MoveMailApiArg[] = is_web ?
					(_.uniqBy(_.map(messages, (message: ThreadMessage) => ({
						collectionId: current_account.mail_cid,
						tid: message.thread_id,
						source_mailbox: current_mailbox.mailbox_id,
						dest_mailbox: destination_mailbox.mailbox_id
					})), "tid")) :
					// NOTE: For App need to pass only the messages that live within the current_mailbox
					(_.compact(_.map(messages, (message: ThreadMessage) => {
						if (message.mailbox_id === current_mailbox.mailbox_id) {
							return {
								collectionId: current_account.mail_cid,
								tid: message.unique_id,
								source_mailbox: current_mailbox.mailbox_id,
								dest_mailbox: destination_mailbox.mailbox_id
							};
						}
					})));

				dispatch(mailActions.moveMail(params));
				if (!!current_thread) {
					const mailbox_name = !isCustomMailbox(current_mailbox) ? current_mailbox.role : (current_mailbox.url_identifier || "");
					navigate(`/mail/${encodeURIComponent(mailbox_name)}/${!!pagination ? pagination.pageIndex + 1 : 1}`);
				}
			}
		},

		// Description: Refresh the data in the current mailbox
		handleRefresh: handleLoadMail,

		// Description: On successful get threads set pagination object
		handleSetThreadsSuccess: (_threads_page: ThreadsPage) => {
			getCurrentThread();
			const totalPageRows = _threads_page.threads.length;
			const totalRows = _threads_page.total || 0;
			if (totalPageRows > 0 && totalRows > 0) {
				const paginationItem = new Pagination(Pagination.getPageIndex(page), totalPageRows, totalRows).pagination_item;
				!!paginationItem && dispatch(mailActions.setPagination(paginationItem));
			} else {
				dispatch(mailActions.resetPagination());
			}
			dispatch(mailActions.setLoading(false));
		},

		// Description: Reset Current thread on leave Threadview
		handleResetCurrentThread: () => {
			dispatch(mailActions.resetCurrentThread());
		},

		handleSetServiceLoading: (is_loading: boolean) => {
			dispatch(mailActions.setLoading(is_loading));
		},

		// Reset data and loading 
		handleSetServiceError: (params: { message: string, stack?: any, display_type: MessageDisplayType }) => {
			dispatch(mailActions.setLoading(false));
			dispatch(uiActions.handleRequestErrors(new Message(params.message, !!params.display_type ? params.display_type : MessageHandlerDisplayType.toastr), params.stack));
		},

		// Description: Initialize a Compose message from within thread (not new)
		handleAddComposeMessage: (params: { message: ThreadMessage, content_type: ComposeTypesType }) => {
			const new_compose = compose_messages.slice();
			const _message = params.message;
			const index = _.findIndex(new_compose, (compose: ComposeMailMessage) => (compose.uid === _message.unique_id));
			// NOTES: Search for this message in state to see if its already opened first, then replace with new request
			// ELSE: Add new compose_message to array
			const compose_mail = new ComposeMail(params.content_type, _message).compose_mail;
			index >= 0 ? new_compose.splice(index, 1, compose_mail) : new_compose.push(compose_mail);
			dispatch(mailActions.setComposeMessage(new_compose));
		},
		// Description: Handler for when the user starts dragging an item or items.
		handleOnDrag: (params: { e: DragEvent, thread: Thread }) => {
			const { e, thread } = params;
			const threads = selected.includes(thread.thread_id) ? selected : [thread.thread_id]; // if this specific entry is selected it will move all the selected items
			setDraggingThreads(threads);
			const image = (
				<div id="drag">
					<Icon className="pv-icon-message" />
					<div>{`Move ${threads.length} conversation${threads.length > 1 ? "s" : ""}`}</div>
				</div>
			);
			const ghost = document.createElement("div");
			ghost.style.transform = "translate(-10000px, -10000px)";
			ghost.style.position = "absolute";
			document.body.appendChild(ghost);
			e.dataTransfer.setDragImage(ghost, 0, 0); // sets a custom drag image like how it looks in web V1
			const root = createRoot(ghost);
			root.render(image);
		},
		handleOnDragEnd: () => {
			setDraggingThreads(undefined);
		},
		// Description: Handle all page / components top level errors
		handlePageErrorMessage: (params: { message: string, stack?: any, display_type?: MessageDisplayType }) => {
			const display_type = !!params.display_type ? params.display_type : MessageHandlerDisplayType.logger;
			dispatch(uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack));
		}
	};


	//  Description: Handle all actions from Children forms
	function handlePageActions(actionObj: IActionHandler) {
		const callback = `handle${actionObj.actionType}`;
		if ((MailWrapperRequests as any)[callback] instanceof Function) {
			(MailWrapperRequests as any)[callback](actionObj.params);
		} else {
			const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
			MailWrapperRequests.handlePageErrorMessage({ message, stack: actionObj });
		}
	}

	// -------------------------------------------------------------------------------
	// Description: General supporting methods
	// -------------------------------------------------------------------------------
	// Description: Set threads and pagination - changed to saga
	// NOTE: Fetch threads if not Client mailbox: ["outbox", "starred", "unread", "search"];
	function getThreads() {
		const active_current_mailbox = currentMailboxRef.current;
		if (!!active_current_mailbox && !!current_account) {
			dispatch(mailActions.setLoading(true));
			const isClientMailbox = ClientDefaultMailboxesType.includes(active_current_mailbox.mailbox_id);
			const params = {
				user_id: current_account.user_id,
				collection_id: current_account.mail_cid,
				mailbox_id: active_current_mailbox.mailbox_id,
				limit: GlobalConstants.LIMIT_NUMBER_THREAD_TO_FETCH,
				offset
			};
			!isClientMailbox ?
				dispatch(mailActions.getMailThreads(params)) : fetchClientMailbox(params);
		} else {
			MailWrapperRequests.handlePageErrorMessage({
				message: MailErrorMessages.error_getting_mail_threads, stack: {
					stack_message: "no current_mailbox selected"
				}, display_type: MessageHandlerDisplayType.toastr
			});
		}
	}

	// Description: Handle refresh of mailboxes when new_mail (notify) comes in or on handleRefresh
	function refreshMailboxes() {
		dispatch(collectionApi.util.invalidateTags(["mailbox"]));
	}

	// Description: Set current thread from decrypted threads_page but if not found fetch from cs
	function getCurrentThread() {
		if (!!thread_id && !!current_account) {
			const _current_thread = threads_page?.threads.find((thread: Thread) => thread.url_identifier === thread_id);
			if (!!_current_thread) {
				const messages = _current_thread.messages;
				dispatch(mailActions.setCurrentThreadSuccess({
					thread_id: _current_thread.thread_id,
					messages,
					unique_ids: MailThread.getUniqueIds(messages),
					message_count: messages.length
				}));
			} else {
				const tid = decodeURIComponent(thread_id);
				dispatch(mailActions.getCurrentThread({
					collectionId: current_account.mail_cid,
					tid,
					sinceRevId: 0,
					mailbox_id: threads_page?.mailbox_id || ""
				}));
			}
		}
	}

	// Description: Set up bulk action / multiselect loading state locally and in store
	function setBulkActionState(_loading: boolean = true) {
		dispatch(mailActions.setLoading(_loading));
		dispatch(mailActions.setBulkAction(_loading));
	}

	// Description: Fetch the selected thread messages for flagging
	function getSelectedThreadMessages(dragging_threads?: string[]): ThreadMessage[] {
		let selected_messages: ThreadMessage[] = [];
		const threads = threads_page?.threads;
		const selected_threads = !!dragging_threads ? dragging_threads : selected;
		if (!!threads && selected_threads.length > 0) {
			_.forEach(selected_threads, (id: string) => {
				const thread = _.find(threads, (_thread: Thread) => (_thread.thread_id === id));
				(!!thread) && (selected_messages = selected_messages.concat(thread.messages));
			});
		};
		return selected_messages;
	}

	// Description: Fetch Cient mailboxes: ["outbox", "starred", "unread"]; 
	// NOTE: Ignore search
	function fetchClientMailbox(params: GetThreadsParams) {
		const mailbox_id = getVirtualMailboxId(params.mailbox_id);
		if (mailbox_id !== VIRTUAL_MAILBOXES_SERVER_IDS.search) {
			if (!!mailbox_id) {
				const new_params = Object.assign({}, params, { mailbox_id });
				dispatch(mailActions.getClientMailThreads(new_params));
			} else {
				MailWrapperRequests.handlePageErrorMessage({
					message: MailErrorMessages.error_getting_mail_threads, stack: {
						stack_message: "no VIRTUAL_MAILBOXES_SERVER_IDS found"
					}, display_type: MessageHandlerDisplayType.toastr
				});
			}
		} else {
			// NOTE: remove loading for search transitions and reset threads page
			dispatch(mailActions.setLoading(false));
		}
	}

	// Description: Get left navigation "bubble" counts on changes 
	function getMailboxNotifications() {
		(!!current_account) &&
			getCounts({
				account_ids: Account.getAccountIdentifiers(current_account),
				body: {
					collectionId: current_account.mail_cid,
				}
			}).unwrap()
				.then((response: MailboxNotificationCounts) => {
					!!response && dispatch(mailActions.setMailboxesNotificationCountsSuccess(response));
				})
				.catch((stack: unknown) => {
					MailWrapperRequests.handlePageErrorMessage({
						message: MailErrorMessages.error_getting_mail_threads, stack, display_type: MessageHandlerDisplayType.logger
					});
				});
	}

	// Description: Handle external links
	function handleExternalLink(external_link: string): void {
		let decoded = decodeURIComponent(external_link);
		decoded = decoded.replaceAll(new RegExp(QueryParamKeys.EXTERNAL_LINK_PARAM, "gi"), "");
		if (decoded.match(/http:\/\/127\.0\.0\.1:.003/gi)) {
			const path = decoded.replace(/http:\/\/127\.0\.0\.1:.003/gi, "");
			if (is_web && path.startsWith(DefaultRoutes.settings_approvals)) {
				// NOTE: Handle subsume modal
				navigate(-1);
				const request_id = path.split("/").pop()?.replace("?", "");
				!!request_id ? setSubsumeRequestId(request_id) :
					dispatch(uiActions.handleRequestErrors(new Message(MailErrorMessages.default)));
			} else {
				// NOTE: This is an internal link navigate within the right build
				navigate(path);
			}
		} else {
			// NOTE: Reset url remove hash
			navigate(-1);
			if (decoded.includes("mailto:")) {
				// Get email address from decoded string, need to account for subject included in old admin messages separated by ?
				const arr = decoded.split("?");
				const subject = arr.length > 1 && arr[1].includes("subject") ? arr[1].replace("subject=", "") : undefined;
				const to = arr[0].replace("mailto:", "");
				if (RegexHelper.testEmailAddress(to)) {
					const compose_message = new ComposeMail(ComposeTypes.new);
					// NOTE: Pass default form values to compose mail
					compose_message.setDefaults(to, subject);
					dispatch(mailActions.addComposeMessage(compose_message));
				} else {
					MailWrapperRequests.handlePageErrorMessage({
						message: MailErrorMessages.error_external_link, stack: {
							stack_error: `Incorrectly Built external_link: ${external_link}; decoded ${decoded}`
						}, display_type: MessageHandlerDisplayType.toastr
					});
				}
			} else {
				// NOTE: This is a link to an external page, set modal warning
				setExternalLinkModal(decoded);
			}
		}
	}

	// Description: Render the correct view
	function RenderView() {
		if (!!current_account && !!current_mailbox) {
			const props = { current_account, current_mailbox, offset, page, selected, isLoading };
			if (!!mailbox && mailbox === "search") {
				return <SearchResultsView {...props}
					pagination={pagination}
					handleAction={handlePageActions} />;
			} else {
				return <>
					{
						!!thread_id ?
							((!!current_thread && thread_id === current_thread.thread_id) ?
								<ThreadView {...props} current_thread={current_thread} handleAction={handlePageActions} /> : <Loading className="in-place mt-5" />) :
							<ListView	{...props} handleAction={handlePageActions} dragging_threads={dragging_threads} />
					}
				</>;
			}
		}
	}

	// -------------------------------------------------------------------------------
	// Description: Render components 
	// ------------------------------------------------------------------------------- 
	return <>
		{
			(isLoading && !thread_id) && <Loading className="mt-5" />
		}
		<CoverTemplate className={`mail-component${isLoading ? " isloading" : ""}`}>
			<PageHeader>
				<MailSearchHeader mailbox_name={active_mailbox_name || DefaultMailboxes.inbox}
					full_search={current_mailbox?.mailbox_id === DefaultMailboxes.search} handleAction={handlePageActions} />
				{
					!!current_account && <InviteNotifications current_account={current_account} handleAction={handlePageActions} />
				}
				{
					!!current_mailbox &&
					<Toolbar
						isLoading={isLoading}
						selected={selected}
						mailbox_name={!isCustomMailbox(current_mailbox) ? current_mailbox.role : (current_mailbox.url_identifier || "")}
						pagination={pagination}
						thread={current_thread}
						handleAction={handlePageActions} />
				}
			</PageHeader>
			<div className={`cover-content ${!!thread_id ? "thread-view" : "list-view"}`}>
				{
					RenderView()
				}
				{
					(!!current_account) &&
					<>
						<Mailboxes current_account={current_account} mailbox_name={mailbox || DefaultMailboxes.inbox} />
						<ComposeMailList current_account={current_account} compose_messages={compose_messages}
							handleAction={handlePageActions} />
						{
							(!!showMoveModal && !!current_mailbox) &&
							<MoveModal count={!!current_thread ? 1 : selected.length} current_mailbox={current_mailbox} handleAction={handlePageActions} />
						}
						{!!showWelcomeModal && <WelcomeModal />}
						{!!externalLinkModal &&
							<ExternalLinkModal external_link={externalLinkModal} handleAction={handlePageActions} />}
						{!!subsumeRequestId &&
							<ExpressSubsumeModal
								account_ids={Account.getAccountIdentifiers(current_account)}
								request_id={subsumeRequestId}
								handleAction={handlePageActions} />}
					</>
				}
			</div>
		</CoverTemplate>
	</>;
}

export default React.memo(MailWrapperComponent);
