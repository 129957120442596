import React, { useEffect, useState } from "react";
import { IActionHandler, OrgInfo, CollectionServerUser, SelectedEmailGroup, MessageDisplayType } from "@preveil-api";
import {
  Account,
  AdminMessages,
  AdminErrorMessages,
  AdminSuccessMessages,
  GlobalErrorMessages,
  collectionApi,
  useAppDispatch,
  useAppSelector,
  useGetUsersOrgsByEntityIdQuery,
  usePostUsersOrgsByEntityIdRecipientGroupsMutation,
  useDeleteUsersOrgsByEntityIdRecipientGroupsAndAliasMutation,
  usePutUsersOrgsByEntityIdRecipientGroupsAndAliasMutation,
  useGetUsersOrgsByEntityIdRecipientGroupsQuery,
  Message,
  MessageAnchors,
  MessageHandlerDisplayType,
  MessageToastTypes,
  isSameUser,
} from "src/common";
import { PageHeader, CoverTemplate, Loading } from "src/components";
import { uiActions } from "src/store";
import { EmailGroupToolbar, EmailGroupListView, EmailGroupSidePanel, CreateGroupEmailModal } from ".";
import _ from "lodash";

type AllProps = {
  account: Account;
  org_info: OrgInfo;
}

function EmailGroup(props: AllProps) {
  const { account, org_info } = props;
  const accounts = useAppSelector((state) => state.account.accounts);
  const [postUsersOrgsByEntityIdRecipientGroups] = usePostUsersOrgsByEntityIdRecipientGroupsMutation();
  const [putUsersOrgsByEntityIdRecipientGroupsAndAlias] = usePutUsersOrgsByEntityIdRecipientGroupsAndAliasMutation();
  const [deleteUsersOrgsByEntityIdRecipientGroupsAndAlias] = useDeleteUsersOrgsByEntityIdRecipientGroupsAndAliasMutation();
  const [getUsersOrgsByEntityIdRecipientGroupsAndAlias] = collectionApi.endpoints.getUsersOrgsByEntityIdRecipientGroupsAndAlias.useLazyQuery();
  const [getUsersOrgsByEntityIdRecipientGroups] = collectionApi.endpoints.getUsersOrgsByEntityIdRecipientGroups.useLazyQuery();
  const [local_users_accounts_map, setLocalUsersAccountsMap] = useState<Map<string, Account>>(); // All accounts from localusers.
  const [show_groups_side_panel, setShowGroupsSidePanel] = useState<boolean>(false);
  const [show_copy_modal, setShowCopyModal] = useState<boolean>(false);
  const [show_modal, setShowModal] = useState<boolean>(false);
  const [edit_mode, setEditMode] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>([]); // Groups selected/checked in the main panel.
  const [aliases, setAliases] = useState<string[]>([]);
  const [is_loading_group_members, setIsLoadingGroupMembers] = useState<boolean>(true);
  const [selected_email_group, setSelectedEmailGroup] = useState<SelectedEmailGroup>();
  const [org_users, setOrgUsers] = useState<CollectionServerUser[]>([]);
  const [is_loading_list, setIsLoadingList] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // RTK HOOKS
  const { data, error, isLoading } = useGetUsersOrgsByEntityIdRecipientGroupsQuery({
    account_ids: Account.getAccountIdentifiers(account),
    body: {
      entityId: account?.org_info?.org_id
    }
  }, { skip: !account });

  const { data: org_data, error: org_error } = useGetUsersOrgsByEntityIdQuery({
    account_ids: Account.getAccountIdentifiers(account),
    body: {
      entity_id: org_info.org_id,
    }
  }, { skip: !account });

  useEffect(() => {
    if (!!data) {
      const { aliases } = data;
      setAliases(aliases);
    } else if (!!error) {
      const params = {
        message: AdminErrorMessages.error_fetching_admin_groups,
        stack: error
      };
      dispatchPageError(params);
    }
  }, [data, error]);

  useEffect(() => {
    if (!_.isEmpty(org_data) && org_data?.users) {
      const { users } = org_data;
      setOrgUsers(users);
      setIsLoadingGroupMembers(false);
    }

    if (org_error) {
      const params = {
        message: AdminErrorMessages.error_fetching_org_info,
        stack: org_error
      };
      dispatchPageError(params);
    }

  }, [org_data, org_error]);

  // Description: When the selected list is changed, we eitheropen or close the side panel. If there is only one group selected,
  // we get the members of that group to display in the side panel.
  useEffect(() => {
    selected.length === 0 ? setShowGroupsSidePanel(false) : setShowGroupsSidePanel(true);
    if (selected && selected.length === 1) {
      const aliasGroup = selected[0];
      getGroupMembers(aliasGroup);
    } else {
      setSelectedEmailGroup(undefined);
    }
  }, [selected]);

  useEffect(() => {
    if (accounts && accounts.length >= 1 && !local_users_accounts_map) {
      const allAccountsMap = new Map(accounts.map((account) => {
        const key = account.user_id;
        return [key, account];
      }));

      setLocalUsersAccountsMap(allAccountsMap);
    };
  }, [local_users_accounts_map]);

  // Description: Updates the admin group
  async function updateAdminGroup(params: { alias: string, add: string[], remove: string[] }) {
    if (account && org_info && params) {
      const entityId = org_info.org_id;
      const account_ids = Account.getAccountIdentifiers(account);
      const { alias, add, remove } = params;
      const body = {
        add,
        remove,
        alias,
        entityId
      };
      await putUsersOrgsByEntityIdRecipientGroupsAndAlias({
        account_ids,
        body
      })
        .unwrap()
        .then(() => {
          const message = AdminSuccessMessages.success_update_admin_group.replace(MessageAnchors.user_id, alias);
          getGroupMembers(alias);
          dispatch(uiActions.handleSetMessage(new Message(message)));
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_updating_admin_group.replace(MessageAnchors.user_id, alias),
            stack: error
          };
          dispatchPageError(params);
        });
    }
  }

  // Description: refreshes the email group list
  async function refreshAdminGroupList() {
    if (account && org_info) {
      setIsLoadingList(true);
      const entityId = org_info?.org_id;
      const account_ids = Account.getAccountIdentifiers(account);
      const body = { entityId };
      await getUsersOrgsByEntityIdRecipientGroups({
        account_ids,
        body
      })
        .unwrap()
        .then((result) => {
          const { aliases } = result;
          setAliases(aliases);
          setIsLoadingList(false);
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_fetching_admin_groups,
            stack: error
          };
          dispatchPageError(params);
        });
    }
  }

  // Description: gets the group members of a specific group_alias.
  async function getGroupMembers(group_alias: string) {
    setIsLoadingGroupMembers(true);
    if (account && account?.org_info && !!group_alias) {
      const current_selected_group: CollectionServerUser[] = [];
      const entityId = account.org_info?.org_id;
      const account_ids = Account.getAccountIdentifiers(account);
      const body = {
        entityId,
        alias: group_alias,
      };

      await getUsersOrgsByEntityIdRecipientGroupsAndAlias({
        account_ids,
        body,
      })
        .unwrap()
        .then((result) => {
          const { members } = result;
          if (members) {
            members.forEach((member) => {
              const user = org_users?.find((user) => isSameUser(user.user_id, member));
              if (!!user) {
                current_selected_group.push(user);
              }
            });
            setIsLoadingGroupMembers(false);
            setSelectedEmailGroup({ alias: group_alias, members: current_selected_group });
          }
        });
    }
  }

  // Description: Request when user is creating a new group.
  async function createNewAdminGroup(params: { alias: string, members: string[] }) {
    if (account && org_info && params) {
      const entityId = org_info.org_id;
      const account_ids = Account.getAccountIdentifiers(account);
      const { alias, members } = params;
      const body = {
        entityId,
        alias,
        members
      };

      await postUsersOrgsByEntityIdRecipientGroups({
        account_ids,
        body
      })
        .unwrap()
        .then(() => {
          const message = AdminSuccessMessages.success_create_admin_group.replace(
            MessageAnchors.user_id,
            alias,
          );
          dispatch(uiActions.handleSetMessage(new Message(message)));
          EmailGroupRequests.handleShowModal();
          refreshAdminGroupList();
        });
    }
  }

  // Description: Notifies if the group alias was successfully deleted.
  function deleteAliasesSuccess(alias_count: number) {
    // total selected group emails.
    const count = alias_count.toString();
    const emailQty = alias_count > 1 ? "emails" : "email";
    const message = AdminSuccessMessages.success_delete_admin_group.replace(MessageAnchors.message_content, `${count} selected group ${emailQty}.`);
    dispatch(uiActions.handleSetMessage(new Message(message)));
    setShowGroupsSidePanel(false);
    setSelected([]);
  }

  // Description: Delete Groups/Aliases.
  async function removeGroupFromList(groupAlias: string | string[]) {
    setIsLoadingList(true);
    if (account && org_info && !!groupAlias) {
      const entityId = org_info.org_id;
      const account_ids = Account.getAccountIdentifiers(account);
      // If there are multiple aliases selected to delete we have to make multiple API calls.
      if (_.isArray(groupAlias)) {
        await Promise.all(groupAlias.map((alias) => {
          return deleteUsersOrgsByEntityIdRecipientGroupsAndAlias({
            account_ids,
            body: {
              entityId,
              alias
            }
          })
            .unwrap()
            .then(() => {
              deleteAliasesSuccess(groupAlias.length);
              setIsLoadingList(false);
            })
            .catch((error) => {
              const params = {
                message: AdminErrorMessages.error_remove_admin_group_aliases,
                stack: error
              };
              dispatchPageError(params);
              setIsLoadingList(false);
            });
        }));
      } else {
        await deleteUsersOrgsByEntityIdRecipientGroupsAndAlias({
          account_ids,
          body: {
            entityId,
            alias: groupAlias
          }
        })
          .unwrap()
          .then(() => {
            deleteAliasesSuccess(1);
            setIsLoadingList(false);
          })
          .catch((error) => {
            const params = {
              message: AdminErrorMessages.error_remove_admin_group_aliases,
              stack: error
            };
            dispatchPageError(params);
            setIsLoadingList(false);
          });
      }
    }
  }

  // Description: Confirmation modal for deleting group emails
  function confirmDeleteGroupDialog(group_alias: string | string[]) {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_delete_group.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_delete_group.title,
      {
        label: "Yes",
        data: true,
        action: () => removeGroupFromList(group_alias)
      },
      {
        label: "No"
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  const EmailGroupRequests = {
    handleRefresh: () => {
      refreshAdminGroupList();
    },
    handleSelect: (params: { id?: string, selected: boolean; }) => {
      let new_selected = selected.slice();
      const _id = params.id;
      if (!!_id) { // Note: individual check
        if (params.selected) {
          !_.find(new_selected, (_selected: string) => (_selected === _id)) &&
            new_selected.push(_id);
        } else {
          const index = _.findIndex(new_selected, (_selected: string) => (_selected === _id));
          index >= 0 && (new_selected.splice(index, 1));
        }
      } else { // Note: bulk actions
        new_selected = params.selected ? aliases : [];
      }
      setSelected(new_selected);
    },
    handleShowSideNavMenu: () => {
      setSelected([]);
      setShowGroupsSidePanel(!show_groups_side_panel);
    },
    handleReset: () => {
      // User click deselect all in side-nav panel.
      setSelectedEmailGroup(undefined);
      setSelected([]);
      setShowGroupsSidePanel(false);
    },
    handleShowModal: (edit?: boolean) => {
      edit && setEditMode(true);
      show_modal && setEditMode(false);
      if (!show_modal)
        setShowCopyModal(false);
      setShowModal(!show_modal);
    },
    handleCopy: () => {
      setShowCopyModal(true);
      setShowModal(!show_modal);
    },
    handleCreate: (params: { alias: string, members: string[] }) => {
      createNewAdminGroup(params);
    },
    handleDelete: () => {
      const group_alias = selected.length > 1 ? selected : selected_email_group?.alias;
      !!group_alias && confirmDeleteGroupDialog(group_alias);
      setShowGroupsSidePanel(false);
    },
    handleUpdate: (params: { alias: string, add: string[], remove: string[] }) => {
      updateAdminGroup(params);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any, type?: MessageDisplayType }) => {
      const { message, stack, type } = params;
      const _type = type || MessageHandlerDisplayType.logger;
      dispatch(uiActions.handleRequestErrors(new Message(message, _type), stack));
    }
  };
  // Description: dispatch any error found in the page.
  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((EmailGroupRequests as any)[callback] instanceof Function) {
      (EmailGroupRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      EmailGroupRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <CoverTemplate className="admin-wrapper">
      <PageHeader>
        <h1>Email Groups</h1>
      </PageHeader>
      <EmailGroupToolbar is_loading={isLoading || is_loading_list} total_items={aliases.length} handleAction={handlePageActions} selected={selected}></EmailGroupToolbar>

      {isLoading || is_loading_list ? (
        <div className="cover-content">
          <div className="admin-panel-center">
            <Loading className="in-place" />
          </div>
        </div>
      ) : (
        <EmailGroupListView
          handleAction={handlePageActions}
          groups_aliases={aliases}
          selected={selected}
        ></EmailGroupListView>
      )}
      {selected.length >= 1 && <EmailGroupSidePanel is_loading={is_loading_group_members} handleAction={handlePageActions} selected_group={selected_email_group} show={show_groups_side_panel} selected={selected}></EmailGroupSidePanel>}
      <CreateGroupEmailModal
        show_modal={show_modal}
        handleAction={handlePageActions}
        org_users={org_users}
        aliases={aliases}
        current_account={account}
        selected_group={!edit_mode && !show_copy_modal ? undefined : selected_email_group}
        is_update_mode={edit_mode}
      ></CreateGroupEmailModal>
    </CoverTemplate>
  );
}

export default React.memo(EmailGroup);

