import React, { useState, useEffect } from "react";
import { ActivityLogInfo, IActionHandler, MessageDisplayType } from "@preveil-api";
import { Card } from "react-bootstrap";
import { GlobalConstants, ActivityLog, filesyncApi, GlobalErrorMessages, Message, MessageAnchors, MessageHandlerDisplayType, SettingsErrorMessages, SimplePaginationAction, SimplePaginationActionType, useAppDispatch, useAppSelector, GetLogsApiResponse, dayjs } from "src/common";
import { PageHeader, DateSelectorDropdown } from "src/components";
import { uiActions } from "src/store";
import { LogsListView, LogsToolBar } from ".";

/* Description: Parent component for settings activity logs */
function SettingsActivityLogsComponent() {
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state) => state.account.current_account);
  const [start, setStart] = useState<string>(dayjs().subtract(2, "week").toDate().toISOString()); // The default time frame is set to the last 2 weeks
  const [end, setEnd] = useState<string>(dayjs().toDate().toISOString());
  const [page, setPage] = useState<number>(0);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [activity_logs, setActivityLogs] = useState<{ [page: number]: ActivityLogInfo[] }>({ 0: [] });
  const [getUserLogs] = filesyncApi.endpoints.getUserLogs.useLazyQuery();

  /* Description: on initial load, get the users logs based on the pagination.
  on clean up of the component (unmounting) - reset the pagination */
  useEffect(() => {
    const previous_page = page - 1;
    let last_log: ActivityLogInfo | undefined;
    if (previous_page in activity_logs) {
      last_log = activity_logs[previous_page].slice(-1)[0];
    }
    const to_index = !!last_log ? last_log.global_idx : undefined;
    const offset = page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
    getLogs(offset, start, end, to_index);
  }, []);

  /* Description: Gets the users logs from the backend depending on the time frame, and offset, page number, and to_index
  we then map the response entries and create an ActivityLog object for each entry. */
  function getLogs(offset: number, start_time: string, end_time: string, to_index?: number, page_index?: number) {
    !!current_account &&
      getUserLogs({
        offset, start_time, end_time, to_index, user_id: current_account.user_id
      }).unwrap()
        .then((response: GetLogsApiResponse) => {
          const logs: ActivityLogInfo[] = response.log_entries.map((log) => {
            const user = log.user_info.user_id.toLowerCase() === current_account.user_id.toLowerCase()
              ? { name: "You", address: log.user_info.user_id }
              : { name: log.user_info.display_name, address: log.user_info.user_id };
            const device = !!log.user_info.devices && log.user_info.devices.length === 1 ? log.user_info.devices[0].device_name : "";
            const al = new ActivityLog(user.address, log.entry_ts, log.collection_id, log.topic, log.action, device, user, log.metadata, log.global_idx);
            return al.getActivityLogInfo();
          });
          const p = (!!page_index || page_index === 0) ? page_index : page;
          activity_logs[p] = logs;
          setPage(p);
          setActivityLogs(activity_logs);
          setIsLoading(false);
        })
        .catch((stack) => {
          setIsLoading(false);
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_fetching_activity),
              stack
            ),
          );
        });
  }

  /* Description: Handle all children component actions and store it */
  const SettingsActivityLogCalls = {
    /* Description: refresh the page by calling get logs */
    handleRefresh: () => {
      setIsLoading(true);
      const offset = page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
      const previous_page = page - 1;
      let last_log: ActivityLogInfo | undefined;
      if (previous_page in activity_logs) {
        last_log = activity_logs[previous_page].slice(-1)[0];
      }
      const _end = dayjs(end).isSame(dayjs(), "date") ? dayjs().toDate().toISOString() : end;
      const to_index = !!last_log ? last_log.global_idx : undefined;
      getLogs(offset, start, _end, to_index);
    },
    /* Description: handled when the user goes to the next page or previous page - calls get log based on the new page */
    handleSimplePaging: (action: SimplePaginationActionType) => {
      if (!!action) {
        const new_page = action === SimplePaginationAction.next ? page + 1
          : page - 1;
        if (!!activity_logs[new_page]) {
          setPage(new_page);
        } else {
          setIsLoading(true);
          const offset = new_page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
          const previous_page = new_page - 1;
          let last_log: ActivityLogInfo | undefined;
          if (previous_page in activity_logs) {
            last_log = activity_logs[previous_page].slice(-1)[0];
          }
          const to_index = !!last_log ? last_log.global_idx : undefined;
          getLogs(offset, start, end, to_index, new_page);
        }
      }
    },
    /* Description: When a new date or time frame is selected, get logs is called again */
    handleDateSelector: (params: { start: Date; end: Date }) => {
      setIsLoading(true);
      setPage(0);
      setStart(params.start.toISOString());
      setEnd(params.end.toISOString());
      const offset = page * GlobalConstants.ACTIVITY_PER_PAGE_COUNT;
      getLogs(offset, params.start.toISOString(), params.end.toISOString(), undefined, 0);
    },
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((SettingsActivityLogCalls as any)[callback] instanceof Function) {
      (SettingsActivityLogCalls as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      SettingsActivityLogCalls.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return (
    <>
      <PageHeader>
        <h1>Activity</h1>
      </PageHeader>
      <Card className="card-section settings-height">
        <b className="mb-1">Timeframe:</b>
        <DateSelectorDropdown handleAction={handlePageActions}></DateSelectorDropdown>
        <LogsToolBar handleAction={handlePageActions} activity_logs={activity_logs[page]} page={page} is_loading={is_loading}></LogsToolBar>
        {!!current_account && (
          <LogsListView loading={is_loading} activity_logs={activity_logs[page]}></LogsListView>
        )}
      </Card>
    </>
  );
}

export default React.memo(SettingsActivityLogsComponent);
