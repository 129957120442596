import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  AccountIdentifiers, ApprovalRequestStatus, ExpressSubsumeErrorMessages, MailUIActionTypes, Message, MessageHandlerDisplayType,
  MessageToastTypes, useAppDispatch, useGetSubsumeApproval
} from "src/common";
import { Loading, Details, Icon } from "src/components";
import { accountActions, uiActions } from "src/store";

type AllProps = {
  account_ids: AccountIdentifiers;
  request_id: string;
  handleAction: ActionHandlerFunction;
}

function ExpressSubsumeModalComponent(props: AllProps) {
  const dispatch = useAppDispatch();
  const { account_ids, request_id, handleAction } = props;
  const { request, error } = useGetSubsumeApproval(account_ids, request_id);

  useEffect(() => {
    !!error?.message && dispatch(uiActions.handleRequestErrors(error.message));
    !!error?.dismiss && handleHideModal();
  }, [error]);

  useEffect(() => {
    switch (request?.status) {
      case ApprovalRequestStatus.approved:
      case ApprovalRequestStatus.denied:
        dispatch(uiActions.handleSetMessage(
          new Message(ExpressSubsumeErrorMessages.repeated_response, MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
        break;
      case ApprovalRequestStatus.failed:
        dispatch(uiActions.handleSetMessage(
          new Message(ExpressSubsumeErrorMessages.request_expired, MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
    }
  }, [request]);

  function handleHideModal() {
    handleAction({ actionType: MailUIActionTypes.ResetSubsumeModal });
  }

  function respondToSubsumeRequest(approve: boolean) {
    if (!request) {
      return dispatch(uiActions.handleRequestErrors(new Message(ExpressSubsumeErrorMessages.default)));
    }
    dispatch(accountActions.respondToSubsumeRequest({ account_ids, request, approve }));
    handleHideModal();
  }

  return <Modal
    size="lg"
    dialogClassName="express-subsume-modal"
    show
    onHide={() => handleHideModal()}
    centered
    aria-labelledby="Express Subsume Modal">
    {!request ? (
      <Loading className="in-place"/>
    ) : (
      <>
        <Modal.Header closeButton>
          <Modal.Title as="h2">Invitation to Join <b>"{request.org_details?.org_name}"</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have been invited to join the organization{" "}
            <b>{!!request.org_details && request.org_details.org_name}</b> by{" "}
            <b>{!!request.requester && request.requester.name}</b>
          </p>
          <p>By joining this group...</p>
          <ul className="check-list">
            <li>
              <Icon className="icon-checkmark" />
              <p>
                Your account will be managed for you by the{" "}
                <b>{!!request.org_details && request.org_details.org_name}</b> administrators.
              </p>
            </li>
            <li>
              <Icon className="icon-checkmark" />
              <p>
                You'll receive the benefit of account recovery from the members of your{" "}
                <a target="_blank" href="https://preveil.atlassian.net/servicedesk/customer/portal/2/article/313655487" rel="noreferrer">Recovery Group</a>.
              </p>
            </li>
            <li>
              <Icon className="ficon-alert-circle" />
              <p>
                Your files and emails will still be available to you. However, your administrators could access your data using the{" "}
                <a target="_blank" href="https://preveil.atlassian.net/servicedesk/customer/portal/2/article/1525645349" rel="noreferrer">Data Export</a> tool.
              </p>
            </li>
          </ul>
          <Details
            request={request}
            current_optionals_required={false}
            compact
          ></Details>
        </Modal.Body>
        {request.status === ApprovalRequestStatus.pending &&
          <Modal.Footer>
            <Button onClick={() => {
              respondToSubsumeRequest(true);
            }}>Join this Organization</Button>
            <Button variant="no-outline-primary" onClick={() => {
              respondToSubsumeRequest(false);
            }}>Reject Invitation</Button>
          </Modal.Footer>
        }
      </>
    )}
  </Modal>;
}

export default React.memo(ExpressSubsumeModalComponent);
