import React, { useEffect, useState, FocusEvent, useRef } from "react";
import { IActionHandler, OrgInfo, CollectionServerUser, UserProfile, WebsocketCryptoMessage } from "@preveil-api";
import { uiActions, WebsocketStatus, websocketActions } from "src/store";
import { 
  AdminMessages,
  AdminErrorMessages,
  AdminSuccessMessages,
  useGetOrgInfo, 
  useRequestExportMutation, 
  Account, 
  ApprovalGroup, 
  GlobalErrorMessages, 
  MessageAnchors, 
  MessageHandlerDisplayType, 
  Message, 
  useAppDispatch, 
  CheckboxStates, 
  CheckboxStatesTypes, 
  dayjs,
  useGetExportRequestsMutation,
  AccounWStSteps,
  useAppSelector,
  useDeleteAdminRequestMutation,
  useGetOrgRequestsMutation,
  useStartExportMutation,
  useGetExportProgressMutation,
  useCancelExportMutation,
  useDeleteExportRequestMutation,
  RequestExportApiResponse,
  MessageToastTypes
} from "src/common";
import { PageHeader, CoverTemplate, Loading, AdminToolbar, DateSelectorDropdown } from "src/components";
import { 
  DataExportGetStartedStep, 
  DataExportGroupUsersPanel, 
  DataExportContent, 
  CollapseSection, 
  DataExportApproveStep, 
  DataExportRequestProgress, 
  DataExportNotApprovalGroup, 
  DataExportGetLocationModal,
  DataExportHeader,
  DataExportRequestDone
 } from ".";
import { ExportRequest } from "./helpers.class";
import { parseExportRequest, createExportRequest, parseDataExportErrorMessage, ExportGroupApprovers } from "./utility";
import keyMirror from "keymirror";
import _  from "lodash";

type AllProps = {
  account: Account;
  org_info: OrgInfo;
}

type DataExportAllContent = {
  mail: boolean;
  drive: boolean;
  logs: boolean;
  acl_report: boolean;
}

type RequestMetadata = {
  include_emails: boolean;
  include_files: boolean;
  include_logs: boolean;
  include_org_acl_report: boolean;
  users: Account[];
} & RequestExportApiResponse

const dataExportStatus = keyMirror({
  not_init: null, // not approval groups assigned yet.
  not_started: null, // no data export request has been made.
  init: null, // data export request has been made and is pending approval.
  pending: null, // data export request is pending approval.
  started: null, // data export request has been approved and started.
  running: null, // data export download is running.
  complete: null, // data export download is complete.
  cancelled: null // data export download is cancelled.
});

const dropdownLabels = {
  users: "All Users",
  content: {
    email: "Email",
    mail: "Email",
    drive: "Drive",
    logs: "Logs",
    acl_report: "ACL Report",
    org_acl_report: "ACL Report"
  },
  date: "Forever"
};

function DataExportComponent({ account, org_info }: AllProps) {
  const dispatch = useAppDispatch();
  const EXPORT_APPROVAL_GROUP = "export_approval_group";
  const os_info = useAppSelector((state) => state.app.os_info);
  const [ is_loading, setIsLoading ] = useState<boolean>(true);
  const [ show_modal, setShowModal ] = useState<boolean>(false);
  const [ data_export_approval_group, setDataExportApprovalGroup ] = useState<ApprovalGroup | undefined>(undefined); // Assigned Approval Group for Data Export.
  const [ data_export_group_users, setDataExportGroupUsers ] = useState<CollectionServerUser[]>([]); // Users in the Data Export Approval Group.
  const [ data_export_group_approvers, setDataExportGroupApprovers ] = useState<UserProfile[]>([]); // Approvers in the Data Export Approval Group.
  const [ request_approvements, setRequestApprovements ] = useState<UserProfile[]>([]); // Users who have approved the Data Export Request.
  const [ selected_users, setSelectedUsers ] = useState<CollectionServerUser[]>([]); // Users selected for Data Export.
  const [ export_request, setExportRequest ] = useState<ExportRequest | null>(null); // Data Export Request.
  const [ export_request_status_step, setExportRequestStatusStep ] = useState<string>(dataExportStatus.not_started); // Data Export Request Status Step.
  const [ filter_param, setFilterParam ] = useState<string>(""); // Filter Param for searching users.
  const [ requestId, setRequestId ] = useState<string>(""); // Request ID for Data Export.
  const [ pin, setPin ] = useState<string>("");
  const [ drop_dir, setDropDir ] = useState<string>(""); // Drop Directory for the Data Export Request.
  const [ data_export_content, setDataExportContent ] = useState<DataExportAllContent>({
    mail: true,
    drive: true,
    logs: false,
    acl_report: false
  });
  // added default values for the start date and end date
  const [start_date, setStartDate] = useState<Date | string>(dayjs().subtract(2, "week").toDate().toISOString());
  const [end_date, setEndDate] = useState<Date | string>(dayjs().toDate().toISOString());
  const [selected_range, setSelectedRange] = useState<string>("default");
  const [ raw_request, setRawRequest ] = useState({});
  const [ export_status, setExportStatus ] = useState<string>("");
  const [ is_open_users_collapse, setIsOpenUsersCollapse ] = useState<boolean>(false);
  const [ is_open_content_collapse, setIsOpenContentCollapse ] = useState<boolean>(false);
  const [ is_open_date_range_collapse, setIsOpenDateRangeCollapse ] = useState<boolean>(false);

  const timeout_id = useRef<any | null>(null);

  // HOOKS
  const { data, error, refresh } = useGetOrgInfo(account, org_info);
  const [ requestExport ] = useRequestExportMutation();
  const [ getExportRequests ] = useGetExportRequestsMutation();
  const [ deleteExportRequest ] = useDeleteExportRequestMutation();
  const [ deleteAdminRequest ] = useDeleteAdminRequestMutation();
  const [ getOrgRequests ] = useGetOrgRequestsMutation();
  const [ startExport ] = useStartExportMutation();
  const [ getExportProgress ] = useGetExportProgressMutation();
  const [ cancelExport ] = useCancelExportMutation();

  // Websocket
  const [ wsStep, setWsStep ] = useState<number>(AccounWStSteps.DISCONNECTED);
  const message = useAppSelector((state) => state.websocket.message as WebsocketCryptoMessage);
  const status = useAppSelector((state) => state.websocket.status);
  const errors = useAppSelector((state) => state.websocket.errors);
  
  const filtered_users = _.isEmpty(filter_param) ? data_export_group_users : filterUsersList(filter_param);

  function filterUsersList(filter_param: string) {
    return data_export_group_users.filter((user) => {
      const lower_case_param = filter_param.toLowerCase();
      return user.user_id?.toLowerCase().includes(lower_case_param)
        || user.display_name?.toLowerCase().includes(lower_case_param)
        || user.entity_metadata.department.toLowerCase().includes(lower_case_param);
    });
  }

  function fetchOrgData() {
    return refresh({
      history: false,
      status: "pending",
      hideExpired: true,
      limit: 200
    });
  }

  // Description: First onload and unload events - destroy ws on leave
  useEffect(() => {
    return () => {
      dispatch(websocketActions.destroyWebsocket());
    };
  }, []);

  useEffect(() => {
    !!errors &&
      dispatch(uiActions.handleRequestErrors(new Message(AdminErrorMessages.default, MessageHandlerDisplayType.logger), errors));
  }, [errors]);

  useEffect(() => {
    if (export_request_status_step === dataExportStatus.not_started && wsStep !== AccounWStSteps.DISCONNECTED) { 
      setWsStep(AccounWStSteps.DISCONNECTED);
    }
  }, [wsStep, export_request_status_step]);

  useEffect(() => {
    fetchOrgData();
    fetchOrgRequests();
  }, []);

  // Description: Respond to changes in message and websocket status
  useEffect(() => {
    const isError = status === WebsocketStatus.error || message?.status === WebsocketStatus.error;
    let next = isError ? AccounWStSteps.ERROR : !!message ? wsStep + 1 : wsStep;

    if (!!message?.pin) {
      setPin(message.pin);
      next = AccounWStSteps.GET_CONNECTION_ESTABLISHED;
    }
    setWsStep(next);

    if (!!message?.status && message.status === WebsocketStatus.key_transfer_complete) {
      getCurrentUserExportRequests(data_export_approval_group);
      setWsStep(AccounWStSteps.DISCONNECTED);
    }

  }, [message, status]);

  useEffect(() => {
    if (export_request_status_step === dataExportStatus.pending) {
      getCurrentUserExportRequests(data_export_group_approvers);
    } else if (!export_request && !!data_export_approval_group) {
      getCurrentUserExportRequests(data_export_approval_group);
    }

  }, [export_request_status_step, data_export_approval_group]);

  useEffect(() => {
    if (data && data?.org_users) {
      const export_approval_group = data.approval_groups?.find((group) => group.roles?.includes(EXPORT_APPROVAL_GROUP));
      // Description: If the export approval group exists, we want to get the users and approvers in the group.
      // If not, we want to set the status to not_init, and show a different UI.
      if (!!export_approval_group && !_.isEmpty(export_approval_group)) {
        const { org_users: orgUsers, export_request } = data;
        const { approvers } = export_approval_group;
        // TODO: If we get support/fix for the deleted users
        // This is where we do the change to include delete users.
        // Remove the !user.deleted and make sure to include the deleted users
        // in useGetOrgInfo hook.
        const org_users = orgUsers.filter((user) => {
          return user.claimed && !user.deleted;
        });

        setDataExportApprovalGroup(export_approval_group);
        setDataExportGroupApprovers(approvers);
        setDataExportGroupUsers(org_users);
        setSelectedUsers(org_users); // As Default: Select all users.

        if (!!export_request && export_request?.type === "export") {
          const { status } = export_request;
          setExportRequestStatusStep(status);
          setExportRequest(export_request);
          const { requester } = export_request;
          if (requester === account.user_id) {
            const get_requester_profile = export_approval_group.approvers.filter((approver) => approver.address === requester)[0];
            const is_requester_in_approvements_list = request_approvements.filter((approver) => approver.address === requester).length > 0;
            !is_requester_in_approvements_list && setRequestApprovements([...request_approvements, get_requester_profile]);
          }
          if (export_request?.request_id && requestId !== export_request?.request_id) {
            setRequestId(export_request?.request_id);
          }
        }
      } else {
        setExportRequestStatusStep(dataExportStatus.not_init);
      }
      setIsLoading(false);
    }

    if (error) {
      const params = {
        message: AdminErrorMessages.default,
        stack: error
      };
      dispatchPageError(params);
    }
  }, [data, data?.export_request, error]);

  function dispatchPageError(params: { message: string, stack?: any}) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  };

  // Description: Get the export request for the current user (Init state or Cancelled state)
  async function getCurrentUserExportRequests(approvers_group: ExportGroupApprovers | null) {
    // Description: We need to get the approvers_info for the current request if there is an actual request under current user.
    // If one of the approvers already approved the request it will be return under approvers_info.
    if (account && approvers_group) {
     // setIsLoading(true);
      const user_id = account.user_id;
      await getExportRequests({
        userId: user_id
      })
      .unwrap()
      .then((response: any) => {
        if (!_.isEmpty(response)) {
          // Description: Long explanation... please bear with me. Stil a WIP.
          // INIT STATE:
          // - We'll get to this point if the user has an init export request status (one of the approvers already has approved the request, that is not the requester, look at the shards).
          // - If we are at the init state, the regular flow of the export request will work, but if for some reason the page is reloaded (or state change), we need to grab
          //   the request again and formatted it to the ExportRequest class, because the request is no longer pending and it will no longer be with the other requests.
          // - If the requester/admin is part of the approvers group, we need to add him to the approved users list, 
          //   so it shows in the UI with an approved checkmark (we don't ask the requester to verify, it's already auto approved).
          // - If the requester/admin is not part of the approvers groups, we need to make sure the approver will not be in the approve users list.
          // - The explanation above works for when the status is in init state.
          // CANCELLED STATE:
          // If the status is in cancelled state, we need to grab the request again because is not longer pending and we need to format it to the ExportRequest class.
          // The explanation above works for when the status is in cancelled state.
          const { approvers_info, status, request } = response[Object.keys(response)[0]]; // TODO: how to get the request_id when re-loading the page?
          const { request_id } = request;
          const _request = dataExportStatus.init === status || dataExportStatus.cancelled === status ? { ...JSON.parse(request.request_payload), request_id } : request;
          const _raw_request = dataExportStatus.init === status || dataExportStatus.cancelled === status ? request.request_payload : request.raw_request;
          const approver_shards = Object.keys(approvers_info);
          const isRequesterFound = approver_shards.includes(account.user_id); // TODO: this is working but probably not needed, more testing pending.
          const members_group_approvers = approvers_group && approvers_group?.group_version ? approvers_group.approvers : approvers_group;
          const isRequesterInApproversGroup = members_group_approvers.filter((approver: any) => approver.address === user_id).length > 0;
          const current_account_found = members_group_approvers
              .filter((approver: any) => approver.address === user_id)
              .map((approver: any) => approver.address);
          const current_approvers_shards = current_account_found.length > 0 && !isRequesterFound ? [...current_account_found, ...approver_shards] : [...approver_shards];

          const current_users_approved = current_approvers_shards.map((approver: string) => {
            return members_group_approvers.filter((approver_info: any) => approver_info.address === approver)[0];
          }).filter(Boolean);
          isRequesterFound && !isRequesterInApproversGroup && current_users_approved.push({ name: account.display_name, address: account.user_id, role: "admin" });
          const new_request: ExportRequest | null = createExportRequest(_request, account, _raw_request, status, current_users_approved, members_group_approvers);
          !!new_request && setRequestApprovements(new_request.approvedUsers);
          setExportRequestStatusStep(status);
          setExportRequest(new_request);
          // setIsLoading(false);
        }
      });
    }
  }

  function initWebsocketConnection(params: { subsumeUser: string }) {
    const { subsumeUser } = params;
    if (!!subsumeUser && account) {
      // This is the signature we are using in V1.
      const options = {
        signature: "dummy"
      };
      const host = `${process.env.REACT_APP_LOCAL_WEBSOCKET_SERVER}/users/${encodeURIComponent(account.user_id)}/connect/${encodeURIComponent(subsumeUser)}`;
      dispatch(websocketActions.wsConnect({ host, init: { action: "pre_init", request: {...raw_request, ...options } }}));
    } else {
      const params = {
        message: AdminErrorMessages.error_no_subsume_or_admin_account,
        stack: { subsumeUser, account }
      };
      dispatchPageError(params);
    }
  }

  async function fetchOrgRequests() {
    if (account && org_info) {
      const userId = account.user_id;
      const orgId = org_info.org_id;

      await getOrgRequests({ 
        userId,
        orgId,
        hideExpired: true,
        limit: 200,
        offset: null,
        status: "pending"
      })
      .unwrap()
      .then((response) => {
        const { requests } = response;
        if (requests && requests.length > 0) {
          const export_request = requests.filter((request) => request.type === "export")[0];
          if (!_.isEmpty(export_request)) {
            const { request_id } = export_request as any;
            setRawRequest(export_request); // TODO: why does it work only in setRawRequest? state is not updated?
            setRequestId(request_id);
          }
        }
      });
    }
  }
  // Description: Delete the export request/cancel export request at any step until the export is process
  // if it's processing then it will be another call.
  async function deleteDataExportRequest(requestId: string) {
    if (account && org_info && requestId) {
      const approvers_shards = request_approvements.filter((approver) => approver.address !== account.user_id);
      // if at least one of the approvers has approved the request (that is not the requester if the requester is in the approvers group)
      // we'll first use this call and then proceed to deleteAdminRequest call if the request is not fully approved.
      if (approvers_shards.length > 0) {
        try {
          await deleteExportRequest({ exportId: requestId, userId: account.user_id, orgId: org_info.org_id })
            .unwrap()
            .then(() => {
              if (request_approvements.length === data_export_approval_group?.required_approvers) {
                clearState();
              }
            });
        } catch (error) {
          const params = {
            message: AdminErrorMessages.error_delete_existing_data_export,
            stack: error
          };
          dispatchPageError(params);
        }
      }
      if (request_approvements.length !== data_export_approval_group?.required_approvers) {
        if (!!requestId) {
          await deleteAdminRequest({ requestId, orgId: org_info.org_id, userId: account.user_id })
            .unwrap()
            .then(() => {
              clearState();
            })
            .catch((error) => {
              const params = {
                message: AdminErrorMessages.error_cancel_export_request,
                stack: error
              };
              dispatchPageError(params);
            });
        }
      }
    } else if (!requestId) {
      const params = {
        message: AdminErrorMessages.error_delete_data_export_request_no_id,
        stack: requestId
      };
      dispatchPageError(params);
    } else {
      const params = { message: AdminErrorMessages.default, stack: { account, org_info, requestId } };
      dispatchPageError(params);
    }
  }

  // Description: Clear the state after the export request is deleted.
  function clearState(clear_only?: boolean) {
    DataExportRequests.handleDestroy();
    setExportRequestStatusStep(dataExportStatus.not_started);
    setRequestApprovements([]);
    setExportRequest(null);
    setIsOpenUsersCollapse(false);
    setIsOpenContentCollapse(false);
    setIsOpenDateRangeCollapse(false);
    setSelectedUsers(data_export_group_users);
    setDataExportContent({
      mail: true,
      drive: true,
      logs: false,
      acl_report: false
    });
    setStartDate(dayjs().subtract(2, "week").toDate().toISOString());
    setEndDate(dayjs().toDate().toISOString());
    !clear_only && dispatch(uiActions.handleSetMessage(new Message(AdminSuccessMessages.success_cancel_export_request)));
  }

  // Description: Get the data export request started.
  async function getStartedRequestDataExport() {
    if (account && account?.org_info) {
      setIsLoading(true);
      const userId = account.user_id;
      const orgId = account.org_info.org_id;
      const users = selected_users.map((user) => {
        return { user_id: user.user_id, key_version: account.account_version };
      });
      const from = start_date as string;
      const until = end_date as string;
      const { mail, drive, logs, acl_report } = data_export_content;

      await requestExport({
        userId,
        orgId,
        payload: {
          from,
          include_emails: mail,
          include_files: drive,
          include_logs: logs,
          include_org_acl_report: acl_report,
          until,
          users
        }
      })
      .unwrap()
      .then((response) => {
        const { request } = response;
        if (request && !_.isEmpty(request) && request.status) {
          const raw_request = response.request;
          const { status, requester, request_id, metadata } = request;
          setRawRequest(raw_request);
          if (status === dataExportStatus.pending) {
            const { include_emails: mail, include_files: drive, include_logs: logs, include_org_acl_report: acl_report } = metadata as RequestMetadata;
            const requester_approver = data_export_group_approvers.filter((approver) => approver.address === requester);
            !!request_id && setRequestId(request_id);
            setExportRequest(request as any);
            setExportRequestStatusStep(status);
            requester_approver.length === 1 && setRequestApprovements(requester_approver);
            setDataExportContent(prevState => ({
              ...prevState,
              mail,
              drive,
              logs,
              acl_report
            }));
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        const params = {
          message: AdminErrorMessages.error_start_data_export,
          stack: error
        };
        dispatchPageError(params);
      });
    }
  }

  // Description: Start the export request.
  // This is when the user has selected the drop directory and the request is ready to be started.
  async function startExportRequest(export_id: string, drop_dir: string, requester_id: string) {
    if (account && org_info && export_id && drop_dir) {
      await startExport({
        userId: account.user_id,
        orgId: org_info.org_id,
        exportId: export_id,
        dropDir: drop_dir
      })
      .unwrap()
      .then((response) => {
        const { dropdir } = response;
        setDropDir(dropdir as string);
        setShowModal(false);
        dropdir && setDropDir(dropdir);
        timeout_id.current = setInterval(() => {
          getDataExportProgress(export_id);
        }, 3000);
        setExportStatus(dataExportStatus.started);
        setExportRequestStatusStep(dataExportStatus.started);
      })
      .catch((error) => {
        const message = error?.data ? parseDataExportErrorMessage(error.data) : "";
        const params = {
          message,
          stack: error
        };
        dispatchPageError(params);
      });
    }
  }

  // Description: Get the data export progress.
  // After the export process has started we need to keep polling the server to get the progress of the export.
  // it will stop when this call returns an error (job no longer running).
  async function getDataExportProgress(export_id: string) {
    if (!!export_id && _.isString(export_id)) {
      await getExportProgress({
        export_id,
        user_id: account.user_id
      })
      .unwrap()
      .then((response) => {
        if (export_request_status_step !== dataExportStatus.running) {
          setExportStatus(dataExportStatus.running);
          setExportRequestStatusStep(dataExportStatus.running);
        }
      })
      .catch(() => {
        // TODO: check v1 again
        setExportStatus(dataExportStatus.complete);
        setExportRequestStatusStep(dataExportStatus.complete);
        dispatch(uiActions.handleSetMessage(new Message(AdminSuccessMessages.success_completing_export)));
        clearInterval(timeout_id.current);
      });

    } else {
      const params = {
        message: AdminErrorMessages.error_no_request_id_found,
        stack: export_id
      };
      DataExportRequests.handlePageErrorMessage(params);
      clearInterval(timeout_id.current);
    }
  }

  // Description: Cancel the data export while it's running.
  async function cancelExportRequest(export_id: string, user_id: string) {
    if (!!export_id && !!user_id) {
      await cancelExport({
        userId: user_id,
        orgId: org_info.org_id,
        exportId: export_id
      })
      .unwrap()
      .then(() => {
        clearInterval(timeout_id.current);
        setExportRequestStatusStep(dataExportStatus.cancelled);
        const successMessage = AdminSuccessMessages.success_stop_data_export_process;
        setExportStatus(dataExportStatus.cancelled);
        dispatch(uiActions.handleSetMessage(new Message(successMessage)));
      })
      .catch((error) => {
        clearInterval(timeout_id.current);
        const params = { 
          message: AdminErrorMessages.error_cancel_export_request,
          stack: error
        };
        dispatchPageError(params);
      });
    } else if(!export_id) {
      const params = {
        message: AdminErrorMessages.error_delete_existing_data_export,
        stack: { export_id, user_id }
      };
      clearInterval(timeout_id.current);
      dispatchPageError(params);
    } else {
      const params = {
        message: AdminErrorMessages.default,
        stack: { export_id, user_id }
      };
      clearInterval(timeout_id.current);
      dispatchPageError(params);
    }
  }

  function singleSelectUser(params: { e: React.FocusEvent<HTMLInputElement>, user: CollectionServerUser }) {
    const {e, user} = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedUsers([...selected_users, user]);
    } else {
      setSelectedUsers(selected_users.filter((selected_user) => selected_user.user_id !== user.user_id));
    }
  }

  function handleMultiSelectUsers(params: { e: React.FocusEvent<HTMLInputElement> }) {
    const { e } = params;
    const { checked } = e.target;
    if (checked) {
      setSelectedUsers(data_export_group_users);
    } else if (!checked) {
      setSelectedUsers([]);
    }
  }

  // Description: Handle collapse dropdowns for the Get Started page.
  function handleCollapseDropdowns(id: string) {
    switch (id) {
      case "users":
        setIsOpenUsersCollapse(!is_open_users_collapse);
        break;
      case "content":
        setIsOpenContentCollapse(!is_open_content_collapse);
        break;
      case "date":
        setIsOpenDateRangeCollapse(!is_open_date_range_collapse);
        break;
      default:
        break;
    }
  }

  function confirmGetStartedDataExport() {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_get_started_data_export.body.replace(MessageAnchors.message_content, data_export_approval_group?.name || ""),
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_get_started_data_export.title,
      {
        label: "Yes",
        data: true,
        action: () => getStartedRequestDataExport()
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  function confirmStopDataExportProcess(export_id: string, user_id: string) {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_stop_export_process.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_stop_export_process.title,
      {
        label: "Yes",
        data: true,
        action: () => cancelExportRequest(export_id, user_id)
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  function confirmCancelDataExportRequest(request_id: string) {
    const confirmation_dialog = new Message(
      AdminMessages.confirm_cancel_export_request.body,
      MessageHandlerDisplayType.confirm,
      MessageToastTypes.primary,
      AdminMessages.confirm_cancel_export_request.title,
      {
        label: "Yes",
        data: true,
        action: () => deleteDataExportRequest(request_id)
      }
    );
    dispatch(uiActions.handleSetMessage(confirmation_dialog));
  }

  const DataExportRequests = {
    handleRequestDataExport: () => {
      confirmGetStartedDataExport();
    },
    handleInitWebsocketConnection: (params: { subsumeUser: string}) => {
      initWebsocketConnection(params);
    },
    handleDestroy: () => {
      dispatch(websocketActions.wsDisconnect());
      dispatch(websocketActions.destroyWebsocket());
      setWsStep(AccounWStSteps.DISCONNECTED);
    },
    handleSingleSelect:(params: {e: FocusEvent<HTMLInputElement>, user: CollectionServerUser}) => {
      singleSelectUser(params);
    },
    handleMultiSelect:(params: {e: FocusEvent<HTMLInputElement>}) => {
      handleMultiSelectUsers(params);
    },
    handleAddressSearchParam: (param: string) => {
      setFilterParam(param);
    },
    handleReset: () => {
      setFilterParam("");
    },
    handleSelectDataExportContent: (params: {e: FocusEvent<HTMLInputElement>, id: string}) => {
      const { e, id } = params;
      setDataExportContent((prevState) => {
        return {
          ...prevState,
          [id]: e.target.checked
        };
      });
    },
    handleShowModal: () => {
      setShowModal(!show_modal);
    },
    handleStartDataExport: (drop_dir: string) => {
      const request_id = export_request?.request_id || requestId;
      const requester_id = export_request?.requester_id || account.user_id;
      startExportRequest(request_id, drop_dir, requester_id);
    },
    handleDateSelector: ({ start = new Date(), end = new Date(), selected_range = "default" }: { start?: Date; end?: Date; selected_range?: string }) => {
      setStartDate(start.toISOString());
      setEndDate(end.toISOString());
      setSelectedRange(selected_range);
    },
    handleToggleCollapse: (params: { id: string }) => {
      handleCollapseDropdowns(params.id);
    },
    unHandleErrorMessage: (params: { message: string, stack?: any}) => {
      const { message, stack } = params;
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    },
    handleCancel: () => {
      // Deleting the current request.
      const request_id = export_request?.request_id || requestId;
      confirmCancelDataExportRequest(request_id);
    },
    handleFinish: () => {
      clearState(true);
    },
    handleStopDataExportRunning: () => {
      const export_id = export_request?.request_id || requestId;
      const user_id = account.user_id;
      confirmStopDataExportProcess(export_id, user_id);
    },
    handlePageError: (params: { message: string, stack?: any }) => { 
      dispatchPageError(params);
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DataExportRequests as any)[callback] instanceof Function) {
      (DataExportRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      DataExportRequests.unHandleErrorMessage({ message, stack: actionObj });
    }
  }

  function ViewUi(step: string, selectAllCheckboxstate: CheckboxStatesTypes) { 
    switch (step) {
      case dataExportStatus.not_init:
        return <DataExportNotApprovalGroup />;
      case dataExportStatus.not_started:
        // variables for Users Table
        const selected_users_total = selected_users.length;
        const is_selected_users_empty = selected_users_total === 0;
        const data_export_group_users_total = data_export_group_users.length;

        // Label for the Users Table
        const users_table_label = 
          selected_users_total === data_export_group_users_total && data_export_group_users_total > 0 ? dropdownLabels.users : `${selected_users_total} of ${data_export_group_users_total} Users`;

        // Label for Content Panel
        const content_label = Object.keys(data_export_content).filter((key) => (data_export_content as any)[key]).map((key) => (dropdownLabels.content as any)[key]).join(" & ");
        const is_content_selected = !!content_label;

        // Label for Date Range Panel
        const is_date_range_selected = !!start_date && !!end_date;
        const date_range_label = selected_range === "forever" ? ": Forever" : (is_date_range_selected ? `: ${dayjs(start_date).format("MM/DD/YYYY")} - ${dayjs(end_date).format("MM/DD/YYYY")}` : "");
        const is_get_started_disabled = is_selected_users_empty || !is_content_selected || !is_date_range_selected;
        return (
          <DataExportGetStartedStep
            handleActions={handlePageActions}
            approvalGroup={data_export_approval_group}
            isGetStartedDisabled={is_get_started_disabled}
          >
            <CollapseSection
              id="users"
              field_name="Users"
              label={is_selected_users_empty ? "" : `: ${users_table_label}`}
              is_collapse={is_open_users_collapse}
              handleActions={handlePageActions}
            >
              <AdminToolbar
                is_loading={false}
                filter_param={filter_param}
                label="Users"
                total_items_filtered={filtered_users.length}
                total_items={data_export_group_users.length}
                is_data_export_page
                handleActions={handlePageActions}
              />
              <DataExportGroupUsersPanel
                selectAllCheckboxState={selectAllCheckboxstate}
                filteredUsersList={filtered_users}
                selectedUsersList={selected_users}
                handleActions={handlePageActions}
              />
            </CollapseSection>

            <CollapseSection
              id="content"
              field_name="Content"
              label={is_content_selected ? `: ${content_label}` : ""}
              is_collapse={is_open_content_collapse}
              handleActions={handlePageActions}
            >
              <DataExportContent
                content={data_export_content}
                handleActions={handlePageActions}
              />
            </CollapseSection>

            <CollapseSection
              id="date"
              field_name="Date Range"
              label={date_range_label}
              is_collapse={is_open_date_range_collapse}
              handleActions={handlePageActions}
            >
              <DateSelectorDropdown
                handleAction={handlePageActions}
              />
            </CollapseSection>
          </DataExportGetStartedStep>
        );
      case dataExportStatus.init:
      case dataExportStatus.pending:
      case dataExportStatus.cancelled:
        const required_approvers = data_export_approval_group?.required_approvers;
        const response = 
          dataExportStatus.pending === step ? parseExportRequest(raw_request, data_export_group_users, data_export_group_approvers, account) : parseExportRequest(export_request, data_export_group_users) as any; // TODO: fix typing.
        const approval_group = data_export_group_approvers.length > 0 ? data_export_group_approvers : response?.approval_group;
        return (
          <DataExportApproveStep
            requester_name={response?.requesterName}
            request_expiration={response?.expirationTime}
            request_timeframe={response?.timeframe}
            requested_content_label={response?.content}
            requested_users={response?.users}
            pin={pin}
            wsStep={wsStep}
            current_account={account}
            approvers={approval_group}
            currentUsersApproved={request_approvements}
            requiredApprovers={required_approvers}
            handleActions={handlePageActions}
          />
       );
      case dataExportStatus.started:
      case dataExportStatus.running:
      case dataExportStatus.complete:
        let status;
        const export_request_data = parseExportRequest(export_request, data_export_group_users, data_export_group_approvers, account);
        if (export_status === dataExportStatus.started) {
          status = "Started";
        } else if (export_status === dataExportStatus.running) { 
          status = "Running";
        } else if (export_status === dataExportStatus.complete) { 
          status = "Complete";
        } else {
          status = "";
        }
        return <DataExportRequestDone drop_dir={drop_dir} export_status={status} export_request={export_request_data} />;
      default:
        return null;
    }
  }

  let select_all_checkbox_state: CheckboxStatesTypes = CheckboxStates.empty;

  if (data_export_group_users.length === selected_users.length && selected_users.length !== 0) {
    select_all_checkbox_state = "checked";
  } else if (selected_users.length < data_export_group_users.length && selected_users.length !== 0) {
    select_all_checkbox_state = "indeterminate";
  }

  const data_export_progress_status = dataExportStatus[export_request_status_step as unknown as keyof typeof dataExportStatus];
  return (
    <CoverTemplate className="admin-wrapper">
      <PageHeader>
        <DataExportHeader
          handleActions={handlePageActions} 
          step={export_request_status_step}
          approved_users={request_approvements.length}
          required_approvers={data_export_approval_group?.required_approvers}
        />
      </PageHeader>
      <DataExportGetLocationModal
        os_info={os_info}
        show={show_modal} 
        handleActions={handlePageActions} 
      />
      {!is_loading && export_request_status_step !== dataExportStatus.not_init && export_request_status_step !== dataExportStatus.not_started &&
        <DataExportRequestProgress status={data_export_progress_status} />
      }
      {is_loading ? (
        <div className="cover-content">
          <div className="admin-panel-center">
            <Loading className="in-place" />
          </div>
        </div>
      ) :
        <div className="data-export-container">
          {ViewUi(export_request_status_step, select_all_checkbox_state)}
        </div>
      }
    </CoverTemplate>
  );
}

export default React.memo(DataExportComponent);
