import React, { useEffect, useState } from "react";
import { JSONOrgInfo, DeviceBase, DeviceLockInfoBase } from "@preveil-api";
import { uiActions } from "src/store";
import { RootState } from "src/store/configureStore";
import {
  Account,
  AdminErrorMessages,
  collectionApi,
  GlobalErrorMessages,
  Message,
  MessageAnchors,
  MessageHandlerDisplayType,
  useAppDispatch,
  useAppSelector,
  useAdminSetInviteEmailTypeMutation,
  usePatchUsersOrgsByEntityIdShowMuaPromptMutation,
  usePostUsersOrgsByEntityIdAllowAddDeviceMutation,
  usePutUsersOrgsByEntityIdKeyvalMutation,
  dayjs
} from "src/common";
import { AlertWithSwitch, CoverTemplate, PageHeader, Loading } from "src/components";
import { Button, Card } from "react-bootstrap";
import { saveAs } from "file-saver";

interface UsersOrgByEntityId extends JSONOrgInfo {
  show_mua_prompt: boolean;
  allow_add_devices: boolean;
}

type DeviceObject = {
  userId: string;
  deviceName: string;
  deviceId: string;
  userName: string;
  deviceType: string;
  active: string;
  lockedBy: string | null | undefined;
  lockTime: string | null | undefined;
}

// Device List headers for CSV File.
const HEADERS: string[] = [
  "User Id",
  "Device Name",
  "Device Id",
  "User Name",
  "Device Type",
  "Active",
  "Locked By",
  "Lock Time",
];

// Byte Order Mark (BOM)
// it may be required by some apps to be present in UTF-8 encoded files.
const BOM = "\ufeff";

function AdminSettingsComponent() {
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);

  // RTK Hooks
  const [getUsersOrgsByEntityId] = collectionApi.endpoints.getUsersOrgsByEntityId.useLazyQuery();
  const [getUsersOrgsByEntityIdKeyvalAndKey] = collectionApi.endpoints.getUsersOrgsByEntityIdKeyvalAndKey.useLazyQuery();
  const [adminSetInviteEmailType] = useAdminSetInviteEmailTypeMutation();
  const [patchUsersOrgsByEntityIdShowMuaPrompt] = usePatchUsersOrgsByEntityIdShowMuaPromptMutation();
  const [postUsersOrgsByEntityIdAllowAddDevice] = usePostUsersOrgsByEntityIdAllowAddDeviceMutation();
  const [putUsersOrgsByEntityIdKeyval] = usePutUsersOrgsByEntityIdKeyvalMutation();

  // State
  const [user_onboarding, setUserOnboarding] = useState<boolean>(false);
  const [user_device_management, setUserDeviceManagement] = useState<boolean>(false);
  const [add_to_mail_client, setAddToMailClient] = useState<boolean>(false);
  const [drive_sync_settings, setDriveSyncSettings] = useState<boolean>(false);
  const [is_loading, setIsLoading] = useState<boolean>(true); // we want to show loading component until the fetch calls are done.
  const [users_device_list, setUsersDeviceList] = useState<UsersOrgByEntityId>();

  useEffect(() => {
    getAdminSettingsState();
  }, []);

  function dispatchPageError(params: { message: string, stack?: any }) {
    dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.toastr), params.stack));
  }

  // Description: function will set the error message object if there
  // is anything wrong with current_account
  function handleCurrentAccountErrors(current_account: Account | undefined) {
    let params;
    if (!current_account?.org_info) {
      params = {
        message: AdminErrorMessages.error_missing_org,
        stack: current_account
      };
    } else if (!current_account?.user_id) {
      params = {
        message: AdminErrorMessages.error_missing_user_id,
        stack: current_account
      };
    } else {
      params = {
        message: GlobalErrorMessages.no_current_account_set,
        stack: current_account
      };
    };
    dispatchPageError(params);
  };

  // Description: Handles the fetch request to load the state for each switch/toggle
  // And the render state for the Loading component.
  async function getAdminSettingsState() {
    if (current_account && current_account?.user_id && current_account?.org_info) {
      const { org_info } = current_account;
      try {
        const { data: dataUsersOrgByEntityId, isSuccess: entityIdSuccess } = await getUsersOrgsByEntityId({
          account_ids: Account.getAccountIdentifiers(current_account),
          body: {
            entity_id: org_info.org_id
          }
        });
        const { data: dataUsersOrgByEntityIdKeyVal, isSuccess: entityIdKeyvalSucess } = await getUsersOrgsByEntityIdKeyvalAndKey({
          account_ids: Account.getAccountIdentifiers(current_account),
          body: {
            entityId: org_info.org_id,
            key: "sync_collections_by_default"
          }
        });

        if (entityIdSuccess && entityIdKeyvalSucess) {
          const { allow_add_devices, no_download_email, show_mua_prompt } = dataUsersOrgByEntityId as UsersOrgByEntityId;
          const { value } = dataUsersOrgByEntityIdKeyVal;
          // sestUsersDeviceList: One time set on state so we could use it later in case
          // user wants to print a device list, that way we don't have to
          // call the services again to fetch the same data prior to export csv.
          // TODO: we can also store it in a ref and it will persist the data
          // between renders.
          setUsersDeviceList(dataUsersOrgByEntityId as UsersOrgByEntityId);
          setUserOnboarding(!no_download_email);
          setUserDeviceManagement(allow_add_devices);
          setAddToMailClient(show_mua_prompt);
          setDriveSyncSettings(value);
          setIsLoading(false);
        }
      } catch (error) {
        const params = {
          message: AdminErrorMessages.error_admin_settings_data,
          stack: error
        };
        dispatchPageError(params);
      }
    } else {
      handleCurrentAccountErrors(current_account);
    }
  }

  // Description: Handles Request for User Onboarding.
  async function toggleUserOnboardingRequest() {
    if (current_account && current_account?.user_id && current_account?.org_info) {
      const { user_id, org_info } = current_account;
      // Only Crypto Endpoint the others are coming from Collection API.
      await adminSetInviteEmailType({
        userId: user_id,
        orgId: org_info.org_id,
        inviteEmailState: user_onboarding,
      })
        .unwrap()
        .then(() => {
          setUserOnboarding(!user_onboarding);
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, "changing User Onboarding options"),
            stack: error
          };
          dispatchPageError(params);
        });
    }
  };

  // Description: Handles Request for User Device Management.
  async function toggleUserDeviceManagementRequest() {
    if (current_account && current_account?.user_id && current_account?.org_info) {
      const { org_info } = current_account;
      const is_allowed = !user_device_management;
      await postUsersOrgsByEntityIdAllowAddDevice({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          entityId: org_info.org_id,
          is_allowed
        }
      })
        .unwrap()
        .then(() => {
          setUserDeviceManagement(is_allowed);
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, "changing User Device Management options"),
            stack: error
          };
          dispatchPageError(params);
        });
    }
  };

  // Description: Handles Request for Add to Mail Client.
  async function toggleAddToMailClientPrompt() {
    if (current_account && current_account?.user_id && current_account?.org_info) {
      const { org_info } = current_account;
      const show_prompt = !add_to_mail_client;
      await patchUsersOrgsByEntityIdShowMuaPrompt({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          entityId: org_info.org_id,
          show_prompt
        }
      })
        .unwrap()
        .then(() => {
          setAddToMailClient(show_prompt);
        })
        .catch((error) => {
          const failedToggleState = show_prompt ? "disabling Add to Mail Client prompt" : "enabling Add to Mail Client prompt";
          const params = {
            message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, failedToggleState),
            stack: error
          };
          dispatchPageError(params);
        });
    }
  };

  // Description: Handles Request for Drive Sync Default Settings.
  async function toggleDriveSyncDefaultSettings() {
    if (current_account && current_account?.user_id && current_account?.org_info) {
      const { org_info } = current_account;
      const value = (!drive_sync_settings).toString();
      const key = "sync_collections_by_default";
      await putUsersOrgsByEntityIdKeyval({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: {
          entityId: org_info.org_id,
          key,
          value
        } 
      })
        .unwrap()
        .then(() => {
          setDriveSyncSettings(!drive_sync_settings);
        })
        .catch((error) => {
          const params = {
            message: AdminErrorMessages.error_admin_settings_switch_options.replace(MessageAnchors.message_content, "changing Drive Sync Default Settings"),
            stack: error
          };
          dispatchPageError(params);
        });
    }
  };

  // Description: returns a single device object with the columns data for the CSV file.
  function createDeviceObject(userData: { user_id: string, display_name: string }, deviceData: DeviceBase): DeviceObject {
    const { user_id: userId, display_name: userName } = userData;
    const { device_id: deviceId, platform: deviceType, device_name: deviceName, is_active, lock_info } = deviceData;
    const active = is_active ? "Yes" : "No";
    const { lock_by_user_id: lockedBy, lock_time: lockTime } = lock_info as DeviceLockInfoBase;
    return {
      userId,
      deviceName,
      deviceId,
      userName,
      deviceType,
      active,
      lockedBy,
      lockTime
    };
  };

  // Description: Export devices list as a CSV file
  function handleExportDevicesList() {
    const { users } = users_device_list as UsersOrgByEntityId;

    try {
      // creating an array of objects from device list and user_id.
      const devicesArray = users.map((user: any) => {
        const userData = {
          user_id: user.user_id,
          display_name: user.display_name
        };
        if (user.devices) {
          return user.devices.map((device: any) => {
            return createDeviceObject(userData, device);
          });
        } else {
          return [];
        }
      }).flat();

      const csvString = [
        [...HEADERS],
        ...devicesArray.map((device: DeviceObject) => [
          device.userId,
          device.deviceName,
          device.deviceId,
          device.userName,
          device.deviceType,
          device.active,
          device.lockedBy,
          device.lockTime
        ]
        )
      ]
        .map(row => row.join(","))
        .join("\n");

      const csvData = `${BOM}${csvString}`;
      const blob = new Blob([csvData], { type: "text/csv; charset=utf-8" });
      saveAs(blob, `users-${dayjs(new Date()).format("MM.DD.YYYY-HH_mm_ss")}.csv`);
    } catch (error) {
      const params = {
        message: AdminErrorMessages.error_admin_settings_export_csv,
        stack: error
      };
      dispatchPageError(params);
    };
  };

  return (
    <CoverTemplate className="admin-settings-content">
      <PageHeader>
        <h1>Admin Settings</h1>
      </PageHeader>
      {is_loading ? (
        <Loading />
      ) : (
        <Card className="card-section">
          <h5 className="content-header">User Onboarding</h5>
          <AlertWithSwitch
            popover={true}
            toggleState={user_onboarding}
            handleChange={toggleUserOnboardingRequest}
            popover_message="Disabling this option will remove the prompts asking members to install PreVeil. Installation link should be disabled when PreVeil is deploying through Active Directory or other remote deployment tools."
          >
            <span>
              Onboarding emails <strong>{`${!user_onboarding ? "won't" : "will"}`}</strong>{" "}
              include installation link.
            </span>
          </AlertWithSwitch>

          <h5 className="content-header">User Device Management</h5>
          <AlertWithSwitch
            popover={true}
            toggleState={user_device_management}
            handleChange={toggleUserDeviceManagementRequest}
            popover_message="Enabling this option will allow users in your organization to add new devices. This block can be overriden individually for users."
          >
            <span>
              All users are <strong>{`${user_device_management ? "allowed to add" : "blocked from adding"}`}</strong> devices.
            </span>
          </AlertWithSwitch>
            
          
          <h5 className="content-header">Mail Client Prompt</h5>
          <AlertWithSwitch
            popover={true}
            toggleState={add_to_mail_client}
            handleChange={toggleAddToMailClientPrompt}
            popover_message="Disabling this option will remove the Mail Client prompt upon claiming an account."
          >
            <span>
              New users <strong>{`${add_to_mail_client ? "will" : "will not"}`}</strong> be prompted to install email client integration.
            </span>
          </AlertWithSwitch>

          <h5 className="content-header">Drive Sync Default Setting</h5>
            <AlertWithSwitch
              popover={true}
              toggleState={drive_sync_settings}
              handleChange={toggleDriveSyncDefaultSettings}
              popover_message="When PreVeil Drive folders are shared with others, this setting defines the default behavior for file sync. Sync means shared folders will sync to user devices’ Drive folders and file explorer. No-sync means shared folders will remain in the PreVeil cloud and are visible in the PreVeil browser app only."
            >

              <span>
                All users <strong>{`${drive_sync_settings ? "will automatically sync" : "will not automatically sync"}`}</strong> all shared files to their
                device.
              </span>

            </AlertWithSwitch>
            
          <hr />
          <div>
            <h3 className="content-header pt-3">Export Device List</h3>
            <p>
              Export information about your current organization members' devices into a .csv file.
            </p>
            <div><Button onClick={handleExportDevicesList}>Export</Button></div>
          </div>
        </Card>
      )}
    </CoverTemplate>
  );
}

export default React.memo(AdminSettingsComponent);
