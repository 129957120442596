// source: collections.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var elixirpb_pb = require('./elixirpb_pb.js');
goog.object.extend(proto, elixirpb_pb);
goog.exportSymbol('proto.collection.Collection', null, global);
goog.exportSymbol('proto.collection.Collection.Attributes', null, global);
goog.exportSymbol('proto.collection.FSBlockType', null, global);
goog.exportSymbol('proto.collection.FSEnvelope', null, global);
goog.exportSymbol('proto.collection.FSEnvelope.Signature', null, global);
goog.exportSymbol('proto.collection.FSEnvelope.Signature.RoleCase', null, global);
goog.exportSymbol('proto.collection.FSMessage', null, global);
goog.exportSymbol('proto.collection.FSMessage.ACLNodePermissions', null, global);
goog.exportSymbol('proto.collection.FSMessage.ACLTree', null, global);
goog.exportSymbol('proto.collection.FSMessage.ACLUserPermissions', null, global);
goog.exportSymbol('proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.AdminGroupInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.Block', null, global);
goog.exportSymbol('proto.collection.FSMessage.BlockData', null, global);
goog.exportSymbol('proto.collection.FSMessage.BlockData.DataCase', null, global);
goog.exportSymbol('proto.collection.FSMessage.BulkUpdateFailure', null, global);
goog.exportSymbol('proto.collection.FSMessage.BulkUpdateFailure.Status', null, global);
goog.exportSymbol('proto.collection.FSMessage.BulkUpdateFailure.UpdateType', null, global);
goog.exportSymbol('proto.collection.FSMessage.DataBlocks', null, global);
goog.exportSymbol('proto.collection.FSMessage.DeletedEntry', null, global);
goog.exportSymbol('proto.collection.FSMessage.Dir', null, global);
goog.exportSymbol('proto.collection.FSMessage.Dir.Entry', null, global);
goog.exportSymbol('proto.collection.FSMessage.Dir.PathNode', null, global);
goog.exportSymbol('proto.collection.FSMessage.File', null, global);
goog.exportSymbol('proto.collection.FSMessage.GranteeFlat', null, global);
goog.exportSymbol('proto.collection.FSMessage.GroupInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.Groups', null, global);
goog.exportSymbol('proto.collection.FSMessage.KeyHistoryEntry', null, global);
goog.exportSymbol('proto.collection.FSMessage.Link', null, global);
goog.exportSymbol('proto.collection.FSMessage.LinkView', null, global);
goog.exportSymbol('proto.collection.FSMessage.LinkView.Entry', null, global);
goog.exportSymbol('proto.collection.FSMessage.Links', null, global);
goog.exportSymbol('proto.collection.FSMessage.LockInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.Logs', null, global);
goog.exportSymbol('proto.collection.FSMessage.Logs.LogEntry', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.CollectionRename', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Device', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Device.Action', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Device.Type', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Event', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.GroupPermission', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Key', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.LegacyNode', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Node', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Node.DataCase', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Node.Dir', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Node.File', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Node.Link', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.NodeGrant', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Permission', null, global);
goog.exportSymbol('proto.collection.FSMessage.Notification.Permission.RoleInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.OrgPermissions', null, global);
goog.exportSymbol('proto.collection.FSMessage.Permissions', null, global);
goog.exportSymbol('proto.collection.FSMessage.PublicKey', null, global);
goog.exportSymbol('proto.collection.FSMessage.RestoreResult', null, global);
goog.exportSymbol('proto.collection.FSMessage.RestoreResult.Status', null, global);
goog.exportSymbol('proto.collection.FSMessage.Role', null, global);
goog.exportSymbol('proto.collection.FSMessage.RootInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.Share', null, global);
goog.exportSymbol('proto.collection.FSMessage.Share.RoleInfo', null, global);
goog.exportSymbol('proto.collection.FSMessage.Snapshot', null, global);
goog.exportSymbol('proto.collection.FSMessage.SyncRuleTree', null, global);
goog.exportSymbol('proto.collection.FSMessage.SyncRules', null, global);
goog.exportSymbol('proto.collection.FSMessage.SyncTreeNode', null, global);
goog.exportSymbol('proto.collection.FSMessage.SyncTreeNode.SyncState', null, global);
goog.exportSymbol('proto.collection.FSMessage.Type', null, global);
goog.exportSymbol('proto.collection.FSMessage.UnlinkedEntry', null, global);
goog.exportSymbol('proto.collection.FSMessage.User', null, global);
goog.exportSymbol('proto.collection.FSMessage.UserPermissions', null, global);
goog.exportSymbol('proto.collection.FSMessage.UserPermissions.UserPermissionInfo', null, global);
goog.exportSymbol('proto.collection.FSMethod', null, global);
goog.exportSymbol('proto.collection.FSRequest', null, global);
goog.exportSymbol('proto.collection.FSRequest.ACLKeyHistory', null, global);
goog.exportSymbol('proto.collection.FSRequest.ACLTree', null, global);
goog.exportSymbol('proto.collection.FSRequest.Ack', null, global);
goog.exportSymbol('proto.collection.FSRequest.Ack.Type', null, global);
goog.exportSymbol('proto.collection.FSRequest.AddKey', null, global);
goog.exportSymbol('proto.collection.FSRequest.BulkUpdate', null, global);
goog.exportSymbol('proto.collection.FSRequest.BulkUpdate.Block', null, global);
goog.exportSymbol('proto.collection.FSRequest.Create', null, global);
goog.exportSymbol('proto.collection.FSRequest.Delete', null, global);
goog.exportSymbol('proto.collection.FSRequest.DownloadBlocks', null, global);
goog.exportSymbol('proto.collection.FSRequest.DownloadBlocks.BlockAccessType', null, global);
goog.exportSymbol('proto.collection.FSRequest.Fetch', null, global);
goog.exportSymbol('proto.collection.FSRequest.FindLinks', null, global);
goog.exportSymbol('proto.collection.FSRequest.Grant', null, global);
goog.exportSymbol('proto.collection.FSRequest.Grant.Group', null, global);
goog.exportSymbol('proto.collection.FSRequest.Grant.Role', null, global);
goog.exportSymbol('proto.collection.FSRequest.Grant.RoleInfo', null, global);
goog.exportSymbol('proto.collection.FSRequest.Grant.User', null, global);
goog.exportSymbol('proto.collection.FSRequest.Grant.User.RoleInfo', null, global);
goog.exportSymbol('proto.collection.FSRequest.GroupPermission', null, global);
goog.exportSymbol('proto.collection.FSRequest.Init', null, global);
goog.exportSymbol('proto.collection.FSRequest.Init.Key', null, global);
goog.exportSymbol('proto.collection.FSRequest.LockFile', null, global);
goog.exportSymbol('proto.collection.FSRequest.MakeShareable', null, global);
goog.exportSymbol('proto.collection.FSRequest.MakeShareable.Block', null, global);
goog.exportSymbol('proto.collection.FSRequest.MakeShareable.Entry', null, global);
goog.exportSymbol('proto.collection.FSRequest.Paging', null, global);
goog.exportSymbol('proto.collection.FSRequest.Permission', null, global);
goog.exportSymbol('proto.collection.FSRequest.PublicKey', null, global);
goog.exportSymbol('proto.collection.FSRequest.ReadLog', null, global);
goog.exportSymbol('proto.collection.FSRequest.Rekey', null, global);
goog.exportSymbol('proto.collection.FSRequest.Rekey.RekeyRole', null, global);
goog.exportSymbol('proto.collection.FSRequest.Restore', null, global);
goog.exportSymbol('proto.collection.FSRequest.SetCollectionAttributes', null, global);
goog.exportSymbol('proto.collection.FSRequest.Snapshot', null, global);
goog.exportSymbol('proto.collection.FSRequest.UnlockFile', null, global);
goog.exportSymbol('proto.collection.FSRequest.Update', null, global);
goog.exportSymbol('proto.collection.FSRequest.Update.BlockUpdate', null, global);
goog.exportSymbol('proto.collection.FSRequest.Update.Change', null, global);
goog.exportSymbol('proto.collection.FSRequest.Update.Op', null, global);
goog.exportSymbol('proto.collection.FSRequest.UpgradeCollection', null, global);
goog.exportSymbol('proto.collection.FSRequest.UpgradeCollection.AccessGrant', null, global);
goog.exportSymbol('proto.collection.FSRequest.Upload', null, global);
goog.exportSymbol('proto.collection.FSRole', null, global);
goog.exportSymbol('proto.collection.FSStatus', null, global);
goog.exportSymbol('proto.collection.FSType', null, global);
goog.exportSymbol('proto.collection.FSWrappedKey', null, global);
goog.exportSymbol('proto.collection.Key', null, global);
goog.exportSymbol('proto.collection.Node', null, global);
goog.exportSymbol('proto.collection.Node.ACLRole', null, global);
goog.exportSymbol('proto.collection.Node.Grant', null, global);
goog.exportSymbol('proto.collection.Node.Grantee', null, global);
goog.exportSymbol('proto.collection.Node.Permission', null, global);
goog.exportSymbol('proto.collection.PublicUserKey', null, global);
goog.exportSymbol('proto.collection.SealedContent', null, global);
goog.exportSymbol('proto.collection.SyncRule', null, global);
goog.exportSymbol('proto.collection.UserKey', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSWrappedKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSWrappedKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSWrappedKey.displayName = 'proto.collection.FSWrappedKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.SealedContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.SealedContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.SealedContent.displayName = 'proto.collection.SealedContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Node.displayName = 'proto.collection.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Node.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.Node.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Node.Permission.displayName = 'proto.collection.Node.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Node.Grantee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.Node.Grantee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Node.Grantee.displayName = 'proto.collection.Node.Grantee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Node.Grant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.Node.Grant.repeatedFields_, null);
};
goog.inherits(proto.collection.Node.Grant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Node.Grant.displayName = 'proto.collection.Node.Grant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Collection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.Collection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Collection.displayName = 'proto.collection.Collection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Collection.Attributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.Collection.Attributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Collection.Attributes.displayName = 'proto.collection.Collection.Attributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.SyncRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.SyncRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.SyncRule.displayName = 'proto.collection.SyncRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSEnvelope = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSEnvelope.repeatedFields_, null);
};
goog.inherits(proto.collection.FSEnvelope, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSEnvelope.displayName = 'proto.collection.FSEnvelope';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSEnvelope.Signature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.collection.FSEnvelope.Signature.oneofGroups_);
};
goog.inherits(proto.collection.FSEnvelope.Signature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSEnvelope.Signature.displayName = 'proto.collection.FSEnvelope.Signature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.displayName = 'proto.collection.FSRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.PublicKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.PublicKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.PublicKey.displayName = 'proto.collection.FSRequest.PublicKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Init = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Init.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Init, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Init.displayName = 'proto.collection.FSRequest.Init';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Init.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Init.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Init.Key.displayName = 'proto.collection.FSRequest.Init.Key';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Create = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Create.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Create, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Create.displayName = 'proto.collection.FSRequest.Create';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Delete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Delete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Delete.displayName = 'proto.collection.FSRequest.Delete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Update = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Update.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Update, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Update.displayName = 'proto.collection.FSRequest.Update';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Update.BlockUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Update.BlockUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Update.BlockUpdate.displayName = 'proto.collection.FSRequest.Update.BlockUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Update.Change = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Update.Change.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Update.Change, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Update.Change.displayName = 'proto.collection.FSRequest.Update.Change';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Upload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Upload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Upload.displayName = 'proto.collection.FSRequest.Upload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Permission.displayName = 'proto.collection.FSRequest.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.GroupPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.GroupPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.GroupPermission.displayName = 'proto.collection.FSRequest.GroupPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Grant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Grant.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Grant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Grant.displayName = 'proto.collection.FSRequest.Grant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Grant.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Grant.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Grant.Role.displayName = 'proto.collection.FSRequest.Grant.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Grant.RoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Grant.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Grant.RoleInfo.displayName = 'proto.collection.FSRequest.Grant.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Grant.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Grant.User.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Grant.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Grant.User.displayName = 'proto.collection.FSRequest.Grant.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Grant.User.RoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Grant.User.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Grant.User.RoleInfo.displayName = 'proto.collection.FSRequest.Grant.User.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Grant.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Grant.Group.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Grant.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Grant.Group.displayName = 'proto.collection.FSRequest.Grant.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Rekey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Rekey.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Rekey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Rekey.displayName = 'proto.collection.FSRequest.Rekey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Rekey.RekeyRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.Rekey.RekeyRole.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.Rekey.RekeyRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Rekey.RekeyRole.displayName = 'proto.collection.FSRequest.Rekey.RekeyRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.AddKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.AddKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.AddKey.displayName = 'proto.collection.FSRequest.AddKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Snapshot.displayName = 'proto.collection.FSRequest.Snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.ACLTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.ACLTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.ACLTree.displayName = 'proto.collection.FSRequest.ACLTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Ack = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Ack, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Ack.displayName = 'proto.collection.FSRequest.Ack';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Fetch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Fetch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Fetch.displayName = 'proto.collection.FSRequest.Fetch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.MakeShareable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.MakeShareable.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.MakeShareable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.MakeShareable.displayName = 'proto.collection.FSRequest.MakeShareable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.MakeShareable.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.MakeShareable.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.MakeShareable.Entry.displayName = 'proto.collection.FSRequest.MakeShareable.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.MakeShareable.Block = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.MakeShareable.Block, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.MakeShareable.Block.displayName = 'proto.collection.FSRequest.MakeShareable.Block';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.ReadLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.ReadLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.ReadLog.displayName = 'proto.collection.FSRequest.ReadLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.BulkUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.BulkUpdate.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.BulkUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.BulkUpdate.displayName = 'proto.collection.FSRequest.BulkUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.BulkUpdate.Block = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.BulkUpdate.Block, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.BulkUpdate.Block.displayName = 'proto.collection.FSRequest.BulkUpdate.Block';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Paging = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Paging, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Paging.displayName = 'proto.collection.FSRequest.Paging';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.UpgradeCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.UpgradeCollection.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.UpgradeCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.UpgradeCollection.displayName = 'proto.collection.FSRequest.UpgradeCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.UpgradeCollection.AccessGrant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.UpgradeCollection.AccessGrant.displayName = 'proto.collection.FSRequest.UpgradeCollection.AccessGrant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.SetCollectionAttributes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.SetCollectionAttributes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.SetCollectionAttributes.displayName = 'proto.collection.FSRequest.SetCollectionAttributes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.ACLKeyHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.ACLKeyHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.ACLKeyHistory.displayName = 'proto.collection.FSRequest.ACLKeyHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.LockFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.LockFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.LockFile.displayName = 'proto.collection.FSRequest.LockFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.UnlockFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.UnlockFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.UnlockFile.displayName = 'proto.collection.FSRequest.UnlockFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.FindLinks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.FindLinks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.FindLinks.displayName = 'proto.collection.FSRequest.FindLinks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.DownloadBlocks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSRequest.DownloadBlocks.repeatedFields_, null);
};
goog.inherits(proto.collection.FSRequest.DownloadBlocks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.DownloadBlocks.displayName = 'proto.collection.FSRequest.DownloadBlocks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSRequest.Restore = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSRequest.Restore, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSRequest.Restore.displayName = 'proto.collection.FSRequest.Restore';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.displayName = 'proto.collection.FSMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.PublicKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.PublicKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.PublicKey.displayName = 'proto.collection.FSMessage.PublicKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.RootInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.RootInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.RootInfo.displayName = 'proto.collection.FSMessage.RootInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Block = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Block, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Block.displayName = 'proto.collection.FSMessage.Block';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.LockInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.LockInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.LockInfo.displayName = 'proto.collection.FSMessage.LockInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.File.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.File.displayName = 'proto.collection.FSMessage.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Dir = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Dir.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Dir, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Dir.displayName = 'proto.collection.FSMessage.Dir';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Dir.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Dir.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Dir.Entry.displayName = 'proto.collection.FSMessage.Dir.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Dir.PathNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Dir.PathNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Dir.PathNode.displayName = 'proto.collection.FSMessage.Dir.PathNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Link.displayName = 'proto.collection.FSMessage.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.DeletedEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.DeletedEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.DeletedEntry.displayName = 'proto.collection.FSMessage.DeletedEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Snapshot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Snapshot.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Snapshot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Snapshot.displayName = 'proto.collection.FSMessage.Snapshot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.ACLTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.ACLTree.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.ACLTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.ACLTree.displayName = 'proto.collection.FSMessage.ACLTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.SyncRuleTree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.SyncRuleTree.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.SyncRuleTree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.SyncRuleTree.displayName = 'proto.collection.FSMessage.SyncRuleTree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.SyncTreeNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.SyncTreeNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.SyncTreeNode.displayName = 'proto.collection.FSMessage.SyncTreeNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Role.displayName = 'proto.collection.FSMessage.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Permissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Permissions.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Permissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Permissions.displayName = 'proto.collection.FSMessage.Permissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.GroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.GroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.GroupInfo.displayName = 'proto.collection.FSMessage.GroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Groups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Groups.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Groups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Groups.displayName = 'proto.collection.FSMessage.Groups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.AdminGroupInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.AdminGroupInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.AdminGroupInfo.displayName = 'proto.collection.FSMessage.AdminGroupInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.RestoreResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.RestoreResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.RestoreResult.displayName = 'proto.collection.FSMessage.RestoreResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.BulkUpdateFailure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.BulkUpdateFailure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.BulkUpdateFailure.displayName = 'proto.collection.FSMessage.BulkUpdateFailure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.KeyHistoryEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.KeyHistoryEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.KeyHistoryEntry.displayName = 'proto.collection.FSMessage.KeyHistoryEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Share = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Share.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Share, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Share.displayName = 'proto.collection.FSMessage.Share';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Share.RoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Share.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Share.RoleInfo.displayName = 'proto.collection.FSMessage.Share.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.User.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.User.displayName = 'proto.collection.FSMessage.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Logs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Logs.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Logs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Logs.displayName = 'proto.collection.FSMessage.Logs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Logs.LogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Logs.LogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Logs.LogEntry.displayName = 'proto.collection.FSMessage.Logs.LogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.LinkView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.LinkView.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.LinkView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.LinkView.displayName = 'proto.collection.FSMessage.LinkView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.LinkView.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.LinkView.Entry.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.LinkView.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.LinkView.Entry.displayName = 'proto.collection.FSMessage.LinkView.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.UnlinkedEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.UnlinkedEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.UnlinkedEntry.displayName = 'proto.collection.FSMessage.UnlinkedEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.displayName = 'proto.collection.FSMessage.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.LegacyNode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Notification.LegacyNode.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Notification.LegacyNode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.LegacyNode.displayName = 'proto.collection.FSMessage.Notification.LegacyNode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Node = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.collection.FSMessage.Notification.Node.oneofGroups_);
};
goog.inherits(proto.collection.FSMessage.Notification.Node, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Node.displayName = 'proto.collection.FSMessage.Notification.Node';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Node.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Notification.Node.File.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Node.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Node.File.displayName = 'proto.collection.FSMessage.Notification.Node.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Node.Dir = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Notification.Node.Dir.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Node.Dir, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Node.Dir.displayName = 'proto.collection.FSMessage.Notification.Node.Dir';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Node.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Node.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Node.Link.displayName = 'proto.collection.FSMessage.Notification.Node.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Key.displayName = 'proto.collection.FSMessage.Notification.Key';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Device.displayName = 'proto.collection.FSMessage.Notification.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Notification.Permission.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Permission.displayName = 'proto.collection.FSMessage.Notification.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification.Permission.RoleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.Permission.RoleInfo.displayName = 'proto.collection.FSMessage.Notification.Permission.RoleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.GroupPermission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification.GroupPermission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.GroupPermission.displayName = 'proto.collection.FSMessage.Notification.GroupPermission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.NodeGrant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Notification.NodeGrant.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Notification.NodeGrant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.NodeGrant.displayName = 'proto.collection.FSMessage.Notification.NodeGrant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Notification.CollectionRename = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.FSMessage.Notification.CollectionRename, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Notification.CollectionRename.displayName = 'proto.collection.FSMessage.Notification.CollectionRename';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.OrgPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.OrgPermissions.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.OrgPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.OrgPermissions.displayName = 'proto.collection.FSMessage.OrgPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.ACLNodePermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.ACLNodePermissions.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.ACLNodePermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.ACLNodePermissions.displayName = 'proto.collection.FSMessage.ACLNodePermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.UserPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.UserPermissions.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.UserPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.UserPermissions.displayName = 'proto.collection.FSMessage.UserPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.UserPermissions.UserPermissionInfo.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.UserPermissions.UserPermissionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.UserPermissions.UserPermissionInfo.displayName = 'proto.collection.FSMessage.UserPermissions.UserPermissionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.ACLUserPermissions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.ACLUserPermissions.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.ACLUserPermissions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.ACLUserPermissions.displayName = 'proto.collection.FSMessage.ACLUserPermissions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.displayName = 'proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.Links = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.Links.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.Links, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.Links.displayName = 'proto.collection.FSMessage.Links';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.BlockData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.collection.FSMessage.BlockData.oneofGroups_);
};
goog.inherits(proto.collection.FSMessage.BlockData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.BlockData.displayName = 'proto.collection.FSMessage.BlockData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.DataBlocks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.DataBlocks.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.DataBlocks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.DataBlocks.displayName = 'proto.collection.FSMessage.DataBlocks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.SyncRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.SyncRules.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.SyncRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.SyncRules.displayName = 'proto.collection.FSMessage.SyncRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.FSMessage.GranteeFlat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.collection.FSMessage.GranteeFlat.repeatedFields_, null);
};
goog.inherits(proto.collection.FSMessage.GranteeFlat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.FSMessage.GranteeFlat.displayName = 'proto.collection.FSMessage.GranteeFlat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.Key.displayName = 'proto.collection.Key';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.UserKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.UserKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.UserKey.displayName = 'proto.collection.UserKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.collection.PublicUserKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.collection.PublicUserKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.collection.PublicUserKey.displayName = 'proto.collection.PublicUserKey';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSWrappedKey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSWrappedKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSWrappedKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSWrappedKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    wrappedKey: msg.getWrappedKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSWrappedKey}
 */
proto.collection.FSWrappedKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSWrappedKey;
  return proto.collection.FSWrappedKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSWrappedKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSWrappedKey}
 */
proto.collection.FSWrappedKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSWrappedKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSWrappedKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSWrappedKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSWrappedKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint64 key_version = 1;
 * @return {number}
 */
proto.collection.FSWrappedKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSWrappedKey} returns this
 */
proto.collection.FSWrappedKey.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSWrappedKey} returns this
 */
proto.collection.FSWrappedKey.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSWrappedKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes wrapped_key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSWrappedKey.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes wrapped_key = 2;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSWrappedKey.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSWrappedKey.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSWrappedKey} returns this
 */
proto.collection.FSWrappedKey.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSWrappedKey} returns this
 */
proto.collection.FSWrappedKey.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSWrappedKey.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.SealedContent.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.SealedContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.SealedContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.SealedContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.SealedContent}
 */
proto.collection.SealedContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.SealedContent;
  return proto.collection.SealedContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.SealedContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.SealedContent}
 */
proto.collection.SealedContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.SealedContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.SealedContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.SealedContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.SealedContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint64 key_version = 1;
 * @return {number}
 */
proto.collection.SealedContent.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.SealedContent} returns this
 */
proto.collection.SealedContent.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.SealedContent} returns this
 */
proto.collection.SealedContent.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.SealedContent.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes content = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.SealedContent.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.collection.SealedContent.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.collection.SealedContent.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.SealedContent} returns this
 */
proto.collection.SealedContent.prototype.setContent = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.SealedContent} returns this
 */
proto.collection.SealedContent.prototype.clearContent = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.SealedContent.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Node}
 */
proto.collection.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Node;
  return proto.collection.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Node}
 */
proto.collection.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.collection.Node.ACLRole = {
  READER: 0,
  WRITER: 1,
  ACL_READER: 2,
  ACL_WRITER: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Node.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Node.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Node.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    key: (f = msg.getKey()) && proto.collection.PublicUserKey.toObject(includeInstance, f),
    wrappedLastKey: msg.getWrappedLastKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Node.Permission}
 */
proto.collection.Node.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Node.Permission;
  return proto.collection.Node.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Node.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Node.Permission}
 */
proto.collection.Node.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.Node.ACLRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.collection.PublicUserKey;
      reader.readMessage(value,proto.collection.PublicUserKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedLastKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Node.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Node.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Node.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.PublicUserKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional ACLRole role = 1;
 * @return {!proto.collection.Node.ACLRole}
 */
proto.collection.Node.Permission.prototype.getRole = function() {
  return /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @return {!proto.collection.Node.Permission} returns this
 */
proto.collection.Node.Permission.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Permission} returns this
 */
proto.collection.Node.Permission.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Permission.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PublicUserKey key = 2;
 * @return {?proto.collection.PublicUserKey}
 */
proto.collection.Node.Permission.prototype.getKey = function() {
  return /** @type{?proto.collection.PublicUserKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.PublicUserKey, 2));
};


/**
 * @param {?proto.collection.PublicUserKey|undefined} value
 * @return {!proto.collection.Node.Permission} returns this
*/
proto.collection.Node.Permission.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.Node.Permission} returns this
 */
proto.collection.Node.Permission.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Permission.prototype.hasKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_last_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.Node.Permission.prototype.getWrappedLastKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_last_key = 3;
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {string}
 */
proto.collection.Node.Permission.prototype.getWrappedLastKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedLastKey()));
};


/**
 * optional bytes wrapped_last_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {!Uint8Array}
 */
proto.collection.Node.Permission.prototype.getWrappedLastKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedLastKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.Node.Permission} returns this
 */
proto.collection.Node.Permission.prototype.setWrappedLastKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Permission} returns this
 */
proto.collection.Node.Permission.prototype.clearWrappedLastKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Permission.prototype.hasWrappedLastKey = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Node.Grantee.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Node.Grantee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Node.Grantee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.Grantee.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    accountVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    grant: (f = msg.getGrant()) && proto.collection.SealedContent.toObject(includeInstance, f),
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    expirationTime: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Node.Grantee}
 */
proto.collection.Node.Grantee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Node.Grantee;
  return proto.collection.Node.Grantee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Node.Grantee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Node.Grantee}
 */
proto.collection.Node.Grantee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setAccountVersion(value);
      break;
    case 3:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setGrant(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Node.Grantee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Node.Grantee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Node.Grantee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.Grantee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getGrant();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.Node.Grantee.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grantee.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 account_version = 2;
 * @return {number}
 */
proto.collection.Node.Grantee.prototype.getAccountVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.setAccountVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.clearAccountVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grantee.prototype.hasAccountVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SealedContent grant = 3;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.Node.Grantee.prototype.getGrant = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 3));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.Node.Grantee} returns this
*/
proto.collection.Node.Grantee.prototype.setGrant = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.clearGrant = function() {
  return this.setGrant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grantee.prototype.hasGrant = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool view_only = 4;
 * @return {boolean}
 */
proto.collection.Node.Grantee.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grantee.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string expiration_time = 5;
 * @return {string}
 */
proto.collection.Node.Grantee.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Grantee} returns this
 */
proto.collection.Node.Grantee.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grantee.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.Node.Grant.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Node.Grant.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Node.Grant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Node.Grant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.Grant.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    granteesList: jspb.Message.toObjectList(msg.getGranteesList(),
    proto.collection.Node.Grantee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.Node.Grant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Node.Grant;
  return proto.collection.Node.Grant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Node.Grant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.Node.Grant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.Node.ACLRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new proto.collection.Node.Grantee;
      reader.readMessage(value,proto.collection.Node.Grantee.deserializeBinaryFromReader);
      msg.addGrantees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Node.Grant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Node.Grant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Node.Grant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Node.Grant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getGranteesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.Node.Grantee.serializeBinaryToWriter
    );
  }
};


/**
 * optional ACLRole role = 1;
 * @return {!proto.collection.Node.ACLRole}
 */
proto.collection.Node.Grant.prototype.getRole = function() {
  return /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @return {!proto.collection.Node.Grant} returns this
 */
proto.collection.Node.Grant.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Grant} returns this
 */
proto.collection.Node.Grant.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grant.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.Node.Grant.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.Node.Grant} returns this
 */
proto.collection.Node.Grant.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Node.Grant} returns this
 */
proto.collection.Node.Grant.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Node.Grant.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Grantee grantees = 3;
 * @return {!Array<!proto.collection.Node.Grantee>}
 */
proto.collection.Node.Grant.prototype.getGranteesList = function() {
  return /** @type{!Array<!proto.collection.Node.Grantee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grantee, 3));
};


/**
 * @param {!Array<!proto.collection.Node.Grantee>} value
 * @return {!proto.collection.Node.Grant} returns this
*/
proto.collection.Node.Grant.prototype.setGranteesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.Node.Grantee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grantee}
 */
proto.collection.Node.Grant.prototype.addGrantees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.Node.Grantee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.Node.Grant} returns this
 */
proto.collection.Node.Grant.prototype.clearGranteesList = function() {
  return this.setGranteesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Collection.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Collection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Collection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Collection.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Collection}
 */
proto.collection.Collection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Collection;
  return proto.collection.Collection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Collection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Collection}
 */
proto.collection.Collection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Collection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Collection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Collection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Collection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Collection.Attributes.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Collection.Attributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Collection.Attributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Collection.Attributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = msg.getName()) && proto.collection.SealedContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Collection.Attributes}
 */
proto.collection.Collection.Attributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Collection.Attributes;
  return proto.collection.Collection.Attributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Collection.Attributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Collection.Attributes}
 */
proto.collection.Collection.Attributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Collection.Attributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Collection.Attributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Collection.Attributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Collection.Attributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.collection.Collection.Attributes.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.Collection.Attributes} returns this
 */
proto.collection.Collection.Attributes.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Collection.Attributes} returns this
 */
proto.collection.Collection.Attributes.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Collection.Attributes.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SealedContent name = 2;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.Collection.Attributes.prototype.getName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 2));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.Collection.Attributes} returns this
*/
proto.collection.Collection.Attributes.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.Collection.Attributes} returns this
 */
proto.collection.Collection.Attributes.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Collection.Attributes.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.SyncRule.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.SyncRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.SyncRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.SyncRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: msg.getNodeId_asB64(),
    sync: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.SyncRule}
 */
proto.collection.SyncRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.SyncRule;
  return proto.collection.SyncRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.SyncRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.SyncRule}
 */
proto.collection.SyncRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNodeId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSync(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.SyncRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.SyncRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.SyncRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.SyncRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes node_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.SyncRule.prototype.getNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes node_id = 1;
 * This is a type-conversion wrapper around `getNodeId()`
 * @return {string}
 */
proto.collection.SyncRule.prototype.getNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNodeId()));
};


/**
 * optional bytes node_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.SyncRule.prototype.getNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.SyncRule} returns this
 */
proto.collection.SyncRule.prototype.setNodeId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.SyncRule} returns this
 */
proto.collection.SyncRule.prototype.clearNodeId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.SyncRule.prototype.hasNodeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool sync = 2;
 * @return {boolean}
 */
proto.collection.SyncRule.prototype.getSync = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.SyncRule} returns this
 */
proto.collection.SyncRule.prototype.setSync = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.SyncRule} returns this
 */
proto.collection.SyncRule.prototype.clearSync = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.SyncRule.prototype.hasSync = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSEnvelope.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSEnvelope.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSEnvelope.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSEnvelope} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSEnvelope.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    userKeyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    requestId: msg.getRequestId_asB64(),
    signedPayload: msg.getSignedPayload_asB64(),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    proto.collection.FSEnvelope.Signature.toObject, includeInstance),
    userSignature: msg.getUserSignature_asB64(),
    deviceId: msg.getDeviceId_asB64(),
    deviceKeyVersion: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    dataExportId: msg.getDataExportId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSEnvelope}
 */
proto.collection.FSEnvelope.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSEnvelope;
  return proto.collection.FSEnvelope.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSEnvelope} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSEnvelope}
 */
proto.collection.FSEnvelope.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRequestId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignedPayload(value);
      break;
    case 5:
      var value = new proto.collection.FSEnvelope.Signature;
      reader.readMessage(value,proto.collection.FSEnvelope.Signature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setUserSignature(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeviceId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDeviceKeyVersion(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDataExportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSEnvelope.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSEnvelope} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSEnvelope.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSEnvelope.Signature.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.collection.FSEnvelope.Signature.oneofGroups_ = [[1,5]];

/**
 * @enum {number}
 */
proto.collection.FSEnvelope.Signature.RoleCase = {
  ROLE_NOT_SET: 0,
  COLLECTION_ROLE: 1,
  ACL_ROLE: 5
};

/**
 * @return {proto.collection.FSEnvelope.Signature.RoleCase}
 */
proto.collection.FSEnvelope.Signature.prototype.getRoleCase = function() {
  return /** @type {proto.collection.FSEnvelope.Signature.RoleCase} */(jspb.Message.computeOneofCase(this, proto.collection.FSEnvelope.Signature.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSEnvelope.Signature.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSEnvelope.Signature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSEnvelope.Signature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSEnvelope.Signature.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionRole: jspb.Message.getFieldWithDefault(msg, 1, 0),
    aclRole: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    signature: msg.getSignature_asB64(),
    groupId: msg.getGroupId_asB64(),
    maintainerId: msg.getMaintainerId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSEnvelope.Signature}
 */
proto.collection.FSEnvelope.Signature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSEnvelope.Signature;
  return proto.collection.FSEnvelope.Signature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSEnvelope.Signature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSEnvelope.Signature}
 */
proto.collection.FSEnvelope.Signature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setCollectionRole(value);
      break;
    case 5:
      var value = /** @type {!proto.collection.Node.ACLRole} */ (reader.readEnum());
      msg.setAclRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.Signature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSEnvelope.Signature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSEnvelope.Signature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSEnvelope.Signature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional FSRole collection_role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSEnvelope.Signature.prototype.getCollectionRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.setCollectionRole = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.collection.FSEnvelope.Signature.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.clearCollectionRole = function() {
  return jspb.Message.setOneofField(this, 1, proto.collection.FSEnvelope.Signature.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.Signature.prototype.hasCollectionRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Node.ACLRole acl_role = 5;
 * @return {!proto.collection.Node.ACLRole}
 */
proto.collection.FSEnvelope.Signature.prototype.getAclRole = function() {
  return /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.setAclRole = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.collection.FSEnvelope.Signature.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.clearAclRole = function() {
  return jspb.Message.setOneofField(this, 5, proto.collection.FSEnvelope.Signature.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.Signature.prototype.hasAclRole = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.FSEnvelope.Signature.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.Signature.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes signature = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.Signature.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes signature = 3;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.collection.FSEnvelope.Signature.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.Signature.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.setSignature = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.clearSignature = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.Signature.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes group_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.Signature.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes group_id = 4;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSEnvelope.Signature.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.Signature.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.Signature.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes maintainer_id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.Signature.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes maintainer_id = 6;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSEnvelope.Signature.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.Signature.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope.Signature} returns this
 */
proto.collection.FSEnvelope.Signature.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.Signature.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSEnvelope.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 user_key_version = 2;
 * @return {number}
 */
proto.collection.FSEnvelope.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes request_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.prototype.getRequestId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes request_id = 3;
 * This is a type-conversion wrapper around `getRequestId()`
 * @return {string}
 */
proto.collection.FSEnvelope.prototype.getRequestId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRequestId()));
};


/**
 * optional bytes request_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRequestId()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.prototype.getRequestId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRequestId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setRequestId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearRequestId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes signed_payload = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.prototype.getSignedPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes signed_payload = 4;
 * This is a type-conversion wrapper around `getSignedPayload()`
 * @return {string}
 */
proto.collection.FSEnvelope.prototype.getSignedPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignedPayload()));
};


/**
 * optional bytes signed_payload = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignedPayload()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.prototype.getSignedPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignedPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setSignedPayload = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearSignedPayload = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasSignedPayload = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Signature signatures = 5;
 * @return {!Array<!proto.collection.FSEnvelope.Signature>}
 */
proto.collection.FSEnvelope.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.collection.FSEnvelope.Signature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSEnvelope.Signature, 5));
};


/**
 * @param {!Array<!proto.collection.FSEnvelope.Signature>} value
 * @return {!proto.collection.FSEnvelope} returns this
*/
proto.collection.FSEnvelope.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSEnvelope.Signature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSEnvelope.Signature}
 */
proto.collection.FSEnvelope.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSEnvelope.Signature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};


/**
 * optional bytes user_signature = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.prototype.getUserSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes user_signature = 6;
 * This is a type-conversion wrapper around `getUserSignature()`
 * @return {string}
 */
proto.collection.FSEnvelope.prototype.getUserSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getUserSignature()));
};


/**
 * optional bytes user_signature = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getUserSignature()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.prototype.getUserSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getUserSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setUserSignature = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearUserSignature = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasUserSignature = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes device_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.prototype.getDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes device_id = 7;
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {string}
 */
proto.collection.FSEnvelope.prototype.getDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeviceId()));
};


/**
 * optional bytes device_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.prototype.getDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setDeviceId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearDeviceId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasDeviceId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 device_key_version = 8;
 * @return {number}
 */
proto.collection.FSEnvelope.prototype.getDeviceKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setDeviceKeyVersion = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearDeviceKeyVersion = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasDeviceKeyVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes data_export_id = 9;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSEnvelope.prototype.getDataExportId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes data_export_id = 9;
 * This is a type-conversion wrapper around `getDataExportId()`
 * @return {string}
 */
proto.collection.FSEnvelope.prototype.getDataExportId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDataExportId()));
};


/**
 * optional bytes data_export_id = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDataExportId()`
 * @return {!Uint8Array}
 */
proto.collection.FSEnvelope.prototype.getDataExportId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDataExportId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.setDataExportId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSEnvelope} returns this
 */
proto.collection.FSEnvelope.prototype.clearDataExportId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSEnvelope.prototype.hasDataExportId = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.repeatedFields_ = [38,39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiVersion: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f,
    collectionId: msg.getCollectionId_asB64(),
    method: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    init: (f = msg.getInit()) && proto.collection.FSRequest.Init.toObject(includeInstance, f),
    create: (f = msg.getCreate()) && proto.collection.FSRequest.Create.toObject(includeInstance, f),
    pb_delete: (f = msg.getDelete()) && proto.collection.FSRequest.Delete.toObject(includeInstance, f),
    update: (f = msg.getUpdate()) && proto.collection.FSRequest.Update.toObject(includeInstance, f),
    upload: (f = msg.getUpload()) && proto.collection.FSRequest.Upload.toObject(includeInstance, f),
    legacyDownload: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    id: msg.getId_asB64(),
    seq: jspb.Message.getFieldWithDefault(msg, 10, 0),
    grant: (f = msg.getGrant()) && proto.collection.FSRequest.Grant.toObject(includeInstance, f),
    ack: (f = msg.getAck()) && proto.collection.FSRequest.Ack.toObject(includeInstance, f),
    rekey: (f = msg.getRekey()) && proto.collection.FSRequest.Rekey.toObject(includeInstance, f),
    role: jspb.Message.getFieldWithDefault(msg, 14, 0),
    accept: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    fetch: (f = msg.getFetch()) && proto.collection.FSRequest.Fetch.toObject(includeInstance, f),
    makeShareable: (f = msg.getMakeShareable()) && proto.collection.FSRequest.MakeShareable.toObject(includeInstance, f),
    readLog: (f = msg.getReadLog()) && proto.collection.FSRequest.ReadLog.toObject(includeInstance, f),
    groupId: msg.getGroupId_asB64(),
    addKey: (f = msg.getAddKey()) && proto.collection.FSRequest.AddKey.toObject(includeInstance, f),
    snapshot: (f = msg.getSnapshot()) && proto.collection.FSRequest.Snapshot.toObject(includeInstance, f),
    forUser: (f = jspb.Message.getField(msg, 24)) == null ? undefined : f,
    bulkUpdate: (f = msg.getBulkUpdate()) && proto.collection.FSRequest.BulkUpdate.toObject(includeInstance, f),
    includeSnapshot: jspb.Message.getBooleanFieldWithDefault(msg, 26, true),
    paging: (f = msg.getPaging()) && proto.collection.FSRequest.Paging.toObject(includeInstance, f),
    upgrade: (f = msg.getUpgrade()) && proto.collection.FSRequest.UpgradeCollection.toObject(includeInstance, f),
    collectionAttributes: (f = msg.getCollectionAttributes()) && proto.collection.FSRequest.SetCollectionAttributes.toObject(includeInstance, f),
    aclTree: (f = msg.getAclTree()) && proto.collection.FSRequest.ACLTree.toObject(includeInstance, f),
    aclKeyHistory: (f = msg.getAclKeyHistory()) && proto.collection.FSRequest.ACLKeyHistory.toObject(includeInstance, f),
    lockFile: (f = msg.getLockFile()) && proto.collection.FSRequest.LockFile.toObject(includeInstance, f),
    unlockFile: (f = msg.getUnlockFile()) && proto.collection.FSRequest.UnlockFile.toObject(includeInstance, f),
    linkedCollectionsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    findLinks: (f = msg.getFindLinks()) && proto.collection.FSRequest.FindLinks.toObject(includeInstance, f),
    download: (f = msg.getDownload()) && proto.collection.FSRequest.DownloadBlocks.toObject(includeInstance, f),
    restore: (f = msg.getRestore()) && proto.collection.FSRequest.Restore.toObject(includeInstance, f),
    collectionIdsList: msg.getCollectionIdsList_asB64(),
    syncRulesList: jspb.Message.toObjectList(msg.getSyncRulesList(),
    proto.collection.SyncRule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest}
 */
proto.collection.FSRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest;
  return proto.collection.FSRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest}
 */
proto.collection.FSRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setApiVersion(value);
      break;
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.FSMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    case 3:
      var value = new proto.collection.FSRequest.Init;
      reader.readMessage(value,proto.collection.FSRequest.Init.deserializeBinaryFromReader);
      msg.setInit(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.Create;
      reader.readMessage(value,proto.collection.FSRequest.Create.deserializeBinaryFromReader);
      msg.setCreate(value);
      break;
    case 5:
      var value = new proto.collection.FSRequest.Delete;
      reader.readMessage(value,proto.collection.FSRequest.Delete.deserializeBinaryFromReader);
      msg.setDelete(value);
      break;
    case 6:
      var value = new proto.collection.FSRequest.Update;
      reader.readMessage(value,proto.collection.FSRequest.Update.deserializeBinaryFromReader);
      msg.setUpdate(value);
      break;
    case 7:
      var value = new proto.collection.FSRequest.Upload;
      reader.readMessage(value,proto.collection.FSRequest.Upload.deserializeBinaryFromReader);
      msg.setUpload(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegacyDownload(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    case 11:
      var value = new proto.collection.FSRequest.Grant;
      reader.readMessage(value,proto.collection.FSRequest.Grant.deserializeBinaryFromReader);
      msg.setGrant(value);
      break;
    case 12:
      var value = new proto.collection.FSRequest.Ack;
      reader.readMessage(value,proto.collection.FSRequest.Ack.deserializeBinaryFromReader);
      msg.setAck(value);
      break;
    case 13:
      var value = new proto.collection.FSRequest.Rekey;
      reader.readMessage(value,proto.collection.FSRequest.Rekey.deserializeBinaryFromReader);
      msg.setRekey(value);
      break;
    case 14:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccept(value);
      break;
    case 18:
      var value = new proto.collection.FSRequest.Fetch;
      reader.readMessage(value,proto.collection.FSRequest.Fetch.deserializeBinaryFromReader);
      msg.setFetch(value);
      break;
    case 19:
      var value = new proto.collection.FSRequest.MakeShareable;
      reader.readMessage(value,proto.collection.FSRequest.MakeShareable.deserializeBinaryFromReader);
      msg.setMakeShareable(value);
      break;
    case 20:
      var value = new proto.collection.FSRequest.ReadLog;
      reader.readMessage(value,proto.collection.FSRequest.ReadLog.deserializeBinaryFromReader);
      msg.setReadLog(value);
      break;
    case 21:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 22:
      var value = new proto.collection.FSRequest.AddKey;
      reader.readMessage(value,proto.collection.FSRequest.AddKey.deserializeBinaryFromReader);
      msg.setAddKey(value);
      break;
    case 23:
      var value = new proto.collection.FSRequest.Snapshot;
      reader.readMessage(value,proto.collection.FSRequest.Snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setForUser(value);
      break;
    case 25:
      var value = new proto.collection.FSRequest.BulkUpdate;
      reader.readMessage(value,proto.collection.FSRequest.BulkUpdate.deserializeBinaryFromReader);
      msg.setBulkUpdate(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeSnapshot(value);
      break;
    case 27:
      var value = new proto.collection.FSRequest.Paging;
      reader.readMessage(value,proto.collection.FSRequest.Paging.deserializeBinaryFromReader);
      msg.setPaging(value);
      break;
    case 28:
      var value = new proto.collection.FSRequest.UpgradeCollection;
      reader.readMessage(value,proto.collection.FSRequest.UpgradeCollection.deserializeBinaryFromReader);
      msg.setUpgrade(value);
      break;
    case 29:
      var value = new proto.collection.FSRequest.SetCollectionAttributes;
      reader.readMessage(value,proto.collection.FSRequest.SetCollectionAttributes.deserializeBinaryFromReader);
      msg.setCollectionAttributes(value);
      break;
    case 30:
      var value = new proto.collection.FSRequest.ACLTree;
      reader.readMessage(value,proto.collection.FSRequest.ACLTree.deserializeBinaryFromReader);
      msg.setAclTree(value);
      break;
    case 31:
      var value = new proto.collection.FSRequest.ACLKeyHistory;
      reader.readMessage(value,proto.collection.FSRequest.ACLKeyHistory.deserializeBinaryFromReader);
      msg.setAclKeyHistory(value);
      break;
    case 32:
      var value = new proto.collection.FSRequest.LockFile;
      reader.readMessage(value,proto.collection.FSRequest.LockFile.deserializeBinaryFromReader);
      msg.setLockFile(value);
      break;
    case 33:
      var value = new proto.collection.FSRequest.UnlockFile;
      reader.readMessage(value,proto.collection.FSRequest.UnlockFile.deserializeBinaryFromReader);
      msg.setUnlockFile(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLinkedCollectionsOnly(value);
      break;
    case 35:
      var value = new proto.collection.FSRequest.FindLinks;
      reader.readMessage(value,proto.collection.FSRequest.FindLinks.deserializeBinaryFromReader);
      msg.setFindLinks(value);
      break;
    case 36:
      var value = new proto.collection.FSRequest.DownloadBlocks;
      reader.readMessage(value,proto.collection.FSRequest.DownloadBlocks.deserializeBinaryFromReader);
      msg.setDownload(value);
      break;
    case 37:
      var value = new proto.collection.FSRequest.Restore;
      reader.readMessage(value,proto.collection.FSRequest.Restore.deserializeBinaryFromReader);
      msg.setRestore(value);
      break;
    case 38:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addCollectionIds(value);
      break;
    case 39:
      var value = new proto.collection.SyncRule;
      reader.readMessage(value,proto.collection.SyncRule.deserializeBinaryFromReader);
      msg.addSyncRules(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.FSMethod} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getInit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.FSRequest.Init.serializeBinaryToWriter
    );
  }
  f = message.getCreate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSRequest.Create.serializeBinaryToWriter
    );
  }
  f = message.getDelete();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSRequest.Delete.serializeBinaryToWriter
    );
  }
  f = message.getUpdate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.FSRequest.Update.serializeBinaryToWriter
    );
  }
  f = message.getUpload();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.collection.FSRequest.Upload.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getGrant();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.collection.FSRequest.Grant.serializeBinaryToWriter
    );
  }
  f = message.getAck();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.collection.FSRequest.Ack.serializeBinaryToWriter
    );
  }
  f = message.getRekey();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.collection.FSRequest.Rekey.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getFetch();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.collection.FSRequest.Fetch.serializeBinaryToWriter
    );
  }
  f = message.getMakeShareable();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.collection.FSRequest.MakeShareable.serializeBinaryToWriter
    );
  }
  f = message.getReadLog();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.collection.FSRequest.ReadLog.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeBytes(
      21,
      f
    );
  }
  f = message.getAddKey();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.collection.FSRequest.AddKey.serializeBinaryToWriter
    );
  }
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.collection.FSRequest.Snapshot.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getBulkUpdate();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.collection.FSRequest.BulkUpdate.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getPaging();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.collection.FSRequest.Paging.serializeBinaryToWriter
    );
  }
  f = message.getUpgrade();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.collection.FSRequest.UpgradeCollection.serializeBinaryToWriter
    );
  }
  f = message.getCollectionAttributes();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.collection.FSRequest.SetCollectionAttributes.serializeBinaryToWriter
    );
  }
  f = message.getAclTree();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.collection.FSRequest.ACLTree.serializeBinaryToWriter
    );
  }
  f = message.getAclKeyHistory();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.collection.FSRequest.ACLKeyHistory.serializeBinaryToWriter
    );
  }
  f = message.getLockFile();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.collection.FSRequest.LockFile.serializeBinaryToWriter
    );
  }
  f = message.getUnlockFile();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      proto.collection.FSRequest.UnlockFile.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getFindLinks();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.collection.FSRequest.FindLinks.serializeBinaryToWriter
    );
  }
  f = message.getDownload();
  if (f != null) {
    writer.writeMessage(
      36,
      f,
      proto.collection.FSRequest.DownloadBlocks.serializeBinaryToWriter
    );
  }
  f = message.getRestore();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.collection.FSRequest.Restore.serializeBinaryToWriter
    );
  }
  f = message.getCollectionIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      38,
      f
    );
  }
  f = message.getSyncRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      39,
      f,
      proto.collection.SyncRule.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.PublicKey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.PublicKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.PublicKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.PublicKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: msg.getPublicKey_asB64(),
    verifyKey: msg.getVerifyKey_asB64(),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    publicKeyProtocol: jspb.Message.getFieldWithDefault(msg, 4, 1),
    verifyKeyProtocol: jspb.Message.getFieldWithDefault(msg, 5, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.PublicKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.PublicKey;
  return proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.PublicKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPublicKey(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVerifyKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPublicKeyProtocol(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVerifyKeyProtocol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.PublicKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.PublicKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.PublicKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.PublicKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional bytes public_key = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.PublicKey.prototype.getPublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes public_key = 1;
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {string}
 */
proto.collection.FSRequest.PublicKey.prototype.getPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPublicKey()));
};


/**
 * optional bytes public_key = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.PublicKey.prototype.getPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.setPublicKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.clearPublicKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.PublicKey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes verify_key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.PublicKey.prototype.getVerifyKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes verify_key = 2;
 * This is a type-conversion wrapper around `getVerifyKey()`
 * @return {string}
 */
proto.collection.FSRequest.PublicKey.prototype.getVerifyKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVerifyKey()));
};


/**
 * optional bytes verify_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVerifyKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.PublicKey.prototype.getVerifyKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVerifyKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.setVerifyKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.clearVerifyKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.PublicKey.prototype.hasVerifyKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 version = 3;
 * @return {number}
 */
proto.collection.FSRequest.PublicKey.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.PublicKey.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 public_key_protocol = 4;
 * @return {number}
 */
proto.collection.FSRequest.PublicKey.prototype.getPublicKeyProtocol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 1));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.setPublicKeyProtocol = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.clearPublicKeyProtocol = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.PublicKey.prototype.hasPublicKeyProtocol = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 verify_key_protocol = 5;
 * @return {number}
 */
proto.collection.FSRequest.PublicKey.prototype.getVerifyKeyProtocol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 1));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.setVerifyKeyProtocol = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.PublicKey} returns this
 */
proto.collection.FSRequest.PublicKey.prototype.clearVerifyKeyProtocol = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.PublicKey.prototype.hasVerifyKeyProtocol = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Init.repeatedFields_ = [14,18,19,7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Init.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Init.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Init} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Init.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootDirId: msg.getRootDirId_asB64(),
    rootDirVersion: msg.getRootDirVersion_asB64(),
    rootDirName: msg.getRootDirName_asB64(),
    keysList: jspb.Message.toObjectList(msg.getKeysList(),
    proto.collection.FSRequest.Init.Key.toObject, includeInstance),
    wrappedRootDirKey: msg.getWrappedRootDirKey_asB64(),
    isComplete: jspb.Message.getBooleanFieldWithDefault(msg, 15, true),
    protocolVersion: jspb.Message.getFieldWithDefault(msg, 16, 1),
    name: msg.getName_asB64(),
    rootDirPermissionsList: jspb.Message.toObjectList(msg.getRootDirPermissionsList(),
    proto.collection.Node.Permission.toObject, includeInstance),
    rootDirGrantsList: jspb.Message.toObjectList(msg.getRootDirGrantsList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    maintainerScopedName: (f = msg.getMaintainerScopedName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    ownerKey: (f = msg.getOwnerKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedOwnerKey: msg.getWrappedOwnerKey_asB64(),
    readerKey: (f = msg.getReaderKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedReaderKey: msg.getWrappedReaderKey_asB64(),
    writerKey: (f = msg.getWriterKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedWriterKey: msg.getWrappedWriterKey_asB64(),
    ownerPermission: (f = msg.getOwnerPermission()) && proto.collection.FSRequest.Permission.toObject(includeInstance, f),
    readerAclList: jspb.Message.toObjectList(msg.getReaderAclList(),
    proto.collection.FSRequest.Permission.toObject, includeInstance),
    writerAclList: jspb.Message.toObjectList(msg.getWriterAclList(),
    proto.collection.FSRequest.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Init}
 */
proto.collection.FSRequest.Init.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Init;
  return proto.collection.FSRequest.Init.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Init} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Init}
 */
proto.collection.FSRequest.Init.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRootDirId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRootDirVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRootDirName(value);
      break;
    case 14:
      var value = new proto.collection.FSRequest.Init.Key;
      reader.readMessage(value,proto.collection.FSRequest.Init.Key.deserializeBinaryFromReader);
      msg.addKeys(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedRootDirKey(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsComplete(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 18:
      var value = new proto.collection.Node.Permission;
      reader.readMessage(value,proto.collection.Node.Permission.deserializeBinaryFromReader);
      msg.addRootDirPermissions(value);
      break;
    case 19:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addRootDirGrants(value);
      break;
    case 20:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setMaintainerScopedName(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setOwnerKey(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedOwnerKey(value);
      break;
    case 6:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setReaderKey(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedReaderKey(value);
      break;
    case 8:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setWriterKey(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedWriterKey(value);
      break;
    case 5:
      var value = new proto.collection.FSRequest.Permission;
      reader.readMessage(value,proto.collection.FSRequest.Permission.deserializeBinaryFromReader);
      msg.setOwnerPermission(value);
      break;
    case 7:
      var value = new proto.collection.FSRequest.Permission;
      reader.readMessage(value,proto.collection.FSRequest.Permission.deserializeBinaryFromReader);
      msg.addReaderAcl(value);
      break;
    case 9:
      var value = new proto.collection.FSRequest.Permission;
      reader.readMessage(value,proto.collection.FSRequest.Permission.deserializeBinaryFromReader);
      msg.addWriterAcl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Init.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Init} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Init.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.collection.FSRequest.Init.Key.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBytes(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getRootDirPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.collection.Node.Permission.serializeBinaryToWriter
    );
  }
  f = message.getRootDirGrantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = message.getMaintainerScopedName();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = message.getOwnerKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getReaderKey();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getWriterKey();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = message.getOwnerPermission();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSRequest.Permission.serializeBinaryToWriter
    );
  }
  f = message.getReaderAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.collection.FSRequest.Permission.serializeBinaryToWriter
    );
  }
  f = message.getWriterAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.collection.FSRequest.Permission.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Init.Key.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Init.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Init.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Init.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    publicKey: (f = msg.getPublicKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedKey: msg.getWrappedKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Init.Key}
 */
proto.collection.FSRequest.Init.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Init.Key;
  return proto.collection.FSRequest.Init.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Init.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Init.Key}
 */
proto.collection.FSRequest.Init.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Init.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Init.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Init.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Init.Key.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Init.Key} returns this
 */
proto.collection.FSRequest.Init.Key.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init.Key} returns this
 */
proto.collection.FSRequest.Init.Key.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.Key.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PublicKey public_key = 2;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.Init.Key.prototype.getPublicKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 2));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.Init.Key} returns this
*/
proto.collection.FSRequest.Init.Key.prototype.setPublicKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Init.Key} returns this
 */
proto.collection.FSRequest.Init.Key.prototype.clearPublicKey = function() {
  return this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.Key.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.Key.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSRequest.Init.Key.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.Key.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init.Key} returns this
 */
proto.collection.FSRequest.Init.Key.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init.Key} returns this
 */
proto.collection.FSRequest.Init.Key.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.Key.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes root_dir_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getRootDirId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes root_dir_id = 1;
 * This is a type-conversion wrapper around `getRootDirId()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getRootDirId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRootDirId()));
};


/**
 * optional bytes root_dir_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRootDirId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getRootDirId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRootDirId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setRootDirId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearRootDirId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasRootDirId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes root_dir_version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getRootDirVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes root_dir_version = 2;
 * This is a type-conversion wrapper around `getRootDirVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getRootDirVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRootDirVersion()));
};


/**
 * optional bytes root_dir_version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRootDirVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getRootDirVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRootDirVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setRootDirVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearRootDirVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasRootDirVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes root_dir_name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getRootDirName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes root_dir_name = 3;
 * This is a type-conversion wrapper around `getRootDirName()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getRootDirName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRootDirName()));
};


/**
 * optional bytes root_dir_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRootDirName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getRootDirName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRootDirName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setRootDirName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearRootDirName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasRootDirName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Key keys = 14;
 * @return {!Array<!proto.collection.FSRequest.Init.Key>}
 */
proto.collection.FSRequest.Init.prototype.getKeysList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Init.Key>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Init.Key, 14));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Init.Key>} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setKeysList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.collection.FSRequest.Init.Key=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Init.Key}
 */
proto.collection.FSRequest.Init.prototype.addKeys = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.collection.FSRequest.Init.Key, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};


/**
 * optional bytes wrapped_root_dir_key = 10;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getWrappedRootDirKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes wrapped_root_dir_key = 10;
 * This is a type-conversion wrapper around `getWrappedRootDirKey()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getWrappedRootDirKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedRootDirKey()));
};


/**
 * optional bytes wrapped_root_dir_key = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedRootDirKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getWrappedRootDirKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedRootDirKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setWrappedRootDirKey = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearWrappedRootDirKey = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasWrappedRootDirKey = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_complete = 15;
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.getIsComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, true));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setIsComplete = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearIsComplete = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasIsComplete = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional uint64 protocol_version = 16;
 * @return {number}
 */
proto.collection.FSRequest.Init.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 1));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bytes name = 17;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes name = 17;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setName = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearName = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasName = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated Node.Permission root_dir_permissions = 18;
 * @return {!Array<!proto.collection.Node.Permission>}
 */
proto.collection.FSRequest.Init.prototype.getRootDirPermissionsList = function() {
  return /** @type{!Array<!proto.collection.Node.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Permission, 18));
};


/**
 * @param {!Array<!proto.collection.Node.Permission>} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setRootDirPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.collection.Node.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Permission}
 */
proto.collection.FSRequest.Init.prototype.addRootDirPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.collection.Node.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearRootDirPermissionsList = function() {
  return this.setRootDirPermissionsList([]);
};


/**
 * repeated Node.Grant root_dir_grants = 19;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSRequest.Init.prototype.getRootDirGrantsList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 19));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setRootDirGrantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSRequest.Init.prototype.addRootDirGrants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearRootDirGrantsList = function() {
  return this.setRootDirGrantsList([]);
};


/**
 * optional SealedContent maintainer_scoped_name = 20;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSRequest.Init.prototype.getMaintainerScopedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 20));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setMaintainerScopedName = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearMaintainerScopedName = function() {
  return this.setMaintainerScopedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasMaintainerScopedName = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PublicKey owner_key = 4;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.Init.prototype.getOwnerKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 4));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setOwnerKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearOwnerKey = function() {
  return this.setOwnerKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasOwnerKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes wrapped_owner_key = 11;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getWrappedOwnerKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes wrapped_owner_key = 11;
 * This is a type-conversion wrapper around `getWrappedOwnerKey()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getWrappedOwnerKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedOwnerKey()));
};


/**
 * optional bytes wrapped_owner_key = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedOwnerKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getWrappedOwnerKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedOwnerKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setWrappedOwnerKey = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearWrappedOwnerKey = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasWrappedOwnerKey = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PublicKey reader_key = 6;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.Init.prototype.getReaderKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 6));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setReaderKey = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearReaderKey = function() {
  return this.setReaderKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasReaderKey = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes wrapped_reader_key = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getWrappedReaderKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes wrapped_reader_key = 12;
 * This is a type-conversion wrapper around `getWrappedReaderKey()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getWrappedReaderKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedReaderKey()));
};


/**
 * optional bytes wrapped_reader_key = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedReaderKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getWrappedReaderKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedReaderKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setWrappedReaderKey = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearWrappedReaderKey = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasWrappedReaderKey = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional PublicKey writer_key = 8;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.Init.prototype.getWriterKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 8));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setWriterKey = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearWriterKey = function() {
  return this.setWriterKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasWriterKey = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes wrapped_writer_key = 13;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Init.prototype.getWrappedWriterKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes wrapped_writer_key = 13;
 * This is a type-conversion wrapper around `getWrappedWriterKey()`
 * @return {string}
 */
proto.collection.FSRequest.Init.prototype.getWrappedWriterKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedWriterKey()));
};


/**
 * optional bytes wrapped_writer_key = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedWriterKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Init.prototype.getWrappedWriterKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedWriterKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.setWrappedWriterKey = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearWrappedWriterKey = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasWrappedWriterKey = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Permission owner_permission = 5;
 * @return {?proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Init.prototype.getOwnerPermission = function() {
  return /** @type{?proto.collection.FSRequest.Permission} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Permission, 5));
};


/**
 * @param {?proto.collection.FSRequest.Permission|undefined} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setOwnerPermission = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearOwnerPermission = function() {
  return this.setOwnerPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Init.prototype.hasOwnerPermission = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Permission reader_acl = 7;
 * @return {!Array<!proto.collection.FSRequest.Permission>}
 */
proto.collection.FSRequest.Init.prototype.getReaderAclList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Permission, 7));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Permission>} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setReaderAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.collection.FSRequest.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Init.prototype.addReaderAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.collection.FSRequest.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearReaderAclList = function() {
  return this.setReaderAclList([]);
};


/**
 * repeated Permission writer_acl = 9;
 * @return {!Array<!proto.collection.FSRequest.Permission>}
 */
proto.collection.FSRequest.Init.prototype.getWriterAclList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Permission, 9));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Permission>} value
 * @return {!proto.collection.FSRequest.Init} returns this
*/
proto.collection.FSRequest.Init.prototype.setWriterAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.collection.FSRequest.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Init.prototype.addWriterAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.collection.FSRequest.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Init} returns this
 */
proto.collection.FSRequest.Init.prototype.clearWriterAclList = function() {
  return this.setWriterAclList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Create.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Create.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Create.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Create} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Create.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentId: msg.getParentId_asB64(),
    parentVer: msg.getParentVer_asB64(),
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    name: msg.getName_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    wrappedDirKey: (f = msg.getWrappedDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    linkedCollectionId: msg.getLinkedCollectionId_asB64(),
    blockIdsList: msg.getBlockIdsList_asB64(),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Create}
 */
proto.collection.FSRequest.Create.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Create;
  return proto.collection.FSRequest.Create.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Create} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Create}
 */
proto.collection.FSRequest.Create.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentVer(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {!proto.collection.FSType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedDirKey(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addBlockIds(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Create.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Create} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Create.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {!proto.collection.FSType} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getWrappedDirKey();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getBlockIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional bytes parent_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Create.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes parent_id = 1;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes parent_ver = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Create.prototype.getParentVer = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes parent_ver = 2;
 * This is a type-conversion wrapper around `getParentVer()`
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getParentVer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentVer()));
};


/**
 * optional bytes parent_ver = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentVer()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.getParentVer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentVer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setParentVer = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearParentVer = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasParentVer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Create.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes id = 3;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes version = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Create.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes version = 4;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes name = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Create.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes name = 5;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setName = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearName = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FSType type = 6;
 * @return {!proto.collection.FSType}
 */
proto.collection.FSRequest.Create.prototype.getType = function() {
  return /** @type {!proto.collection.FSType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.collection.FSType} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setType = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearType = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FSWrappedKey wrapped_dir_key = 7;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.Create.prototype.getWrappedDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 7));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.Create} returns this
*/
proto.collection.FSRequest.Create.prototype.setWrappedDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearWrappedDirKey = function() {
  return this.setWrappedDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasWrappedDirKey = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes linked_collection_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Create.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes linked_collection_id = 8;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Create.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated bytes block_ids = 9;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.collection.FSRequest.Create.prototype.getBlockIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * repeated bytes block_ids = 9;
 * This is a type-conversion wrapper around `getBlockIdsList()`
 * @return {!Array<string>}
 */
proto.collection.FSRequest.Create.prototype.getBlockIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getBlockIdsList()));
};


/**
 * repeated bytes block_ids = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.collection.FSRequest.Create.prototype.getBlockIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getBlockIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setBlockIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.addBlockIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearBlockIdsList = function() {
  return this.setBlockIdsList([]);
};


/**
 * optional string last_local_fs_update = 10;
 * @return {string}
 */
proto.collection.FSRequest.Create.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Create} returns this
 */
proto.collection.FSRequest.Create.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Create.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Delete.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Delete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Delete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Delete.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    expunge: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Delete}
 */
proto.collection.FSRequest.Delete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Delete;
  return proto.collection.FSRequest.Delete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Delete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Delete}
 */
proto.collection.FSRequest.Delete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpunge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Delete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Delete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Delete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Delete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Delete.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.Delete.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Delete.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Delete} returns this
 */
proto.collection.FSRequest.Delete.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Delete} returns this
 */
proto.collection.FSRequest.Delete.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Delete.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Delete.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes version = 2;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Delete.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Delete.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Delete} returns this
 */
proto.collection.FSRequest.Delete.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Delete} returns this
 */
proto.collection.FSRequest.Delete.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Delete.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool expunge = 3;
 * @return {boolean}
 */
proto.collection.FSRequest.Delete.prototype.getExpunge = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Delete} returns this
 */
proto.collection.FSRequest.Delete.prototype.setExpunge = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Delete} returns this
 */
proto.collection.FSRequest.Delete.prototype.clearExpunge = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Delete.prototype.hasExpunge = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Update.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Update.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Update.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Update} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Update.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    oldVersion: msg.getOldVersion_asB64(),
    newVersion: msg.getNewVersion_asB64(),
    changesList: jspb.Message.toObjectList(msg.getChangesList(),
    proto.collection.FSRequest.Update.Change.toObject, includeInstance),
    newParentVer: msg.getNewParentVer_asB64(),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Update}
 */
proto.collection.FSRequest.Update.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Update;
  return proto.collection.FSRequest.Update.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Update} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Update}
 */
proto.collection.FSRequest.Update.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewVersion(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.Update.Change;
      reader.readMessage(value,proto.collection.FSRequest.Update.Change.deserializeBinaryFromReader);
      msg.addChanges(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewParentVer(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Update.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Update} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Update.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.Update.Change.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSRequest.Update.Op = {
  ALTER: 0,
  RENAME: 1,
  MOVE: 2,
  REKEY: 3,
  REVERT: 4,
  REWRAP: 5,
  ACL: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Update.BlockUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Update.BlockUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Update.BlockUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    rewrappedKey: (f = msg.getRewrappedKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    newBlockId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Update.BlockUpdate}
 */
proto.collection.FSRequest.Update.BlockUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Update.BlockUpdate;
  return proto.collection.FSRequest.Update.BlockUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Update.BlockUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Update.BlockUpdate}
 */
proto.collection.FSRequest.Update.BlockUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockId(value);
      break;
    case 2:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setRewrappedKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewBlockId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Update.BlockUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Update.BlockUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Update.BlockUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRewrappedKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string block_id = 1;
 * @return {string}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.getBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Update.BlockUpdate} returns this
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.setBlockId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.BlockUpdate} returns this
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.clearBlockId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FSWrappedKey rewrapped_key = 2;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.getRewrappedKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 2));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.Update.BlockUpdate} returns this
*/
proto.collection.FSRequest.Update.BlockUpdate.prototype.setRewrappedKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Update.BlockUpdate} returns this
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.clearRewrappedKey = function() {
  return this.setRewrappedKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.hasRewrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string new_block_id = 3;
 * @return {string}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.getNewBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Update.BlockUpdate} returns this
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.setNewBlockId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.BlockUpdate} returns this
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.clearNewBlockId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.BlockUpdate.prototype.hasNewBlockId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Update.Change.repeatedFields_ = [2,8,9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Update.Change.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Update.Change.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Update.Change} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Update.Change.toObject = function(includeInstance, msg) {
  var f, obj = {
    op: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dataList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    newName: msg.getNewName_asB64(),
    newParentId: msg.getNewParentId_asB64(),
    newDirKey: (f = msg.getNewDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    revertVersion: msg.getRevertVersion_asB64(),
    maintainerId: msg.getMaintainerId_asB64(),
    newNodeGrantsList: jspb.Message.toObjectList(msg.getNewNodeGrantsList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    newNodePermissionsList: jspb.Message.toObjectList(msg.getNewNodePermissionsList(),
    proto.collection.Node.Permission.toObject, includeInstance),
    maintainerScopedName: (f = msg.getMaintainerScopedName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    blockUpdatesList: jspb.Message.toObjectList(msg.getBlockUpdatesList(),
    proto.collection.FSRequest.Update.BlockUpdate.toObject, includeInstance),
    readKeyNodeId: msg.getReadKeyNodeId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Update.Change}
 */
proto.collection.FSRequest.Update.Change.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Update.Change;
  return proto.collection.FSRequest.Update.Change.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Update.Change} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Update.Change}
 */
proto.collection.FSRequest.Update.Change.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRequest.Update.Op} */ (reader.readEnum());
      msg.setOp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addData(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewName(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewParentId(value);
      break;
    case 5:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setNewDirKey(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRevertVersion(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 8:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addNewNodeGrants(value);
      break;
    case 9:
      var value = new proto.collection.Node.Permission;
      reader.readMessage(value,proto.collection.Node.Permission.deserializeBinaryFromReader);
      msg.addNewNodePermissions(value);
      break;
    case 10:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setMaintainerScopedName(value);
      break;
    case 11:
      var value = new proto.collection.FSRequest.Update.BlockUpdate;
      reader.readMessage(value,proto.collection.FSRequest.Update.BlockUpdate.deserializeBinaryFromReader);
      msg.addBlockUpdates(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.Change.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Update.Change.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Update.Change} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Update.Change.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRequest.Update.Op} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getNewDirKey();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getNewNodeGrantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = message.getNewNodePermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.collection.Node.Permission.serializeBinaryToWriter
    );
  }
  f = message.getMaintainerScopedName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = message.getBlockUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.collection.FSRequest.Update.BlockUpdate.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
};


/**
 * optional Op op = 1;
 * @return {!proto.collection.FSRequest.Update.Op}
 */
proto.collection.FSRequest.Update.Change.prototype.getOp = function() {
  return /** @type {!proto.collection.FSRequest.Update.Op} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRequest.Update.Op} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setOp = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearOp = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasOp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string data = 2;
 * @return {!Array<string>}
 */
proto.collection.FSRequest.Update.Change.prototype.getDataList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setDataList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.addData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional bytes new_name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes new_name = 3;
 * This is a type-conversion wrapper around `getNewName()`
 * @return {string}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewName()));
};


/**
 * optional bytes new_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setNewName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearNewName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasNewName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes new_parent_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes new_parent_id = 4;
 * This is a type-conversion wrapper around `getNewParentId()`
 * @return {string}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewParentId()));
};


/**
 * optional bytes new_parent_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setNewParentId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearNewParentId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasNewParentId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FSWrappedKey new_dir_key = 5;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 5));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
*/
proto.collection.FSRequest.Update.Change.prototype.setNewDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearNewDirKey = function() {
  return this.setNewDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasNewDirKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes revert_version = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.Change.prototype.getRevertVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes revert_version = 6;
 * This is a type-conversion wrapper around `getRevertVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Update.Change.prototype.getRevertVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRevertVersion()));
};


/**
 * optional bytes revert_version = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRevertVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.Change.prototype.getRevertVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRevertVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setRevertVersion = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearRevertVersion = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasRevertVersion = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes maintainer_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.Change.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes maintainer_id = 7;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSRequest.Update.Change.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.Change.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated Node.Grant new_node_grants = 8;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewNodeGrantsList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 8));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
*/
proto.collection.FSRequest.Update.Change.prototype.setNewNodeGrantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSRequest.Update.Change.prototype.addNewNodeGrants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearNewNodeGrantsList = function() {
  return this.setNewNodeGrantsList([]);
};


/**
 * repeated Node.Permission new_node_permissions = 9;
 * @return {!Array<!proto.collection.Node.Permission>}
 */
proto.collection.FSRequest.Update.Change.prototype.getNewNodePermissionsList = function() {
  return /** @type{!Array<!proto.collection.Node.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Permission, 9));
};


/**
 * @param {!Array<!proto.collection.Node.Permission>} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
*/
proto.collection.FSRequest.Update.Change.prototype.setNewNodePermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.collection.Node.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Permission}
 */
proto.collection.FSRequest.Update.Change.prototype.addNewNodePermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.collection.Node.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearNewNodePermissionsList = function() {
  return this.setNewNodePermissionsList([]);
};


/**
 * optional SealedContent maintainer_scoped_name = 10;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSRequest.Update.Change.prototype.getMaintainerScopedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 10));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
*/
proto.collection.FSRequest.Update.Change.prototype.setMaintainerScopedName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearMaintainerScopedName = function() {
  return this.setMaintainerScopedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasMaintainerScopedName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated BlockUpdate block_updates = 11;
 * @return {!Array<!proto.collection.FSRequest.Update.BlockUpdate>}
 */
proto.collection.FSRequest.Update.Change.prototype.getBlockUpdatesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Update.BlockUpdate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Update.BlockUpdate, 11));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Update.BlockUpdate>} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
*/
proto.collection.FSRequest.Update.Change.prototype.setBlockUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.collection.FSRequest.Update.BlockUpdate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Update.BlockUpdate}
 */
proto.collection.FSRequest.Update.Change.prototype.addBlockUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.collection.FSRequest.Update.BlockUpdate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearBlockUpdatesList = function() {
  return this.setBlockUpdatesList([]);
};


/**
 * optional bytes read_key_node_id = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.Change.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes read_key_node_id = 12;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSRequest.Update.Change.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.Change.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update.Change} returns this
 */
proto.collection.FSRequest.Update.Change.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.Change.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.Update.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes old_version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.prototype.getOldVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes old_version = 2;
 * This is a type-conversion wrapper around `getOldVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Update.prototype.getOldVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldVersion()));
};


/**
 * optional bytes old_version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.prototype.getOldVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.setOldVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.clearOldVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.prototype.hasOldVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes new_version = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.prototype.getNewVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes new_version = 3;
 * This is a type-conversion wrapper around `getNewVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Update.prototype.getNewVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewVersion()));
};


/**
 * optional bytes new_version = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.prototype.getNewVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.setNewVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.clearNewVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.prototype.hasNewVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Change changes = 4;
 * @return {!Array<!proto.collection.FSRequest.Update.Change>}
 */
proto.collection.FSRequest.Update.prototype.getChangesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Update.Change>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Update.Change, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Update.Change>} value
 * @return {!proto.collection.FSRequest.Update} returns this
*/
proto.collection.FSRequest.Update.prototype.setChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.Update.Change=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Update.Change}
 */
proto.collection.FSRequest.Update.prototype.addChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.Update.Change, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.clearChangesList = function() {
  return this.setChangesList([]);
};


/**
 * optional bytes new_parent_ver = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Update.prototype.getNewParentVer = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes new_parent_ver = 5;
 * This is a type-conversion wrapper around `getNewParentVer()`
 * @return {string}
 */
proto.collection.FSRequest.Update.prototype.getNewParentVer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewParentVer()));
};


/**
 * optional bytes new_parent_ver = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewParentVer()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Update.prototype.getNewParentVer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewParentVer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.setNewParentVer = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.clearNewParentVer = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.prototype.hasNewParentVer = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string last_local_fs_update = 6;
 * @return {string}
 */
proto.collection.FSRequest.Update.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Update} returns this
 */
proto.collection.FSRequest.Update.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Update.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Upload.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Upload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Upload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Upload.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    wrappedKey: (f = msg.getWrappedKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    data: msg.getData_asB64(),
    blockSize: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    hash: msg.getHash_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Upload}
 */
proto.collection.FSRequest.Upload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Upload;
  return proto.collection.FSRequest.Upload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Upload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Upload}
 */
proto.collection.FSRequest.Upload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockId(value);
      break;
    case 2:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBlockSize(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Upload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Upload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Upload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Upload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWrappedKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string block_id = 1;
 * @return {string}
 */
proto.collection.FSRequest.Upload.prototype.getBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.setBlockId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.clearBlockId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Upload.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FSWrappedKey wrapped_key = 2;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.Upload.prototype.getWrappedKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 2));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.Upload} returns this
*/
proto.collection.FSRequest.Upload.prototype.setWrappedKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.clearWrappedKey = function() {
  return this.setWrappedKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Upload.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes data = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Upload.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes data = 3;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.collection.FSRequest.Upload.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Upload.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.setData = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.clearData = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Upload.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 block_size = 4;
 * @return {number}
 */
proto.collection.FSRequest.Upload.prototype.getBlockSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.setBlockSize = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.clearBlockSize = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Upload.prototype.hasBlockSize = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes hash = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Upload.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes hash = 5;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.collection.FSRequest.Upload.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Upload.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.setHash = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Upload} returns this
 */
proto.collection.FSRequest.Upload.prototype.clearHash = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Upload.prototype.hasHash = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    wrappedKey: msg.getWrappedKey_asB64(),
    userKeyVersion: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Permission;
  return proto.collection.FSRequest.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSRequest.Permission.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Permission.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes wrapped_key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Permission.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes wrapped_key = 2;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSRequest.Permission.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Permission.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Permission.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 user_key_version = 3;
 * @return {number}
 */
proto.collection.FSRequest.Permission.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Permission.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool view_only = 4;
 * @return {boolean}
 */
proto.collection.FSRequest.Permission.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Permission} returns this
 */
proto.collection.FSRequest.Permission.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Permission.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.GroupPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.GroupPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.GroupPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.GroupPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: msg.getGroupId_asB64(),
    wrappedKey: msg.getWrappedKey_asB64(),
    groupKeyVersion: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.GroupPermission}
 */
proto.collection.FSRequest.GroupPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.GroupPermission;
  return proto.collection.FSRequest.GroupPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.GroupPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.GroupPermission}
 */
proto.collection.FSRequest.GroupPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGroupKeyVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.GroupPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.GroupPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.GroupPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.GroupPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional bytes group_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.GroupPermission.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes group_id = 1;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSRequest.GroupPermission.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.GroupPermission.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.GroupPermission} returns this
 */
proto.collection.FSRequest.GroupPermission.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.GroupPermission} returns this
 */
proto.collection.FSRequest.GroupPermission.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.GroupPermission.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes wrapped_key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.GroupPermission.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes wrapped_key = 2;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSRequest.GroupPermission.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.GroupPermission.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.GroupPermission} returns this
 */
proto.collection.FSRequest.GroupPermission.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.GroupPermission} returns this
 */
proto.collection.FSRequest.GroupPermission.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.GroupPermission.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 group_key_version = 3;
 * @return {number}
 */
proto.collection.FSRequest.GroupPermission.prototype.getGroupKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.GroupPermission} returns this
 */
proto.collection.FSRequest.GroupPermission.prototype.setGroupKeyVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.GroupPermission} returns this
 */
proto.collection.FSRequest.GroupPermission.prototype.clearGroupKeyVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.GroupPermission.prototype.hasGroupKeyVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Grant.repeatedFields_ = [4,5,6,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Grant.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Grant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Grant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.collection.FSRequest.Grant.Role.toObject, includeInstance),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.collection.FSRequest.Grant.User.toObject, includeInstance),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.collection.FSRequest.Grant.Group.toObject, includeInstance),
    obsoleteRole: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    obsoleteVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    obsoleteAclList: jspb.Message.toObjectList(msg.getObsoleteAclList(),
    proto.collection.FSRequest.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Grant}
 */
proto.collection.FSRequest.Grant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Grant;
  return proto.collection.FSRequest.Grant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Grant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Grant}
 */
proto.collection.FSRequest.Grant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new proto.collection.FSRequest.Grant.Role;
      reader.readMessage(value,proto.collection.FSRequest.Grant.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 5:
      var value = new proto.collection.FSRequest.Grant.User;
      reader.readMessage(value,proto.collection.FSRequest.Grant.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 6:
      var value = new proto.collection.FSRequest.Grant.Group;
      reader.readMessage(value,proto.collection.FSRequest.Grant.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setObsoleteRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setObsoleteVersion(value);
      break;
    case 3:
      var value = new proto.collection.FSRequest.Permission;
      reader.readMessage(value,proto.collection.FSRequest.Permission.deserializeBinaryFromReader);
      msg.addObsoleteAcl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Grant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Grant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.Grant.Role.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSRequest.Grant.User.serializeBinaryToWriter
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.collection.FSRequest.Grant.Group.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getObsoleteAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.FSRequest.Permission.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Grant.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Grant.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Grant.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    version: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Grant.Role}
 */
proto.collection.FSRequest.Grant.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Grant.Role;
  return proto.collection.FSRequest.Grant.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Grant.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Grant.Role}
 */
proto.collection.FSRequest.Grant.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Grant.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Grant.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Grant.Role.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Grant.Role} returns this
 */
proto.collection.FSRequest.Grant.Role.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.Role} returns this
 */
proto.collection.FSRequest.Grant.Role.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.Role.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 version = 2;
 * @return {number}
 */
proto.collection.FSRequest.Grant.Role.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Grant.Role} returns this
 */
proto.collection.FSRequest.Grant.Role.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.Role} returns this
 */
proto.collection.FSRequest.Grant.Role.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.Role.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Grant.RoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Grant.RoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.RoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    signature: msg.getSignature_asB64(),
    wrappedKey: msg.getWrappedKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Grant.RoleInfo}
 */
proto.collection.FSRequest.Grant.RoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Grant.RoleInfo;
  return proto.collection.FSRequest.Grant.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Grant.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Grant.RoleInfo}
 */
proto.collection.FSRequest.Grant.RoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Grant.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Grant.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.RoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Grant.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes signature = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes signature = 2;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.setSignature = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.clearSignature = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.RoleInfo.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Grant.User.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Grant.User.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Grant.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Grant.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    encCollectionName: msg.getEncCollectionName_asB64(),
    roleInfoList: jspb.Message.toObjectList(msg.getRoleInfoList(),
    proto.collection.FSRequest.Grant.User.RoleInfo.toObject, includeInstance),
    expirationTime: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Grant.User}
 */
proto.collection.FSRequest.Grant.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Grant.User;
  return proto.collection.FSRequest.Grant.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Grant.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Grant.User}
 */
proto.collection.FSRequest.Grant.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncCollectionName(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.Grant.User.RoleInfo;
      reader.readMessage(value,proto.collection.FSRequest.Grant.User.RoleInfo.deserializeBinaryFromReader);
      msg.addRoleInfo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Grant.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Grant.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getRoleInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.Grant.User.RoleInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Grant.User.RoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Grant.User.RoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.User.RoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    signature: msg.getSignature_asB64(),
    wrappedKey: msg.getWrappedKey_asB64(),
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Grant.User.RoleInfo;
  return proto.collection.FSRequest.Grant.User.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Grant.User.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Grant.User.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Grant.User.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.User.RoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes signature = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes signature = 2;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.setSignature = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.clearSignature = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool view_only = 4;
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo} returns this
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.RoleInfo.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSRequest.Grant.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.FSRequest.Grant.User.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes enc_collection_name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.User.prototype.getEncCollectionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes enc_collection_name = 3;
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.User.prototype.getEncCollectionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncCollectionName()));
};


/**
 * optional bytes enc_collection_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.User.prototype.getEncCollectionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncCollectionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.setEncCollectionName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.clearEncCollectionName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.prototype.hasEncCollectionName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated RoleInfo role_info = 4;
 * @return {!Array<!proto.collection.FSRequest.Grant.User.RoleInfo>}
 */
proto.collection.FSRequest.Grant.User.prototype.getRoleInfoList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Grant.User.RoleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Grant.User.RoleInfo, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Grant.User.RoleInfo>} value
 * @return {!proto.collection.FSRequest.Grant.User} returns this
*/
proto.collection.FSRequest.Grant.User.prototype.setRoleInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.Grant.User.RoleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Grant.User.RoleInfo}
 */
proto.collection.FSRequest.Grant.User.prototype.addRoleInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.Grant.User.RoleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.clearRoleInfoList = function() {
  return this.setRoleInfoList([]);
};


/**
 * optional string expiration_time = 6;
 * @return {string}
 */
proto.collection.FSRequest.Grant.User.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.User} returns this
 */
proto.collection.FSRequest.Grant.User.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.User.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Grant.Group.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Grant.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Grant.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Grant.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: msg.getGroupId_asB64(),
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    encCollectionName: msg.getEncCollectionName_asB64(),
    roleInfoList: jspb.Message.toObjectList(msg.getRoleInfoList(),
    proto.collection.FSRequest.Grant.RoleInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Grant.Group}
 */
proto.collection.FSRequest.Grant.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Grant.Group;
  return proto.collection.FSRequest.Grant.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Grant.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Grant.Group}
 */
proto.collection.FSRequest.Grant.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncCollectionName(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.Grant.RoleInfo;
      reader.readMessage(value,proto.collection.FSRequest.Grant.RoleInfo.deserializeBinaryFromReader);
      msg.addRoleInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Grant.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Grant.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Grant.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getRoleInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.Grant.RoleInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes group_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.Group.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes group_id = 1;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.Group.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.Group.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.Group.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.FSRequest.Grant.Group.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.Group.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes enc_collection_name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Grant.Group.prototype.getEncCollectionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes enc_collection_name = 3;
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {string}
 */
proto.collection.FSRequest.Grant.Group.prototype.getEncCollectionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncCollectionName()));
};


/**
 * optional bytes enc_collection_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Grant.Group.prototype.getEncCollectionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncCollectionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.setEncCollectionName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.clearEncCollectionName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.Group.prototype.hasEncCollectionName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated RoleInfo role_info = 4;
 * @return {!Array<!proto.collection.FSRequest.Grant.RoleInfo>}
 */
proto.collection.FSRequest.Grant.Group.prototype.getRoleInfoList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Grant.RoleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Grant.RoleInfo, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Grant.RoleInfo>} value
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
*/
proto.collection.FSRequest.Grant.Group.prototype.setRoleInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.Grant.RoleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Grant.RoleInfo}
 */
proto.collection.FSRequest.Grant.Group.prototype.addRoleInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.Grant.RoleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Grant.Group} returns this
 */
proto.collection.FSRequest.Grant.Group.prototype.clearRoleInfoList = function() {
  return this.setRoleInfoList([]);
};


/**
 * repeated Role roles = 4;
 * @return {!Array<!proto.collection.FSRequest.Grant.Role>}
 */
proto.collection.FSRequest.Grant.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Grant.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Grant.Role, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Grant.Role>} value
 * @return {!proto.collection.FSRequest.Grant} returns this
*/
proto.collection.FSRequest.Grant.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.Grant.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Grant.Role}
 */
proto.collection.FSRequest.Grant.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.Grant.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated User users = 5;
 * @return {!Array<!proto.collection.FSRequest.Grant.User>}
 */
proto.collection.FSRequest.Grant.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Grant.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Grant.User, 5));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Grant.User>} value
 * @return {!proto.collection.FSRequest.Grant} returns this
*/
proto.collection.FSRequest.Grant.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSRequest.Grant.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Grant.User}
 */
proto.collection.FSRequest.Grant.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSRequest.Grant.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * repeated Group groups = 6;
 * @return {!Array<!proto.collection.FSRequest.Grant.Group>}
 */
proto.collection.FSRequest.Grant.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Grant.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Grant.Group, 6));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Grant.Group>} value
 * @return {!proto.collection.FSRequest.Grant} returns this
*/
proto.collection.FSRequest.Grant.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.collection.FSRequest.Grant.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Grant.Group}
 */
proto.collection.FSRequest.Grant.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.collection.FSRequest.Grant.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional FSRole OBSOLETE_role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Grant.prototype.getObsoleteRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.setObsoleteRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.clearObsoleteRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.prototype.hasObsoleteRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 OBSOLETE_version = 2;
 * @return {number}
 */
proto.collection.FSRequest.Grant.prototype.getObsoleteVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.setObsoleteVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.clearObsoleteVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Grant.prototype.hasObsoleteVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Permission OBSOLETE_acl = 3;
 * @return {!Array<!proto.collection.FSRequest.Permission>}
 */
proto.collection.FSRequest.Grant.prototype.getObsoleteAclList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Permission, 3));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Permission>} value
 * @return {!proto.collection.FSRequest.Grant} returns this
*/
proto.collection.FSRequest.Grant.prototype.setObsoleteAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.FSRequest.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Grant.prototype.addObsoleteAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.FSRequest.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Grant} returns this
 */
proto.collection.FSRequest.Grant.prototype.clearObsoleteAclList = function() {
  return this.setObsoleteAclList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Rekey.repeatedFields_ = [5,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Rekey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Rekey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Rekey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Rekey.toObject = function(includeInstance, msg) {
  var f, obj = {
    rekeyRolesList: jspb.Message.toObjectList(msg.getRekeyRolesList(),
    proto.collection.FSRequest.Rekey.RekeyRole.toObject, includeInstance),
    encryptedCollectionName: msg.getEncryptedCollectionName_asB64(),
    role: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    publicKey: (f = msg.getPublicKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedLastKey: msg.getWrappedLastKey_asB64(),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.FSRequest.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Rekey}
 */
proto.collection.FSRequest.Rekey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Rekey;
  return proto.collection.FSRequest.Rekey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Rekey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Rekey}
 */
proto.collection.FSRequest.Rekey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.collection.FSRequest.Rekey.RekeyRole;
      reader.readMessage(value,proto.collection.FSRequest.Rekey.RekeyRole.deserializeBinaryFromReader);
      msg.addRekeyRoles(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncryptedCollectionName(value);
      break;
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedLastKey(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.Permission;
      reader.readMessage(value,proto.collection.FSRequest.Permission.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Rekey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Rekey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Rekey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Rekey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRekeyRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSRequest.Rekey.RekeyRole.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.Permission.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.Rekey.RekeyRole.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Rekey.RekeyRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Rekey.RekeyRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Rekey.RekeyRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    publicKey: (f = msg.getPublicKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedLastKey: msg.getWrappedLastKey_asB64(),
    userAclList: jspb.Message.toObjectList(msg.getUserAclList(),
    proto.collection.FSRequest.Permission.toObject, includeInstance),
    groupAclList: jspb.Message.toObjectList(msg.getGroupAclList(),
    proto.collection.FSRequest.GroupPermission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole}
 */
proto.collection.FSRequest.Rekey.RekeyRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Rekey.RekeyRole;
  return proto.collection.FSRequest.Rekey.RekeyRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Rekey.RekeyRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole}
 */
proto.collection.FSRequest.Rekey.RekeyRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedLastKey(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.Permission;
      reader.readMessage(value,proto.collection.FSRequest.Permission.deserializeBinaryFromReader);
      msg.addUserAcl(value);
      break;
    case 5:
      var value = new proto.collection.FSRequest.GroupPermission;
      reader.readMessage(value,proto.collection.FSRequest.GroupPermission.deserializeBinaryFromReader);
      msg.addGroupAcl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Rekey.RekeyRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Rekey.RekeyRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Rekey.RekeyRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getUserAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.Permission.serializeBinaryToWriter
    );
  }
  f = message.getGroupAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSRequest.GroupPermission.serializeBinaryToWriter
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PublicKey public_key = 2;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getPublicKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 2));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
*/
proto.collection.FSRequest.Rekey.RekeyRole.prototype.setPublicKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.clearPublicKey = function() {
  return this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_last_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getWrappedLastKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_last_key = 3;
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {string}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getWrappedLastKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedLastKey()));
};


/**
 * optional bytes wrapped_last_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getWrappedLastKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedLastKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.setWrappedLastKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.clearWrappedLastKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.hasWrappedLastKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Permission user_acl = 4;
 * @return {!Array<!proto.collection.FSRequest.Permission>}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getUserAclList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Permission, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Permission>} value
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
*/
proto.collection.FSRequest.Rekey.RekeyRole.prototype.setUserAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.addUserAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.clearUserAclList = function() {
  return this.setUserAclList([]);
};


/**
 * repeated GroupPermission group_acl = 5;
 * @return {!Array<!proto.collection.FSRequest.GroupPermission>}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.getGroupAclList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.GroupPermission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.GroupPermission, 5));
};


/**
 * @param {!Array<!proto.collection.FSRequest.GroupPermission>} value
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
*/
proto.collection.FSRequest.Rekey.RekeyRole.prototype.setGroupAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSRequest.GroupPermission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.GroupPermission}
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.addGroupAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSRequest.GroupPermission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole} returns this
 */
proto.collection.FSRequest.Rekey.RekeyRole.prototype.clearGroupAclList = function() {
  return this.setGroupAclList([]);
};


/**
 * repeated RekeyRole rekey_roles = 5;
 * @return {!Array<!proto.collection.FSRequest.Rekey.RekeyRole>}
 */
proto.collection.FSRequest.Rekey.prototype.getRekeyRolesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Rekey.RekeyRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Rekey.RekeyRole, 5));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Rekey.RekeyRole>} value
 * @return {!proto.collection.FSRequest.Rekey} returns this
*/
proto.collection.FSRequest.Rekey.prototype.setRekeyRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSRequest.Rekey.RekeyRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Rekey.RekeyRole}
 */
proto.collection.FSRequest.Rekey.prototype.addRekeyRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSRequest.Rekey.RekeyRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.clearRekeyRolesList = function() {
  return this.setRekeyRolesList([]);
};


/**
 * optional bytes encrypted_collection_name = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Rekey.prototype.getEncryptedCollectionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes encrypted_collection_name = 6;
 * This is a type-conversion wrapper around `getEncryptedCollectionName()`
 * @return {string}
 */
proto.collection.FSRequest.Rekey.prototype.getEncryptedCollectionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncryptedCollectionName()));
};


/**
 * optional bytes encrypted_collection_name = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncryptedCollectionName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Rekey.prototype.getEncryptedCollectionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncryptedCollectionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.setEncryptedCollectionName = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.clearEncryptedCollectionName = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.prototype.hasEncryptedCollectionName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.Rekey.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PublicKey public_key = 2;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.Rekey.prototype.getPublicKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 2));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.Rekey} returns this
*/
proto.collection.FSRequest.Rekey.prototype.setPublicKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.clearPublicKey = function() {
  return this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_last_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Rekey.prototype.getWrappedLastKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_last_key = 3;
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {string}
 */
proto.collection.FSRequest.Rekey.prototype.getWrappedLastKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedLastKey()));
};


/**
 * optional bytes wrapped_last_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Rekey.prototype.getWrappedLastKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedLastKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.setWrappedLastKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.clearWrappedLastKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Rekey.prototype.hasWrappedLastKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Permission acl = 4;
 * @return {!Array<!proto.collection.FSRequest.Permission>}
 */
proto.collection.FSRequest.Rekey.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Permission, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Permission>} value
 * @return {!proto.collection.FSRequest.Rekey} returns this
*/
proto.collection.FSRequest.Rekey.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Permission}
 */
proto.collection.FSRequest.Rekey.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.Rekey} returns this
 */
proto.collection.FSRequest.Rekey.prototype.clearAclList = function() {
  return this.setAclList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.AddKey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.AddKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.AddKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.AddKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    publicKey: (f = msg.getPublicKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    wrappedKey: msg.getWrappedKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.AddKey}
 */
proto.collection.FSRequest.AddKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.AddKey;
  return proto.collection.FSRequest.AddKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.AddKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.AddKey}
 */
proto.collection.FSRequest.AddKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.AddKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.AddKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.AddKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.AddKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.AddKey.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest.AddKey} returns this
 */
proto.collection.FSRequest.AddKey.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.AddKey} returns this
 */
proto.collection.FSRequest.AddKey.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.AddKey.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PublicKey public_key = 2;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.AddKey.prototype.getPublicKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 2));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.AddKey} returns this
*/
proto.collection.FSRequest.AddKey.prototype.setPublicKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.AddKey} returns this
 */
proto.collection.FSRequest.AddKey.prototype.clearPublicKey = function() {
  return this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.AddKey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.AddKey.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSRequest.AddKey.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.AddKey.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.AddKey} returns this
 */
proto.collection.FSRequest.AddKey.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.AddKey} returns this
 */
proto.collection.FSRequest.AddKey.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.AddKey.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeVersions: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    includeDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    allFileVersions: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    omitBlocks: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    startTime: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    endTime: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    subseq: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    limit: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    dirId: msg.getDirId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Snapshot}
 */
proto.collection.FSRequest.Snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Snapshot;
  return proto.collection.FSRequest.Snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Snapshot}
 */
proto.collection.FSRequest.Snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeVersions(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDeleted(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllFileVersions(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOmitBlocks(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSubseq(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDirId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
};


/**
 * optional bool include_versions = 1;
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.getIncludeVersions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setIncludeVersions = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearIncludeVersions = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasIncludeVersions = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool include_deleted = 2;
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.getIncludeDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setIncludeDeleted = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearIncludeDeleted = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasIncludeDeleted = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool all_file_versions = 3;
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.getAllFileVersions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setAllFileVersions = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearAllFileVersions = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasAllFileVersions = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool omit_blocks = 4;
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.getOmitBlocks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setOmitBlocks = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearOmitBlocks = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasOmitBlocks = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string start_time = 5;
 * @return {string}
 */
proto.collection.FSRequest.Snapshot.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setStartTime = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearStartTime = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string end_time = 6;
 * @return {string}
 */
proto.collection.FSRequest.Snapshot.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setEndTime = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearEndTime = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 subseq = 7;
 * @return {number}
 */
proto.collection.FSRequest.Snapshot.prototype.getSubseq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setSubseq = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearSubseq = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasSubseq = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 limit = 8;
 * @return {number}
 */
proto.collection.FSRequest.Snapshot.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes dir_id = 9;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Snapshot.prototype.getDirId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes dir_id = 9;
 * This is a type-conversion wrapper around `getDirId()`
 * @return {string}
 */
proto.collection.FSRequest.Snapshot.prototype.getDirId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDirId()));
};


/**
 * optional bytes dir_id = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDirId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Snapshot.prototype.getDirId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDirId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.setDirId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Snapshot} returns this
 */
proto.collection.FSRequest.Snapshot.prototype.clearDirId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Snapshot.prototype.hasDirId = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.ACLTree.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.ACLTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.ACLTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.ACLTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    dirId: msg.getDirId_asB64(),
    includeDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.ACLTree}
 */
proto.collection.FSRequest.ACLTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.ACLTree;
  return proto.collection.FSRequest.ACLTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.ACLTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.ACLTree}
 */
proto.collection.FSRequest.ACLTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDirId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.ACLTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.ACLTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.ACLTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.ACLTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes dir_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.ACLTree.prototype.getDirId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes dir_id = 1;
 * This is a type-conversion wrapper around `getDirId()`
 * @return {string}
 */
proto.collection.FSRequest.ACLTree.prototype.getDirId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDirId()));
};


/**
 * optional bytes dir_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDirId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.ACLTree.prototype.getDirId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDirId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.ACLTree} returns this
 */
proto.collection.FSRequest.ACLTree.prototype.setDirId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ACLTree} returns this
 */
proto.collection.FSRequest.ACLTree.prototype.clearDirId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ACLTree.prototype.hasDirId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool include_deleted = 2;
 * @return {boolean}
 */
proto.collection.FSRequest.ACLTree.prototype.getIncludeDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.ACLTree} returns this
 */
proto.collection.FSRequest.ACLTree.prototype.setIncludeDeleted = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ACLTree} returns this
 */
proto.collection.FSRequest.ACLTree.prototype.clearIncludeDeleted = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ACLTree.prototype.hasIncludeDeleted = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Ack.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Ack.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Ack} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Ack.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seq: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    topic: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Ack}
 */
proto.collection.FSRequest.Ack.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Ack;
  return proto.collection.FSRequest.Ack.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Ack} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Ack}
 */
proto.collection.FSRequest.Ack.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRequest.Ack.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Ack.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Ack.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Ack} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Ack.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRequest.Ack.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSRequest.Ack.Type = {
  DATA: 0,
  PERMISSIONS: 1,
  GROUPS: 2,
  LOGS: 3
};

/**
 * optional Type type = 1;
 * @return {!proto.collection.FSRequest.Ack.Type}
 */
proto.collection.FSRequest.Ack.prototype.getType = function() {
  return /** @type {!proto.collection.FSRequest.Ack.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRequest.Ack.Type} value
 * @return {!proto.collection.FSRequest.Ack} returns this
 */
proto.collection.FSRequest.Ack.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Ack} returns this
 */
proto.collection.FSRequest.Ack.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Ack.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 seq = 2;
 * @return {number}
 */
proto.collection.FSRequest.Ack.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Ack} returns this
 */
proto.collection.FSRequest.Ack.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Ack} returns this
 */
proto.collection.FSRequest.Ack.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Ack.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string topic = 3;
 * @return {string}
 */
proto.collection.FSRequest.Ack.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.Ack} returns this
 */
proto.collection.FSRequest.Ack.prototype.setTopic = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Ack} returns this
 */
proto.collection.FSRequest.Ack.prototype.clearTopic = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Ack.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Fetch.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Fetch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Fetch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Fetch.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    includePath: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    version: msg.getVersion_asB64(),
    includeAcl: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    subseq: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    limit: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    includeTotal: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    offset: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    includeSelfInPath: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Fetch}
 */
proto.collection.FSRequest.Fetch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Fetch;
  return proto.collection.FSRequest.Fetch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Fetch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Fetch}
 */
proto.collection.FSRequest.Fetch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePath(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeDeleted(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeAcl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubseq(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeTotal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeSelfInPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Fetch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Fetch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Fetch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Fetch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Fetch.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.Fetch.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Fetch.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool include_path = 2;
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.getIncludePath = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setIncludePath = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearIncludePath = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasIncludePath = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool include_deleted = 3;
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.getIncludeDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setIncludeDeleted = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearIncludeDeleted = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasIncludeDeleted = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes version = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Fetch.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes version = 4;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Fetch.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Fetch.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool include_acl = 5;
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.getIncludeAcl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setIncludeAcl = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearIncludeAcl = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasIncludeAcl = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 subseq = 6;
 * @return {number}
 */
proto.collection.FSRequest.Fetch.prototype.getSubseq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setSubseq = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearSubseq = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasSubseq = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int64 limit = 7;
 * @return {number}
 */
proto.collection.FSRequest.Fetch.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool include_total = 8;
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.getIncludeTotal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setIncludeTotal = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearIncludeTotal = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasIncludeTotal = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 offset = 9;
 * @return {number}
 */
proto.collection.FSRequest.Fetch.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool include_self_in_path = 10;
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.getIncludeSelfInPath = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.setIncludeSelfInPath = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Fetch} returns this
 */
proto.collection.FSRequest.Fetch.prototype.clearIncludeSelfInPath = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Fetch.prototype.hasIncludeSelfInPath = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.MakeShareable.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.MakeShareable.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.MakeShareable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.MakeShareable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.MakeShareable.toObject = function(includeInstance, msg) {
  var f, obj = {
    dirId: msg.getDirId_asB64(),
    dirVersion: msg.getDirVersion_asB64(),
    linkId: msg.getLinkId_asB64(),
    linkVersion: msg.getLinkVersion_asB64(),
    linkedCollectionId: msg.getLinkedCollectionId_asB64(),
    linkedCollectionInit: (f = msg.getLinkedCollectionInit()) && proto.collection.FSRequest.Init.toObject(includeInstance, f),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.collection.FSRequest.MakeShareable.Entry.toObject, includeInstance),
    blocksList: jspb.Message.toObjectList(msg.getBlocksList(),
    proto.collection.FSRequest.MakeShareable.Block.toObject, includeInstance),
    seq: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.MakeShareable}
 */
proto.collection.FSRequest.MakeShareable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.MakeShareable;
  return proto.collection.FSRequest.MakeShareable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.MakeShareable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.MakeShareable}
 */
proto.collection.FSRequest.MakeShareable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDirId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDirVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkVersion(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    case 6:
      var value = new proto.collection.FSRequest.Init;
      reader.readMessage(value,proto.collection.FSRequest.Init.deserializeBinaryFromReader);
      msg.setLinkedCollectionInit(value);
      break;
    case 7:
      var value = new proto.collection.FSRequest.MakeShareable.Entry;
      reader.readMessage(value,proto.collection.FSRequest.MakeShareable.Entry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    case 8:
      var value = new proto.collection.FSRequest.MakeShareable.Block;
      reader.readMessage(value,proto.collection.FSRequest.MakeShareable.Block.deserializeBinaryFromReader);
      msg.addBlocks(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.MakeShareable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.MakeShareable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.MakeShareable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = message.getLinkedCollectionInit();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.FSRequest.Init.serializeBinaryToWriter
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.collection.FSRequest.MakeShareable.Entry.serializeBinaryToWriter
    );
  }
  f = message.getBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.collection.FSRequest.MakeShareable.Block.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint64(
      9,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.MakeShareable.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.MakeShareable.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.MakeShareable.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentId: msg.getCurrentId_asB64(),
    currentVersion: msg.getCurrentVersion_asB64(),
    newId: msg.getNewId_asB64(),
    newVersion: msg.getNewVersion_asB64(),
    newWrappedDirKey: (f = msg.getNewWrappedDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry}
 */
proto.collection.FSRequest.MakeShareable.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.MakeShareable.Entry;
  return proto.collection.FSRequest.MakeShareable.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.MakeShareable.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry}
 */
proto.collection.FSRequest.MakeShareable.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCurrentId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCurrentVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNewVersion(value);
      break;
    case 5:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setNewWrappedDirKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.MakeShareable.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.MakeShareable.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.MakeShareable.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getNewWrappedDirKey();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes current_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getCurrentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes current_id = 1;
 * This is a type-conversion wrapper around `getCurrentId()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getCurrentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCurrentId()));
};


/**
 * optional bytes current_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCurrentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getCurrentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCurrentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.setCurrentId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.clearCurrentId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.hasCurrentId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes current_version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getCurrentVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes current_version = 2;
 * This is a type-conversion wrapper around `getCurrentVersion()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getCurrentVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCurrentVersion()));
};


/**
 * optional bytes current_version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCurrentVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getCurrentVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCurrentVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.setCurrentVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.clearCurrentVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.hasCurrentVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes new_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes new_id = 3;
 * This is a type-conversion wrapper around `getNewId()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewId()));
};


/**
 * optional bytes new_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.setNewId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.clearNewId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.hasNewId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes new_version = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes new_version = 4;
 * This is a type-conversion wrapper around `getNewVersion()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNewVersion()));
};


/**
 * optional bytes new_version = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNewVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNewVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.setNewVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.clearNewVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.hasNewVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FSWrappedKey new_wrapped_dir_key = 5;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.getNewWrappedDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 5));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
*/
proto.collection.FSRequest.MakeShareable.Entry.prototype.setNewWrappedDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Entry} returns this
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.clearNewWrappedDirKey = function() {
  return this.setNewWrappedDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Entry.prototype.hasNewWrappedDirKey = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.MakeShareable.Block.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.MakeShareable.Block} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.MakeShareable.Block.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    newId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    newWrappedKey: (f = msg.getNewWrappedKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.MakeShareable.Block}
 */
proto.collection.FSRequest.MakeShareable.Block.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.MakeShareable.Block;
  return proto.collection.FSRequest.MakeShareable.Block.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.MakeShareable.Block} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.MakeShareable.Block}
 */
proto.collection.FSRequest.MakeShareable.Block.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewId(value);
      break;
    case 3:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setNewWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.MakeShareable.Block.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.MakeShareable.Block} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.MakeShareable.Block.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewWrappedKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional string current_id = 1;
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.getCurrentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.MakeShareable.Block} returns this
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.setCurrentId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Block} returns this
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.clearCurrentId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.hasCurrentId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string new_id = 2;
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.getNewId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.MakeShareable.Block} returns this
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.setNewId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Block} returns this
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.clearNewId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.hasNewId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FSWrappedKey new_wrapped_key = 3;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.getNewWrappedKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 3));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.MakeShareable.Block} returns this
*/
proto.collection.FSRequest.MakeShareable.Block.prototype.setNewWrappedKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable.Block} returns this
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.clearNewWrappedKey = function() {
  return this.setNewWrappedKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.Block.prototype.hasNewWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes dir_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.prototype.getDirId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes dir_id = 1;
 * This is a type-conversion wrapper around `getDirId()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.prototype.getDirId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDirId()));
};


/**
 * optional bytes dir_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDirId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.prototype.getDirId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDirId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.setDirId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearDirId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasDirId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes dir_version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.prototype.getDirVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes dir_version = 2;
 * This is a type-conversion wrapper around `getDirVersion()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.prototype.getDirVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDirVersion()));
};


/**
 * optional bytes dir_version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDirVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.prototype.getDirVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDirVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.setDirVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearDirVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasDirVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes link_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes link_id = 3;
 * This is a type-conversion wrapper around `getLinkId()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkId()));
};


/**
 * optional bytes link_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.setLinkId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearLinkId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasLinkId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes link_version = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes link_version = 4;
 * This is a type-conversion wrapper around `getLinkVersion()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkVersion()));
};


/**
 * optional bytes link_version = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.setLinkVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearLinkVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasLinkVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes linked_collection_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes linked_collection_id = 5;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Init linked_collection_init = 6;
 * @return {?proto.collection.FSRequest.Init}
 */
proto.collection.FSRequest.MakeShareable.prototype.getLinkedCollectionInit = function() {
  return /** @type{?proto.collection.FSRequest.Init} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Init, 6));
};


/**
 * @param {?proto.collection.FSRequest.Init|undefined} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
*/
proto.collection.FSRequest.MakeShareable.prototype.setLinkedCollectionInit = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearLinkedCollectionInit = function() {
  return this.setLinkedCollectionInit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasLinkedCollectionInit = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Entry entries = 7;
 * @return {!Array<!proto.collection.FSRequest.MakeShareable.Entry>}
 */
proto.collection.FSRequest.MakeShareable.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.MakeShareable.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.MakeShareable.Entry, 7));
};


/**
 * @param {!Array<!proto.collection.FSRequest.MakeShareable.Entry>} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
*/
proto.collection.FSRequest.MakeShareable.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.collection.FSRequest.MakeShareable.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.MakeShareable.Entry}
 */
proto.collection.FSRequest.MakeShareable.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.collection.FSRequest.MakeShareable.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};


/**
 * repeated Block blocks = 8;
 * @return {!Array<!proto.collection.FSRequest.MakeShareable.Block>}
 */
proto.collection.FSRequest.MakeShareable.prototype.getBlocksList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.MakeShareable.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.MakeShareable.Block, 8));
};


/**
 * @param {!Array<!proto.collection.FSRequest.MakeShareable.Block>} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
*/
proto.collection.FSRequest.MakeShareable.prototype.setBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.collection.FSRequest.MakeShareable.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.MakeShareable.Block}
 */
proto.collection.FSRequest.MakeShareable.prototype.addBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.collection.FSRequest.MakeShareable.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearBlocksList = function() {
  return this.setBlocksList([]);
};


/**
 * optional uint64 seq = 9;
 * @return {number}
 */
proto.collection.FSRequest.MakeShareable.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.MakeShareable} returns this
 */
proto.collection.FSRequest.MakeShareable.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.MakeShareable.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.ReadLog.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.ReadLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.ReadLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.ReadLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    startTime: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    endTime: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    entries: jspb.Message.getFieldWithDefault(msg, 3, 100),
    fromIndex: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 5, true),
    notificationsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    legacyPriorityLevel: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    priorityLevel: jspb.Message.getFieldWithDefault(msg, 8, 0),
    toIndex: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    fromGlobalIndex: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    toGlobalIndex: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.ReadLog}
 */
proto.collection.FSRequest.ReadLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.ReadLog;
  return proto.collection.FSRequest.ReadLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.ReadLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.ReadLog}
 */
proto.collection.FSRequest.ReadLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEntries(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromIndex(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotificationsOnly(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLegacyPriorityLevel(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setPriorityLevel(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToIndex(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFromGlobalIndex(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToGlobalIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.ReadLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.ReadLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.ReadLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.ReadLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeSint64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeUint64(
      11,
      f
    );
  }
};


/**
 * optional string start_time = 1;
 * @return {string}
 */
proto.collection.FSRequest.ReadLog.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setStartTime = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearStartTime = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string end_time = 2;
 * @return {string}
 */
proto.collection.FSRequest.ReadLog.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setEndTime = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearEndTime = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 entries = 3;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getEntries = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 100));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setEntries = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearEntries = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasEntries = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 from_index = 4;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getFromIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setFromIndex = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearFromIndex = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasFromIndex = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool desc = 5;
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, true));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setDesc = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearDesc = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasDesc = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool notifications_only = 6;
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.getNotificationsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setNotificationsOnly = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearNotificationsOnly = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasNotificationsOnly = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 legacy_priority_level = 7;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getLegacyPriorityLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setLegacyPriorityLevel = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearLegacyPriorityLevel = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasLegacyPriorityLevel = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional sint64 priority_level = 8;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getPriorityLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setPriorityLevel = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearPriorityLevel = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasPriorityLevel = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 to_index = 9;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getToIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setToIndex = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearToIndex = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasToIndex = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint64 from_global_index = 10;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getFromGlobalIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setFromGlobalIndex = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearFromGlobalIndex = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasFromGlobalIndex = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint64 to_global_index = 11;
 * @return {number}
 */
proto.collection.FSRequest.ReadLog.prototype.getToGlobalIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.setToGlobalIndex = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ReadLog} returns this
 */
proto.collection.FSRequest.ReadLog.prototype.clearToGlobalIndex = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ReadLog.prototype.hasToGlobalIndex = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.BulkUpdate.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.BulkUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.BulkUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.BulkUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.BulkUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    newEntriesList: jspb.Message.toObjectList(msg.getNewEntriesList(),
    proto.collection.FSRequest.Create.toObject, includeInstance),
    updatedEntriesList: jspb.Message.toObjectList(msg.getUpdatedEntriesList(),
    proto.collection.FSRequest.Update.toObject, includeInstance),
    deletedEntriesList: jspb.Message.toObjectList(msg.getDeletedEntriesList(),
    proto.collection.FSRequest.Delete.toObject, includeInstance),
    newBlocksList: jspb.Message.toObjectList(msg.getNewBlocksList(),
    proto.collection.FSRequest.BulkUpdate.Block.toObject, includeInstance),
    restoredEntriesList: jspb.Message.toObjectList(msg.getRestoredEntriesList(),
    proto.collection.FSRequest.Restore.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.BulkUpdate}
 */
proto.collection.FSRequest.BulkUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.BulkUpdate;
  return proto.collection.FSRequest.BulkUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.BulkUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.BulkUpdate}
 */
proto.collection.FSRequest.BulkUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSRequest.Create;
      reader.readMessage(value,proto.collection.FSRequest.Create.deserializeBinaryFromReader);
      msg.addNewEntries(value);
      break;
    case 2:
      var value = new proto.collection.FSRequest.Update;
      reader.readMessage(value,proto.collection.FSRequest.Update.deserializeBinaryFromReader);
      msg.addUpdatedEntries(value);
      break;
    case 3:
      var value = new proto.collection.FSRequest.Delete;
      reader.readMessage(value,proto.collection.FSRequest.Delete.deserializeBinaryFromReader);
      msg.addDeletedEntries(value);
      break;
    case 4:
      var value = new proto.collection.FSRequest.BulkUpdate.Block;
      reader.readMessage(value,proto.collection.FSRequest.BulkUpdate.Block.deserializeBinaryFromReader);
      msg.addNewBlocks(value);
      break;
    case 5:
      var value = new proto.collection.FSRequest.Restore;
      reader.readMessage(value,proto.collection.FSRequest.Restore.deserializeBinaryFromReader);
      msg.addRestoredEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.BulkUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.BulkUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.BulkUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.BulkUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSRequest.Create.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSRequest.Update.serializeBinaryToWriter
    );
  }
  f = message.getDeletedEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.FSRequest.Delete.serializeBinaryToWriter
    );
  }
  f = message.getNewBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSRequest.BulkUpdate.Block.serializeBinaryToWriter
    );
  }
  f = message.getRestoredEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSRequest.Restore.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.BulkUpdate.Block.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.BulkUpdate.Block} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.BulkUpdate.Block.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    wrappedKey: (f = msg.getWrappedKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    blockType: jspb.Message.getFieldWithDefault(msg, 3, 1),
    blockSize: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    hash: msg.getHash_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block}
 */
proto.collection.FSRequest.BulkUpdate.Block.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.BulkUpdate.Block;
  return proto.collection.FSRequest.BulkUpdate.Block.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.BulkUpdate.Block} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block}
 */
proto.collection.FSRequest.BulkUpdate.Block.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedKey(value);
      break;
    case 3:
      var value = /** @type {!proto.collection.FSBlockType} */ (reader.readEnum());
      msg.setBlockType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBlockSize(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.BulkUpdate.Block.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.BulkUpdate.Block} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.BulkUpdate.Block.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWrappedKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.collection.FSBlockType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FSWrappedKey wrapped_key = 2;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getWrappedKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 2));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
*/
proto.collection.FSRequest.BulkUpdate.Block.prototype.setWrappedKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.clearWrappedKey = function() {
  return this.setWrappedKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FSBlockType block_type = 3;
 * @return {!proto.collection.FSBlockType}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getBlockType = function() {
  return /** @type {!proto.collection.FSBlockType} */ (jspb.Message.getFieldWithDefault(this, 3, 1));
};


/**
 * @param {!proto.collection.FSBlockType} value
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.setBlockType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.clearBlockType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.hasBlockType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 block_size = 4;
 * @return {number}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getBlockSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.setBlockSize = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.clearBlockSize = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.hasBlockSize = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes hash = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes hash = 5;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.setHash = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.BulkUpdate.Block} returns this
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.clearHash = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.BulkUpdate.Block.prototype.hasHash = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated Create new_entries = 1;
 * @return {!Array<!proto.collection.FSRequest.Create>}
 */
proto.collection.FSRequest.BulkUpdate.prototype.getNewEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Create>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Create, 1));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Create>} value
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
*/
proto.collection.FSRequest.BulkUpdate.prototype.setNewEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSRequest.Create=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Create}
 */
proto.collection.FSRequest.BulkUpdate.prototype.addNewEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSRequest.Create, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
 */
proto.collection.FSRequest.BulkUpdate.prototype.clearNewEntriesList = function() {
  return this.setNewEntriesList([]);
};


/**
 * repeated Update updated_entries = 2;
 * @return {!Array<!proto.collection.FSRequest.Update>}
 */
proto.collection.FSRequest.BulkUpdate.prototype.getUpdatedEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Update>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Update, 2));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Update>} value
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
*/
proto.collection.FSRequest.BulkUpdate.prototype.setUpdatedEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSRequest.Update=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Update}
 */
proto.collection.FSRequest.BulkUpdate.prototype.addUpdatedEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSRequest.Update, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
 */
proto.collection.FSRequest.BulkUpdate.prototype.clearUpdatedEntriesList = function() {
  return this.setUpdatedEntriesList([]);
};


/**
 * repeated Delete deleted_entries = 3;
 * @return {!Array<!proto.collection.FSRequest.Delete>}
 */
proto.collection.FSRequest.BulkUpdate.prototype.getDeletedEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Delete>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Delete, 3));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Delete>} value
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
*/
proto.collection.FSRequest.BulkUpdate.prototype.setDeletedEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.FSRequest.Delete=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Delete}
 */
proto.collection.FSRequest.BulkUpdate.prototype.addDeletedEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.FSRequest.Delete, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
 */
proto.collection.FSRequest.BulkUpdate.prototype.clearDeletedEntriesList = function() {
  return this.setDeletedEntriesList([]);
};


/**
 * repeated Block new_blocks = 4;
 * @return {!Array<!proto.collection.FSRequest.BulkUpdate.Block>}
 */
proto.collection.FSRequest.BulkUpdate.prototype.getNewBlocksList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.BulkUpdate.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.BulkUpdate.Block, 4));
};


/**
 * @param {!Array<!proto.collection.FSRequest.BulkUpdate.Block>} value
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
*/
proto.collection.FSRequest.BulkUpdate.prototype.setNewBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSRequest.BulkUpdate.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.BulkUpdate.Block}
 */
proto.collection.FSRequest.BulkUpdate.prototype.addNewBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSRequest.BulkUpdate.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
 */
proto.collection.FSRequest.BulkUpdate.prototype.clearNewBlocksList = function() {
  return this.setNewBlocksList([]);
};


/**
 * repeated Restore restored_entries = 5;
 * @return {!Array<!proto.collection.FSRequest.Restore>}
 */
proto.collection.FSRequest.BulkUpdate.prototype.getRestoredEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSRequest.Restore>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSRequest.Restore, 5));
};


/**
 * @param {!Array<!proto.collection.FSRequest.Restore>} value
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
*/
proto.collection.FSRequest.BulkUpdate.prototype.setRestoredEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSRequest.Restore=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.Restore}
 */
proto.collection.FSRequest.BulkUpdate.prototype.addRestoredEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSRequest.Restore, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.BulkUpdate} returns this
 */
proto.collection.FSRequest.BulkUpdate.prototype.clearRestoredEntriesList = function() {
  return this.setRestoredEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Paging.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Paging.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Paging} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Paging.toObject = function(includeInstance, msg) {
  var f, obj = {
    offset: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    limit: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Paging}
 */
proto.collection.FSRequest.Paging.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Paging;
  return proto.collection.FSRequest.Paging.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Paging} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Paging}
 */
proto.collection.FSRequest.Paging.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setOffset(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Paging.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Paging.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Paging} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Paging.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 offset = 1;
 * @return {number}
 */
proto.collection.FSRequest.Paging.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Paging} returns this
 */
proto.collection.FSRequest.Paging.prototype.setOffset = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Paging} returns this
 */
proto.collection.FSRequest.Paging.prototype.clearOffset = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Paging.prototype.hasOffset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 limit = 2;
 * @return {number}
 */
proto.collection.FSRequest.Paging.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.Paging} returns this
 */
proto.collection.FSRequest.Paging.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Paging} returns this
 */
proto.collection.FSRequest.Paging.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Paging.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.UpgradeCollection.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.UpgradeCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.UpgradeCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.UpgradeCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    toVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = msg.getName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    accessGrant: (f = msg.getAccessGrant()) && proto.collection.FSRequest.UpgradeCollection.AccessGrant.toObject(includeInstance, f),
    rootDirPermissionsList: jspb.Message.toObjectList(msg.getRootDirPermissionsList(),
    proto.collection.Node.Permission.toObject, includeInstance),
    rootDirGrantsList: jspb.Message.toObjectList(msg.getRootDirGrantsList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    maintainerScopedName: (f = msg.getMaintainerScopedName()) && proto.collection.SealedContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.UpgradeCollection}
 */
proto.collection.FSRequest.UpgradeCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.UpgradeCollection;
  return proto.collection.FSRequest.UpgradeCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.UpgradeCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.UpgradeCollection}
 */
proto.collection.FSRequest.UpgradeCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setToVersion(value);
      break;
    case 2:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto.collection.FSRequest.UpgradeCollection.AccessGrant;
      reader.readMessage(value,proto.collection.FSRequest.UpgradeCollection.AccessGrant.deserializeBinaryFromReader);
      msg.setAccessGrant(value);
      break;
    case 4:
      var value = new proto.collection.Node.Permission;
      reader.readMessage(value,proto.collection.Node.Permission.deserializeBinaryFromReader);
      msg.addRootDirPermissions(value);
      break;
    case 5:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addRootDirGrants(value);
      break;
    case 6:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setMaintainerScopedName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.UpgradeCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.UpgradeCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.UpgradeCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = message.getAccessGrant();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.FSRequest.UpgradeCollection.AccessGrant.serializeBinaryToWriter
    );
  }
  f = message.getRootDirPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.Node.Permission.serializeBinaryToWriter
    );
  }
  f = message.getRootDirGrantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = message.getMaintainerScopedName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.UpgradeCollection.AccessGrant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.collection.FSRequest.PublicKey.toObject(includeInstance, f),
    grant: (f = msg.getGrant()) && proto.collection.FSRequest.Grant.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.UpgradeCollection.AccessGrant}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.UpgradeCollection.AccessGrant;
  return proto.collection.FSRequest.UpgradeCollection.AccessGrant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.UpgradeCollection.AccessGrant}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSRequest.PublicKey;
      reader.readMessage(value,proto.collection.FSRequest.PublicKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 3:
      var value = new proto.collection.FSRequest.Grant;
      reader.readMessage(value,proto.collection.FSRequest.Grant.deserializeBinaryFromReader);
      msg.setGrant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.UpgradeCollection.AccessGrant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.collection.FSRequest.PublicKey.serializeBinaryToWriter
    );
  }
  f = message.getGrant();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.FSRequest.Grant.serializeBinaryToWriter
    );
  }
};


/**
 * optional PublicKey key = 1;
 * @return {?proto.collection.FSRequest.PublicKey}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.getKey = function() {
  return /** @type{?proto.collection.FSRequest.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.PublicKey, 1));
};


/**
 * @param {?proto.collection.FSRequest.PublicKey|undefined} value
 * @return {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} returns this
*/
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} returns this
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Grant grant = 3;
 * @return {?proto.collection.FSRequest.Grant}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.getGrant = function() {
  return /** @type{?proto.collection.FSRequest.Grant} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Grant, 3));
};


/**
 * @param {?proto.collection.FSRequest.Grant|undefined} value
 * @return {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} returns this
*/
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.setGrant = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.UpgradeCollection.AccessGrant} returns this
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.clearGrant = function() {
  return this.setGrant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UpgradeCollection.AccessGrant.prototype.hasGrant = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 to_version = 1;
 * @return {number}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.getToVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.setToVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.clearToVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.hasToVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SealedContent name = 2;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.getName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 2));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
*/
proto.collection.FSRequest.UpgradeCollection.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AccessGrant access_grant = 3;
 * @return {?proto.collection.FSRequest.UpgradeCollection.AccessGrant}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.getAccessGrant = function() {
  return /** @type{?proto.collection.FSRequest.UpgradeCollection.AccessGrant} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.UpgradeCollection.AccessGrant, 3));
};


/**
 * @param {?proto.collection.FSRequest.UpgradeCollection.AccessGrant|undefined} value
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
*/
proto.collection.FSRequest.UpgradeCollection.prototype.setAccessGrant = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.clearAccessGrant = function() {
  return this.setAccessGrant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.hasAccessGrant = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Node.Permission root_dir_permissions = 4;
 * @return {!Array<!proto.collection.Node.Permission>}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.getRootDirPermissionsList = function() {
  return /** @type{!Array<!proto.collection.Node.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Permission, 4));
};


/**
 * @param {!Array<!proto.collection.Node.Permission>} value
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
*/
proto.collection.FSRequest.UpgradeCollection.prototype.setRootDirPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.Node.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Permission}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.addRootDirPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.Node.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.clearRootDirPermissionsList = function() {
  return this.setRootDirPermissionsList([]);
};


/**
 * repeated Node.Grant root_dir_grants = 5;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.getRootDirGrantsList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 5));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
*/
proto.collection.FSRequest.UpgradeCollection.prototype.setRootDirGrantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.addRootDirGrants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.clearRootDirGrantsList = function() {
  return this.setRootDirGrantsList([]);
};


/**
 * optional SealedContent maintainer_scoped_name = 6;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.getMaintainerScopedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 6));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
*/
proto.collection.FSRequest.UpgradeCollection.prototype.setMaintainerScopedName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.UpgradeCollection} returns this
 */
proto.collection.FSRequest.UpgradeCollection.prototype.clearMaintainerScopedName = function() {
  return this.setMaintainerScopedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UpgradeCollection.prototype.hasMaintainerScopedName = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.SetCollectionAttributes.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.SetCollectionAttributes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.SetCollectionAttributes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.SetCollectionAttributes.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.collection.SealedContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.SetCollectionAttributes}
 */
proto.collection.FSRequest.SetCollectionAttributes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.SetCollectionAttributes;
  return proto.collection.FSRequest.SetCollectionAttributes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.SetCollectionAttributes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.SetCollectionAttributes}
 */
proto.collection.FSRequest.SetCollectionAttributes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.SetCollectionAttributes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.SetCollectionAttributes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.SetCollectionAttributes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.SetCollectionAttributes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional SealedContent name = 1;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSRequest.SetCollectionAttributes.prototype.getName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 1));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSRequest.SetCollectionAttributes} returns this
*/
proto.collection.FSRequest.SetCollectionAttributes.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest.SetCollectionAttributes} returns this
 */
proto.collection.FSRequest.SetCollectionAttributes.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.SetCollectionAttributes.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.ACLKeyHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.ACLKeyHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.ACLKeyHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    aclNodeId: msg.getAclNodeId_asB64(),
    aclRole: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.ACLKeyHistory}
 */
proto.collection.FSRequest.ACLKeyHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.ACLKeyHistory;
  return proto.collection.FSRequest.ACLKeyHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.ACLKeyHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.ACLKeyHistory}
 */
proto.collection.FSRequest.ACLKeyHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAclNodeId(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.Node.ACLRole} */ (reader.readEnum());
      msg.setAclRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.ACLKeyHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.ACLKeyHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.ACLKeyHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bytes acl_node_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.getAclNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes acl_node_id = 1;
 * This is a type-conversion wrapper around `getAclNodeId()`
 * @return {string}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.getAclNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAclNodeId()));
};


/**
 * optional bytes acl_node_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAclNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.getAclNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAclNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.ACLKeyHistory} returns this
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.setAclNodeId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ACLKeyHistory} returns this
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.clearAclNodeId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.hasAclNodeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Node.ACLRole acl_role = 2;
 * @return {!proto.collection.Node.ACLRole}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.getAclRole = function() {
  return /** @type {!proto.collection.Node.ACLRole} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @return {!proto.collection.FSRequest.ACLKeyHistory} returns this
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.setAclRole = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.ACLKeyHistory} returns this
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.clearAclRole = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.ACLKeyHistory.prototype.hasAclRole = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.LockFile.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.LockFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.LockFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.LockFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    canForceUnlockAfterSeconds: jspb.Message.getFieldWithDefault(msg, 2, 7200)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.LockFile}
 */
proto.collection.FSRequest.LockFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.LockFile;
  return proto.collection.FSRequest.LockFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.LockFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.LockFile}
 */
proto.collection.FSRequest.LockFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCanForceUnlockAfterSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.LockFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.LockFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.LockFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.LockFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.LockFile.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.LockFile.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.LockFile.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.LockFile} returns this
 */
proto.collection.FSRequest.LockFile.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.LockFile} returns this
 */
proto.collection.FSRequest.LockFile.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.LockFile.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 can_force_unlock_after_seconds = 2;
 * @return {number}
 */
proto.collection.FSRequest.LockFile.prototype.getCanForceUnlockAfterSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 7200));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest.LockFile} returns this
 */
proto.collection.FSRequest.LockFile.prototype.setCanForceUnlockAfterSeconds = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.LockFile} returns this
 */
proto.collection.FSRequest.LockFile.prototype.clearCanForceUnlockAfterSeconds = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.LockFile.prototype.hasCanForceUnlockAfterSeconds = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.UnlockFile.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.UnlockFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.UnlockFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.UnlockFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.UnlockFile}
 */
proto.collection.FSRequest.UnlockFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.UnlockFile;
  return proto.collection.FSRequest.UnlockFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.UnlockFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.UnlockFile}
 */
proto.collection.FSRequest.UnlockFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.UnlockFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.UnlockFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.UnlockFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.UnlockFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.UnlockFile.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.UnlockFile.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.UnlockFile.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.UnlockFile} returns this
 */
proto.collection.FSRequest.UnlockFile.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.UnlockFile} returns this
 */
proto.collection.FSRequest.UnlockFile.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.UnlockFile.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.FindLinks.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.FindLinks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.FindLinks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.FindLinks.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkedCollectionId: msg.getLinkedCollectionId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.FindLinks}
 */
proto.collection.FSRequest.FindLinks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.FindLinks;
  return proto.collection.FSRequest.FindLinks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.FindLinks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.FindLinks}
 */
proto.collection.FSRequest.FindLinks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.FindLinks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.FindLinks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.FindLinks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.FindLinks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes linked_collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.FindLinks.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes linked_collection_id = 1;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSRequest.FindLinks.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.FindLinks.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.FindLinks} returns this
 */
proto.collection.FSRequest.FindLinks.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.FindLinks} returns this
 */
proto.collection.FSRequest.FindLinks.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.FindLinks.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSRequest.DownloadBlocks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.DownloadBlocks.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.DownloadBlocks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.DownloadBlocks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.DownloadBlocks.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    accessType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.DownloadBlocks}
 */
proto.collection.FSRequest.DownloadBlocks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.DownloadBlocks;
  return proto.collection.FSRequest.DownloadBlocks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.DownloadBlocks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.DownloadBlocks}
 */
proto.collection.FSRequest.DownloadBlocks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBlockIds(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.FSRequest.DownloadBlocks.BlockAccessType} */ (reader.readEnum());
      msg.setAccessType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.DownloadBlocks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.DownloadBlocks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.DownloadBlocks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.DownloadBlocks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.FSRequest.DownloadBlocks.BlockAccessType} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSRequest.DownloadBlocks.BlockAccessType = {
  UNSPECIFIED: 0,
  VIEW: 1,
  DOWNLOAD_TO_PREVEIL: 2,
  DOWNLOAD_TO_BROWSER: 3,
  CLOUD_EDIT: 4,
  SYNC: 5,
  EXPORT: 6
};

/**
 * repeated string block_ids = 1;
 * @return {!Array<string>}
 */
proto.collection.FSRequest.DownloadBlocks.prototype.getBlockIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.collection.FSRequest.DownloadBlocks} returns this
 */
proto.collection.FSRequest.DownloadBlocks.prototype.setBlockIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest.DownloadBlocks} returns this
 */
proto.collection.FSRequest.DownloadBlocks.prototype.addBlockIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest.DownloadBlocks} returns this
 */
proto.collection.FSRequest.DownloadBlocks.prototype.clearBlockIdsList = function() {
  return this.setBlockIdsList([]);
};


/**
 * optional BlockAccessType access_type = 2;
 * @return {!proto.collection.FSRequest.DownloadBlocks.BlockAccessType}
 */
proto.collection.FSRequest.DownloadBlocks.prototype.getAccessType = function() {
  return /** @type {!proto.collection.FSRequest.DownloadBlocks.BlockAccessType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.collection.FSRequest.DownloadBlocks.BlockAccessType} value
 * @return {!proto.collection.FSRequest.DownloadBlocks} returns this
 */
proto.collection.FSRequest.DownloadBlocks.prototype.setAccessType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.DownloadBlocks} returns this
 */
proto.collection.FSRequest.DownloadBlocks.prototype.clearAccessType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.DownloadBlocks.prototype.hasAccessType = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSRequest.Restore.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSRequest.Restore.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSRequest.Restore} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Restore.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    parentId: msg.getParentId_asB64(),
    name: msg.getName_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSRequest.Restore}
 */
proto.collection.FSRequest.Restore.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSRequest.Restore;
  return proto.collection.FSRequest.Restore.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSRequest.Restore} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSRequest.Restore}
 */
proto.collection.FSRequest.Restore.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Restore.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSRequest.Restore.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSRequest.Restore} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSRequest.Restore.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Restore.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.Restore.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Restore.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Restore.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Restore.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes version = 2;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSRequest.Restore.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Restore.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Restore.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes parent_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Restore.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes parent_id = 3;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSRequest.Restore.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Restore.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Restore.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes name = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.Restore.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes name = 4;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSRequest.Restore.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.Restore.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.setName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest.Restore} returns this
 */
proto.collection.FSRequest.Restore.prototype.clearName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.Restore.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 api_version = 16;
 * @return {number}
 */
proto.collection.FSRequest.prototype.getApiVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setApiVersion = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearApiVersion = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasApiVersion = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSRequest.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FSMethod method = 2;
 * @return {!proto.collection.FSMethod}
 */
proto.collection.FSRequest.prototype.getMethod = function() {
  return /** @type {!proto.collection.FSMethod} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.collection.FSMethod} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setMethod = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearMethod = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasMethod = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Init init = 3;
 * @return {?proto.collection.FSRequest.Init}
 */
proto.collection.FSRequest.prototype.getInit = function() {
  return /** @type{?proto.collection.FSRequest.Init} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Init, 3));
};


/**
 * @param {?proto.collection.FSRequest.Init|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setInit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearInit = function() {
  return this.setInit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasInit = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Create create = 4;
 * @return {?proto.collection.FSRequest.Create}
 */
proto.collection.FSRequest.prototype.getCreate = function() {
  return /** @type{?proto.collection.FSRequest.Create} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Create, 4));
};


/**
 * @param {?proto.collection.FSRequest.Create|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setCreate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearCreate = function() {
  return this.setCreate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasCreate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Delete delete = 5;
 * @return {?proto.collection.FSRequest.Delete}
 */
proto.collection.FSRequest.prototype.getDelete = function() {
  return /** @type{?proto.collection.FSRequest.Delete} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Delete, 5));
};


/**
 * @param {?proto.collection.FSRequest.Delete|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setDelete = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearDelete = function() {
  return this.setDelete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasDelete = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Update update = 6;
 * @return {?proto.collection.FSRequest.Update}
 */
proto.collection.FSRequest.prototype.getUpdate = function() {
  return /** @type{?proto.collection.FSRequest.Update} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Update, 6));
};


/**
 * @param {?proto.collection.FSRequest.Update|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearUpdate = function() {
  return this.setUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasUpdate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Upload upload = 7;
 * @return {?proto.collection.FSRequest.Upload}
 */
proto.collection.FSRequest.prototype.getUpload = function() {
  return /** @type{?proto.collection.FSRequest.Upload} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Upload, 7));
};


/**
 * @param {?proto.collection.FSRequest.Upload|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setUpload = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearUpload = function() {
  return this.setUpload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasUpload = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string legacy_download = 8;
 * @return {string}
 */
proto.collection.FSRequest.prototype.getLegacyDownload = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setLegacyDownload = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearLegacyDownload = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasLegacyDownload = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes id = 9;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes id = 9;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSRequest.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional uint64 seq = 10;
 * @return {number}
 */
proto.collection.FSRequest.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Grant grant = 11;
 * @return {?proto.collection.FSRequest.Grant}
 */
proto.collection.FSRequest.prototype.getGrant = function() {
  return /** @type{?proto.collection.FSRequest.Grant} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Grant, 11));
};


/**
 * @param {?proto.collection.FSRequest.Grant|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setGrant = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearGrant = function() {
  return this.setGrant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasGrant = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Ack ack = 12;
 * @return {?proto.collection.FSRequest.Ack}
 */
proto.collection.FSRequest.prototype.getAck = function() {
  return /** @type{?proto.collection.FSRequest.Ack} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Ack, 12));
};


/**
 * @param {?proto.collection.FSRequest.Ack|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setAck = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearAck = function() {
  return this.setAck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasAck = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Rekey rekey = 13;
 * @return {?proto.collection.FSRequest.Rekey}
 */
proto.collection.FSRequest.prototype.getRekey = function() {
  return /** @type{?proto.collection.FSRequest.Rekey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Rekey, 13));
};


/**
 * @param {?proto.collection.FSRequest.Rekey|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setRekey = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearRekey = function() {
  return this.setRekey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasRekey = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional FSRole role = 14;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSRequest.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearRole = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasRole = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool accept = 17;
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.getAccept = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setAccept = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearAccept = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasAccept = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Fetch fetch = 18;
 * @return {?proto.collection.FSRequest.Fetch}
 */
proto.collection.FSRequest.prototype.getFetch = function() {
  return /** @type{?proto.collection.FSRequest.Fetch} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Fetch, 18));
};


/**
 * @param {?proto.collection.FSRequest.Fetch|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setFetch = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearFetch = function() {
  return this.setFetch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasFetch = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional MakeShareable make_shareable = 19;
 * @return {?proto.collection.FSRequest.MakeShareable}
 */
proto.collection.FSRequest.prototype.getMakeShareable = function() {
  return /** @type{?proto.collection.FSRequest.MakeShareable} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.MakeShareable, 19));
};


/**
 * @param {?proto.collection.FSRequest.MakeShareable|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setMakeShareable = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearMakeShareable = function() {
  return this.setMakeShareable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasMakeShareable = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional ReadLog read_log = 20;
 * @return {?proto.collection.FSRequest.ReadLog}
 */
proto.collection.FSRequest.prototype.getReadLog = function() {
  return /** @type{?proto.collection.FSRequest.ReadLog} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.ReadLog, 20));
};


/**
 * @param {?proto.collection.FSRequest.ReadLog|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setReadLog = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearReadLog = function() {
  return this.setReadLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasReadLog = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional bytes group_id = 21;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSRequest.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * optional bytes group_id = 21;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSRequest.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 21;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSRequest.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional AddKey add_key = 22;
 * @return {?proto.collection.FSRequest.AddKey}
 */
proto.collection.FSRequest.prototype.getAddKey = function() {
  return /** @type{?proto.collection.FSRequest.AddKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.AddKey, 22));
};


/**
 * @param {?proto.collection.FSRequest.AddKey|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setAddKey = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearAddKey = function() {
  return this.setAddKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasAddKey = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional Snapshot snapshot = 23;
 * @return {?proto.collection.FSRequest.Snapshot}
 */
proto.collection.FSRequest.prototype.getSnapshot = function() {
  return /** @type{?proto.collection.FSRequest.Snapshot} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Snapshot, 23));
};


/**
 * @param {?proto.collection.FSRequest.Snapshot|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string for_user = 24;
 * @return {string}
 */
proto.collection.FSRequest.prototype.getForUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setForUser = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearForUser = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasForUser = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional BulkUpdate bulk_update = 25;
 * @return {?proto.collection.FSRequest.BulkUpdate}
 */
proto.collection.FSRequest.prototype.getBulkUpdate = function() {
  return /** @type{?proto.collection.FSRequest.BulkUpdate} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.BulkUpdate, 25));
};


/**
 * @param {?proto.collection.FSRequest.BulkUpdate|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setBulkUpdate = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearBulkUpdate = function() {
  return this.setBulkUpdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasBulkUpdate = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool include_snapshot = 26;
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.getIncludeSnapshot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, true));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setIncludeSnapshot = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearIncludeSnapshot = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasIncludeSnapshot = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional Paging paging = 27;
 * @return {?proto.collection.FSRequest.Paging}
 */
proto.collection.FSRequest.prototype.getPaging = function() {
  return /** @type{?proto.collection.FSRequest.Paging} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Paging, 27));
};


/**
 * @param {?proto.collection.FSRequest.Paging|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setPaging = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearPaging = function() {
  return this.setPaging(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasPaging = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional UpgradeCollection upgrade = 28;
 * @return {?proto.collection.FSRequest.UpgradeCollection}
 */
proto.collection.FSRequest.prototype.getUpgrade = function() {
  return /** @type{?proto.collection.FSRequest.UpgradeCollection} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.UpgradeCollection, 28));
};


/**
 * @param {?proto.collection.FSRequest.UpgradeCollection|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setUpgrade = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearUpgrade = function() {
  return this.setUpgrade(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasUpgrade = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional SetCollectionAttributes collection_attributes = 29;
 * @return {?proto.collection.FSRequest.SetCollectionAttributes}
 */
proto.collection.FSRequest.prototype.getCollectionAttributes = function() {
  return /** @type{?proto.collection.FSRequest.SetCollectionAttributes} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.SetCollectionAttributes, 29));
};


/**
 * @param {?proto.collection.FSRequest.SetCollectionAttributes|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setCollectionAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearCollectionAttributes = function() {
  return this.setCollectionAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasCollectionAttributes = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional ACLTree acl_tree = 30;
 * @return {?proto.collection.FSRequest.ACLTree}
 */
proto.collection.FSRequest.prototype.getAclTree = function() {
  return /** @type{?proto.collection.FSRequest.ACLTree} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.ACLTree, 30));
};


/**
 * @param {?proto.collection.FSRequest.ACLTree|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setAclTree = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearAclTree = function() {
  return this.setAclTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasAclTree = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional ACLKeyHistory acl_key_history = 31;
 * @return {?proto.collection.FSRequest.ACLKeyHistory}
 */
proto.collection.FSRequest.prototype.getAclKeyHistory = function() {
  return /** @type{?proto.collection.FSRequest.ACLKeyHistory} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.ACLKeyHistory, 31));
};


/**
 * @param {?proto.collection.FSRequest.ACLKeyHistory|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setAclKeyHistory = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearAclKeyHistory = function() {
  return this.setAclKeyHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasAclKeyHistory = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional LockFile lock_file = 32;
 * @return {?proto.collection.FSRequest.LockFile}
 */
proto.collection.FSRequest.prototype.getLockFile = function() {
  return /** @type{?proto.collection.FSRequest.LockFile} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.LockFile, 32));
};


/**
 * @param {?proto.collection.FSRequest.LockFile|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setLockFile = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearLockFile = function() {
  return this.setLockFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasLockFile = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional UnlockFile unlock_file = 33;
 * @return {?proto.collection.FSRequest.UnlockFile}
 */
proto.collection.FSRequest.prototype.getUnlockFile = function() {
  return /** @type{?proto.collection.FSRequest.UnlockFile} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.UnlockFile, 33));
};


/**
 * @param {?proto.collection.FSRequest.UnlockFile|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setUnlockFile = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearUnlockFile = function() {
  return this.setUnlockFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasUnlockFile = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional bool linked_collections_only = 34;
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.getLinkedCollectionsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setLinkedCollectionsOnly = function(value) {
  return jspb.Message.setField(this, 34, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearLinkedCollectionsOnly = function() {
  return jspb.Message.setField(this, 34, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasLinkedCollectionsOnly = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional FindLinks find_links = 35;
 * @return {?proto.collection.FSRequest.FindLinks}
 */
proto.collection.FSRequest.prototype.getFindLinks = function() {
  return /** @type{?proto.collection.FSRequest.FindLinks} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.FindLinks, 35));
};


/**
 * @param {?proto.collection.FSRequest.FindLinks|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setFindLinks = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearFindLinks = function() {
  return this.setFindLinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasFindLinks = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional DownloadBlocks download = 36;
 * @return {?proto.collection.FSRequest.DownloadBlocks}
 */
proto.collection.FSRequest.prototype.getDownload = function() {
  return /** @type{?proto.collection.FSRequest.DownloadBlocks} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.DownloadBlocks, 36));
};


/**
 * @param {?proto.collection.FSRequest.DownloadBlocks|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setDownload = function(value) {
  return jspb.Message.setWrapperField(this, 36, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearDownload = function() {
  return this.setDownload(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasDownload = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional Restore restore = 37;
 * @return {?proto.collection.FSRequest.Restore}
 */
proto.collection.FSRequest.prototype.getRestore = function() {
  return /** @type{?proto.collection.FSRequest.Restore} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSRequest.Restore, 37));
};


/**
 * @param {?proto.collection.FSRequest.Restore|undefined} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setRestore = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearRestore = function() {
  return this.setRestore(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSRequest.prototype.hasRestore = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * repeated bytes collection_ids = 38;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.collection.FSRequest.prototype.getCollectionIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 38));
};


/**
 * repeated bytes collection_ids = 38;
 * This is a type-conversion wrapper around `getCollectionIdsList()`
 * @return {!Array<string>}
 */
proto.collection.FSRequest.prototype.getCollectionIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getCollectionIdsList()));
};


/**
 * repeated bytes collection_ids = 38;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.collection.FSRequest.prototype.getCollectionIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getCollectionIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.setCollectionIdsList = function(value) {
  return jspb.Message.setField(this, 38, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.addCollectionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 38, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearCollectionIdsList = function() {
  return this.setCollectionIdsList([]);
};


/**
 * repeated SyncRule sync_rules = 39;
 * @return {!Array<!proto.collection.SyncRule>}
 */
proto.collection.FSRequest.prototype.getSyncRulesList = function() {
  return /** @type{!Array<!proto.collection.SyncRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.SyncRule, 39));
};


/**
 * @param {!Array<!proto.collection.SyncRule>} value
 * @return {!proto.collection.FSRequest} returns this
*/
proto.collection.FSRequest.prototype.setSyncRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 39, value);
};


/**
 * @param {!proto.collection.SyncRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.SyncRule}
 */
proto.collection.FSRequest.prototype.addSyncRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 39, opt_value, proto.collection.SyncRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSRequest} returns this
 */
proto.collection.FSRequest.prototype.clearSyncRulesList = function() {
  return this.setSyncRulesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.repeatedFields_ = [11,12,13,14,20,23,24,32,33,35,36,42,43];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestId: msg.getRequestId_asB64(),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rootInfo: (f = msg.getRootInfo()) && proto.collection.FSMessage.RootInfo.toObject(includeInstance, f),
    snapshot: (f = msg.getSnapshot()) && proto.collection.FSMessage.Snapshot.toObject(includeInstance, f),
    notification: (f = msg.getNotification()) && proto.collection.FSMessage.Notification.toObject(includeInstance, f),
    file: (f = msg.getFile()) && proto.collection.FSMessage.File.toObject(includeInstance, f),
    dir: (f = msg.getDir()) && proto.collection.FSMessage.Dir.toObject(includeInstance, f),
    data: msg.getData_asB64(),
    permissions: (f = msg.getPermissions()) && proto.collection.FSMessage.Permissions.toObject(includeInstance, f),
    keyHistoryList: jspb.Message.toObjectList(msg.getKeyHistoryList(),
    proto.collection.FSMessage.KeyHistoryEntry.toObject, includeInstance),
    failedUserIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    sharesList: jspb.Message.toObjectList(msg.getSharesList(),
    proto.collection.FSMessage.Share.toObject, includeInstance),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.collection.FSMessage.User.toObject, includeInstance),
    link: (f = msg.getLink()) && proto.collection.FSMessage.Link.toObject(includeInstance, f),
    defaultCollectionId: msg.getDefaultCollectionId_asB64(),
    logs: (f = msg.getLogs()) && proto.collection.FSMessage.Logs.toObject(includeInstance, f),
    groups: (f = msg.getGroups()) && proto.collection.FSMessage.Groups.toObject(includeInstance, f),
    adminGroupInfo: (f = msg.getAdminGroupInfo()) && proto.collection.FSMessage.AdminGroupInfo.toObject(includeInstance, f),
    deletedList: jspb.Message.toObjectList(msg.getDeletedList(),
    proto.collection.FSMessage.DeletedEntry.toObject, includeInstance),
    blockUrl: (f = jspb.Message.getField(msg, 21)) == null ? undefined : f,
    linkView: (f = msg.getLinkView()) && proto.collection.FSMessage.LinkView.toObject(includeInstance, f),
    orgPermissionsList: jspb.Message.toObjectList(msg.getOrgPermissionsList(),
    proto.collection.FSMessage.OrgPermissions.toObject, includeInstance),
    userPermissionsList: jspb.Message.toObjectList(msg.getUserPermissionsList(),
    proto.collection.FSMessage.UserPermissions.toObject, includeInstance),
    nextSeq: (f = jspb.Message.getField(msg, 25)) == null ? undefined : f,
    hasMore: (f = jspb.Message.getBooleanField(msg, 26)) == null ? undefined : f,
    collectionAttributes: (f = msg.getCollectionAttributes()) && proto.collection.Collection.Attributes.toObject(includeInstance, f),
    aclTree: (f = msg.getAclTree()) && proto.collection.FSMessage.ACLTree.toObject(includeInstance, f),
    links: (f = msg.getLinks()) && proto.collection.FSMessage.Links.toObject(includeInstance, f),
    dataBlocks: (f = msg.getDataBlocks()) && proto.collection.FSMessage.DataBlocks.toObject(includeInstance, f),
    nextSubseq: (f = jspb.Message.getField(msg, 31)) == null ? undefined : f,
    dirEntriesList: jspb.Message.toObjectList(msg.getDirEntriesList(),
    proto.collection.FSMessage.Dir.Entry.toObject, includeInstance),
    bulkUpdateFailureList: jspb.Message.toObjectList(msg.getBulkUpdateFailureList(),
    proto.collection.FSMessage.BulkUpdateFailure.toObject, includeInstance),
    restoreResult: (f = msg.getRestoreResult()) && proto.collection.FSMessage.RestoreResult.toObject(includeInstance, f),
    aclNodePermissionsList: jspb.Message.toObjectList(msg.getAclNodePermissionsList(),
    proto.collection.FSMessage.ACLNodePermissions.toObject, includeInstance),
    aclUserPermissionsList: jspb.Message.toObjectList(msg.getAclUserPermissionsList(),
    proto.collection.FSMessage.ACLUserPermissions.toObject, includeInstance),
    errorMessage: (f = jspb.Message.getField(msg, 37)) == null ? undefined : f,
    totalCount: (f = jspb.Message.getField(msg, 38)) == null ? undefined : f,
    syncRules: (f = msg.getSyncRules()) && proto.collection.FSMessage.SyncRules.toObject(includeInstance, f),
    syncRulesSeq: (f = jspb.Message.getField(msg, 40)) == null ? undefined : f,
    syncRuleTree: (f = msg.getSyncRuleTree()) && proto.collection.FSMessage.SyncRuleTree.toObject(includeInstance, f),
    granteesFlatList: jspb.Message.toObjectList(msg.getGranteesFlatList(),
    proto.collection.FSMessage.GranteeFlat.toObject, includeInstance),
    unlinkedEntriesList: jspb.Message.toObjectList(msg.getUnlinkedEntriesList(),
    proto.collection.FSMessage.UnlinkedEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage}
 */
proto.collection.FSMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage;
  return proto.collection.FSMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage}
 */
proto.collection.FSMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSMessage.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRequestId(value);
      break;
    case 3:
      var value = /** @type {!proto.collection.FSStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.collection.FSMessage.RootInfo;
      reader.readMessage(value,proto.collection.FSMessage.RootInfo.deserializeBinaryFromReader);
      msg.setRootInfo(value);
      break;
    case 5:
      var value = new proto.collection.FSMessage.Snapshot;
      reader.readMessage(value,proto.collection.FSMessage.Snapshot.deserializeBinaryFromReader);
      msg.setSnapshot(value);
      break;
    case 6:
      var value = new proto.collection.FSMessage.Notification;
      reader.readMessage(value,proto.collection.FSMessage.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 7:
      var value = new proto.collection.FSMessage.File;
      reader.readMessage(value,proto.collection.FSMessage.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 8:
      var value = new proto.collection.FSMessage.Dir;
      reader.readMessage(value,proto.collection.FSMessage.Dir.deserializeBinaryFromReader);
      msg.setDir(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 10:
      var value = new proto.collection.FSMessage.Permissions;
      reader.readMessage(value,proto.collection.FSMessage.Permissions.deserializeBinaryFromReader);
      msg.setPermissions(value);
      break;
    case 11:
      var value = new proto.collection.FSMessage.KeyHistoryEntry;
      reader.readMessage(value,proto.collection.FSMessage.KeyHistoryEntry.deserializeBinaryFromReader);
      msg.addKeyHistory(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedUserIds(value);
      break;
    case 13:
      var value = new proto.collection.FSMessage.Share;
      reader.readMessage(value,proto.collection.FSMessage.Share.deserializeBinaryFromReader);
      msg.addShares(value);
      break;
    case 14:
      var value = new proto.collection.FSMessage.User;
      reader.readMessage(value,proto.collection.FSMessage.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 15:
      var value = new proto.collection.FSMessage.Link;
      reader.readMessage(value,proto.collection.FSMessage.Link.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDefaultCollectionId(value);
      break;
    case 17:
      var value = new proto.collection.FSMessage.Logs;
      reader.readMessage(value,proto.collection.FSMessage.Logs.deserializeBinaryFromReader);
      msg.setLogs(value);
      break;
    case 18:
      var value = new proto.collection.FSMessage.Groups;
      reader.readMessage(value,proto.collection.FSMessage.Groups.deserializeBinaryFromReader);
      msg.setGroups(value);
      break;
    case 19:
      var value = new proto.collection.FSMessage.AdminGroupInfo;
      reader.readMessage(value,proto.collection.FSMessage.AdminGroupInfo.deserializeBinaryFromReader);
      msg.setAdminGroupInfo(value);
      break;
    case 20:
      var value = new proto.collection.FSMessage.DeletedEntry;
      reader.readMessage(value,proto.collection.FSMessage.DeletedEntry.deserializeBinaryFromReader);
      msg.addDeleted(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockUrl(value);
      break;
    case 22:
      var value = new proto.collection.FSMessage.LinkView;
      reader.readMessage(value,proto.collection.FSMessage.LinkView.deserializeBinaryFromReader);
      msg.setLinkView(value);
      break;
    case 23:
      var value = new proto.collection.FSMessage.OrgPermissions;
      reader.readMessage(value,proto.collection.FSMessage.OrgPermissions.deserializeBinaryFromReader);
      msg.addOrgPermissions(value);
      break;
    case 24:
      var value = new proto.collection.FSMessage.UserPermissions;
      reader.readMessage(value,proto.collection.FSMessage.UserPermissions.deserializeBinaryFromReader);
      msg.addUserPermissions(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNextSeq(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    case 27:
      var value = new proto.collection.Collection.Attributes;
      reader.readMessage(value,proto.collection.Collection.Attributes.deserializeBinaryFromReader);
      msg.setCollectionAttributes(value);
      break;
    case 28:
      var value = new proto.collection.FSMessage.ACLTree;
      reader.readMessage(value,proto.collection.FSMessage.ACLTree.deserializeBinaryFromReader);
      msg.setAclTree(value);
      break;
    case 29:
      var value = new proto.collection.FSMessage.Links;
      reader.readMessage(value,proto.collection.FSMessage.Links.deserializeBinaryFromReader);
      msg.setLinks(value);
      break;
    case 30:
      var value = new proto.collection.FSMessage.DataBlocks;
      reader.readMessage(value,proto.collection.FSMessage.DataBlocks.deserializeBinaryFromReader);
      msg.setDataBlocks(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNextSubseq(value);
      break;
    case 32:
      var value = new proto.collection.FSMessage.Dir.Entry;
      reader.readMessage(value,proto.collection.FSMessage.Dir.Entry.deserializeBinaryFromReader);
      msg.addDirEntries(value);
      break;
    case 33:
      var value = new proto.collection.FSMessage.BulkUpdateFailure;
      reader.readMessage(value,proto.collection.FSMessage.BulkUpdateFailure.deserializeBinaryFromReader);
      msg.addBulkUpdateFailure(value);
      break;
    case 34:
      var value = new proto.collection.FSMessage.RestoreResult;
      reader.readMessage(value,proto.collection.FSMessage.RestoreResult.deserializeBinaryFromReader);
      msg.setRestoreResult(value);
      break;
    case 35:
      var value = new proto.collection.FSMessage.ACLNodePermissions;
      reader.readMessage(value,proto.collection.FSMessage.ACLNodePermissions.deserializeBinaryFromReader);
      msg.addAclNodePermissions(value);
      break;
    case 36:
      var value = new proto.collection.FSMessage.ACLUserPermissions;
      reader.readMessage(value,proto.collection.FSMessage.ACLUserPermissions.deserializeBinaryFromReader);
      msg.addAclUserPermissions(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    case 39:
      var value = new proto.collection.FSMessage.SyncRules;
      reader.readMessage(value,proto.collection.FSMessage.SyncRules.deserializeBinaryFromReader);
      msg.setSyncRules(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSyncRulesSeq(value);
      break;
    case 41:
      var value = new proto.collection.FSMessage.SyncRuleTree;
      reader.readMessage(value,proto.collection.FSMessage.SyncRuleTree.deserializeBinaryFromReader);
      msg.setSyncRuleTree(value);
      break;
    case 42:
      var value = new proto.collection.FSMessage.GranteeFlat;
      reader.readMessage(value,proto.collection.FSMessage.GranteeFlat.deserializeBinaryFromReader);
      msg.addGranteesFlat(value);
      break;
    case 43:
      var value = new proto.collection.FSMessage.UnlinkedEntry;
      reader.readMessage(value,proto.collection.FSMessage.UnlinkedEntry.deserializeBinaryFromReader);
      msg.addUnlinkedEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSMessage.Type} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!proto.collection.FSStatus} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRootInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSMessage.RootInfo.serializeBinaryToWriter
    );
  }
  f = message.getSnapshot();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSMessage.Snapshot.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.FSMessage.Notification.serializeBinaryToWriter
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.collection.FSMessage.File.serializeBinaryToWriter
    );
  }
  f = message.getDir();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.collection.FSMessage.Dir.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getPermissions();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.collection.FSMessage.Permissions.serializeBinaryToWriter
    );
  }
  f = message.getKeyHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.collection.FSMessage.KeyHistoryEntry.serializeBinaryToWriter
    );
  }
  f = message.getFailedUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getSharesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.collection.FSMessage.Share.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.collection.FSMessage.User.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.collection.FSMessage.Link.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeBytes(
      16,
      f
    );
  }
  f = message.getLogs();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.collection.FSMessage.Logs.serializeBinaryToWriter
    );
  }
  f = message.getGroups();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.collection.FSMessage.Groups.serializeBinaryToWriter
    );
  }
  f = message.getAdminGroupInfo();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.collection.FSMessage.AdminGroupInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeletedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.collection.FSMessage.DeletedEntry.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLinkView();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.collection.FSMessage.LinkView.serializeBinaryToWriter
    );
  }
  f = message.getOrgPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.collection.FSMessage.OrgPermissions.serializeBinaryToWriter
    );
  }
  f = message.getUserPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.collection.FSMessage.UserPermissions.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getCollectionAttributes();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.collection.Collection.Attributes.serializeBinaryToWriter
    );
  }
  f = message.getAclTree();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.collection.FSMessage.ACLTree.serializeBinaryToWriter
    );
  }
  f = message.getLinks();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.collection.FSMessage.Links.serializeBinaryToWriter
    );
  }
  f = message.getDataBlocks();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.collection.FSMessage.DataBlocks.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 31));
  if (f != null) {
    writer.writeUint64(
      31,
      f
    );
  }
  f = message.getDirEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.collection.FSMessage.Dir.Entry.serializeBinaryToWriter
    );
  }
  f = message.getBulkUpdateFailureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      proto.collection.FSMessage.BulkUpdateFailure.serializeBinaryToWriter
    );
  }
  f = message.getRestoreResult();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.collection.FSMessage.RestoreResult.serializeBinaryToWriter
    );
  }
  f = message.getAclNodePermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.collection.FSMessage.ACLNodePermissions.serializeBinaryToWriter
    );
  }
  f = message.getAclUserPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      proto.collection.FSMessage.ACLUserPermissions.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 37));
  if (f != null) {
    writer.writeString(
      37,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 38));
  if (f != null) {
    writer.writeInt64(
      38,
      f
    );
  }
  f = message.getSyncRules();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.collection.FSMessage.SyncRules.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeUint64(
      40,
      f
    );
  }
  f = message.getSyncRuleTree();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.collection.FSMessage.SyncRuleTree.serializeBinaryToWriter
    );
  }
  f = message.getGranteesFlatList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      42,
      f,
      proto.collection.FSMessage.GranteeFlat.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkedEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      43,
      f,
      proto.collection.FSMessage.UnlinkedEntry.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSMessage.Type = {
  RESPONSE: 0,
  NOTIFICATION: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.PublicKey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.PublicKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.PublicKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.PublicKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: msg.getPublicKey_asB64(),
    verifyKey: msg.getVerifyKey_asB64(),
    version: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    publicKeyProtocol: jspb.Message.getFieldWithDefault(msg, 4, 1),
    verifyKeyProtocol: jspb.Message.getFieldWithDefault(msg, 5, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.PublicKey}
 */
proto.collection.FSMessage.PublicKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.PublicKey;
  return proto.collection.FSMessage.PublicKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.PublicKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.PublicKey}
 */
proto.collection.FSMessage.PublicKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPublicKey(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVerifyKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPublicKeyProtocol(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVerifyKeyProtocol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.PublicKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.PublicKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.PublicKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.PublicKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional bytes public_key = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.PublicKey.prototype.getPublicKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes public_key = 1;
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {string}
 */
proto.collection.FSMessage.PublicKey.prototype.getPublicKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPublicKey()));
};


/**
 * optional bytes public_key = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPublicKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.PublicKey.prototype.getPublicKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPublicKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.setPublicKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.clearPublicKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.PublicKey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes verify_key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.PublicKey.prototype.getVerifyKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes verify_key = 2;
 * This is a type-conversion wrapper around `getVerifyKey()`
 * @return {string}
 */
proto.collection.FSMessage.PublicKey.prototype.getVerifyKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVerifyKey()));
};


/**
 * optional bytes verify_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVerifyKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.PublicKey.prototype.getVerifyKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVerifyKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.setVerifyKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.clearVerifyKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.PublicKey.prototype.hasVerifyKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 version = 3;
 * @return {number}
 */
proto.collection.FSMessage.PublicKey.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.PublicKey.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 public_key_protocol = 4;
 * @return {number}
 */
proto.collection.FSMessage.PublicKey.prototype.getPublicKeyProtocol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 1));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.setPublicKeyProtocol = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.clearPublicKeyProtocol = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.PublicKey.prototype.hasPublicKeyProtocol = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 verify_key_protocol = 5;
 * @return {number}
 */
proto.collection.FSMessage.PublicKey.prototype.getVerifyKeyProtocol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 1));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.setVerifyKeyProtocol = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.PublicKey} returns this
 */
proto.collection.FSMessage.PublicKey.prototype.clearVerifyKeyProtocol = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.PublicKey.prototype.hasVerifyKeyProtocol = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.RootInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.RootInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.RootInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.RootInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    name: msg.getName_asB64(),
    version: msg.getVersion_asB64(),
    wrappedDirKey: (f = msg.getWrappedDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    isComplete: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
    versionedName: (f = msg.getVersionedName()) && proto.collection.SealedContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.RootInfo}
 */
proto.collection.FSMessage.RootInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.RootInfo;
  return proto.collection.FSMessage.RootInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.RootInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.RootInfo}
 */
proto.collection.FSMessage.RootInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 4:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedDirKey(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsComplete(value);
      break;
    case 6:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setVersionedName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RootInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.RootInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.RootInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.RootInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getWrappedDirKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getVersionedName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RootInfo.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.RootInfo.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RootInfo.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes name = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RootInfo.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes name = 2;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.RootInfo.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RootInfo.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes version = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RootInfo.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes version = 3;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.RootInfo.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RootInfo.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FSWrappedKey wrapped_dir_key = 4;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.RootInfo.prototype.getWrappedDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 4));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.RootInfo} returns this
*/
proto.collection.FSMessage.RootInfo.prototype.setWrappedDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.clearWrappedDirKey = function() {
  return this.setWrappedDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.hasWrappedDirKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_complete = 5;
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.getIsComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.setIsComplete = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.clearIsComplete = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.hasIsComplete = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SealedContent versioned_name = 6;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.RootInfo.prototype.getVersionedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 6));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.RootInfo} returns this
*/
proto.collection.FSMessage.RootInfo.prototype.setVersionedName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.RootInfo} returns this
 */
proto.collection.FSMessage.RootInfo.prototype.clearVersionedName = function() {
  return this.setVersionedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RootInfo.prototype.hasVersionedName = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Block.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Block.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Block} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Block.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    wrappedKey: (f = msg.getWrappedKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    blockType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    blockSize: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    hash: msg.getHash_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Block}
 */
proto.collection.FSMessage.Block.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Block;
  return proto.collection.FSMessage.Block.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Block} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Block}
 */
proto.collection.FSMessage.Block.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedKey(value);
      break;
    case 3:
      var value = /** @type {!proto.collection.FSBlockType} */ (reader.readEnum());
      msg.setBlockType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBlockSize(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Block.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Block.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Block} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Block.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWrappedKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.collection.FSBlockType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.collection.FSMessage.Block.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Block.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FSWrappedKey wrapped_key = 2;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Block.prototype.getWrappedKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 2));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Block} returns this
*/
proto.collection.FSMessage.Block.prototype.setWrappedKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.clearWrappedKey = function() {
  return this.setWrappedKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Block.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FSBlockType block_type = 3;
 * @return {!proto.collection.FSBlockType}
 */
proto.collection.FSMessage.Block.prototype.getBlockType = function() {
  return /** @type {!proto.collection.FSBlockType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.collection.FSBlockType} value
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.setBlockType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.clearBlockType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Block.prototype.hasBlockType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 block_size = 4;
 * @return {number}
 */
proto.collection.FSMessage.Block.prototype.getBlockSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.setBlockSize = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.clearBlockSize = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Block.prototype.hasBlockSize = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes hash = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Block.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes hash = 5;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.collection.FSMessage.Block.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Block.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.setHash = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Block} returns this
 */
proto.collection.FSMessage.Block.prototype.clearHash = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Block.prototype.hasHash = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.LockInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.LockInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.LockInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.LockInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    lockedByUserId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lockedByDeviceId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lockedAt: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    canForceUnlockAt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.LockInfo}
 */
proto.collection.FSMessage.LockInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.LockInfo;
  return proto.collection.FSMessage.LockInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.LockInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.LockInfo}
 */
proto.collection.FSMessage.LockInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockedByUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockedByDeviceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCanForceUnlockAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.LockInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.LockInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.LockInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.LockInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string locked_by_user_id = 1;
 * @return {string}
 */
proto.collection.FSMessage.LockInfo.prototype.getLockedByUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.setLockedByUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.clearLockedByUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LockInfo.prototype.hasLockedByUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string locked_by_device_id = 2;
 * @return {string}
 */
proto.collection.FSMessage.LockInfo.prototype.getLockedByDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.setLockedByDeviceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.clearLockedByDeviceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LockInfo.prototype.hasLockedByDeviceId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string locked_at = 3;
 * @return {string}
 */
proto.collection.FSMessage.LockInfo.prototype.getLockedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.setLockedAt = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.clearLockedAt = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LockInfo.prototype.hasLockedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string can_force_unlock_at = 4;
 * @return {string}
 */
proto.collection.FSMessage.LockInfo.prototype.getCanForceUnlockAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.setCanForceUnlockAt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LockInfo} returns this
 */
proto.collection.FSMessage.LockInfo.prototype.clearCanForceUnlockAt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LockInfo.prototype.hasCanForceUnlockAt = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.File.repeatedFields_ = [3,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.File.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.collection.FSMessage.Block.toObject, includeInstance),
    lastUpdate: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fileSize: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    parentId: msg.getParentId_asB64(),
    maintainerId: msg.getMaintainerId_asB64(),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    maintainerScopedName: (f = msg.getMaintainerScopedName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    readKeyNodeId: msg.getReadKeyNodeId_asB64(),
    aclParentId: msg.getAclParentId_asB64(),
    lockInfo: (f = msg.getLockInfo()) && proto.collection.FSMessage.LockInfo.toObject(includeInstance, f),
    isExpunged: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    lastUpdateMsecs: jspb.Message.getFieldWithDefault(msg, 15, 0),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64(),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.File}
 */
proto.collection.FSMessage.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.File;
  return proto.collection.FSMessage.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.File}
 */
proto.collection.FSMessage.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.collection.FSMessage.Block;
      reader.readMessage(value,proto.collection.FSMessage.Block.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFileSize(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 9:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    case 10:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setMaintainerScopedName(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAclParentId(value);
      break;
    case 13:
      var value = new proto.collection.FSMessage.LockInfo;
      reader.readMessage(value,proto.collection.FSMessage.LockInfo.deserializeBinaryFromReader);
      msg.setLockInfo(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpunged(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdateMsecs(value);
      break;
    case 16:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.FSMessage.Block.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = message.getMaintainerScopedName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = message.getLockInfo();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.collection.FSMessage.LockInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeBytes(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes version = 2;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Block data = 3;
 * @return {!Array<!proto.collection.FSMessage.Block>}
 */
proto.collection.FSMessage.File.prototype.getDataList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Block, 3));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Block>} value
 * @return {!proto.collection.FSMessage.File} returns this
*/
proto.collection.FSMessage.File.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.FSMessage.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Block}
 */
proto.collection.FSMessage.File.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.FSMessage.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional uint64 last_update = 4;
 * @return {number}
 */
proto.collection.FSMessage.File.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setLastUpdate = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearLastUpdate = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_deleted = 5;
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setIsDeleted = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearIsDeleted = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasIsDeleted = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 file_size = 6;
 * @return {number}
 */
proto.collection.FSMessage.File.prototype.getFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setFileSize = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearFileSize = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasFileSize = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes parent_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes parent_id = 7;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes maintainer_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes maintainer_id = 8;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Node.Grant acl = 9;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSMessage.File.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 9));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSMessage.File} returns this
*/
proto.collection.FSMessage.File.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSMessage.File.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearAclList = function() {
  return this.setAclList([]);
};


/**
 * optional SealedContent maintainer_scoped_name = 10;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.File.prototype.getMaintainerScopedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 10));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.File} returns this
*/
proto.collection.FSMessage.File.prototype.setMaintainerScopedName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearMaintainerScopedName = function() {
  return this.setMaintainerScopedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasMaintainerScopedName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bytes read_key_node_id = 11;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes read_key_node_id = 11;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bytes acl_parent_id = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getAclParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes acl_parent_id = 12;
 * This is a type-conversion wrapper around `getAclParentId()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getAclParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAclParentId()));
};


/**
 * optional bytes acl_parent_id = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAclParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getAclParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAclParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setAclParentId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearAclParentId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasAclParentId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional LockInfo lock_info = 13;
 * @return {?proto.collection.FSMessage.LockInfo}
 */
proto.collection.FSMessage.File.prototype.getLockInfo = function() {
  return /** @type{?proto.collection.FSMessage.LockInfo} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.LockInfo, 13));
};


/**
 * @param {?proto.collection.FSMessage.LockInfo|undefined} value
 * @return {!proto.collection.FSMessage.File} returns this
*/
proto.collection.FSMessage.File.prototype.setLockInfo = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearLockInfo = function() {
  return this.setLockInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasLockInfo = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool is_expunged = 14;
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.getIsExpunged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setIsExpunged = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearIsExpunged = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasIsExpunged = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional uint32 last_update_msecs = 15;
 * @return {number}
 */
proto.collection.FSMessage.File.prototype.getLastUpdateMsecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setLastUpdateMsecs = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearLastUpdateMsecs = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasLastUpdateMsecs = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bytes last_modified_device_id = 16;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.File.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes last_modified_device_id = 16;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.File.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string last_local_fs_update = 17;
 * @return {string}
 */
proto.collection.FSMessage.File.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.File} returns this
 */
proto.collection.FSMessage.File.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.File.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 17) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Dir.repeatedFields_ = [3,5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Dir.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Dir.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Dir} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Dir.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.collection.FSMessage.Dir.Entry.toObject, includeInstance),
    wrappedDirKey: (f = msg.getWrappedDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    pathList: jspb.Message.toObjectList(msg.getPathList(),
    proto.collection.FSMessage.Dir.PathNode.toObject, includeInstance),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    parentId: msg.getParentId_asB64(),
    maintainerId: msg.getMaintainerId_asB64(),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    maintainerScopedName: (f = msg.getMaintainerScopedName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    readKeyNodeId: msg.getReadKeyNodeId_asB64(),
    aclParentId: msg.getAclParentId_asB64(),
    isRoot: (f = jspb.Message.getBooleanField(msg, 13)) == null ? undefined : f,
    isExpunged: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    lastUpdate: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    lastUpdateMsecs: jspb.Message.getFieldWithDefault(msg, 16, 0),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64(),
    rootDirName: (f = msg.getRootDirName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 19)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Dir}
 */
proto.collection.FSMessage.Dir.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Dir;
  return proto.collection.FSMessage.Dir.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Dir} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Dir}
 */
proto.collection.FSMessage.Dir.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 3:
      var value = new proto.collection.FSMessage.Dir.Entry;
      reader.readMessage(value,proto.collection.FSMessage.Dir.Entry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    case 4:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedDirKey(value);
      break;
    case 5:
      var value = new proto.collection.FSMessage.Dir.PathNode;
      reader.readMessage(value,proto.collection.FSMessage.Dir.PathNode.deserializeBinaryFromReader);
      msg.addPath(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 9:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    case 10:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setMaintainerScopedName(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAclParentId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRoot(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpunged(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdate(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdateMsecs(value);
      break;
    case 17:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    case 18:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setRootDirName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Dir.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Dir} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Dir.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.FSMessage.Dir.Entry.serializeBinaryToWriter
    );
  }
  f = message.getWrappedDirKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSMessage.Dir.PathNode.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = message.getMaintainerScopedName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBytes(
      17,
      f
    );
  }
  f = message.getRootDirName();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Dir.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Dir.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Dir.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Dir.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    name: msg.getName_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isExpunged: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64(),
    lastUpdate: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    fileSize: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    lockInfo: (f = msg.getLockInfo()) && proto.collection.FSMessage.LockInfo.toObject(includeInstance, f),
    linkedCollectionId: msg.getLinkedCollectionId_asB64(),
    deletedAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    lastUpdateMsecs: jspb.Message.getFieldWithDefault(msg, 11, 0),
    maintainerId: msg.getMaintainerId_asB64(),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    version: msg.getVersion_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Dir.Entry}
 */
proto.collection.FSMessage.Dir.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Dir.Entry;
  return proto.collection.FSMessage.Dir.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Dir.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Dir.Entry}
 */
proto.collection.FSMessage.Dir.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.collection.FSType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpunged(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFileSize(value);
      break;
    case 7:
      var value = new proto.collection.FSMessage.LockInfo;
      reader.readMessage(value,proto.collection.FSMessage.LockInfo.deserializeBinaryFromReader);
      msg.setLockInfo(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDeletedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdateMsecs(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Dir.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Dir.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Dir.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!proto.collection.FSType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getLockInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.collection.FSMessage.LockInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBytes(
      15,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes name = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes name = 2;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FSType type = 3;
 * @return {!proto.collection.FSType}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getType = function() {
  return /** @type {!proto.collection.FSType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.collection.FSType} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_deleted = 4;
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setIsDeleted = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearIsDeleted = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasIsDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_expunged = 9;
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getIsExpunged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setIsExpunged = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearIsExpunged = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasIsExpunged = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bytes last_modified_device_id = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes last_modified_device_id = 12;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint64 last_update = 5;
 * @return {number}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setLastUpdate = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearLastUpdate = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 file_size = 6;
 * @return {number}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getFileSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setFileSize = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearFileSize = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasFileSize = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LockInfo lock_info = 7;
 * @return {?proto.collection.FSMessage.LockInfo}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLockInfo = function() {
  return /** @type{?proto.collection.FSMessage.LockInfo} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.LockInfo, 7));
};


/**
 * @param {?proto.collection.FSMessage.LockInfo|undefined} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
*/
proto.collection.FSMessage.Dir.Entry.prototype.setLockInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearLockInfo = function() {
  return this.setLockInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasLockInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes linked_collection_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes linked_collection_id = 8;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 deleted_at = 10;
 * @return {number}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setDeletedAt = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearDeletedAt = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint32 last_update_msecs = 11;
 * @return {number}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLastUpdateMsecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setLastUpdateMsecs = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearLastUpdateMsecs = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasLastUpdateMsecs = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bytes maintainer_id = 13;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes maintainer_id = 13;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string last_local_fs_update = 14;
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bytes version = 15;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes version = 15;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.Entry.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.Entry} returns this
 */
proto.collection.FSMessage.Dir.Entry.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.Entry.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Dir.PathNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Dir.PathNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Dir.PathNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    name: msg.getName_asB64(),
    wrappedDirKey: (f = msg.getWrappedDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    maintainerScopedName: (f = msg.getMaintainerScopedName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    maintainerId: msg.getMaintainerId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Dir.PathNode}
 */
proto.collection.FSMessage.Dir.PathNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Dir.PathNode;
  return proto.collection.FSMessage.Dir.PathNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Dir.PathNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Dir.PathNode}
 */
proto.collection.FSMessage.Dir.PathNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedDirKey(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 5:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setMaintainerScopedName(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Dir.PathNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Dir.PathNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Dir.PathNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getWrappedDirKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getMaintainerScopedName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes name = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes name = 2;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FSWrappedKey wrapped_dir_key = 3;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getWrappedDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 3));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
*/
proto.collection.FSMessage.Dir.PathNode.prototype.setWrappedDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.clearWrappedDirKey = function() {
  return this.setWrappedDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.hasWrappedDirKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_deleted = 4;
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.setIsDeleted = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.clearIsDeleted = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.hasIsDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SealedContent maintainer_scoped_name = 5;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getMaintainerScopedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 5));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
*/
proto.collection.FSMessage.Dir.PathNode.prototype.setMaintainerScopedName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.clearMaintainerScopedName = function() {
  return this.setMaintainerScopedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.hasMaintainerScopedName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes maintainer_id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes maintainer_id = 6;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir.PathNode} returns this
 */
proto.collection.FSMessage.Dir.PathNode.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.PathNode.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes version = 2;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Entry entries = 3;
 * @return {!Array<!proto.collection.FSMessage.Dir.Entry>}
 */
proto.collection.FSMessage.Dir.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Dir.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Dir.Entry, 3));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Dir.Entry>} value
 * @return {!proto.collection.FSMessage.Dir} returns this
*/
proto.collection.FSMessage.Dir.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.FSMessage.Dir.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Dir.Entry}
 */
proto.collection.FSMessage.Dir.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.FSMessage.Dir.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};


/**
 * optional FSWrappedKey wrapped_dir_key = 4;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Dir.prototype.getWrappedDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 4));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Dir} returns this
*/
proto.collection.FSMessage.Dir.prototype.setWrappedDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearWrappedDirKey = function() {
  return this.setWrappedDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasWrappedDirKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated PathNode path = 5;
 * @return {!Array<!proto.collection.FSMessage.Dir.PathNode>}
 */
proto.collection.FSMessage.Dir.prototype.getPathList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Dir.PathNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Dir.PathNode, 5));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Dir.PathNode>} value
 * @return {!proto.collection.FSMessage.Dir} returns this
*/
proto.collection.FSMessage.Dir.prototype.setPathList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSMessage.Dir.PathNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Dir.PathNode}
 */
proto.collection.FSMessage.Dir.prototype.addPath = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSMessage.Dir.PathNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearPathList = function() {
  return this.setPathList([]);
};


/**
 * optional bool is_deleted = 6;
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setIsDeleted = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearIsDeleted = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasIsDeleted = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes parent_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes parent_id = 7;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes maintainer_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes maintainer_id = 8;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated Node.Grant acl = 9;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSMessage.Dir.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 9));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSMessage.Dir} returns this
*/
proto.collection.FSMessage.Dir.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSMessage.Dir.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearAclList = function() {
  return this.setAclList([]);
};


/**
 * optional SealedContent maintainer_scoped_name = 10;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.Dir.prototype.getMaintainerScopedName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 10));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.Dir} returns this
*/
proto.collection.FSMessage.Dir.prototype.setMaintainerScopedName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearMaintainerScopedName = function() {
  return this.setMaintainerScopedName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasMaintainerScopedName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bytes read_key_node_id = 11;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes read_key_node_id = 11;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bytes acl_parent_id = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getAclParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes acl_parent_id = 12;
 * This is a type-conversion wrapper around `getAclParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getAclParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAclParentId()));
};


/**
 * optional bytes acl_parent_id = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAclParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getAclParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAclParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setAclParentId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearAclParentId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasAclParentId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool is_root = 13;
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.getIsRoot = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setIsRoot = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearIsRoot = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasIsRoot = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool is_expunged = 14;
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.getIsExpunged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setIsExpunged = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearIsExpunged = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasIsExpunged = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional uint64 last_update = 15;
 * @return {number}
 */
proto.collection.FSMessage.Dir.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setLastUpdate = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearLastUpdate = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional uint32 last_update_msecs = 16;
 * @return {number}
 */
proto.collection.FSMessage.Dir.prototype.getLastUpdateMsecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setLastUpdateMsecs = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearLastUpdateMsecs = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasLastUpdateMsecs = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bytes last_modified_device_id = 17;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Dir.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * optional bytes last_modified_device_id = 17;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 17;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Dir.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional SealedContent root_dir_name = 18;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.Dir.prototype.getRootDirName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 18));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.Dir} returns this
*/
proto.collection.FSMessage.Dir.prototype.setRootDirName = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearRootDirName = function() {
  return this.setRootDirName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasRootDirName = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string last_local_fs_update = 19;
 * @return {string}
 */
proto.collection.FSMessage.Dir.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Dir} returns this
 */
proto.collection.FSMessage.Dir.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Dir.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 19) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    linkedCollectionId: msg.getLinkedCollectionId_asB64(),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    parentId: msg.getParentId_asB64(),
    isExpunged: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64(),
    collectionProtocolVersion: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Link}
 */
proto.collection.FSMessage.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Link;
  return proto.collection.FSMessage.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Link}
 */
proto.collection.FSMessage.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpunged(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCollectionProtocolVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Link.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Link.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Link.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Link.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes version = 2;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Link.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Link.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes linked_collection_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Link.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes linked_collection_id = 3;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Link.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Link.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_deleted = 4;
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setIsDeleted = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearIsDeleted = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasIsDeleted = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes parent_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Link.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes parent_id = 5;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Link.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Link.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_expunged = 6;
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.getIsExpunged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setIsExpunged = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearIsExpunged = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasIsExpunged = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes last_modified_device_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Link.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes last_modified_device_id = 7;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.Link.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Link.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 collection_protocol_version = 8;
 * @return {number}
 */
proto.collection.FSMessage.Link.prototype.getCollectionProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.setCollectionProtocolVersion = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Link} returns this
 */
proto.collection.FSMessage.Link.prototype.clearCollectionProtocolVersion = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Link.prototype.hasCollectionProtocolVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.DeletedEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.DeletedEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.DeletedEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.DeletedEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    id: msg.getId_asB64(),
    name: msg.getName_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    wrappedDirKey: (f = msg.getWrappedDirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    lastUpdate: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    readKeyNodeId: msg.getReadKeyNodeId_asB64(),
    deletedAt: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    isExpunged: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    parentId: msg.getParentId_asB64(),
    version: msg.getVersion_asB64(),
    deletedBy: msg.getDeletedBy_asB64(),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64(),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.DeletedEntry}
 */
proto.collection.FSMessage.DeletedEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.DeletedEntry;
  return proto.collection.FSMessage.DeletedEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.DeletedEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.DeletedEntry}
 */
proto.collection.FSMessage.DeletedEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.collection.FSType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedDirKey(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDeletedAt(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpunged(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeletedBy(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.DeletedEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.DeletedEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.DeletedEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!proto.collection.FSType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getWrappedDirKey();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes id = 2;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes name = 3;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FSType type = 4;
 * @return {!proto.collection.FSType}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getType = function() {
  return /** @type {!proto.collection.FSType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.collection.FSType} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FSWrappedKey wrapped_dir_key = 5;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getWrappedDirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 5));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
*/
proto.collection.FSMessage.DeletedEntry.prototype.setWrappedDirKey = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearWrappedDirKey = function() {
  return this.setWrappedDirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasWrappedDirKey = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 last_update = 6;
 * @return {number}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setLastUpdate = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearLastUpdate = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool is_deleted = 7;
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setIsDeleted = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearIsDeleted = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasIsDeleted = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes read_key_node_id = 8;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes read_key_node_id = 8;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 deleted_at = 9;
 * @return {number}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getDeletedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setDeletedAt = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearDeletedAt = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasDeletedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_expunged = 10;
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getIsExpunged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setIsExpunged = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearIsExpunged = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasIsExpunged = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bytes parent_id = 11;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes parent_id = 11;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bytes version = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes version = 12;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bytes deleted_by = 13;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getDeletedBy = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes deleted_by = 13;
 * This is a type-conversion wrapper around `getDeletedBy()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getDeletedBy_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeletedBy()));
};


/**
 * optional bytes deleted_by = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeletedBy()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getDeletedBy_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeletedBy()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setDeletedBy = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearDeletedBy = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasDeletedBy = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bytes last_modified_device_id = 14;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes last_modified_device_id = 14;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string last_local_fs_update = 15;
 * @return {string}
 */
proto.collection.FSMessage.DeletedEntry.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.DeletedEntry} returns this
 */
proto.collection.FSMessage.DeletedEntry.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.DeletedEntry.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Snapshot.repeatedFields_ = [1,2,5,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Snapshot.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Snapshot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Snapshot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Snapshot.toObject = function(includeInstance, msg) {
  var f, obj = {
    dirsList: jspb.Message.toObjectList(msg.getDirsList(),
    proto.collection.FSMessage.Dir.toObject, includeInstance),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.collection.FSMessage.File.toObject, includeInstance),
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.collection.FSMessage.Link.toObject, includeInstance),
    versionsList: msg.getVersionsList_asB64(),
    seq: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cursorSeq: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cursorSubseq: jspb.Message.getFieldWithDefault(msg, 7, 0),
    hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Snapshot}
 */
proto.collection.FSMessage.Snapshot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Snapshot;
  return proto.collection.FSMessage.Snapshot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Snapshot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Snapshot}
 */
proto.collection.FSMessage.Snapshot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.Dir;
      reader.readMessage(value,proto.collection.FSMessage.Dir.deserializeBinaryFromReader);
      msg.addDirs(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.File;
      reader.readMessage(value,proto.collection.FSMessage.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    case 5:
      var value = new proto.collection.FSMessage.Link;
      reader.readMessage(value,proto.collection.FSMessage.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addVersions(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCursorSeq(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCursorSubseq(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Snapshot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Snapshot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Snapshot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Snapshot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.Dir.serializeBinaryToWriter
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSMessage.File.serializeBinaryToWriter
    );
  }
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.collection.FSMessage.Link.serializeBinaryToWriter
    );
  }
  f = message.getVersionsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * repeated Dir dirs = 1;
 * @return {!Array<!proto.collection.FSMessage.Dir>}
 */
proto.collection.FSMessage.Snapshot.prototype.getDirsList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Dir>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Dir, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Dir>} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
*/
proto.collection.FSMessage.Snapshot.prototype.setDirsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.Dir=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Dir}
 */
proto.collection.FSMessage.Snapshot.prototype.addDirs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.Dir, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearDirsList = function() {
  return this.setDirsList([]);
};


/**
 * repeated File files = 2;
 * @return {!Array<!proto.collection.FSMessage.File>}
 */
proto.collection.FSMessage.Snapshot.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.File, 2));
};


/**
 * @param {!Array<!proto.collection.FSMessage.File>} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
*/
proto.collection.FSMessage.Snapshot.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSMessage.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.File}
 */
proto.collection.FSMessage.Snapshot.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSMessage.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


/**
 * repeated Link links = 5;
 * @return {!Array<!proto.collection.FSMessage.Link>}
 */
proto.collection.FSMessage.Snapshot.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Link, 5));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Link>} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
*/
proto.collection.FSMessage.Snapshot.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.collection.FSMessage.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Link}
 */
proto.collection.FSMessage.Snapshot.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.collection.FSMessage.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * repeated bytes versions = 3;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.collection.FSMessage.Snapshot.prototype.getVersionsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * repeated bytes versions = 3;
 * This is a type-conversion wrapper around `getVersionsList()`
 * @return {!Array<string>}
 */
proto.collection.FSMessage.Snapshot.prototype.getVersionsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getVersionsList()));
};


/**
 * repeated bytes versions = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersionsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.collection.FSMessage.Snapshot.prototype.getVersionsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getVersionsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.setVersionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.addVersions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


/**
 * optional uint64 seq = 4;
 * @return {number}
 */
proto.collection.FSMessage.Snapshot.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Snapshot.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 cursor_seq = 6;
 * @return {number}
 */
proto.collection.FSMessage.Snapshot.prototype.getCursorSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.setCursorSeq = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearCursorSeq = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Snapshot.prototype.hasCursorSeq = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 cursor_subseq = 7;
 * @return {number}
 */
proto.collection.FSMessage.Snapshot.prototype.getCursorSubseq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.setCursorSubseq = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearCursorSubseq = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Snapshot.prototype.hasCursorSubseq = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool has_more = 8;
 * @return {boolean}
 */
proto.collection.FSMessage.Snapshot.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.setHasMore = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Snapshot} returns this
 */
proto.collection.FSMessage.Snapshot.prototype.clearHasMore = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Snapshot.prototype.hasHasMore = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.ACLTree.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.ACLTree.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.ACLTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.ACLTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    dirsList: jspb.Message.toObjectList(msg.getDirsList(),
    proto.collection.FSMessage.Dir.toObject, includeInstance),
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    proto.collection.FSMessage.File.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.ACLTree}
 */
proto.collection.FSMessage.ACLTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.ACLTree;
  return proto.collection.FSMessage.ACLTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.ACLTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.ACLTree}
 */
proto.collection.FSMessage.ACLTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.Dir;
      reader.readMessage(value,proto.collection.FSMessage.Dir.deserializeBinaryFromReader);
      msg.addDirs(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.File;
      reader.readMessage(value,proto.collection.FSMessage.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.ACLTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.ACLTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.Dir.serializeBinaryToWriter
    );
  }
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSMessage.File.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dir dirs = 1;
 * @return {!Array<!proto.collection.FSMessage.Dir>}
 */
proto.collection.FSMessage.ACLTree.prototype.getDirsList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Dir>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Dir, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Dir>} value
 * @return {!proto.collection.FSMessage.ACLTree} returns this
*/
proto.collection.FSMessage.ACLTree.prototype.setDirsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.Dir=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Dir}
 */
proto.collection.FSMessage.ACLTree.prototype.addDirs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.Dir, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.ACLTree} returns this
 */
proto.collection.FSMessage.ACLTree.prototype.clearDirsList = function() {
  return this.setDirsList([]);
};


/**
 * repeated File files = 2;
 * @return {!Array<!proto.collection.FSMessage.File>}
 */
proto.collection.FSMessage.ACLTree.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.File, 2));
};


/**
 * @param {!Array<!proto.collection.FSMessage.File>} value
 * @return {!proto.collection.FSMessage.ACLTree} returns this
*/
proto.collection.FSMessage.ACLTree.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSMessage.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.File}
 */
proto.collection.FSMessage.ACLTree.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSMessage.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.ACLTree} returns this
 */
proto.collection.FSMessage.ACLTree.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.SyncRuleTree.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.SyncRuleTree.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.SyncRuleTree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.SyncRuleTree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.SyncRuleTree.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodesList: jspb.Message.toObjectList(msg.getNodesList(),
    proto.collection.FSMessage.SyncTreeNode.toObject, includeInstance),
    revId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.SyncRuleTree}
 */
proto.collection.FSMessage.SyncRuleTree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.SyncRuleTree;
  return proto.collection.FSMessage.SyncRuleTree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.SyncRuleTree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.SyncRuleTree}
 */
proto.collection.FSMessage.SyncRuleTree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.SyncTreeNode;
      reader.readMessage(value,proto.collection.FSMessage.SyncTreeNode.deserializeBinaryFromReader);
      msg.addNodes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRevId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.SyncRuleTree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.SyncRuleTree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.SyncRuleTree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.SyncRuleTree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNodesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.SyncTreeNode.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated SyncTreeNode nodes = 1;
 * @return {!Array<!proto.collection.FSMessage.SyncTreeNode>}
 */
proto.collection.FSMessage.SyncRuleTree.prototype.getNodesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.SyncTreeNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.SyncTreeNode, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.SyncTreeNode>} value
 * @return {!proto.collection.FSMessage.SyncRuleTree} returns this
*/
proto.collection.FSMessage.SyncRuleTree.prototype.setNodesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.SyncTreeNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.SyncTreeNode}
 */
proto.collection.FSMessage.SyncRuleTree.prototype.addNodes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.SyncTreeNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.SyncRuleTree} returns this
 */
proto.collection.FSMessage.SyncRuleTree.prototype.clearNodesList = function() {
  return this.setNodesList([]);
};


/**
 * optional uint64 rev_id = 2;
 * @return {number}
 */
proto.collection.FSMessage.SyncRuleTree.prototype.getRevId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.SyncRuleTree} returns this
 */
proto.collection.FSMessage.SyncRuleTree.prototype.setRevId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncRuleTree} returns this
 */
proto.collection.FSMessage.SyncRuleTree.prototype.clearRevId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncRuleTree.prototype.hasRevId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.SyncTreeNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.SyncTreeNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.SyncTreeNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    id: msg.getId_asB64(),
    parentId: msg.getParentId_asB64(),
    maintainerId: msg.getMaintainerId_asB64(),
    sync: jspb.Message.getFieldWithDefault(msg, 5, 0),
    syncLinkedCollection: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.SyncTreeNode}
 */
proto.collection.FSMessage.SyncTreeNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.SyncTreeNode;
  return proto.collection.FSMessage.SyncTreeNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.SyncTreeNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.SyncTreeNode}
 */
proto.collection.FSMessage.SyncTreeNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 5:
      var value = /** @type {!proto.collection.FSMessage.SyncTreeNode.SyncState} */ (reader.readEnum());
      msg.setSync(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncLinkedCollection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.SyncTreeNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.SyncTreeNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.SyncTreeNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {!proto.collection.FSMessage.SyncTreeNode.SyncState} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSMessage.SyncTreeNode.SyncState = {
  NOT_SPECIFIED: 0,
  SYNC: 1,
  DONT_SYNC: 2
};

/**
 * optional FSType type = 1;
 * @return {!proto.collection.FSType}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getType = function() {
  return /** @type {!proto.collection.FSType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSType} value
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes id = 2;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.setId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.clearId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes parent_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes parent_id = 3;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes maintainer_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes maintainer_id = 4;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SyncState sync = 5;
 * @return {!proto.collection.FSMessage.SyncTreeNode.SyncState}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getSync = function() {
  return /** @type {!proto.collection.FSMessage.SyncTreeNode.SyncState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.collection.FSMessage.SyncTreeNode.SyncState} value
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.setSync = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.clearSync = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.hasSync = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool sync_linked_collection = 6;
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.getSyncLinkedCollection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.setSyncLinkedCollection = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncTreeNode} returns this
 */
proto.collection.FSMessage.SyncTreeNode.prototype.clearSyncLinkedCollection = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncTreeNode.prototype.hasSyncLinkedCollection = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    version: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    wrappedKey: msg.getWrappedKey_asB64(),
    userKeyVersion: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    expirationTime: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Role}
 */
proto.collection.FSMessage.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Role;
  return proto.collection.FSMessage.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Role}
 */
proto.collection.FSMessage.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Role.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Role.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Role.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FSRole role = 2;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSMessage.Role.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearRole = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 version = 3;
 * @return {number}
 */
proto.collection.FSMessage.Role.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes wrapped_key = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Role.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes wrapped_key = 4;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSMessage.Role.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Role.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 user_key_version = 5;
 * @return {number}
 */
proto.collection.FSMessage.Role.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool view_only = 6;
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string expiration_time = 7;
 * @return {string}
 */
proto.collection.FSMessage.Role.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Role} returns this
 */
proto.collection.FSMessage.Role.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Role.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Permissions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Permissions.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Permissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Permissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Permissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.collection.FSMessage.Role.toObject, includeInstance),
    userKeyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    seq: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Permissions}
 */
proto.collection.FSMessage.Permissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Permissions;
  return proto.collection.FSMessage.Permissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Permissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Permissions}
 */
proto.collection.FSMessage.Permissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.Role;
      reader.readMessage(value,proto.collection.FSMessage.Role.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Permissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Permissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Permissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Permissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.Role.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * repeated Role roles = 1;
 * @return {!Array<!proto.collection.FSMessage.Role>}
 */
proto.collection.FSMessage.Permissions.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Role, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Role>} value
 * @return {!proto.collection.FSMessage.Permissions} returns this
*/
proto.collection.FSMessage.Permissions.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Role}
 */
proto.collection.FSMessage.Permissions.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Permissions} returns this
 */
proto.collection.FSMessage.Permissions.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional uint64 user_key_version = 2;
 * @return {number}
 */
proto.collection.FSMessage.Permissions.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Permissions} returns this
 */
proto.collection.FSMessage.Permissions.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Permissions} returns this
 */
proto.collection.FSMessage.Permissions.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Permissions.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 seq = 3;
 * @return {number}
 */
proto.collection.FSMessage.Permissions.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Permissions} returns this
 */
proto.collection.FSMessage.Permissions.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Permissions} returns this
 */
proto.collection.FSMessage.Permissions.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Permissions.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.GroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.GroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.GroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.GroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: msg.getGroupId_asB64(),
    version: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    wrappedKey: msg.getWrappedKey_asB64(),
    userKeyVersion: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.GroupInfo}
 */
proto.collection.FSMessage.GroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.GroupInfo;
  return proto.collection.FSMessage.GroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.GroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.GroupInfo}
 */
proto.collection.FSMessage.GroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.GroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.GroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.GroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.GroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional bytes group_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.GroupInfo.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes group_id = 1;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSMessage.GroupInfo.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.GroupInfo.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GroupInfo.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 version = 2;
 * @return {number}
 */
proto.collection.FSMessage.GroupInfo.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GroupInfo.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.GroupInfo.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSMessage.GroupInfo.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.GroupInfo.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GroupInfo.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 user_key_version = 4;
 * @return {number}
 */
proto.collection.FSMessage.GroupInfo.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GroupInfo} returns this
 */
proto.collection.FSMessage.GroupInfo.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GroupInfo.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Groups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Groups.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Groups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Groups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Groups.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupInfoList: jspb.Message.toObjectList(msg.getGroupInfoList(),
    proto.collection.FSMessage.GroupInfo.toObject, includeInstance),
    userKeyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    seq: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Groups}
 */
proto.collection.FSMessage.Groups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Groups;
  return proto.collection.FSMessage.Groups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Groups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Groups}
 */
proto.collection.FSMessage.Groups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.GroupInfo;
      reader.readMessage(value,proto.collection.FSMessage.GroupInfo.deserializeBinaryFromReader);
      msg.addGroupInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Groups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Groups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Groups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Groups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.GroupInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * repeated GroupInfo group_info = 1;
 * @return {!Array<!proto.collection.FSMessage.GroupInfo>}
 */
proto.collection.FSMessage.Groups.prototype.getGroupInfoList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.GroupInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.GroupInfo, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.GroupInfo>} value
 * @return {!proto.collection.FSMessage.Groups} returns this
*/
proto.collection.FSMessage.Groups.prototype.setGroupInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.GroupInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.GroupInfo}
 */
proto.collection.FSMessage.Groups.prototype.addGroupInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.GroupInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Groups} returns this
 */
proto.collection.FSMessage.Groups.prototype.clearGroupInfoList = function() {
  return this.setGroupInfoList([]);
};


/**
 * optional uint64 user_key_version = 2;
 * @return {number}
 */
proto.collection.FSMessage.Groups.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Groups} returns this
 */
proto.collection.FSMessage.Groups.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Groups} returns this
 */
proto.collection.FSMessage.Groups.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Groups.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 seq = 3;
 * @return {number}
 */
proto.collection.FSMessage.Groups.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Groups} returns this
 */
proto.collection.FSMessage.Groups.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Groups} returns this
 */
proto.collection.FSMessage.Groups.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Groups.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.AdminGroupInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.AdminGroupInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.AdminGroupInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: msg.getGroupId_asB64(),
    groupKey: (f = msg.getGroupKey()) && proto.collection.FSMessage.PublicKey.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.AdminGroupInfo}
 */
proto.collection.FSMessage.AdminGroupInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.AdminGroupInfo;
  return proto.collection.FSMessage.AdminGroupInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.AdminGroupInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.AdminGroupInfo}
 */
proto.collection.FSMessage.AdminGroupInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.PublicKey;
      reader.readMessage(value,proto.collection.FSMessage.PublicKey.deserializeBinaryFromReader);
      msg.setGroupKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.AdminGroupInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.AdminGroupInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.AdminGroupInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getGroupKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.FSMessage.PublicKey.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes group_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes group_id = 1;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.AdminGroupInfo} returns this
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.AdminGroupInfo} returns this
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PublicKey group_key = 2;
 * @return {?proto.collection.FSMessage.PublicKey}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.getGroupKey = function() {
  return /** @type{?proto.collection.FSMessage.PublicKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.PublicKey, 2));
};


/**
 * @param {?proto.collection.FSMessage.PublicKey|undefined} value
 * @return {!proto.collection.FSMessage.AdminGroupInfo} returns this
*/
proto.collection.FSMessage.AdminGroupInfo.prototype.setGroupKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.AdminGroupInfo} returns this
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.clearGroupKey = function() {
  return this.setGroupKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.AdminGroupInfo.prototype.hasGroupKey = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.RestoreResult.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.RestoreResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.RestoreResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.RestoreResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    conflictingId: msg.getConflictingId_asB64(),
    deletedParentId: msg.getDeletedParentId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.RestoreResult}
 */
proto.collection.FSMessage.RestoreResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.RestoreResult;
  return proto.collection.FSMessage.RestoreResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.RestoreResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.RestoreResult}
 */
proto.collection.FSMessage.RestoreResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSMessage.RestoreResult.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConflictingId(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeletedParentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RestoreResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.RestoreResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.RestoreResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.RestoreResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSMessage.RestoreResult.Status} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSMessage.RestoreResult.Status = {
  OK: 0,
  CONFLICT: 1,
  NOT_FOUND: 2,
  PARENT_DELETED: 3
};

/**
 * optional Status status = 1;
 * @return {!proto.collection.FSMessage.RestoreResult.Status}
 */
proto.collection.FSMessage.RestoreResult.prototype.getStatus = function() {
  return /** @type {!proto.collection.FSMessage.RestoreResult.Status} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSMessage.RestoreResult.Status} value
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RestoreResult.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RestoreResult.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes id = 2;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.RestoreResult.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RestoreResult.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.setId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.clearId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RestoreResult.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes version = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RestoreResult.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes version = 3;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.RestoreResult.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RestoreResult.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RestoreResult.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes conflicting_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RestoreResult.prototype.getConflictingId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes conflicting_id = 4;
 * This is a type-conversion wrapper around `getConflictingId()`
 * @return {string}
 */
proto.collection.FSMessage.RestoreResult.prototype.getConflictingId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConflictingId()));
};


/**
 * optional bytes conflicting_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConflictingId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RestoreResult.prototype.getConflictingId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConflictingId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.setConflictingId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.clearConflictingId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RestoreResult.prototype.hasConflictingId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes deleted_parent_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.RestoreResult.prototype.getDeletedParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes deleted_parent_id = 5;
 * This is a type-conversion wrapper around `getDeletedParentId()`
 * @return {string}
 */
proto.collection.FSMessage.RestoreResult.prototype.getDeletedParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeletedParentId()));
};


/**
 * optional bytes deleted_parent_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeletedParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.RestoreResult.prototype.getDeletedParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeletedParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.setDeletedParentId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.RestoreResult} returns this
 */
proto.collection.FSMessage.RestoreResult.prototype.clearDeletedParentId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.RestoreResult.prototype.hasDeletedParentId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.BulkUpdateFailure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.BulkUpdateFailure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.BulkUpdateFailure.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateType: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    status: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    conflictingId: msg.getConflictingId_asB64(),
    deletedParentId: msg.getDeletedParentId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure}
 */
proto.collection.FSMessage.BulkUpdateFailure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.BulkUpdateFailure;
  return proto.collection.FSMessage.BulkUpdateFailure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.BulkUpdateFailure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure}
 */
proto.collection.FSMessage.BulkUpdateFailure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSMessage.BulkUpdateFailure.UpdateType} */ (reader.readEnum());
      msg.setUpdateType(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.FSMessage.BulkUpdateFailure.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConflictingId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDeletedParentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.BulkUpdateFailure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.BulkUpdateFailure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.BulkUpdateFailure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSMessage.BulkUpdateFailure.UpdateType} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.FSMessage.BulkUpdateFailure.Status} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSMessage.BulkUpdateFailure.UpdateType = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2,
  RESTORE: 3
};

/**
 * @enum {number}
 */
proto.collection.FSMessage.BulkUpdateFailure.Status = {
  CONFLICT: 1,
  NOT_FOUND: 2,
  PARENT_DELETED: 3
};

/**
 * optional UpdateType update_type = 1;
 * @return {!proto.collection.FSMessage.BulkUpdateFailure.UpdateType}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getUpdateType = function() {
  return /** @type {!proto.collection.FSMessage.BulkUpdateFailure.UpdateType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSMessage.BulkUpdateFailure.UpdateType} value
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.setUpdateType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.clearUpdateType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.hasUpdateType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Status status = 2;
 * @return {!proto.collection.FSMessage.BulkUpdateFailure.Status}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getStatus = function() {
  return /** @type {!proto.collection.FSMessage.BulkUpdateFailure.Status} */ (jspb.Message.getFieldWithDefault(this, 2, 1));
};


/**
 * @param {!proto.collection.FSMessage.BulkUpdateFailure.Status} value
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes id = 3;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.setId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.clearId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.hasId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes version = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes version = 4;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes conflicting_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getConflictingId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes conflicting_id = 5;
 * This is a type-conversion wrapper around `getConflictingId()`
 * @return {string}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getConflictingId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConflictingId()));
};


/**
 * optional bytes conflicting_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConflictingId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getConflictingId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConflictingId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.setConflictingId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.clearConflictingId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.hasConflictingId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes deleted_parent_id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getDeletedParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes deleted_parent_id = 6;
 * This is a type-conversion wrapper around `getDeletedParentId()`
 * @return {string}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getDeletedParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDeletedParentId()));
};


/**
 * optional bytes deleted_parent_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDeletedParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.getDeletedParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDeletedParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.setDeletedParentId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BulkUpdateFailure} returns this
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.clearDeletedParentId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BulkUpdateFailure.prototype.hasDeletedParentId = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.KeyHistoryEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.KeyHistoryEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.KeyHistoryEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    wrappedLastKey: msg.getWrappedLastKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.KeyHistoryEntry}
 */
proto.collection.FSMessage.KeyHistoryEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.KeyHistoryEntry;
  return proto.collection.FSMessage.KeyHistoryEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.KeyHistoryEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.KeyHistoryEntry}
 */
proto.collection.FSMessage.KeyHistoryEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedLastKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.KeyHistoryEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.KeyHistoryEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.KeyHistoryEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint64 version = 1;
 * @return {number}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.KeyHistoryEntry} returns this
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.KeyHistoryEntry} returns this
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes wrapped_last_key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.getWrappedLastKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes wrapped_last_key = 2;
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {string}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.getWrappedLastKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedLastKey()));
};


/**
 * optional bytes wrapped_last_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedLastKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.getWrappedLastKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedLastKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.KeyHistoryEntry} returns this
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.setWrappedLastKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.KeyHistoryEntry} returns this
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.clearWrappedLastKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.KeyHistoryEntry.prototype.hasWrappedLastKey = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Share.repeatedFields_ = [7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Share.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Share.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Share} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Share.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    collectionId: msg.getCollectionId_asB64(),
    encCollectionName: msg.getEncCollectionName_asB64(),
    userKeyVersion: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    sharerId: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    sharerKeyVersion: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.collection.FSMessage.Share.RoleInfo.toObject, includeInstance),
    expirationTime: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    syncByDefault: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f,
    aclRolesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    aclViewOnly: (f = jspb.Message.getBooleanField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Share}
 */
proto.collection.FSMessage.Share.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Share;
  return proto.collection.FSMessage.Share.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Share} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Share}
 */
proto.collection.FSMessage.Share.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncCollectionName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSharerId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSharerKeyVersion(value);
      break;
    case 7:
      var value = new proto.collection.FSMessage.Share.RoleInfo;
      reader.readMessage(value,proto.collection.FSMessage.Share.RoleInfo.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSyncByDefault(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.collection.Node.ACLRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAclRoles(values[i]);
      }
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAclViewOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Share.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Share} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Share.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.collection.FSMessage.Share.RoleInfo.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getAclRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Share.RoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Share.RoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Share.RoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    version: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    wrappedKeyHash: msg.getWrappedKeyHash_asB64(),
    signature: msg.getSignature_asB64(),
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Share.RoleInfo}
 */
proto.collection.FSMessage.Share.RoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Share.RoleInfo;
  return proto.collection.FSMessage.Share.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Share.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Share.RoleInfo}
 */
proto.collection.FSMessage.Share.RoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKeyHash(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSignature(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Share.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Share.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Share.RoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 version = 2;
 * @return {number}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key_hash = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getWrappedKeyHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key_hash = 3;
 * This is a type-conversion wrapper around `getWrappedKeyHash()`
 * @return {string}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getWrappedKeyHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKeyHash()));
};


/**
 * optional bytes wrapped_key_hash = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKeyHash()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getWrappedKeyHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKeyHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.setWrappedKeyHash = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.clearWrappedKeyHash = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.hasWrappedKeyHash = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes signature = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getSignature = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes signature = 4;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getSignature_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSignature()));
};


/**
 * optional bytes signature = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getSignature_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSignature()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.setSignature = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.clearSignature = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.hasSignature = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool view_only = 5;
 * @return {boolean}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share.RoleInfo} returns this
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.RoleInfo.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Share.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Share.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes collection_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Share.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes collection_id = 2;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Share.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes enc_collection_name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Share.prototype.getEncCollectionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes enc_collection_name = 3;
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {string}
 */
proto.collection.FSMessage.Share.prototype.getEncCollectionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncCollectionName()));
};


/**
 * optional bytes enc_collection_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Share.prototype.getEncCollectionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncCollectionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setEncCollectionName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearEncCollectionName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasEncCollectionName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 user_key_version = 4;
 * @return {number}
 */
proto.collection.FSMessage.Share.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sharer_id = 5;
 * @return {string}
 */
proto.collection.FSMessage.Share.prototype.getSharerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setSharerId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearSharerId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasSharerId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 sharer_key_version = 6;
 * @return {number}
 */
proto.collection.FSMessage.Share.prototype.getSharerKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setSharerKeyVersion = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearSharerKeyVersion = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasSharerKeyVersion = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated RoleInfo roles = 7;
 * @return {!Array<!proto.collection.FSMessage.Share.RoleInfo>}
 */
proto.collection.FSMessage.Share.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Share.RoleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Share.RoleInfo, 7));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Share.RoleInfo>} value
 * @return {!proto.collection.FSMessage.Share} returns this
*/
proto.collection.FSMessage.Share.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.collection.FSMessage.Share.RoleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Share.RoleInfo}
 */
proto.collection.FSMessage.Share.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.collection.FSMessage.Share.RoleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional string expiration_time = 8;
 * @return {string}
 */
proto.collection.FSMessage.Share.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool sync_by_default = 9;
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.getSyncByDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setSyncByDefault = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearSyncByDefault = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasSyncByDefault = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Node.ACLRole acl_roles = 10;
 * @return {!Array<!proto.collection.Node.ACLRole>}
 */
proto.collection.FSMessage.Share.prototype.getAclRolesList = function() {
  return /** @type {!Array<!proto.collection.Node.ACLRole>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.collection.Node.ACLRole>} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setAclRolesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.addAclRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearAclRolesList = function() {
  return this.setAclRolesList([]);
};


/**
 * optional bool acl_view_only = 11;
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.getAclViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.setAclViewOnly = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Share} returns this
 */
proto.collection.FSMessage.Share.prototype.clearAclViewOnly = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Share.prototype.hasAclViewOnly = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.User.repeatedFields_ = [3,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.User.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    rolesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    pending: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    expirationTime: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    aclRolesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.User}
 */
proto.collection.FSMessage.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.User;
  return proto.collection.FSMessage.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.User}
 */
proto.collection.FSMessage.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.collection.FSRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.collection.Node.ACLRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAclRoles(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAclRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      7,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSMessage.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.FSMessage.User.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated FSRole roles = 3;
 * @return {!Array<!proto.collection.FSRole>}
 */
proto.collection.FSMessage.User.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.collection.FSRole>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.collection.FSRole>} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.collection.FSRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional bool pending = 4;
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setPending = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearPending = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.hasPending = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool view_only = 5;
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string expiration_time = 6;
 * @return {string}
 */
proto.collection.FSMessage.User.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.User.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Node.ACLRole acl_roles = 7;
 * @return {!Array<!proto.collection.Node.ACLRole>}
 */
proto.collection.FSMessage.User.prototype.getAclRolesList = function() {
  return /** @type {!Array<!proto.collection.Node.ACLRole>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.collection.Node.ACLRole>} value
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.setAclRolesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.addAclRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.User} returns this
 */
proto.collection.FSMessage.User.prototype.clearAclRolesList = function() {
  return this.setAclRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Logs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Logs.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Logs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Logs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Logs.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalLogs: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    logEntriesList: jspb.Message.toObjectList(msg.getLogEntriesList(),
    proto.collection.FSMessage.Logs.LogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Logs}
 */
proto.collection.FSMessage.Logs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Logs;
  return proto.collection.FSMessage.Logs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Logs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Logs}
 */
proto.collection.FSMessage.Logs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalLogs(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.Logs.LogEntry;
      reader.readMessage(value,proto.collection.FSMessage.Logs.LogEntry.deserializeBinaryFromReader);
      msg.addLogEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Logs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Logs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Logs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Logs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getLogEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSMessage.Logs.LogEntry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Logs.LogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Logs.LogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Logs.LogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    idx: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    entryTs: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    hash: msg.getHash_asB64(),
    keyVersion: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    payload: msg.getPayload_asB64(),
    userNotification: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    legacyPriorityLevel: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    version: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    priorityLevel: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    globalIdx: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Logs.LogEntry}
 */
proto.collection.FSMessage.Logs.LogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Logs.LogEntry;
  return proto.collection.FSMessage.Logs.LogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Logs.LogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Logs.LogEntry}
 */
proto.collection.FSMessage.Logs.LogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setIdx(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryTs(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setHash(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPayload(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUserNotification(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLegacyPriorityLevel(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setPriorityLevel(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGlobalIdx(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Logs.LogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Logs.LogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Logs.LogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeSint64(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeUint64(
      11,
      f
    );
  }
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 idx = 2;
 * @return {number}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setIdx = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearIdx = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasIdx = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string entry_ts = 3;
 * @return {string}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getEntryTs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setEntryTs = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearEntryTs = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasEntryTs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes hash = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getHash = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes hash = 4;
 * This is a type-conversion wrapper around `getHash()`
 * @return {string}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getHash_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getHash()));
};


/**
 * optional bytes hash = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getHash()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getHash_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getHash()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setHash = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearHash = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasHash = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 key_version = 5;
 * @return {number}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes payload = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getPayload = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes payload = 6;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getPayload_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPayload()));
};


/**
 * optional bytes payload = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getPayload_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPayload()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setPayload = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearPayload = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasPayload = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool user_notification = 7;
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getUserNotification = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setUserNotification = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearUserNotification = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasUserNotification = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 legacy_priority_level = 8;
 * @return {number}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getLegacyPriorityLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setLegacyPriorityLevel = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearLegacyPriorityLevel = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasLegacyPriorityLevel = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint64 version = 9;
 * @return {number}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional sint64 priority_level = 10;
 * @return {number}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getPriorityLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setPriorityLevel = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearPriorityLevel = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasPriorityLevel = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional uint64 global_idx = 11;
 * @return {number}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.getGlobalIdx = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.setGlobalIdx = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs.LogEntry} returns this
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.clearGlobalIdx = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.LogEntry.prototype.hasGlobalIdx = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional uint64 total_logs = 1;
 * @return {number}
 */
proto.collection.FSMessage.Logs.prototype.getTotalLogs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Logs} returns this
 */
proto.collection.FSMessage.Logs.prototype.setTotalLogs = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Logs} returns this
 */
proto.collection.FSMessage.Logs.prototype.clearTotalLogs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Logs.prototype.hasTotalLogs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LogEntry log_entries = 2;
 * @return {!Array<!proto.collection.FSMessage.Logs.LogEntry>}
 */
proto.collection.FSMessage.Logs.prototype.getLogEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Logs.LogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Logs.LogEntry, 2));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Logs.LogEntry>} value
 * @return {!proto.collection.FSMessage.Logs} returns this
*/
proto.collection.FSMessage.Logs.prototype.setLogEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSMessage.Logs.LogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Logs.LogEntry}
 */
proto.collection.FSMessage.Logs.prototype.addLogEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSMessage.Logs.LogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Logs} returns this
 */
proto.collection.FSMessage.Logs.prototype.clearLogEntriesList = function() {
  return this.setLogEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.LinkView.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.LinkView.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.LinkView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.LinkView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.LinkView.toObject = function(includeInstance, msg) {
  var f, obj = {
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.collection.FSMessage.LinkView.Entry.toObject, includeInstance),
    nextSeq: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    hasMore: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.LinkView}
 */
proto.collection.FSMessage.LinkView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.LinkView;
  return proto.collection.FSMessage.LinkView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.LinkView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.LinkView}
 */
proto.collection.FSMessage.LinkView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.LinkView.Entry;
      reader.readMessage(value,proto.collection.FSMessage.LinkView.Entry.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNextSeq(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasMore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.LinkView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.LinkView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.LinkView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.LinkView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.LinkView.Entry.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.LinkView.Entry.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.LinkView.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.LinkView.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.LinkView.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    name: msg.getName_asB64(),
    linkedCollectionId: msg.getLinkedCollectionId_asB64(),
    pathList: jspb.Message.toObjectList(msg.getPathList(),
    proto.collection.FSMessage.Dir.PathNode.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.LinkView.Entry}
 */
proto.collection.FSMessage.LinkView.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.LinkView.Entry;
  return proto.collection.FSMessage.LinkView.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.LinkView.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.LinkView.Entry}
 */
proto.collection.FSMessage.LinkView.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    case 4:
      var value = new proto.collection.FSMessage.Dir.PathNode;
      reader.readMessage(value,proto.collection.FSMessage.Dir.PathNode.deserializeBinaryFromReader);
      msg.addPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.LinkView.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.LinkView.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.LinkView.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getPathList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.collection.FSMessage.Dir.PathNode.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes name = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes name = 2;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes linked_collection_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes linked_collection_id = 3;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Dir.PathNode path = 4;
 * @return {!Array<!proto.collection.FSMessage.Dir.PathNode>}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.getPathList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Dir.PathNode>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Dir.PathNode, 4));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Dir.PathNode>} value
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
*/
proto.collection.FSMessage.LinkView.Entry.prototype.setPathList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.collection.FSMessage.Dir.PathNode=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Dir.PathNode}
 */
proto.collection.FSMessage.LinkView.Entry.prototype.addPath = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.collection.FSMessage.Dir.PathNode, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.LinkView.Entry} returns this
 */
proto.collection.FSMessage.LinkView.Entry.prototype.clearPathList = function() {
  return this.setPathList([]);
};


/**
 * repeated Entry links = 1;
 * @return {!Array<!proto.collection.FSMessage.LinkView.Entry>}
 */
proto.collection.FSMessage.LinkView.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.LinkView.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.LinkView.Entry, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.LinkView.Entry>} value
 * @return {!proto.collection.FSMessage.LinkView} returns this
*/
proto.collection.FSMessage.LinkView.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.LinkView.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.LinkView.Entry}
 */
proto.collection.FSMessage.LinkView.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.LinkView.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.LinkView} returns this
 */
proto.collection.FSMessage.LinkView.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};


/**
 * optional uint64 next_seq = 2;
 * @return {number}
 */
proto.collection.FSMessage.LinkView.prototype.getNextSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.LinkView} returns this
 */
proto.collection.FSMessage.LinkView.prototype.setNextSeq = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LinkView} returns this
 */
proto.collection.FSMessage.LinkView.prototype.clearNextSeq = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LinkView.prototype.hasNextSeq = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool has_more = 3;
 * @return {boolean}
 */
proto.collection.FSMessage.LinkView.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.LinkView} returns this
 */
proto.collection.FSMessage.LinkView.prototype.setHasMore = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.LinkView} returns this
 */
proto.collection.FSMessage.LinkView.prototype.clearHasMore = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.LinkView.prototype.hasHasMore = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.UnlinkedEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.UnlinkedEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.UnlinkedEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    name: (f = msg.getName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    collectionId: msg.getCollectionId_asB64(),
    protocolVersion: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.UnlinkedEntry}
 */
proto.collection.FSMessage.UnlinkedEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.UnlinkedEntry;
  return proto.collection.FSMessage.UnlinkedEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.UnlinkedEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.UnlinkedEntry}
 */
proto.collection.FSMessage.UnlinkedEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.UnlinkedEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.UnlinkedEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.UnlinkedEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SealedContent name = 2;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 2));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
*/
proto.collection.FSMessage.UnlinkedEntry.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes collection_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes collection_id = 3;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 protocol_version = 4;
 * @return {number}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UnlinkedEntry} returns this
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UnlinkedEntry.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    event: jspb.Message.getFieldWithDefault(msg, 2, 0),
    legacyNode: (f = msg.getLegacyNode()) && proto.collection.FSMessage.Notification.LegacyNode.toObject(includeInstance, f),
    obsoleteRole: (f = msg.getObsoleteRole()) && proto.collection.FSMessage.Role.toObject(includeInstance, f),
    key: (f = msg.getKey()) && proto.collection.FSMessage.Notification.Key.toObject(includeInstance, f),
    userKeyVersion: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    seq: jspb.Message.getFieldWithDefault(msg, 8, 0),
    blockId: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    id: msg.getId_asB64(),
    device: (f = msg.getDevice()) && proto.collection.FSMessage.Notification.Device.toObject(includeInstance, f),
    share: (f = msg.getShare()) && proto.collection.FSMessage.Share.toObject(includeInstance, f),
    permission: (f = msg.getPermission()) && proto.collection.FSMessage.Notification.Permission.toObject(includeInstance, f),
    logEntry: (f = msg.getLogEntry()) && proto.collection.FSMessage.Logs.LogEntry.toObject(includeInstance, f),
    groupPermission: (f = msg.getGroupPermission()) && proto.collection.FSMessage.Notification.GroupPermission.toObject(includeInstance, f),
    userId: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
    collectionAttributes: (f = msg.getCollectionAttributes()) && proto.collection.Collection.Attributes.toObject(includeInstance, f),
    nodeGrant: (f = msg.getNodeGrant()) && proto.collection.FSMessage.Notification.NodeGrant.toObject(includeInstance, f),
    node: (f = msg.getNode()) && proto.collection.FSMessage.Notification.Node.toObject(includeInstance, f),
    topic: (f = jspb.Message.getField(msg, 23)) == null ? undefined : f,
    collectionRename: (f = msg.getCollectionRename()) && proto.collection.FSMessage.Notification.CollectionRename.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification}
 */
proto.collection.FSMessage.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification;
  return proto.collection.FSMessage.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification}
 */
proto.collection.FSMessage.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.FSMessage.Notification.Event} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 4:
      var value = new proto.collection.FSMessage.Notification.LegacyNode;
      reader.readMessage(value,proto.collection.FSMessage.Notification.LegacyNode.deserializeBinaryFromReader);
      msg.setLegacyNode(value);
      break;
    case 5:
      var value = new proto.collection.FSMessage.Role;
      reader.readMessage(value,proto.collection.FSMessage.Role.deserializeBinaryFromReader);
      msg.setObsoleteRole(value);
      break;
    case 6:
      var value = new proto.collection.FSMessage.Notification.Key;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Key.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSeq(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockId(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 12:
      var value = new proto.collection.FSMessage.Notification.Device;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 13:
      var value = new proto.collection.FSMessage.Share;
      reader.readMessage(value,proto.collection.FSMessage.Share.deserializeBinaryFromReader);
      msg.setShare(value);
      break;
    case 14:
      var value = new proto.collection.FSMessage.Notification.Permission;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Permission.deserializeBinaryFromReader);
      msg.setPermission(value);
      break;
    case 15:
      var value = new proto.collection.FSMessage.Logs.LogEntry;
      reader.readMessage(value,proto.collection.FSMessage.Logs.LogEntry.deserializeBinaryFromReader);
      msg.setLogEntry(value);
      break;
    case 16:
      var value = new proto.collection.FSMessage.Notification.GroupPermission;
      reader.readMessage(value,proto.collection.FSMessage.Notification.GroupPermission.deserializeBinaryFromReader);
      msg.setGroupPermission(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 18:
      var value = new proto.collection.Collection.Attributes;
      reader.readMessage(value,proto.collection.Collection.Attributes.deserializeBinaryFromReader);
      msg.setCollectionAttributes(value);
      break;
    case 19:
      var value = new proto.collection.FSMessage.Notification.NodeGrant;
      reader.readMessage(value,proto.collection.FSMessage.Notification.NodeGrant.deserializeBinaryFromReader);
      msg.setNodeGrant(value);
      break;
    case 20:
      var value = new proto.collection.FSMessage.Notification.Node;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Node.deserializeBinaryFromReader);
      msg.setNode(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTopic(value);
      break;
    case 24:
      var value = new proto.collection.FSMessage.Notification.CollectionRename;
      reader.readMessage(value,proto.collection.FSMessage.Notification.CollectionRename.deserializeBinaryFromReader);
      msg.setCollectionRename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.FSMessage.Notification.Event} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLegacyNode();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSMessage.Notification.LegacyNode.serializeBinaryToWriter
    );
  }
  f = message.getObsoleteRole();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.collection.FSMessage.Role.serializeBinaryToWriter
    );
  }
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.collection.FSMessage.Notification.Key.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.collection.FSMessage.Notification.Device.serializeBinaryToWriter
    );
  }
  f = message.getShare();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.collection.FSMessage.Share.serializeBinaryToWriter
    );
  }
  f = message.getPermission();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.collection.FSMessage.Notification.Permission.serializeBinaryToWriter
    );
  }
  f = message.getLogEntry();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.collection.FSMessage.Logs.LogEntry.serializeBinaryToWriter
    );
  }
  f = message.getGroupPermission();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.collection.FSMessage.Notification.GroupPermission.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCollectionAttributes();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.collection.Collection.Attributes.serializeBinaryToWriter
    );
  }
  f = message.getNodeGrant();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.collection.FSMessage.Notification.NodeGrant.serializeBinaryToWriter
    );
  }
  f = message.getNode();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.collection.FSMessage.Notification.Node.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getCollectionRename();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.collection.FSMessage.Notification.CollectionRename.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSMessage.Notification.Event = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2,
  GRANT: 4,
  COLLECTION_KEY: 5,
  WRAPPED_KEY: 6,
  USER_KEY: 7,
  OVERFLOW: 8,
  CREATE_BLOCK: 9,
  DEVICE: 10,
  SHARE: 11,
  HANDLE_SHARE: 12,
  PERMISSIONS: 13,
  LOG_ENTRY: 14,
  GROUP_PERMISSIONS: 15,
  UNSHARE: 16,
  PERMISSIONS_LOST: 17,
  TICK: 18,
  NODE_GRANT: 19,
  NODE_UNSHARE: 20,
  RESTORE: 21,
  COLLECTION_RENAME: 22
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Notification.LegacyNode.repeatedFields_ = [7,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.LegacyNode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.LegacyNode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.LegacyNode.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    version: msg.getVersion_asB64(),
    name: msg.getName_asB64(),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    parentId: msg.getParentId_asB64(),
    parentVer: msg.getParentVer_asB64(),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.collection.FSMessage.Block.toObject, includeInstance),
    oldVersion: msg.getOldVersion_asB64(),
    oldParentId: msg.getOldParentId_asB64(),
    wrappedParentKey: (f = msg.getWrappedParentKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    wrappedSubdirKey: (f = msg.getWrappedSubdirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    linkedCollectionId: msg.getLinkedCollectionId_asB64(),
    oldId: msg.getOldId_asB64(),
    maintainerId: msg.getMaintainerId_asB64(),
    readKeyNodeId: msg.getReadKeyNodeId_asB64(),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    lastUpdate: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 18)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode}
 */
proto.collection.FSMessage.Notification.LegacyNode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.LegacyNode;
  return proto.collection.FSMessage.Notification.LegacyNode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.LegacyNode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode}
 */
proto.collection.FSMessage.Notification.LegacyNode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.collection.FSType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentVer(value);
      break;
    case 7:
      var value = new proto.collection.FSMessage.Block;
      reader.readMessage(value,proto.collection.FSMessage.Block.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldVersion(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldParentId(value);
      break;
    case 10:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedParentKey(value);
      break;
    case 11:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedSubdirKey(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldId(value);
      break;
    case 14:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    case 16:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.LegacyNode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.LegacyNode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.LegacyNode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!proto.collection.FSType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.collection.FSMessage.Block.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getWrappedParentKey();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = message.getWrappedSubdirKey();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBytes(
      14,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeUint64(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes version = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes version = 2;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes name = 3;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FSType type = 4;
 * @return {!proto.collection.FSType}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getType = function() {
  return /** @type {!proto.collection.FSType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.collection.FSType} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes parent_id = 5;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes parent_id = 5;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes parent_ver = 6;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getParentVer = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes parent_ver = 6;
 * This is a type-conversion wrapper around `getParentVer()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getParentVer_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentVer()));
};


/**
 * optional bytes parent_ver = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentVer()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getParentVer_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentVer()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setParentVer = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearParentVer = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasParentVer = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Block data = 7;
 * @return {!Array<!proto.collection.FSMessage.Block>}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getDataList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Block, 7));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Block>} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
*/
proto.collection.FSMessage.Notification.LegacyNode.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.collection.FSMessage.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Block}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.collection.FSMessage.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * optional bytes old_version = 8;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes old_version = 8;
 * This is a type-conversion wrapper around `getOldVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldVersion()));
};


/**
 * optional bytes old_version = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setOldVersion = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearOldVersion = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasOldVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes old_parent_id = 9;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes old_parent_id = 9;
 * This is a type-conversion wrapper around `getOldParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldParentId()));
};


/**
 * optional bytes old_parent_id = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setOldParentId = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearOldParentId = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasOldParentId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional FSWrappedKey wrapped_parent_key = 10;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getWrappedParentKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 10));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
*/
proto.collection.FSMessage.Notification.LegacyNode.prototype.setWrappedParentKey = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearWrappedParentKey = function() {
  return this.setWrappedParentKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasWrappedParentKey = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional FSWrappedKey wrapped_subdir_key = 11;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getWrappedSubdirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 11));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
*/
proto.collection.FSMessage.Notification.LegacyNode.prototype.setWrappedSubdirKey = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearWrappedSubdirKey = function() {
  return this.setWrappedSubdirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasWrappedSubdirKey = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bytes linked_collection_id = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes linked_collection_id = 12;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bytes old_id = 13;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes old_id = 13;
 * This is a type-conversion wrapper around `getOldId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldId()));
};


/**
 * optional bytes old_id = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getOldId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setOldId = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearOldId = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasOldId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bytes maintainer_id = 14;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * optional bytes maintainer_id = 14;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 14;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bytes read_key_node_id = 15;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes read_key_node_id = 15;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated Node.Grant acl = 16;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 16));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
*/
proto.collection.FSMessage.Notification.LegacyNode.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearAclList = function() {
  return this.setAclList([]);
};


/**
 * optional uint64 last_update = 17;
 * @return {number}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setLastUpdate = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearLastUpdate = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string last_local_fs_update = 18;
 * @return {string}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.LegacyNode} returns this
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.LegacyNode.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 18) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.collection.FSMessage.Notification.Node.oneofGroups_ = [[8,9,10]];

/**
 * @enum {number}
 */
proto.collection.FSMessage.Notification.Node.DataCase = {
  DATA_NOT_SET: 0,
  FILE: 8,
  DIR: 9,
  LINK: 10
};

/**
 * @return {proto.collection.FSMessage.Notification.Node.DataCase}
 */
proto.collection.FSMessage.Notification.Node.prototype.getDataCase = function() {
  return /** @type {proto.collection.FSMessage.Notification.Node.DataCase} */(jspb.Message.computeOneofCase(this, proto.collection.FSMessage.Notification.Node.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Node.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Node.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Node} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId_asB64(),
    parentId: msg.getParentId_asB64(),
    version: msg.getVersion_asB64(),
    wrappedParentKey: (f = msg.getWrappedParentKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    lastUpdate: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    name: msg.getName_asB64(),
    file: (f = msg.getFile()) && proto.collection.FSMessage.Notification.Node.File.toObject(includeInstance, f),
    dir: (f = msg.getDir()) && proto.collection.FSMessage.Notification.Node.Dir.toObject(includeInstance, f),
    link: (f = msg.getLink()) && proto.collection.FSMessage.Notification.Node.Link.toObject(includeInstance, f),
    oldId: msg.getOldId_asB64(),
    oldVersion: msg.getOldVersion_asB64(),
    oldParentId: msg.getOldParentId_asB64(),
    lastUpdateMsecs: jspb.Message.getFieldWithDefault(msg, 14, 0),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64(),
    lastLocalFsUpdate: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Node}
 */
proto.collection.FSMessage.Notification.Node.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Node;
  return proto.collection.FSMessage.Notification.Node.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Node} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Node}
 */
proto.collection.FSMessage.Notification.Node.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setVersion(value);
      break;
    case 4:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedParentKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLastUpdate(value);
      break;
    case 6:
      var value = /** @type {!proto.collection.FSType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setName(value);
      break;
    case 8:
      var value = new proto.collection.FSMessage.Notification.Node.File;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Node.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 9:
      var value = new proto.collection.FSMessage.Notification.Node.Dir;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Node.Dir.deserializeBinaryFromReader);
      msg.setDir(value);
      break;
    case 10:
      var value = new proto.collection.FSMessage.Notification.Node.Link;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Node.Link.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldId(value);
      break;
    case 12:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldVersion(value);
      break;
    case 13:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOldParentId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUpdateMsecs(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastLocalFsUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Node.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Node} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getWrappedParentKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = /** @type {!proto.collection.FSType} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.collection.FSMessage.Notification.Node.File.serializeBinaryToWriter
    );
  }
  f = message.getDir();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.collection.FSMessage.Notification.Node.Dir.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.collection.FSMessage.Notification.Node.Link.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBytes(
      12,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBytes(
      13,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Notification.Node.File.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Node.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Node.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.collection.FSMessage.Block.toObject, includeInstance),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    maintainerId: msg.getMaintainerId_asB64(),
    readKeyNodeId: msg.getReadKeyNodeId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Node.File}
 */
proto.collection.FSMessage.Notification.Node.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Node.File;
  return proto.collection.FSMessage.Notification.Node.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Node.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Node.File}
 */
proto.collection.FSMessage.Notification.Node.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.Block;
      reader.readMessage(value,proto.collection.FSMessage.Block.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    case 2:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Node.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Node.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.Block.serializeBinaryToWriter
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * repeated Block data = 1;
 * @return {!Array<!proto.collection.FSMessage.Block>}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getDataList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Block>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Block, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Block>} value
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
*/
proto.collection.FSMessage.Notification.Node.File.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.Block=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Block}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.Block, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
 */
proto.collection.FSMessage.Notification.Node.File.prototype.clearDataList = function() {
  return this.setDataList([]);
};


/**
 * repeated Node.Grant acl = 2;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 2));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
*/
proto.collection.FSMessage.Notification.Node.File.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
 */
proto.collection.FSMessage.Notification.Node.File.prototype.clearAclList = function() {
  return this.setAclList([]);
};


/**
 * optional bytes maintainer_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes maintainer_id = 3;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
 */
proto.collection.FSMessage.Notification.Node.File.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
 */
proto.collection.FSMessage.Notification.Node.File.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes read_key_node_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes read_key_node_id = 4;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
 */
proto.collection.FSMessage.Notification.Node.File.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node.File} returns this
 */
proto.collection.FSMessage.Notification.Node.File.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.File.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Notification.Node.Dir.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Node.Dir.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Node.Dir} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.Dir.toObject = function(includeInstance, msg) {
  var f, obj = {
    wrappedSubdirKey: (f = msg.getWrappedSubdirKey()) && proto.collection.FSWrappedKey.toObject(includeInstance, f),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    maintainerId: msg.getMaintainerId_asB64(),
    readKeyNodeId: msg.getReadKeyNodeId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Node.Dir}
 */
proto.collection.FSMessage.Notification.Node.Dir.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Node.Dir;
  return proto.collection.FSMessage.Notification.Node.Dir.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Node.Dir} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Node.Dir}
 */
proto.collection.FSMessage.Notification.Node.Dir.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSWrappedKey;
      reader.readMessage(value,proto.collection.FSWrappedKey.deserializeBinaryFromReader);
      msg.setWrappedSubdirKey(value);
      break;
    case 2:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMaintainerId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReadKeyNodeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Node.Dir.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Node.Dir} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.Dir.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWrappedSubdirKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.collection.FSWrappedKey.serializeBinaryToWriter
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional FSWrappedKey wrapped_subdir_key = 1;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getWrappedSubdirKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 1));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
*/
proto.collection.FSMessage.Notification.Node.Dir.prototype.setWrappedSubdirKey = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.clearWrappedSubdirKey = function() {
  return this.setWrappedSubdirKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.hasWrappedSubdirKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Node.Grant acl = 2;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 2));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
*/
proto.collection.FSMessage.Notification.Node.Dir.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.clearAclList = function() {
  return this.setAclList([]);
};


/**
 * optional bytes maintainer_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getMaintainerId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes maintainer_id = 3;
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getMaintainerId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMaintainerId()));
};


/**
 * optional bytes maintainer_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMaintainerId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getMaintainerId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMaintainerId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.setMaintainerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.clearMaintainerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.hasMaintainerId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes read_key_node_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getReadKeyNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes read_key_node_id = 4;
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getReadKeyNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReadKeyNodeId()));
};


/**
 * optional bytes read_key_node_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReadKeyNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.getReadKeyNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReadKeyNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.setReadKeyNodeId = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node.Dir} returns this
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.clearReadKeyNodeId = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.Dir.prototype.hasReadKeyNodeId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Node.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Node.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkedCollectionId: msg.getLinkedCollectionId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Node.Link}
 */
proto.collection.FSMessage.Notification.Node.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Node.Link;
  return proto.collection.FSMessage.Notification.Node.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Node.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Node.Link}
 */
proto.collection.FSMessage.Notification.Node.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLinkedCollectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Node.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Node.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Node.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes linked_collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.getLinkedCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes linked_collection_id = 1;
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.getLinkedCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLinkedCollectionId()));
};


/**
 * optional bytes linked_collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLinkedCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.getLinkedCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLinkedCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node.Link} returns this
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.setLinkedCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node.Link} returns this
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.clearLinkedCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.Link.prototype.hasLinkedCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes id = 1;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes parent_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes parent_id = 2;
 * This is a type-conversion wrapper around `getParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getParentId()));
};


/**
 * optional bytes parent_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setParentId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearParentId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasParentId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes version = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes version = 3;
 * This is a type-conversion wrapper around `getVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getVersion()));
};


/**
 * optional bytes version = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FSWrappedKey wrapped_parent_key = 4;
 * @return {?proto.collection.FSWrappedKey}
 */
proto.collection.FSMessage.Notification.Node.prototype.getWrappedParentKey = function() {
  return /** @type{?proto.collection.FSWrappedKey} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSWrappedKey, 4));
};


/**
 * @param {?proto.collection.FSWrappedKey|undefined} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
*/
proto.collection.FSMessage.Notification.Node.prototype.setWrappedParentKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearWrappedParentKey = function() {
  return this.setWrappedParentKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasWrappedParentKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 last_update = 5;
 * @return {number}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLastUpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setLastUpdate = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearLastUpdate = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasLastUpdate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FSType type = 6;
 * @return {!proto.collection.FSType}
 */
proto.collection.FSMessage.Notification.Node.prototype.getType = function() {
  return /** @type {!proto.collection.FSType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.collection.FSType} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setType = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearType = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes name = 7;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes name = 7;
 * This is a type-conversion wrapper around `getName()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getName()));
};


/**
 * optional bytes name = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional File file = 8;
 * @return {?proto.collection.FSMessage.Notification.Node.File}
 */
proto.collection.FSMessage.Notification.Node.prototype.getFile = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Node.File} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Node.File, 8));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Node.File|undefined} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
*/
proto.collection.FSMessage.Notification.Node.prototype.setFile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.collection.FSMessage.Notification.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasFile = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Dir dir = 9;
 * @return {?proto.collection.FSMessage.Notification.Node.Dir}
 */
proto.collection.FSMessage.Notification.Node.prototype.getDir = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Node.Dir} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Node.Dir, 9));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Node.Dir|undefined} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
*/
proto.collection.FSMessage.Notification.Node.prototype.setDir = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.collection.FSMessage.Notification.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearDir = function() {
  return this.setDir(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasDir = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Link link = 10;
 * @return {?proto.collection.FSMessage.Notification.Node.Link}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLink = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Node.Link} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Node.Link, 10));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Node.Link|undefined} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
*/
proto.collection.FSMessage.Notification.Node.prototype.setLink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.collection.FSMessage.Notification.Node.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasLink = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bytes old_id = 11;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes old_id = 11;
 * This is a type-conversion wrapper around `getOldId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldId()));
};


/**
 * optional bytes old_id = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setOldId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearOldId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasOldId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bytes old_version = 12;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * optional bytes old_version = 12;
 * This is a type-conversion wrapper around `getOldVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldVersion()));
};


/**
 * optional bytes old_version = 12;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setOldVersion = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearOldVersion = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasOldVersion = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bytes old_parent_id = 13;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldParentId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * optional bytes old_parent_id = 13;
 * This is a type-conversion wrapper around `getOldParentId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldParentId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOldParentId()));
};


/**
 * optional bytes old_parent_id = 13;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOldParentId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getOldParentId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOldParentId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setOldParentId = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearOldParentId = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasOldParentId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional uint32 last_update_msecs = 14;
 * @return {number}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLastUpdateMsecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setLastUpdateMsecs = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearLastUpdateMsecs = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasLastUpdateMsecs = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bytes last_modified_device_id = 15;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes last_modified_device_id = 15;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string last_local_fs_update = 16;
 * @return {string}
 */
proto.collection.FSMessage.Notification.Node.prototype.getLastLocalFsUpdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.setLastLocalFsUpdate = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Node} returns this
 */
proto.collection.FSMessage.Notification.Node.prototype.clearLastLocalFsUpdate = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Node.prototype.hasLastLocalFsUpdate = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Key.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    version: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Key}
 */
proto.collection.FSMessage.Notification.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Key;
  return proto.collection.FSMessage.Notification.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Key}
 */
proto.collection.FSMessage.Notification.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSMessage.Notification.Key.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSMessage.Notification.Key} returns this
 */
proto.collection.FSMessage.Notification.Key.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Key} returns this
 */
proto.collection.FSMessage.Notification.Key.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Key.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 version = 2;
 * @return {number}
 */
proto.collection.FSMessage.Notification.Key.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.Key} returns this
 */
proto.collection.FSMessage.Notification.Key.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Key} returns this
 */
proto.collection.FSMessage.Notification.Key.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Key.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    token: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Device}
 */
proto.collection.FSMessage.Notification.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Device;
  return proto.collection.FSMessage.Notification.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Device}
 */
proto.collection.FSMessage.Notification.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSMessage.Notification.Device.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {!proto.collection.FSMessage.Notification.Device.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSMessage.Notification.Device.Action} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {!proto.collection.FSMessage.Notification.Device.Type} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.collection.FSMessage.Notification.Device.Action = {
  REGISTER: 0,
  DEREGISTER: 1
};

/**
 * @enum {number}
 */
proto.collection.FSMessage.Notification.Device.Type = {
  APNS: 0,
  FCM: 1
};

/**
 * optional Action action = 1;
 * @return {!proto.collection.FSMessage.Notification.Device.Action}
 */
proto.collection.FSMessage.Notification.Device.prototype.getAction = function() {
  return /** @type {!proto.collection.FSMessage.Notification.Device.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSMessage.Notification.Device.Action} value
 * @return {!proto.collection.FSMessage.Notification.Device} returns this
 */
proto.collection.FSMessage.Notification.Device.prototype.setAction = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Device} returns this
 */
proto.collection.FSMessage.Notification.Device.prototype.clearAction = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Device.prototype.hasAction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Type type = 2;
 * @return {!proto.collection.FSMessage.Notification.Device.Type}
 */
proto.collection.FSMessage.Notification.Device.prototype.getType = function() {
  return /** @type {!proto.collection.FSMessage.Notification.Device.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.collection.FSMessage.Notification.Device.Type} value
 * @return {!proto.collection.FSMessage.Notification.Device} returns this
 */
proto.collection.FSMessage.Notification.Device.prototype.setType = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Device} returns this
 */
proto.collection.FSMessage.Notification.Device.prototype.clearType = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Device.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string token = 3;
 * @return {string}
 */
proto.collection.FSMessage.Notification.Device.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Notification.Device} returns this
 */
proto.collection.FSMessage.Notification.Device.prototype.setToken = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Device} returns this
 */
proto.collection.FSMessage.Notification.Device.prototype.clearToken = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Device.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Notification.Permission.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    userKeyVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    proto.collection.FSMessage.Notification.Permission.RoleInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Permission}
 */
proto.collection.FSMessage.Notification.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Permission;
  return proto.collection.FSMessage.Notification.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Permission}
 */
proto.collection.FSMessage.Notification.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUserKeyVersion(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.Notification.Permission.RoleInfo;
      reader.readMessage(value,proto.collection.FSMessage.Notification.Permission.RoleInfo.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSMessage.Notification.Permission.RoleInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.Permission.RoleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.Permission.RoleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: jspb.Message.getFieldWithDefault(msg, 1, 0),
    version: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    wrappedKey: msg.getWrappedKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.Permission.RoleInfo;
  return proto.collection.FSMessage.Notification.Permission.RoleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.Permission.RoleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.collection.FSRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.Permission.RoleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.Permission.RoleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.collection.FSRole} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional FSRole role = 1;
 * @return {!proto.collection.FSRole}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.getRole = function() {
  return /** @type {!proto.collection.FSRole} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSRole} value
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo} returns this
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.setRole = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo} returns this
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.clearRole = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 version = 2;
 * @return {number}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo} returns this
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo} returns this
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo} returns this
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo} returns this
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Permission.RoleInfo.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 user_key_version = 1;
 * @return {number}
 */
proto.collection.FSMessage.Notification.Permission.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.Permission} returns this
 */
proto.collection.FSMessage.Notification.Permission.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.Permission} returns this
 */
proto.collection.FSMessage.Notification.Permission.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.Permission.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated RoleInfo roles = 2;
 * @return {!Array<!proto.collection.FSMessage.Notification.Permission.RoleInfo>}
 */
proto.collection.FSMessage.Notification.Permission.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Notification.Permission.RoleInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Notification.Permission.RoleInfo, 2));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Notification.Permission.RoleInfo>} value
 * @return {!proto.collection.FSMessage.Notification.Permission} returns this
*/
proto.collection.FSMessage.Notification.Permission.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSMessage.Notification.Permission.RoleInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Notification.Permission.RoleInfo}
 */
proto.collection.FSMessage.Notification.Permission.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSMessage.Notification.Permission.RoleInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.Permission} returns this
 */
proto.collection.FSMessage.Notification.Permission.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.GroupPermission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.GroupPermission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.GroupPermission.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: msg.getGroupId_asB64(),
    version: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    wrappedKey: msg.getWrappedKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.GroupPermission}
 */
proto.collection.FSMessage.Notification.GroupPermission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.GroupPermission;
  return proto.collection.FSMessage.Notification.GroupPermission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.GroupPermission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.GroupPermission}
 */
proto.collection.FSMessage.Notification.GroupPermission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWrappedKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.GroupPermission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.GroupPermission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.GroupPermission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes group_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getGroupId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes group_id = 1;
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getGroupId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getGroupId()));
};


/**
 * optional bytes group_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getGroupId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getGroupId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getGroupId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.GroupPermission} returns this
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.setGroupId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.GroupPermission} returns this
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.clearGroupId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.hasGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 version = 2;
 * @return {number}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification.GroupPermission} returns this
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.setVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.GroupPermission} returns this
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.clearVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes wrapped_key = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getWrappedKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes wrapped_key = 3;
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getWrappedKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWrappedKey()));
};


/**
 * optional bytes wrapped_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWrappedKey()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.getWrappedKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWrappedKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.GroupPermission} returns this
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.setWrappedKey = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.GroupPermission} returns this
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.clearWrappedKey = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.GroupPermission.prototype.hasWrappedKey = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Notification.NodeGrant.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.NodeGrant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.NodeGrant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.NodeGrant.toObject = function(includeInstance, msg) {
  var f, obj = {
    nodeId: msg.getNodeId_asB64(),
    aclList: jspb.Message.toObjectList(msg.getAclList(),
    proto.collection.Node.Grant.toObject, includeInstance),
    nodeVersion: msg.getNodeVersion_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.NodeGrant}
 */
proto.collection.FSMessage.Notification.NodeGrant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.NodeGrant;
  return proto.collection.FSMessage.Notification.NodeGrant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.NodeGrant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.NodeGrant}
 */
proto.collection.FSMessage.Notification.NodeGrant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNodeId(value);
      break;
    case 2:
      var value = new proto.collection.Node.Grant;
      reader.readMessage(value,proto.collection.Node.Grant.deserializeBinaryFromReader);
      msg.addAcl(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNodeVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.NodeGrant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.NodeGrant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.NodeGrant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAclList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.Node.Grant.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes node_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes node_id = 1;
 * This is a type-conversion wrapper around `getNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNodeId()));
};


/**
 * optional bytes node_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.NodeGrant} returns this
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.setNodeId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.NodeGrant} returns this
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.clearNodeId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.hasNodeId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Node.Grant acl = 2;
 * @return {!Array<!proto.collection.Node.Grant>}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getAclList = function() {
  return /** @type{!Array<!proto.collection.Node.Grant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.Node.Grant, 2));
};


/**
 * @param {!Array<!proto.collection.Node.Grant>} value
 * @return {!proto.collection.FSMessage.Notification.NodeGrant} returns this
*/
proto.collection.FSMessage.Notification.NodeGrant.prototype.setAclList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.Node.Grant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.Node.Grant}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.addAcl = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.Node.Grant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Notification.NodeGrant} returns this
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.clearAclList = function() {
  return this.setAclList([]);
};


/**
 * optional bytes node_version = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getNodeVersion = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes node_version = 3;
 * This is a type-conversion wrapper around `getNodeVersion()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getNodeVersion_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNodeVersion()));
};


/**
 * optional bytes node_version = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNodeVersion()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.getNodeVersion_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNodeVersion()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.NodeGrant} returns this
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.setNodeVersion = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.NodeGrant} returns this
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.clearNodeVersion = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.NodeGrant.prototype.hasNodeVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Notification.CollectionRename.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Notification.CollectionRename} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.CollectionRename.toObject = function(includeInstance, msg) {
  var f, obj = {
    newName: (f = msg.getNewName()) && proto.collection.SealedContent.toObject(includeInstance, f),
    lastModifiedDeviceId: msg.getLastModifiedDeviceId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Notification.CollectionRename}
 */
proto.collection.FSMessage.Notification.CollectionRename.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Notification.CollectionRename;
  return proto.collection.FSMessage.Notification.CollectionRename.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Notification.CollectionRename} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Notification.CollectionRename}
 */
proto.collection.FSMessage.Notification.CollectionRename.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setNewName(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setLastModifiedDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Notification.CollectionRename.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Notification.CollectionRename} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Notification.CollectionRename.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional SealedContent new_name = 1;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.getNewName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 1));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.Notification.CollectionRename} returns this
*/
proto.collection.FSMessage.Notification.CollectionRename.prototype.setNewName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.CollectionRename} returns this
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.clearNewName = function() {
  return this.setNewName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.hasNewName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes last_modified_device_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.getLastModifiedDeviceId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes last_modified_device_id = 2;
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.getLastModifiedDeviceId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getLastModifiedDeviceId()));
};


/**
 * optional bytes last_modified_device_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getLastModifiedDeviceId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.getLastModifiedDeviceId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getLastModifiedDeviceId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification.CollectionRename} returns this
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.setLastModifiedDeviceId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification.CollectionRename} returns this
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.clearLastModifiedDeviceId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.CollectionRename.prototype.hasLastModifiedDeviceId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event event = 2;
 * @return {!proto.collection.FSMessage.Notification.Event}
 */
proto.collection.FSMessage.Notification.prototype.getEvent = function() {
  return /** @type {!proto.collection.FSMessage.Notification.Event} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.collection.FSMessage.Notification.Event} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setEvent = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearEvent = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LegacyNode legacy_node = 4;
 * @return {?proto.collection.FSMessage.Notification.LegacyNode}
 */
proto.collection.FSMessage.Notification.prototype.getLegacyNode = function() {
  return /** @type{?proto.collection.FSMessage.Notification.LegacyNode} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.LegacyNode, 4));
};


/**
 * @param {?proto.collection.FSMessage.Notification.LegacyNode|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setLegacyNode = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearLegacyNode = function() {
  return this.setLegacyNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasLegacyNode = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Role OBSOLETE_role = 5;
 * @return {?proto.collection.FSMessage.Role}
 */
proto.collection.FSMessage.Notification.prototype.getObsoleteRole = function() {
  return /** @type{?proto.collection.FSMessage.Role} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Role, 5));
};


/**
 * @param {?proto.collection.FSMessage.Role|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setObsoleteRole = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearObsoleteRole = function() {
  return this.setObsoleteRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasObsoleteRole = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Key key = 6;
 * @return {?proto.collection.FSMessage.Notification.Key}
 */
proto.collection.FSMessage.Notification.prototype.getKey = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Key} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Key, 6));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Key|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasKey = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint64 user_key_version = 7;
 * @return {number}
 */
proto.collection.FSMessage.Notification.prototype.getUserKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setUserKeyVersion = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearUserKeyVersion = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasUserKeyVersion = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 seq = 8;
 * @return {number}
 */
proto.collection.FSMessage.Notification.prototype.getSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setSeq = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearSeq = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasSeq = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string block_id = 10;
 * @return {string}
 */
proto.collection.FSMessage.Notification.prototype.getBlockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setBlockId = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearBlockId = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasBlockId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bytes id = 11;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.Notification.prototype.getId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes id = 11;
 * This is a type-conversion wrapper around `getId()`
 * @return {string}
 */
proto.collection.FSMessage.Notification.prototype.getId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getId()));
};


/**
 * optional bytes id = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Notification.prototype.getId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setId = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearId = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Device device = 12;
 * @return {?proto.collection.FSMessage.Notification.Device}
 */
proto.collection.FSMessage.Notification.prototype.getDevice = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Device} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Device, 12));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Device|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional Share share = 13;
 * @return {?proto.collection.FSMessage.Share}
 */
proto.collection.FSMessage.Notification.prototype.getShare = function() {
  return /** @type{?proto.collection.FSMessage.Share} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Share, 13));
};


/**
 * @param {?proto.collection.FSMessage.Share|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setShare = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearShare = function() {
  return this.setShare(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasShare = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Permission permission = 14;
 * @return {?proto.collection.FSMessage.Notification.Permission}
 */
proto.collection.FSMessage.Notification.prototype.getPermission = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Permission} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Permission, 14));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Permission|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setPermission = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearPermission = function() {
  return this.setPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional Logs.LogEntry log_entry = 15;
 * @return {?proto.collection.FSMessage.Logs.LogEntry}
 */
proto.collection.FSMessage.Notification.prototype.getLogEntry = function() {
  return /** @type{?proto.collection.FSMessage.Logs.LogEntry} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Logs.LogEntry, 15));
};


/**
 * @param {?proto.collection.FSMessage.Logs.LogEntry|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setLogEntry = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearLogEntry = function() {
  return this.setLogEntry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasLogEntry = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional GroupPermission group_permission = 16;
 * @return {?proto.collection.FSMessage.Notification.GroupPermission}
 */
proto.collection.FSMessage.Notification.prototype.getGroupPermission = function() {
  return /** @type{?proto.collection.FSMessage.Notification.GroupPermission} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.GroupPermission, 16));
};


/**
 * @param {?proto.collection.FSMessage.Notification.GroupPermission|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setGroupPermission = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearGroupPermission = function() {
  return this.setGroupPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasGroupPermission = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string user_id = 17;
 * @return {string}
 */
proto.collection.FSMessage.Notification.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Collection.Attributes collection_attributes = 18;
 * @return {?proto.collection.Collection.Attributes}
 */
proto.collection.FSMessage.Notification.prototype.getCollectionAttributes = function() {
  return /** @type{?proto.collection.Collection.Attributes} */ (
    jspb.Message.getWrapperField(this, proto.collection.Collection.Attributes, 18));
};


/**
 * @param {?proto.collection.Collection.Attributes|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setCollectionAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearCollectionAttributes = function() {
  return this.setCollectionAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasCollectionAttributes = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional NodeGrant node_grant = 19;
 * @return {?proto.collection.FSMessage.Notification.NodeGrant}
 */
proto.collection.FSMessage.Notification.prototype.getNodeGrant = function() {
  return /** @type{?proto.collection.FSMessage.Notification.NodeGrant} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.NodeGrant, 19));
};


/**
 * @param {?proto.collection.FSMessage.Notification.NodeGrant|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setNodeGrant = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearNodeGrant = function() {
  return this.setNodeGrant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasNodeGrant = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Node node = 20;
 * @return {?proto.collection.FSMessage.Notification.Node}
 */
proto.collection.FSMessage.Notification.prototype.getNode = function() {
  return /** @type{?proto.collection.FSMessage.Notification.Node} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.Node, 20));
};


/**
 * @param {?proto.collection.FSMessage.Notification.Node|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setNode = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearNode = function() {
  return this.setNode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasNode = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string topic = 23;
 * @return {string}
 */
proto.collection.FSMessage.Notification.prototype.getTopic = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.setTopic = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearTopic = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasTopic = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional CollectionRename collection_rename = 24;
 * @return {?proto.collection.FSMessage.Notification.CollectionRename}
 */
proto.collection.FSMessage.Notification.prototype.getCollectionRename = function() {
  return /** @type{?proto.collection.FSMessage.Notification.CollectionRename} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification.CollectionRename, 24));
};


/**
 * @param {?proto.collection.FSMessage.Notification.CollectionRename|undefined} value
 * @return {!proto.collection.FSMessage.Notification} returns this
*/
proto.collection.FSMessage.Notification.prototype.setCollectionRename = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.Notification} returns this
 */
proto.collection.FSMessage.Notification.prototype.clearCollectionRename = function() {
  return this.setCollectionRename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.Notification.prototype.hasCollectionRename = function() {
  return jspb.Message.getField(this, 24) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.OrgPermissions.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.OrgPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.OrgPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.OrgPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.OrgPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    encCollectionName: msg.getEncCollectionName_asB64(),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.collection.FSMessage.User.toObject, includeInstance),
    collectionName: (f = msg.getCollectionName()) && proto.collection.SealedContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.OrgPermissions}
 */
proto.collection.FSMessage.OrgPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.OrgPermissions;
  return proto.collection.FSMessage.OrgPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.OrgPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.OrgPermissions}
 */
proto.collection.FSMessage.OrgPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncCollectionName(value);
      break;
    case 3:
      var value = new proto.collection.FSMessage.User;
      reader.readMessage(value,proto.collection.FSMessage.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 4:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setCollectionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.OrgPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.OrgPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.OrgPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.OrgPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.FSMessage.User.serializeBinaryToWriter
    );
  }
  f = message.getCollectionName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
 */
proto.collection.FSMessage.OrgPermissions.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
 */
proto.collection.FSMessage.OrgPermissions.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.OrgPermissions.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes enc_collection_name = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getEncCollectionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes enc_collection_name = 2;
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {string}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getEncCollectionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncCollectionName()));
};


/**
 * optional bytes enc_collection_name = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getEncCollectionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncCollectionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
 */
proto.collection.FSMessage.OrgPermissions.prototype.setEncCollectionName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
 */
proto.collection.FSMessage.OrgPermissions.prototype.clearEncCollectionName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.OrgPermissions.prototype.hasEncCollectionName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated User users = 3;
 * @return {!Array<!proto.collection.FSMessage.User>}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.User, 3));
};


/**
 * @param {!Array<!proto.collection.FSMessage.User>} value
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
*/
proto.collection.FSMessage.OrgPermissions.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.FSMessage.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.User}
 */
proto.collection.FSMessage.OrgPermissions.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.FSMessage.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
 */
proto.collection.FSMessage.OrgPermissions.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional SealedContent collection_name = 4;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.OrgPermissions.prototype.getCollectionName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 4));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
*/
proto.collection.FSMessage.OrgPermissions.prototype.setCollectionName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.OrgPermissions} returns this
 */
proto.collection.FSMessage.OrgPermissions.prototype.clearCollectionName = function() {
  return this.setCollectionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.OrgPermissions.prototype.hasCollectionName = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.ACLNodePermissions.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.ACLNodePermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.ACLNodePermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLNodePermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    aclNodeId: msg.getAclNodeId_asB64(),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.collection.FSMessage.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.ACLNodePermissions}
 */
proto.collection.FSMessage.ACLNodePermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.ACLNodePermissions;
  return proto.collection.FSMessage.ACLNodePermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.ACLNodePermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.ACLNodePermissions}
 */
proto.collection.FSMessage.ACLNodePermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAclNodeId(value);
      break;
    case 3:
      var value = new proto.collection.FSMessage.User;
      reader.readMessage(value,proto.collection.FSMessage.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.ACLNodePermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.ACLNodePermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLNodePermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.collection.FSMessage.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.ACLNodePermissions} returns this
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLNodePermissions} returns this
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes acl_node_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getAclNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes acl_node_id = 2;
 * This is a type-conversion wrapper around `getAclNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getAclNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAclNodeId()));
};


/**
 * optional bytes acl_node_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAclNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getAclNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAclNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.ACLNodePermissions} returns this
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.setAclNodeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLNodePermissions} returns this
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.clearAclNodeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.hasAclNodeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated User users = 3;
 * @return {!Array<!proto.collection.FSMessage.User>}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.User, 3));
};


/**
 * @param {!Array<!proto.collection.FSMessage.User>} value
 * @return {!proto.collection.FSMessage.ACLNodePermissions} returns this
*/
proto.collection.FSMessage.ACLNodePermissions.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.collection.FSMessage.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.User}
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.collection.FSMessage.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.ACLNodePermissions} returns this
 */
proto.collection.FSMessage.ACLNodePermissions.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.UserPermissions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.UserPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.UserPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.UserPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.UserPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    userPermissionInfoList: jspb.Message.toObjectList(msg.getUserPermissionInfoList(),
    proto.collection.FSMessage.UserPermissions.UserPermissionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.UserPermissions}
 */
proto.collection.FSMessage.UserPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.UserPermissions;
  return proto.collection.FSMessage.UserPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.UserPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.UserPermissions}
 */
proto.collection.FSMessage.UserPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.UserPermissions.UserPermissionInfo;
      reader.readMessage(value,proto.collection.FSMessage.UserPermissions.UserPermissionInfo.deserializeBinaryFromReader);
      msg.addUserPermissionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UserPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.UserPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.UserPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.UserPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserPermissionInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSMessage.UserPermissions.UserPermissionInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.repeatedFields_ = [1,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.UserPermissions.UserPermissionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    orgIdsList: msg.getOrgIdsList_asB64(),
    collectionId: msg.getCollectionId_asB64(),
    encCollectionName: msg.getEncCollectionName_asB64(),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    pending: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    expirationTime: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    keyVersion: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    collectionName: (f = msg.getCollectionName()) && proto.collection.SealedContent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.UserPermissions.UserPermissionInfo;
  return proto.collection.FSMessage.UserPermissions.UserPermissionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addOrgIds(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setEncCollectionName(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.collection.FSRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 9:
      var value = new proto.collection.SealedContent;
      reader.readMessage(value,proto.collection.SealedContent.deserializeBinaryFromReader);
      msg.setCollectionName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.UserPermissions.UserPermissionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrgIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getCollectionName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.collection.SealedContent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated bytes org_ids = 1;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getOrgIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * repeated bytes org_ids = 1;
 * This is a type-conversion wrapper around `getOrgIdsList()`
 * @return {!Array<string>}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getOrgIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getOrgIdsList()));
};


/**
 * repeated bytes org_ids = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrgIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getOrgIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getOrgIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setOrgIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.addOrgIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearOrgIdsList = function() {
  return this.setOrgIdsList([]);
};


/**
 * optional bytes collection_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes collection_id = 2;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bytes enc_collection_name = 3;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getEncCollectionName = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes enc_collection_name = 3;
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {string}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getEncCollectionName_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getEncCollectionName()));
};


/**
 * optional bytes enc_collection_name = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getEncCollectionName()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getEncCollectionName_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getEncCollectionName()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setEncCollectionName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearEncCollectionName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasEncCollectionName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated FSRole roles = 4;
 * @return {!Array<!proto.collection.FSRole>}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.collection.FSRole>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.collection.FSRole>} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.collection.FSRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional bool pending = 5;
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setPending = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearPending = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasPending = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string expiration_time = 6;
 * @return {string}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool view_only = 7;
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint64 key_version = 8;
 * @return {number}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SealedContent collection_name = 9;
 * @return {?proto.collection.SealedContent}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.getCollectionName = function() {
  return /** @type{?proto.collection.SealedContent} */ (
    jspb.Message.getWrapperField(this, proto.collection.SealedContent, 9));
};


/**
 * @param {?proto.collection.SealedContent|undefined} value
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
*/
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.setCollectionName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo} returns this
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.clearCollectionName = function() {
  return this.setCollectionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.UserPermissionInfo.prototype.hasCollectionName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSMessage.UserPermissions.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.UserPermissions} returns this
 */
proto.collection.FSMessage.UserPermissions.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.UserPermissions} returns this
 */
proto.collection.FSMessage.UserPermissions.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.UserPermissions.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated UserPermissionInfo user_permission_info = 2;
 * @return {!Array<!proto.collection.FSMessage.UserPermissions.UserPermissionInfo>}
 */
proto.collection.FSMessage.UserPermissions.prototype.getUserPermissionInfoList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.UserPermissions.UserPermissionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.UserPermissions.UserPermissionInfo, 2));
};


/**
 * @param {!Array<!proto.collection.FSMessage.UserPermissions.UserPermissionInfo>} value
 * @return {!proto.collection.FSMessage.UserPermissions} returns this
*/
proto.collection.FSMessage.UserPermissions.prototype.setUserPermissionInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.UserPermissions.UserPermissionInfo}
 */
proto.collection.FSMessage.UserPermissions.prototype.addUserPermissionInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSMessage.UserPermissions.UserPermissionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.UserPermissions} returns this
 */
proto.collection.FSMessage.UserPermissions.prototype.clearUserPermissionInfoList = function() {
  return this.setUserPermissionInfoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.ACLUserPermissions.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.ACLUserPermissions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.ACLUserPermissions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLUserPermissions.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    aclUserPermissionInfoList: jspb.Message.toObjectList(msg.getAclUserPermissionInfoList(),
    proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.ACLUserPermissions}
 */
proto.collection.FSMessage.ACLUserPermissions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.ACLUserPermissions;
  return proto.collection.FSMessage.ACLUserPermissions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.ACLUserPermissions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.ACLUserPermissions}
 */
proto.collection.FSMessage.ACLUserPermissions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo;
      reader.readMessage(value,proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.deserializeBinaryFromReader);
      msg.addAclUserPermissionInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.ACLUserPermissions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.ACLUserPermissions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLUserPermissions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAclUserPermissionInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: msg.getCollectionId_asB64(),
    aclNodeId: msg.getAclNodeId_asB64(),
    aclRolesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    expirationTime: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    keyVersion: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo;
  return proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAclNodeId(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.collection.Node.ACLRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAclRoles(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getAclRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional bytes collection_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes collection_id = 1;
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCollectionId()));
};


/**
 * optional bytes collection_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.setCollectionId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.clearCollectionId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.hasCollectionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes acl_node_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getAclNodeId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes acl_node_id = 2;
 * This is a type-conversion wrapper around `getAclNodeId()`
 * @return {string}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getAclNodeId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAclNodeId()));
};


/**
 * optional bytes acl_node_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAclNodeId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getAclNodeId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAclNodeId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.setAclNodeId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.clearAclNodeId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.hasAclNodeId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Node.ACLRole acl_roles = 3;
 * @return {!Array<!proto.collection.Node.ACLRole>}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getAclRolesList = function() {
  return /** @type {!Array<!proto.collection.Node.ACLRole>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.collection.Node.ACLRole>} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.setAclRolesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.addAclRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.clearAclRolesList = function() {
  return this.setAclRolesList([]);
};


/**
 * optional string expiration_time = 4;
 * @return {string}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool view_only = 5;
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 key_version = 6;
 * @return {number}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.ACLUserPermissions} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ACLUserPermissionInfo acl_user_permission_info = 2;
 * @return {!Array<!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo>}
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.getAclUserPermissionInfoList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo, 2));
};


/**
 * @param {!Array<!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo>} value
 * @return {!proto.collection.FSMessage.ACLUserPermissions} returns this
*/
proto.collection.FSMessage.ACLUserPermissions.prototype.setAclUserPermissionInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo}
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.addAclUserPermissionInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.collection.FSMessage.ACLUserPermissions.ACLUserPermissionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.ACLUserPermissions} returns this
 */
proto.collection.FSMessage.ACLUserPermissions.prototype.clearAclUserPermissionInfoList = function() {
  return this.setAclUserPermissionInfoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.Links.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.Links.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.Links.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.Links} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Links.toObject = function(includeInstance, msg) {
  var f, obj = {
    linksList: jspb.Message.toObjectList(msg.getLinksList(),
    proto.collection.FSMessage.Link.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.Links}
 */
proto.collection.FSMessage.Links.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.Links;
  return proto.collection.FSMessage.Links.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.Links} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.Links}
 */
proto.collection.FSMessage.Links.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.Link;
      reader.readMessage(value,proto.collection.FSMessage.Link.deserializeBinaryFromReader);
      msg.addLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.Links.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.Links.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.Links} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.Links.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.Link.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Link links = 1;
 * @return {!Array<!proto.collection.FSMessage.Link>}
 */
proto.collection.FSMessage.Links.prototype.getLinksList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Link>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Link, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Link>} value
 * @return {!proto.collection.FSMessage.Links} returns this
*/
proto.collection.FSMessage.Links.prototype.setLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.Link=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Link}
 */
proto.collection.FSMessage.Links.prototype.addLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.Link, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.Links} returns this
 */
proto.collection.FSMessage.Links.prototype.clearLinksList = function() {
  return this.setLinksList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.collection.FSMessage.BlockData.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.collection.FSMessage.BlockData.DataCase = {
  DATA_NOT_SET: 0,
  INLINE_DATA: 2,
  URL: 3
};

/**
 * @return {proto.collection.FSMessage.BlockData.DataCase}
 */
proto.collection.FSMessage.BlockData.prototype.getDataCase = function() {
  return /** @type {proto.collection.FSMessage.BlockData.DataCase} */(jspb.Message.computeOneofCase(this, proto.collection.FSMessage.BlockData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.BlockData.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.BlockData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.BlockData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.BlockData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    inlineData: msg.getInlineData_asB64(),
    url: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.BlockData}
 */
proto.collection.FSMessage.BlockData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.BlockData;
  return proto.collection.FSMessage.BlockData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.BlockData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.BlockData}
 */
proto.collection.FSMessage.BlockData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setInlineData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BlockData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.BlockData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.BlockData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.BlockData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.collection.FSMessage.BlockData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.BlockData} returns this
 */
proto.collection.FSMessage.BlockData.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BlockData} returns this
 */
proto.collection.FSMessage.BlockData.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BlockData.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes inline_data = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.BlockData.prototype.getInlineData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes inline_data = 2;
 * This is a type-conversion wrapper around `getInlineData()`
 * @return {string}
 */
proto.collection.FSMessage.BlockData.prototype.getInlineData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getInlineData()));
};


/**
 * optional bytes inline_data = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getInlineData()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.BlockData.prototype.getInlineData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getInlineData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage.BlockData} returns this
 */
proto.collection.FSMessage.BlockData.prototype.setInlineData = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.collection.FSMessage.BlockData.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BlockData} returns this
 */
proto.collection.FSMessage.BlockData.prototype.clearInlineData = function() {
  return jspb.Message.setOneofField(this, 2, proto.collection.FSMessage.BlockData.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BlockData.prototype.hasInlineData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.collection.FSMessage.BlockData.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.BlockData} returns this
 */
proto.collection.FSMessage.BlockData.prototype.setUrl = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.collection.FSMessage.BlockData.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.BlockData} returns this
 */
proto.collection.FSMessage.BlockData.prototype.clearUrl = function() {
  return jspb.Message.setOneofField(this, 3, proto.collection.FSMessage.BlockData.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.BlockData.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.DataBlocks.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.DataBlocks.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.DataBlocks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.DataBlocks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.DataBlocks.toObject = function(includeInstance, msg) {
  var f, obj = {
    blocksList: jspb.Message.toObjectList(msg.getBlocksList(),
    proto.collection.FSMessage.BlockData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.DataBlocks}
 */
proto.collection.FSMessage.DataBlocks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.DataBlocks;
  return proto.collection.FSMessage.DataBlocks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.DataBlocks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.DataBlocks}
 */
proto.collection.FSMessage.DataBlocks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.FSMessage.BlockData;
      reader.readMessage(value,proto.collection.FSMessage.BlockData.deserializeBinaryFromReader);
      msg.addBlocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.DataBlocks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.DataBlocks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.DataBlocks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.DataBlocks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.FSMessage.BlockData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlockData blocks = 1;
 * @return {!Array<!proto.collection.FSMessage.BlockData>}
 */
proto.collection.FSMessage.DataBlocks.prototype.getBlocksList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.BlockData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.BlockData, 1));
};


/**
 * @param {!Array<!proto.collection.FSMessage.BlockData>} value
 * @return {!proto.collection.FSMessage.DataBlocks} returns this
*/
proto.collection.FSMessage.DataBlocks.prototype.setBlocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.FSMessage.BlockData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.BlockData}
 */
proto.collection.FSMessage.DataBlocks.prototype.addBlocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.FSMessage.BlockData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.DataBlocks} returns this
 */
proto.collection.FSMessage.DataBlocks.prototype.clearBlocksList = function() {
  return this.setBlocksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.SyncRules.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.SyncRules.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.SyncRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.SyncRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.SyncRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    rulesList: jspb.Message.toObjectList(msg.getRulesList(),
    proto.collection.SyncRule.toObject, includeInstance),
    revId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.SyncRules}
 */
proto.collection.FSMessage.SyncRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.SyncRules;
  return proto.collection.FSMessage.SyncRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.SyncRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.SyncRules}
 */
proto.collection.FSMessage.SyncRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.collection.SyncRule;
      reader.readMessage(value,proto.collection.SyncRule.deserializeBinaryFromReader);
      msg.addRules(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRevId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.SyncRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.SyncRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.SyncRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.SyncRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.collection.SyncRule.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * repeated SyncRule rules = 1;
 * @return {!Array<!proto.collection.SyncRule>}
 */
proto.collection.FSMessage.SyncRules.prototype.getRulesList = function() {
  return /** @type{!Array<!proto.collection.SyncRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.SyncRule, 1));
};


/**
 * @param {!Array<!proto.collection.SyncRule>} value
 * @return {!proto.collection.FSMessage.SyncRules} returns this
*/
proto.collection.FSMessage.SyncRules.prototype.setRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.collection.SyncRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.SyncRule}
 */
proto.collection.FSMessage.SyncRules.prototype.addRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.collection.SyncRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.SyncRules} returns this
 */
proto.collection.FSMessage.SyncRules.prototype.clearRulesList = function() {
  return this.setRulesList([]);
};


/**
 * optional uint64 rev_id = 2;
 * @return {number}
 */
proto.collection.FSMessage.SyncRules.prototype.getRevId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage.SyncRules} returns this
 */
proto.collection.FSMessage.SyncRules.prototype.setRevId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.SyncRules} returns this
 */
proto.collection.FSMessage.SyncRules.prototype.clearRevId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.SyncRules.prototype.hasRevId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.collection.FSMessage.GranteeFlat.repeatedFields_ = [2,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.FSMessage.GranteeFlat.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.FSMessage.GranteeFlat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.FSMessage.GranteeFlat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.GranteeFlat.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    pending: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    viewOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    aclRolesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    isOwner: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f,
    expirationTime: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.FSMessage.GranteeFlat}
 */
proto.collection.FSMessage.GranteeFlat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.FSMessage.GranteeFlat;
  return proto.collection.FSMessage.GranteeFlat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.FSMessage.GranteeFlat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.FSMessage.GranteeFlat}
 */
proto.collection.FSMessage.GranteeFlat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var values = /** @type {!Array<!proto.collection.FSRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRoles(values[i]);
      }
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPending(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewOnly(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.collection.Node.ACLRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAclRoles(values[i]);
      }
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOwner(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.GranteeFlat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.FSMessage.GranteeFlat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.FSMessage.GranteeFlat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.FSMessage.GranteeFlat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getAclRolesList();
  if (f.length > 0) {
    writer.writeRepeatedEnum(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setUserId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearUserId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated FSRole roles = 2;
 * @return {!Array<!proto.collection.FSRole>}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getRolesList = function() {
  return /** @type {!Array<!proto.collection.FSRole>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<!proto.collection.FSRole>} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!proto.collection.FSRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional bool pending = 3;
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getPending = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setPending = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearPending = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.hasPending = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool view_only = 4;
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getViewOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setViewOnly = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearViewOnly = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.hasViewOnly = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated Node.ACLRole acl_roles = 5;
 * @return {!Array<!proto.collection.Node.ACLRole>}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getAclRolesList = function() {
  return /** @type {!Array<!proto.collection.Node.ACLRole>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.collection.Node.ACLRole>} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setAclRolesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.collection.Node.ACLRole} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.addAclRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearAclRolesList = function() {
  return this.setAclRolesList([]);
};


/**
 * optional bool is_owner = 6;
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getIsOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setIsOwner = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearIsOwner = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.hasIsOwner = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string expiration_time = 7;
 * @return {string}
 */
proto.collection.FSMessage.GranteeFlat.prototype.getExpirationTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.setExpirationTime = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage.GranteeFlat} returns this
 */
proto.collection.FSMessage.GranteeFlat.prototype.clearExpirationTime = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.GranteeFlat.prototype.hasExpirationTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Type type = 1;
 * @return {!proto.collection.FSMessage.Type}
 */
proto.collection.FSMessage.prototype.getType = function() {
  return /** @type {!proto.collection.FSMessage.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.collection.FSMessage.Type} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setType = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearType = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes request_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.prototype.getRequestId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes request_id = 2;
 * This is a type-conversion wrapper around `getRequestId()`
 * @return {string}
 */
proto.collection.FSMessage.prototype.getRequestId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRequestId()));
};


/**
 * optional bytes request_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRequestId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.prototype.getRequestId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRequestId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setRequestId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearRequestId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FSStatus status = 3;
 * @return {!proto.collection.FSStatus}
 */
proto.collection.FSMessage.prototype.getStatus = function() {
  return /** @type {!proto.collection.FSStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.collection.FSStatus} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setStatus = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearStatus = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RootInfo root_info = 4;
 * @return {?proto.collection.FSMessage.RootInfo}
 */
proto.collection.FSMessage.prototype.getRootInfo = function() {
  return /** @type{?proto.collection.FSMessage.RootInfo} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.RootInfo, 4));
};


/**
 * @param {?proto.collection.FSMessage.RootInfo|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setRootInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearRootInfo = function() {
  return this.setRootInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasRootInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Snapshot snapshot = 5;
 * @return {?proto.collection.FSMessage.Snapshot}
 */
proto.collection.FSMessage.prototype.getSnapshot = function() {
  return /** @type{?proto.collection.FSMessage.Snapshot} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Snapshot, 5));
};


/**
 * @param {?proto.collection.FSMessage.Snapshot|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setSnapshot = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearSnapshot = function() {
  return this.setSnapshot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasSnapshot = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Notification notification = 6;
 * @return {?proto.collection.FSMessage.Notification}
 */
proto.collection.FSMessage.prototype.getNotification = function() {
  return /** @type{?proto.collection.FSMessage.Notification} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Notification, 6));
};


/**
 * @param {?proto.collection.FSMessage.Notification|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional File file = 7;
 * @return {?proto.collection.FSMessage.File}
 */
proto.collection.FSMessage.prototype.getFile = function() {
  return /** @type{?proto.collection.FSMessage.File} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.File, 7));
};


/**
 * @param {?proto.collection.FSMessage.File|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasFile = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Dir dir = 8;
 * @return {?proto.collection.FSMessage.Dir}
 */
proto.collection.FSMessage.prototype.getDir = function() {
  return /** @type{?proto.collection.FSMessage.Dir} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Dir, 8));
};


/**
 * @param {?proto.collection.FSMessage.Dir|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setDir = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearDir = function() {
  return this.setDir(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasDir = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bytes data = 9;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes data = 9;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.collection.FSMessage.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setData = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearData = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Permissions permissions = 10;
 * @return {?proto.collection.FSMessage.Permissions}
 */
proto.collection.FSMessage.prototype.getPermissions = function() {
  return /** @type{?proto.collection.FSMessage.Permissions} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Permissions, 10));
};


/**
 * @param {?proto.collection.FSMessage.Permissions|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setPermissions = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearPermissions = function() {
  return this.setPermissions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasPermissions = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated KeyHistoryEntry key_history = 11;
 * @return {!Array<!proto.collection.FSMessage.KeyHistoryEntry>}
 */
proto.collection.FSMessage.prototype.getKeyHistoryList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.KeyHistoryEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.KeyHistoryEntry, 11));
};


/**
 * @param {!Array<!proto.collection.FSMessage.KeyHistoryEntry>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setKeyHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.collection.FSMessage.KeyHistoryEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.KeyHistoryEntry}
 */
proto.collection.FSMessage.prototype.addKeyHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.collection.FSMessage.KeyHistoryEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearKeyHistoryList = function() {
  return this.setKeyHistoryList([]);
};


/**
 * repeated string failed_user_ids = 12;
 * @return {!Array<string>}
 */
proto.collection.FSMessage.prototype.getFailedUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setFailedUserIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.addFailedUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearFailedUserIdsList = function() {
  return this.setFailedUserIdsList([]);
};


/**
 * repeated Share shares = 13;
 * @return {!Array<!proto.collection.FSMessage.Share>}
 */
proto.collection.FSMessage.prototype.getSharesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Share>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Share, 13));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Share>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setSharesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.collection.FSMessage.Share=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Share}
 */
proto.collection.FSMessage.prototype.addShares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.collection.FSMessage.Share, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearSharesList = function() {
  return this.setSharesList([]);
};


/**
 * repeated User users = 14;
 * @return {!Array<!proto.collection.FSMessage.User>}
 */
proto.collection.FSMessage.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.User, 14));
};


/**
 * @param {!Array<!proto.collection.FSMessage.User>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.collection.FSMessage.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.User}
 */
proto.collection.FSMessage.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.collection.FSMessage.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional Link link = 15;
 * @return {?proto.collection.FSMessage.Link}
 */
proto.collection.FSMessage.prototype.getLink = function() {
  return /** @type{?proto.collection.FSMessage.Link} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Link, 15));
};


/**
 * @param {?proto.collection.FSMessage.Link|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setLink = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasLink = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bytes default_collection_id = 16;
 * @return {!(string|Uint8Array)}
 */
proto.collection.FSMessage.prototype.getDefaultCollectionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * optional bytes default_collection_id = 16;
 * This is a type-conversion wrapper around `getDefaultCollectionId()`
 * @return {string}
 */
proto.collection.FSMessage.prototype.getDefaultCollectionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDefaultCollectionId()));
};


/**
 * optional bytes default_collection_id = 16;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDefaultCollectionId()`
 * @return {!Uint8Array}
 */
proto.collection.FSMessage.prototype.getDefaultCollectionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDefaultCollectionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setDefaultCollectionId = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearDefaultCollectionId = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasDefaultCollectionId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional Logs logs = 17;
 * @return {?proto.collection.FSMessage.Logs}
 */
proto.collection.FSMessage.prototype.getLogs = function() {
  return /** @type{?proto.collection.FSMessage.Logs} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Logs, 17));
};


/**
 * @param {?proto.collection.FSMessage.Logs|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setLogs = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearLogs = function() {
  return this.setLogs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasLogs = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Groups groups = 18;
 * @return {?proto.collection.FSMessage.Groups}
 */
proto.collection.FSMessage.prototype.getGroups = function() {
  return /** @type{?proto.collection.FSMessage.Groups} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Groups, 18));
};


/**
 * @param {?proto.collection.FSMessage.Groups|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setGroups = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearGroups = function() {
  return this.setGroups(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasGroups = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional AdminGroupInfo admin_group_info = 19;
 * @return {?proto.collection.FSMessage.AdminGroupInfo}
 */
proto.collection.FSMessage.prototype.getAdminGroupInfo = function() {
  return /** @type{?proto.collection.FSMessage.AdminGroupInfo} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.AdminGroupInfo, 19));
};


/**
 * @param {?proto.collection.FSMessage.AdminGroupInfo|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setAdminGroupInfo = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearAdminGroupInfo = function() {
  return this.setAdminGroupInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasAdminGroupInfo = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated DeletedEntry deleted = 20;
 * @return {!Array<!proto.collection.FSMessage.DeletedEntry>}
 */
proto.collection.FSMessage.prototype.getDeletedList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.DeletedEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.DeletedEntry, 20));
};


/**
 * @param {!Array<!proto.collection.FSMessage.DeletedEntry>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setDeletedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.collection.FSMessage.DeletedEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.DeletedEntry}
 */
proto.collection.FSMessage.prototype.addDeleted = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.collection.FSMessage.DeletedEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearDeletedList = function() {
  return this.setDeletedList([]);
};


/**
 * optional string block_url = 21;
 * @return {string}
 */
proto.collection.FSMessage.prototype.getBlockUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setBlockUrl = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearBlockUrl = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasBlockUrl = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional LinkView link_view = 22;
 * @return {?proto.collection.FSMessage.LinkView}
 */
proto.collection.FSMessage.prototype.getLinkView = function() {
  return /** @type{?proto.collection.FSMessage.LinkView} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.LinkView, 22));
};


/**
 * @param {?proto.collection.FSMessage.LinkView|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setLinkView = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearLinkView = function() {
  return this.setLinkView(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasLinkView = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated OrgPermissions org_permissions = 23;
 * @return {!Array<!proto.collection.FSMessage.OrgPermissions>}
 */
proto.collection.FSMessage.prototype.getOrgPermissionsList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.OrgPermissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.OrgPermissions, 23));
};


/**
 * @param {!Array<!proto.collection.FSMessage.OrgPermissions>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setOrgPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.collection.FSMessage.OrgPermissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.OrgPermissions}
 */
proto.collection.FSMessage.prototype.addOrgPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.collection.FSMessage.OrgPermissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearOrgPermissionsList = function() {
  return this.setOrgPermissionsList([]);
};


/**
 * repeated UserPermissions user_permissions = 24;
 * @return {!Array<!proto.collection.FSMessage.UserPermissions>}
 */
proto.collection.FSMessage.prototype.getUserPermissionsList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.UserPermissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.UserPermissions, 24));
};


/**
 * @param {!Array<!proto.collection.FSMessage.UserPermissions>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setUserPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.collection.FSMessage.UserPermissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.UserPermissions}
 */
proto.collection.FSMessage.prototype.addUserPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.collection.FSMessage.UserPermissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearUserPermissionsList = function() {
  return this.setUserPermissionsList([]);
};


/**
 * optional uint64 next_seq = 25;
 * @return {number}
 */
proto.collection.FSMessage.prototype.getNextSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setNextSeq = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearNextSeq = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasNextSeq = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool has_more = 26;
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.getHasMore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setHasMore = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearHasMore = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasHasMore = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional Collection.Attributes collection_attributes = 27;
 * @return {?proto.collection.Collection.Attributes}
 */
proto.collection.FSMessage.prototype.getCollectionAttributes = function() {
  return /** @type{?proto.collection.Collection.Attributes} */ (
    jspb.Message.getWrapperField(this, proto.collection.Collection.Attributes, 27));
};


/**
 * @param {?proto.collection.Collection.Attributes|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setCollectionAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearCollectionAttributes = function() {
  return this.setCollectionAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasCollectionAttributes = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional ACLTree acl_tree = 28;
 * @return {?proto.collection.FSMessage.ACLTree}
 */
proto.collection.FSMessage.prototype.getAclTree = function() {
  return /** @type{?proto.collection.FSMessage.ACLTree} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.ACLTree, 28));
};


/**
 * @param {?proto.collection.FSMessage.ACLTree|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setAclTree = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearAclTree = function() {
  return this.setAclTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasAclTree = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional Links links = 29;
 * @return {?proto.collection.FSMessage.Links}
 */
proto.collection.FSMessage.prototype.getLinks = function() {
  return /** @type{?proto.collection.FSMessage.Links} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.Links, 29));
};


/**
 * @param {?proto.collection.FSMessage.Links|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setLinks = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearLinks = function() {
  return this.setLinks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasLinks = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional DataBlocks data_blocks = 30;
 * @return {?proto.collection.FSMessage.DataBlocks}
 */
proto.collection.FSMessage.prototype.getDataBlocks = function() {
  return /** @type{?proto.collection.FSMessage.DataBlocks} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.DataBlocks, 30));
};


/**
 * @param {?proto.collection.FSMessage.DataBlocks|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setDataBlocks = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearDataBlocks = function() {
  return this.setDataBlocks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasDataBlocks = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional uint64 next_subseq = 31;
 * @return {number}
 */
proto.collection.FSMessage.prototype.getNextSubseq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setNextSubseq = function(value) {
  return jspb.Message.setField(this, 31, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearNextSubseq = function() {
  return jspb.Message.setField(this, 31, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasNextSubseq = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * repeated Dir.Entry dir_entries = 32;
 * @return {!Array<!proto.collection.FSMessage.Dir.Entry>}
 */
proto.collection.FSMessage.prototype.getDirEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.Dir.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.Dir.Entry, 32));
};


/**
 * @param {!Array<!proto.collection.FSMessage.Dir.Entry>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setDirEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.collection.FSMessage.Dir.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.Dir.Entry}
 */
proto.collection.FSMessage.prototype.addDirEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.collection.FSMessage.Dir.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearDirEntriesList = function() {
  return this.setDirEntriesList([]);
};


/**
 * repeated BulkUpdateFailure bulk_update_failure = 33;
 * @return {!Array<!proto.collection.FSMessage.BulkUpdateFailure>}
 */
proto.collection.FSMessage.prototype.getBulkUpdateFailureList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.BulkUpdateFailure>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.BulkUpdateFailure, 33));
};


/**
 * @param {!Array<!proto.collection.FSMessage.BulkUpdateFailure>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setBulkUpdateFailureList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!proto.collection.FSMessage.BulkUpdateFailure=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.BulkUpdateFailure}
 */
proto.collection.FSMessage.prototype.addBulkUpdateFailure = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, proto.collection.FSMessage.BulkUpdateFailure, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearBulkUpdateFailureList = function() {
  return this.setBulkUpdateFailureList([]);
};


/**
 * optional RestoreResult restore_result = 34;
 * @return {?proto.collection.FSMessage.RestoreResult}
 */
proto.collection.FSMessage.prototype.getRestoreResult = function() {
  return /** @type{?proto.collection.FSMessage.RestoreResult} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.RestoreResult, 34));
};


/**
 * @param {?proto.collection.FSMessage.RestoreResult|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setRestoreResult = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearRestoreResult = function() {
  return this.setRestoreResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasRestoreResult = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * repeated ACLNodePermissions acl_node_permissions = 35;
 * @return {!Array<!proto.collection.FSMessage.ACLNodePermissions>}
 */
proto.collection.FSMessage.prototype.getAclNodePermissionsList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.ACLNodePermissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.ACLNodePermissions, 35));
};


/**
 * @param {!Array<!proto.collection.FSMessage.ACLNodePermissions>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setAclNodePermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.collection.FSMessage.ACLNodePermissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.ACLNodePermissions}
 */
proto.collection.FSMessage.prototype.addAclNodePermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.collection.FSMessage.ACLNodePermissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearAclNodePermissionsList = function() {
  return this.setAclNodePermissionsList([]);
};


/**
 * repeated ACLUserPermissions acl_user_permissions = 36;
 * @return {!Array<!proto.collection.FSMessage.ACLUserPermissions>}
 */
proto.collection.FSMessage.prototype.getAclUserPermissionsList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.ACLUserPermissions>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.ACLUserPermissions, 36));
};


/**
 * @param {!Array<!proto.collection.FSMessage.ACLUserPermissions>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setAclUserPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.collection.FSMessage.ACLUserPermissions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.ACLUserPermissions}
 */
proto.collection.FSMessage.prototype.addAclUserPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.collection.FSMessage.ACLUserPermissions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearAclUserPermissionsList = function() {
  return this.setAclUserPermissionsList([]);
};


/**
 * optional string error_message = 37;
 * @return {string}
 */
proto.collection.FSMessage.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setErrorMessage = function(value) {
  return jspb.Message.setField(this, 37, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearErrorMessage = function() {
  return jspb.Message.setField(this, 37, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasErrorMessage = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional int64 total_count = 38;
 * @return {number}
 */
proto.collection.FSMessage.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setTotalCount = function(value) {
  return jspb.Message.setField(this, 38, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearTotalCount = function() {
  return jspb.Message.setField(this, 38, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional SyncRules sync_rules = 39;
 * @return {?proto.collection.FSMessage.SyncRules}
 */
proto.collection.FSMessage.prototype.getSyncRules = function() {
  return /** @type{?proto.collection.FSMessage.SyncRules} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.SyncRules, 39));
};


/**
 * @param {?proto.collection.FSMessage.SyncRules|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setSyncRules = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearSyncRules = function() {
  return this.setSyncRules(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasSyncRules = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional uint64 sync_rules_seq = 40;
 * @return {number}
 */
proto.collection.FSMessage.prototype.getSyncRulesSeq = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.setSyncRulesSeq = function(value) {
  return jspb.Message.setField(this, 40, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearSyncRulesSeq = function() {
  return jspb.Message.setField(this, 40, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasSyncRulesSeq = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional SyncRuleTree sync_rule_tree = 41;
 * @return {?proto.collection.FSMessage.SyncRuleTree}
 */
proto.collection.FSMessage.prototype.getSyncRuleTree = function() {
  return /** @type{?proto.collection.FSMessage.SyncRuleTree} */ (
    jspb.Message.getWrapperField(this, proto.collection.FSMessage.SyncRuleTree, 41));
};


/**
 * @param {?proto.collection.FSMessage.SyncRuleTree|undefined} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setSyncRuleTree = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearSyncRuleTree = function() {
  return this.setSyncRuleTree(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.FSMessage.prototype.hasSyncRuleTree = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * repeated GranteeFlat grantees_flat = 42;
 * @return {!Array<!proto.collection.FSMessage.GranteeFlat>}
 */
proto.collection.FSMessage.prototype.getGranteesFlatList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.GranteeFlat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.GranteeFlat, 42));
};


/**
 * @param {!Array<!proto.collection.FSMessage.GranteeFlat>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setGranteesFlatList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 42, value);
};


/**
 * @param {!proto.collection.FSMessage.GranteeFlat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.GranteeFlat}
 */
proto.collection.FSMessage.prototype.addGranteesFlat = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 42, opt_value, proto.collection.FSMessage.GranteeFlat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearGranteesFlatList = function() {
  return this.setGranteesFlatList([]);
};


/**
 * repeated UnlinkedEntry unlinked_entries = 43;
 * @return {!Array<!proto.collection.FSMessage.UnlinkedEntry>}
 */
proto.collection.FSMessage.prototype.getUnlinkedEntriesList = function() {
  return /** @type{!Array<!proto.collection.FSMessage.UnlinkedEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.collection.FSMessage.UnlinkedEntry, 43));
};


/**
 * @param {!Array<!proto.collection.FSMessage.UnlinkedEntry>} value
 * @return {!proto.collection.FSMessage} returns this
*/
proto.collection.FSMessage.prototype.setUnlinkedEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 43, value);
};


/**
 * @param {!proto.collection.FSMessage.UnlinkedEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.collection.FSMessage.UnlinkedEntry}
 */
proto.collection.FSMessage.prototype.addUnlinkedEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 43, opt_value, proto.collection.FSMessage.UnlinkedEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.collection.FSMessage} returns this
 */
proto.collection.FSMessage.prototype.clearUnlinkedEntriesList = function() {
  return this.setUnlinkedEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.Key.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    key: msg.getKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.Key}
 */
proto.collection.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.Key;
  return proto.collection.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.Key}
 */
proto.collection.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.collection.Key.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.Key} returns this
 */
proto.collection.Key.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Key} returns this
 */
proto.collection.Key.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Key.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes key = 2;
 * @return {!(string|Uint8Array)}
 */
proto.collection.Key.prototype.getKey = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes key = 2;
 * This is a type-conversion wrapper around `getKey()`
 * @return {string}
 */
proto.collection.Key.prototype.getKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getKey()));
};


/**
 * optional bytes key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getKey()`
 * @return {!Uint8Array}
 */
proto.collection.Key.prototype.getKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.collection.Key} returns this
 */
proto.collection.Key.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.Key} returns this
 */
proto.collection.Key.prototype.clearKey = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.Key.prototype.hasKey = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.UserKey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.UserKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.UserKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.UserKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    privateKey: (f = msg.getPrivateKey()) && proto.collection.Key.toObject(includeInstance, f),
    signingKey: (f = msg.getSigningKey()) && proto.collection.Key.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.UserKey}
 */
proto.collection.UserKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.UserKey;
  return proto.collection.UserKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.UserKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.UserKey}
 */
proto.collection.UserKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new proto.collection.Key;
      reader.readMessage(value,proto.collection.Key.deserializeBinaryFromReader);
      msg.setPrivateKey(value);
      break;
    case 4:
      var value = new proto.collection.Key;
      reader.readMessage(value,proto.collection.Key.deserializeBinaryFromReader);
      msg.setSigningKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.UserKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.UserKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.UserKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.UserKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPrivateKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.Key.serializeBinaryToWriter
    );
  }
  f = message.getSigningKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.collection.UserKey.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.UserKey} returns this
 */
proto.collection.UserKey.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.UserKey} returns this
 */
proto.collection.UserKey.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.UserKey.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.UserKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.UserKey} returns this
 */
proto.collection.UserKey.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.UserKey} returns this
 */
proto.collection.UserKey.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.UserKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key private_key = 3;
 * @return {?proto.collection.Key}
 */
proto.collection.UserKey.prototype.getPrivateKey = function() {
  return /** @type{?proto.collection.Key} */ (
    jspb.Message.getWrapperField(this, proto.collection.Key, 3));
};


/**
 * @param {?proto.collection.Key|undefined} value
 * @return {!proto.collection.UserKey} returns this
*/
proto.collection.UserKey.prototype.setPrivateKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.UserKey} returns this
 */
proto.collection.UserKey.prototype.clearPrivateKey = function() {
  return this.setPrivateKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.UserKey.prototype.hasPrivateKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Key signing_key = 4;
 * @return {?proto.collection.Key}
 */
proto.collection.UserKey.prototype.getSigningKey = function() {
  return /** @type{?proto.collection.Key} */ (
    jspb.Message.getWrapperField(this, proto.collection.Key, 4));
};


/**
 * @param {?proto.collection.Key|undefined} value
 * @return {!proto.collection.UserKey} returns this
*/
proto.collection.UserKey.prototype.setSigningKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.UserKey} returns this
 */
proto.collection.UserKey.prototype.clearSigningKey = function() {
  return this.setSigningKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.UserKey.prototype.hasSigningKey = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.collection.PublicUserKey.prototype.toObject = function(opt_includeInstance) {
  return proto.collection.PublicUserKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.collection.PublicUserKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.PublicUserKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    protocolVersion: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    keyVersion: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    publicKey: (f = msg.getPublicKey()) && proto.collection.Key.toObject(includeInstance, f),
    verifyKey: (f = msg.getVerifyKey()) && proto.collection.Key.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.collection.PublicUserKey}
 */
proto.collection.PublicUserKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.collection.PublicUserKey;
  return proto.collection.PublicUserKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.collection.PublicUserKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.collection.PublicUserKey}
 */
proto.collection.PublicUserKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProtocolVersion(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setKeyVersion(value);
      break;
    case 3:
      var value = new proto.collection.Key;
      reader.readMessage(value,proto.collection.Key.deserializeBinaryFromReader);
      msg.setPublicKey(value);
      break;
    case 4:
      var value = new proto.collection.Key;
      reader.readMessage(value,proto.collection.Key.deserializeBinaryFromReader);
      msg.setVerifyKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.collection.PublicUserKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.collection.PublicUserKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.collection.PublicUserKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.collection.PublicUserKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getPublicKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.collection.Key.serializeBinaryToWriter
    );
  }
  f = message.getVerifyKey();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.collection.Key.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 protocol_version = 1;
 * @return {number}
 */
proto.collection.PublicUserKey.prototype.getProtocolVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.PublicUserKey} returns this
 */
proto.collection.PublicUserKey.prototype.setProtocolVersion = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.PublicUserKey} returns this
 */
proto.collection.PublicUserKey.prototype.clearProtocolVersion = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.PublicUserKey.prototype.hasProtocolVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 key_version = 2;
 * @return {number}
 */
proto.collection.PublicUserKey.prototype.getKeyVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.collection.PublicUserKey} returns this
 */
proto.collection.PublicUserKey.prototype.setKeyVersion = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.collection.PublicUserKey} returns this
 */
proto.collection.PublicUserKey.prototype.clearKeyVersion = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.PublicUserKey.prototype.hasKeyVersion = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key public_key = 3;
 * @return {?proto.collection.Key}
 */
proto.collection.PublicUserKey.prototype.getPublicKey = function() {
  return /** @type{?proto.collection.Key} */ (
    jspb.Message.getWrapperField(this, proto.collection.Key, 3));
};


/**
 * @param {?proto.collection.Key|undefined} value
 * @return {!proto.collection.PublicUserKey} returns this
*/
proto.collection.PublicUserKey.prototype.setPublicKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.PublicUserKey} returns this
 */
proto.collection.PublicUserKey.prototype.clearPublicKey = function() {
  return this.setPublicKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.PublicUserKey.prototype.hasPublicKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Key verify_key = 4;
 * @return {?proto.collection.Key}
 */
proto.collection.PublicUserKey.prototype.getVerifyKey = function() {
  return /** @type{?proto.collection.Key} */ (
    jspb.Message.getWrapperField(this, proto.collection.Key, 4));
};


/**
 * @param {?proto.collection.Key|undefined} value
 * @return {!proto.collection.PublicUserKey} returns this
*/
proto.collection.PublicUserKey.prototype.setVerifyKey = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.collection.PublicUserKey} returns this
 */
proto.collection.PublicUserKey.prototype.clearVerifyKey = function() {
  return this.setVerifyKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.collection.PublicUserKey.prototype.hasVerifyKey = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.collection.FSMethod = {
  UNKNOWN: 0,
  INIT: 1,
  ROOT_INFO: 2,
  SUBSCRIBE: 3,
  CREATE: 4,
  DELETE: 5,
  UPDATE: 6,
  FETCH_FILE: 7,
  FETCH_DIR: 8,
  UPLOAD_BLOCK: 9,
  DOWNLOAD_BLOCK: 10,
  ACK: 11,
  GRANT: 13,
  PERMISSIONS: 14,
  REKEY: 15,
  KEY_HISTORY: 16,
  FETCH_SHARES: 17,
  HANDLE_SHARE: 18,
  LIST_USERS: 19,
  DEFAULT_COLLECTION_ID: 20,
  SET_DEFAULT_COLLECTION_ID: 21,
  MAKE_SHAREABLE: 22,
  FETCH_LINK: 24,
  READ_LOG: 25,
  GROUPS: 26,
  GROUP_PERMISSIONS: 27,
  ADMIN_GROUP_INFO: 28,
  ADD_KEY: 29,
  SNAPSHOT: 30,
  SUBSCRIBE_LOGS: 31,
  FETCH_DELETED: 32,
  GET_UPLOAD_URL: 33,
  REGISTER_UPLOADED: 34,
  DOWNLOAD_BLOCK_DIRECT: 35,
  FILE_HISTORY: 36,
  LIST_LINKS: 37,
  BULK_UPDATE: 38,
  ACL_REPORT: 39,
  BULK_PERMISSIONS: 40,
  UPGRADE_COLLECTION: 41,
  GET_COLLECTION_ATTRIBUTES: 42,
  SET_COLLECTION_ATTRIBUTES: 43,
  ACL_TREE: 44,
  ACL_KEY_HISTORY: 45,
  LOCK_FILE: 46,
  UNLOCK_FILE: 47,
  FIND_LINKS: 48,
  DOWNLOAD_BLOCKS: 49,
  DOWNLOAD_BLOCKS_DIRECT: 50,
  FETCH_DIR_ENTRIES: 51,
  RESTORE: 52,
  EMPTY_TRASH: 53,
  SYNC_RULES: 54,
  SET_SYNC_RULES: 55,
  SUBSCRIBE_ALL: 56,
  SYNC_RULE_TREE: 57,
  FETCH_NODE_PERMISSIONS: 58,
  CRASH: 59,
  LIST_UNLINKED: 60
};

/**
 * @enum {number}
 */
proto.collection.FSStatus = {
  OK: 0,
  NOT_FOUND: 1,
  CONFLICT: 2,
  VERSION_MISMATCH: 3,
  MALFORMED_REQUEST: 4,
  SERVER_ERROR: 5,
  COLLECTION_NOT_FOUND: 6,
  MISSING_BLOCKS: 7,
  UNAUTHORIZED: 8,
  PARTIALLY_APPLIED: 9,
  UNSUPPORTED_VERSION: 10,
  FORBIDDEN: 11,
  EXPIRED_DEVICE_KEY: 12,
  EXPIRED_USER_KEY: 13,
  LOCKED_DEVICE: 14,
  NO_DIRECT_ACCESS: 15,
  STALE_READ_KEY_NODE_ID: 16,
  BUSY: 17,
  PARENT_DELETED: 18,
  BULK_UPDATE_FAILED: 19,
  PARTIAL_RETURN: 20
};

/**
 * @enum {number}
 */
proto.collection.FSType = {
  FILE: 0,
  DIR: 1,
  LINK: 2
};

/**
 * @enum {number}
 */
proto.collection.FSRole = {
  OWNER: 0,
  READER: 1,
  WRITER: 2,
  ACL_VIEWER: 3,
  SHARER: 4,
  LOG_VIEWER: 5,
  ACCESS: 6,
  VIEWER: 7
};

/**
 * @enum {number}
 */
proto.collection.FSBlockType = {
  BASIC: 0,
  POINTER: 1
};

goog.object.extend(exports, proto.collection);
