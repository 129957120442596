import { CollectionServerUser, DataExportRequest, JSONOrgInfo, OrgInfo, RequestParams, RequestParsedPayload } from "@preveil-api";
import { useCallback, useEffect, useState } from "react";
import {
  Account,
  ApprovalGroup,
  ApprovalRequestStatus,
  collectionApi,
  DataExportClosedStates,
  GetUsersOrgsByEntityIdRequestsApiResponse,
  GetUsersOrgsByEntityIdGroupsApiResponse,
  UserRequest,
  dayjs,
  OrganizationInformation,
  Contacts,
  useAppDispatch,
} from "src/common";
import { useGetPopulatedRequests } from "./use-getpopulatedrequests.hook";
import { adminActions } from "src/store";

/* Description: This is a custom hook for getting the organization info, this hook is used in more than one admin 
   component. It returns the above OrganizationInformation object. */
export function useGetOrgInfo(current_account: Account, org_info: OrgInfo) {
  const [error, setError] = useState<unknown>();
  const [orgInfo, setOrgInfo] = useState<JSONOrgInfo>();
  const [orgUsers, setOrgUsers] = useState<CollectionServerUser[]>([]);
  const [approvalGroup, setApprovalGroup] = useState<ApprovalGroup[]>([]);
  const [exportRequest, setExportRequest] = useState<any>(); // TO DO: leaving this untyped for now as it will be used while working on the Data Export ticket (still need to figure that out)
  const [totalRequestRows, setTotalRequestRows] = useState<number>(0);
  const [data, setData] = useState<OrganizationInformation>();
  const account_ids = Account.getAccountIdentifiers(current_account);
  const [getOrgInfo] = collectionApi.endpoints.getUsersOrgsByEntityId.useLazyQuery();
  const [getOrgAG] = collectionApi.endpoints.getUsersOrgsByEntityIdGroups.useLazyQuery();
  const [getOrgRequests] = collectionApi.endpoints.getUsersOrgsByEntityIdRequests.useLazyQuery();
  const {
    data: populatedRequestsData,
    error: populatedRequestsError,
    refreshPopulatedRequests,
  } = useGetPopulatedRequests(current_account);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!!populatedRequestsData && !!orgInfo) {
      setData({
        org_info: orgInfo,
        approval_groups: approvalGroup,
        org_users: orgUsers,
        total_request_rows: totalRequestRows - populatedRequestsData.invalid_requests_count,
        org_requests: populatedRequestsData.requests,
        export_request: exportRequest,
      });
    }
  }, [populatedRequestsData, populatedRequestsError]);

  /* Description: Get the organization info from the backend and set the org users list. 
     Also calls the get approval groups function */
  async function getOrganizationInfo(request_params?: RequestParams) {
    const params = {
      account_ids,
      body: {
        entity_id: org_info.org_id,
      },
    };
    getOrgInfo(params)
      .unwrap()
      .then((response: JSONOrgInfo) => {
        setOrgInfo(response);
        const org_users = response.users?.filter((user) => !user.deleted);
        if (!!org_users) {
          setOrgUsers(org_users);
          new Contacts(current_account.user_id, null, org_users).updateContactSessions();
          getOrganizationApprovalGroups(response, org_users, request_params);
        }
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  /* Description: Gets the organization approval group from the backend and creates a new ApprovalGroup object. */
  async function getOrganizationApprovalGroups(
    data: JSONOrgInfo,
    org_users: CollectionServerUser[],
    request_params?: RequestParams,
  ) {
    const params = {
      account_ids,
      body: {
        entityId: org_info.org_id,
        sinceRevId: -1,
      },
    };
    getOrgAG(params)
      .unwrap()
      .then((response: GetUsersOrgsByEntityIdGroupsApiResponse) => {
        const approval_groups: ApprovalGroup[] = [];
        response.groups.forEach((ag) => {
          const approvers = ag.group.approvers
            .map((approver) => {
              const user = org_users.find(
                (org_user) => org_user.user_id.toLowerCase() === approver.user_id.toLowerCase(),
              );
              return { name: user?.display_name || null, address: user?.user_id || "", role: user?.entity_metadata.role || "" };
            })
            .filter((approver) => !!approver);
          const approval_group_roles = [];
          const roled_approval_groups = data.roled_approval_groups as any;
          for (const role_name in data.roled_approval_groups) {
            const role = roled_approval_groups[role_name];
            role.group_id === ag.id && approval_group_roles.push(role_name);
          }
          approval_groups.push(
            new ApprovalGroup(
              ag.id,
              ag.name,
              approvers,
              ag.group.optionals_required,
              ag.version,
              approval_group_roles,
              ag.is_deleted,
            ),
          );
        });
        setApprovalGroup(approval_groups);
        const updated_org_users = org_users.map(user => {
          const recovery_group = approval_groups.find(
            (ag) => ag.approval_group_id === user.entity_metadata.recovery_group_id,
          );
          if (!!recovery_group) {
            return { ...user, recovery_group };
          }
          return user;
        });
        setOrgUsers(updated_org_users);
        const user_count = updated_org_users.filter(user => !user.entity_metadata.recovery_group_pending).filter(user => !user.recovery_group).length;
        dispatch(adminActions.setUsersWithoutRecovery(user_count));
        !!request_params
          ? getOrganizationRequests(updated_org_users, approval_groups, request_params)
          : setData({ org_info: data, approval_groups, org_users: updated_org_users, org_requests: [], total_request_rows: 0 });
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  /* Description: Gets the orgnanization requests from the backend, populates the user request objects by calling the
    use get populated requests hook and  */
  async function getOrganizationRequests(
    org_users: CollectionServerUser[],
    approval_groups: ApprovalGroup[],
    request_params?: RequestParams,
  ) {
    if (!!request_params) {
      const params = {
        account_ids,
        body: {
          status: request_params?.status,
          hideExpired: request_params?.hideExpired,
          limit: request_params?.limit,
          offset: request_params?.offset,
          entityId: org_info.org_id,
        },
      };
      await getOrgRequests(params)
        .unwrap()
        .then(async (response: GetUsersOrgsByEntityIdRequestsApiResponse) => {
          setTotalRequestRows(response.total_rows);
          if (!request_params.history) {
            const export_requests = response.requests.filter(
              (r) =>
                r.type === "export" &&
                !!current_account &&
                r.requester.toLowerCase() === current_account.user_id,
            );
            const export_request = export_requests.find(
              (r) => !DataExportClosedStates.includes(r.status),
            );
            if (!!export_request) {
              const parsed_payload = JSON.parse(export_request.payload) as RequestParsedPayload;
              const requester_user = orgUsers.find(
                (user) => user.user_id.toLowerCase() === export_request.requester.toLowerCase(),
              );
              const metadata = export_request.metadata as DataExportRequest;
              const export_req = {
                request_id: export_request.request_id,
                status: export_request.status,
                requester: {
                  name: requester_user?.display_name || null,
                  address: requester_user?.user_id || "",
                },
                /* approval_group: approval_groups.find(
                  (ag) => ag.uid === export_request.group_id.String(),
                ), // TO DO: test this */
                timestamp: export_request.timestamp,
                type: export_request.type,
                export_content: {
                  from: dayjs.utc(metadata.from).local().format("l"),
                  until: dayjs.utc(metadata.until).local().format("l"),
                  include_email: metadata.include_emails,
                  include_drive: metadata.include_files,
                  include_log: metadata.include_logs,
                  include_org_acl_report: metadata.include_org_acl_report,
                },
                expiration: dayjs.utc(export_request.expiration).local().format("lll"),
                // raw_request: export_request,
                for_users: (parsed_payload.data as DataExportRequest).users.map((user) => {
                  const u = orgUsers.find((o_u) => user.user_id === o_u.user_id);
                  return { name: u?.display_name || null, address: u?.user_id || "" };
                }),
              };
              setExportRequest(export_req);
              // TO DO: finish this part during Data Export ticket.
              /* if ( !!export_req.approval_group && export_req.approval_group.approvers.find(
                  (approver) => approver.address.toLowerCase() === current_account.user_id,
                )
              ) {
                const approver_shards = [current_account];
              } */
            }
          }
          const response_requests = !request_params.history ? response.requests
              .filter((request) => request.type !== "export") : response.requests;
          if (!request_params.export) {
            const requests = response_requests
              .map((request) => {
                return new UserRequest(
                  request.request_id,
                  request.metadata,
                  request.payload,
                  request.status,
                  request.requester,
                  [],
                  null,
                  request.group_id,
                  !history && request.status === ApprovalRequestStatus.pending,
                );
              });
            refreshPopulatedRequests(requests, org_users, approval_groups);
          }
        })
        .catch((msg) => {
          setError(msg);
        });
    }
  }

  /* Description: Callback for get org info hook. If we are refreshing from the history tab of approval requests, we already 
  have the org users approval group and so we call the organization requests directly */
  const refresh = useCallback(
    (
      request_params?: RequestParams,
      history?: { orgUsers: CollectionServerUser[]; approvalGroups: ApprovalGroup[] },
    ) => {
      if (!!history) {
        setOrgUsers(history.orgUsers);
        setApprovalGroup(history.approvalGroups);
        getOrganizationRequests(history.orgUsers, history.approvalGroups, request_params);
      } else {
        getOrganizationInfo(request_params);
      }
    },
    [],
  );

  return {
    data,
    error,
    refresh,
  };
}

