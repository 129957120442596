import { Account } from "src/common";
import keyMirror from "keymirror";
// TODO: WIP it's passing the type checking for now.

export type ExportRequest = {
  collection_id?: string;
  group_id?: string;
  group_version?: string;
  request_id?: string;
  requester?: string;
  payload?: string;
  type?: string;
  started?: string | boolean | undefined;
  status?: string;
  timestamp?: string;
  users?: Account[]
  expiration?: string | number | undefined;
  export_content?: {
    from?: string;
    include_emails?: boolean;
    include_files?: boolean;
    include_logs?: boolean;
    include_orgs_acl_report?: boolean;
    until?: string;
  };
  for_users?: Array<{
    name?: string;
    address?: string;
  }>;
  metadata?: {
    from?: string;
    include_emails?: boolean;
    include_files?: boolean;
    include_logs?: boolean;
    include_orgs_acl_report?: boolean;
    include_user_acl_report?: boolean;
    until?: string;
    users?: Account[];
  };
  total_rows?: number;
  raw_request?: string;
  requester_id?: string;
  approvedUsers?: any;
};

export const dataExportRequestStatus = keyMirror({
  not_init: null, // not approval groups assigned yet.
  not_started: null, // no data export request has been made.
  init: null, // data export request has been made and is pending approval.
  pending: null, // data export request is pending approval.
  started: null, // data export request has been approved and started.
  running: null, // data export download is running.
  complete: null, // data export download is complete.
  cancelled: null // data export download is cancelled.
});

export type StepStatus = {
  [key in keyof typeof dataExportRequestStatus]: "not_init" | "not_started" | "init" | "pending" | "started" | "running" | "complete" | "cancelled";
}
