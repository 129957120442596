/*
* Description: Filesync API endpoints for sagas
* Author:      PreVeil, LLC
*/
import { IFetchResponse } from "@preveil-api";
import { BaseAPI, Collection, DriveRoot, RootInfo, Directory, Helpers, form_headers } from "src/common";
import _ from "lodash";

const base_url = `${process.env.REACT_APP_FILESYNC_SERVER}`;
const _getInitializedForm = (user_id: string, identifiers?: Record<string, string>): FormData => {
  const form_data: FormData = new FormData();
  form_data.append("uid", user_id);
  if (!!identifiers) {
    _.forOwn(identifiers, (id, key) => {
      form_data.append(key, Helpers.convertToURLSafeId(id));
    })
  }
  return form_data;
}

// --------------------------------------------------------------------------------------------
// Saga calls
// --------------------------------------------------------------------------------------------
/*
 * Description: Initializes Drive Collection (for express accounts)
 * Request: Form Data submit PUT
 * Response: TBD ****************
 */
const browseDrive = (user_id: string): Promise<IFetchResponse<{ current_directory: any; root_info: RootInfo }>> => {
  const form_data = _getInitializedForm(user_id);
  return BaseAPI.put(`${base_url}/browse/drive`, form_data, form_headers)
    .then((response: IFetchResponse<DriveRoot>) => {
      return {
        ...response, data: {
          current_directory: Directory.parseFSRootDirectory(response.data, ""),// response.data.entries,
          root_info: Collection.parseFSRootInfo(response.data)
        }
      };
    });
  // NEED TO PARSE THE DATA TO AGREE WITH COLLECTION SERVER DATA
}

export const FilesyncAPI = {
  browseDrive
};
