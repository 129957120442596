// Data and constants used for internalization of phone numbers
import { Country, CountryAbbCode } from "src/common";

// An array of abbreviation codes of supported countries
export const SupportedCountryCodes: CountryAbbCode[] = ["AE", "AO", "AU", "AR", "AT", "AZ", "BD", "BE", "BG", "BH", "BO", "BR", "BY", "CA", "CD", "CH", "CI", "CL", "CM", "CN", "CO", "CR", "CU", "CZ", "DE", "DK", "DO", "DZ", "EC", "EG", "ES", "ET", "FI", "FR", "GB", "GH", "GR", "GT", "HK", "HR", "HU", "ID", "IE", "IL", "IN", "IQ", "IR", "IT", "JP", "JO", "KE", "KH", "KR", "KW", "KZ", "LB", "LK", "LT", "LU", "LV", "LY", "MA", "MM", "MY", "MX", "NG", "NL", "NO", "NZ", "OM", "PA", "PE", "PH", "PK", "PL", "PR", "PT", "PY", "QA", "RO", "RS", "RU", "SA", "SE", "SG", "SI", "SK", "TH", "TM", "TN", "TR", "TW", "TZ", "UA", "UG", "US", "UY", "UZ", "VE", "VN", "ZA"];
// All countries
export const Countries: Country[] = [
  {
    name: "Afghanistan",
    country_abb: "AF", // country_abb => CountryCode
    three_letter_code: "AFG",
    country_number_code: "93" // old country_code
  },
  {
    name: "Albania",
    country_abb: "AL",
    three_letter_code: "ALB",
    country_number_code: "355"
  },
  {
    name: "Algeria",
    country_abb: "DZ",
    three_letter_code: "DZA",
    country_number_code: "213"
  },
  {
    name: "American Samoa",
    country_abb: "AS",
    three_letter_code: "ASM",
    country_number_code: "1-684"
  },
  {
    name: "Andorra",
    country_abb: "AD",
    three_letter_code: "AND",
    country_number_code: "376"
  },
  {
    name: "Angola",
    country_abb: "AO",
    three_letter_code: "AGO",
    country_number_code: "244"
  },
  {
    name: "Anguilla",
    country_abb: "AI",
    three_letter_code: "AIA",
    country_number_code: "1-264"
  },
  {
    name: "Antarctica",
    country_abb: "AQ",
    three_letter_code: "ATA",
    country_number_code: "672"
  },
  {
    name: "Antigua and Barbuda",
    country_abb: "AG",
    three_letter_code: "ATG",
    country_number_code: "1-268"
  },
  {
    name: "Argentina",
    country_abb: "AR",
    three_letter_code: "ARG",
    country_number_code: "54"
  },
  {
    name: "Armenia",
    country_abb: "AM",
    three_letter_code: "ARM",
    country_number_code: "374"
  },
  {
    name: "Aruba",
    country_abb: "AW",
    three_letter_code: "ABW",
    country_number_code: "297"
  },
  {
    name: "Australia",
    country_abb: "AU",
    three_letter_code: "AUS",
    country_number_code: "61"
  },
  {
    name: "Austria",
    country_abb: "AT",
    three_letter_code: "AUT",
    country_number_code: "43"
  },
  {
    name: "Azerbaijan",
    country_abb: "AZ",
    three_letter_code: "AZE",
    country_number_code: "994"
  },
  {
    name: "Bahamas",
    country_abb: "BS",
    three_letter_code: "BHS",
    country_number_code: "1-242"
  },
  {
    name: "Bahrain",
    country_abb: "BH",
    three_letter_code: "BHR",
    country_number_code: "973"
  },
  {
    name: "Bangladesh",
    country_abb: "BD",
    three_letter_code: "BGD",
    country_number_code: "880"
  },
  {
    name: "Barbados",
    country_abb: "BB",
    three_letter_code: "BRB",
    country_number_code: "1-246"
  },
  {
    name: "Belarus",
    country_abb: "BY",
    three_letter_code: "BLR",
    country_number_code: "375"
  },
  {
    name: "Belgium",
    country_abb: "BE",
    three_letter_code: "BEL",
    country_number_code: "32"
  },
  {
    name: "Belize",
    country_abb: "BZ",
    three_letter_code: "BLZ",
    country_number_code: "501"
  },
  {
    name: "Benin",
    country_abb: "BJ",
    three_letter_code: "BEN",
    country_number_code: "229"
  },
  {
    name: "Bermuda",
    country_abb: "BM",
    three_letter_code: "BMU",
    country_number_code: "1-441"
  },
  {
    name: "Bhutan",
    country_abb: "BT",
    three_letter_code: "BTN",
    country_number_code: "975"
  },
  {
    name: "Bolivia",
    country_abb: "BO",
    three_letter_code: "BOL",
    country_number_code: "591"
  },
  {
    name: "Bosnia and Herzegovina",
    country_abb: "BA",
    three_letter_code: "BIH",
    country_number_code: "387"
  },
  {
    name: "Botswana",
    country_abb: "BW",
    three_letter_code: "BWA",
    country_number_code: "267"
  },
  {
    name: "Brazil",
    country_abb: "BR",
    three_letter_code: "BRA",
    country_number_code: "55"
  },
  {
    name: "British Indian Ocean Territory",
    country_abb: "IO",
    three_letter_code: "IOT",
    country_number_code: "246"
  },
  {
    name: "British Virgin Islands",
    country_abb: "VG",
    three_letter_code: "VGB",
    country_number_code: "1-284"
  },
  {
    name: "Brunei",
    country_abb: "BN",
    three_letter_code: "BRN",
    country_number_code: "673"
  },
  {
    name: "Bulgaria",
    country_abb: "BG",
    three_letter_code: "BGR",
    country_number_code: "359"
  },
  {
    name: "Burkina Faso",
    country_abb: "BF",
    three_letter_code: "BFA",
    country_number_code: "226"
  },
  {
    name: "Burundi",
    country_abb: "BI",
    three_letter_code: "BDI",
    country_number_code: "257"
  },
  {
    name: "Cambodia",
    country_abb: "KH",
    three_letter_code: "KHM",
    country_number_code: "855"
  },
  {
    name: "Cameroon",
    country_abb: "CM",
    three_letter_code: "CMR",
    country_number_code: "237"
  },
  {
    name: "Canada",
    country_abb: "CA",
    three_letter_code: "CAN",
    country_number_code: "1"
  },
  {
    name: "Cape Verde",
    country_abb: "CV",
    three_letter_code: "CPV",
    country_number_code: "238"
  },
  {
    name: "Cayman Islands",
    country_abb: "KY",
    three_letter_code: "CYM",
    country_number_code: "1-345"
  },
  {
    name: "Central African Republic",
    country_abb: "CF",
    three_letter_code: "CAF",
    country_number_code: "236"
  },
  {
    name: "Chad",
    country_abb: "TD",
    three_letter_code: "TCD",
    country_number_code: "235"
  },
  {
    name: "Chile",
    country_abb: "CL",
    three_letter_code: "CHL",
    country_number_code: "56"
  },
  {
    name: "China",
    country_abb: "CN",
    three_letter_code: "CHN",
    country_number_code: "86"
  },
  {
    name: "Christmas Island",
    country_abb: "CX",
    three_letter_code: "CXR",
    country_number_code: "61"
  },
  {
    name: "Cocos Islands",
    country_abb: "CC",
    three_letter_code: "CCK",
    country_number_code: "61"
  },
  {
    name: "Colombia",
    country_abb: "CO",
    three_letter_code: "COL",
    country_number_code: "57"
  },
  {
    name: "Comoros",
    country_abb: "KM",
    three_letter_code: "COM",
    country_number_code: "269"
  },
  {
    name: "Cook Islands",
    country_abb: "CK",
    three_letter_code: "COK",
    country_number_code: "682"
  },
  {
    name: "Costa Rica",
    country_abb: "CR",
    three_letter_code: "CRI",
    country_number_code: "506"
  },
  {
    name: "Croatia",
    country_abb: "HR",
    three_letter_code: "HRV",
    country_number_code: "385"
  },
  {
    name: "Cuba",
    country_abb: "CU",
    three_letter_code: "CUB",
    country_number_code: "53"
  },
  {
    name: "Curacao",
    country_abb: "CW",
    three_letter_code: "CUW",
    country_number_code: "599"
  },
  {
    name: "Cyprus",
    country_abb: "CY",
    three_letter_code: "CYP",
    country_number_code: "357"
  },
  {
    name: "Czech Republic",
    country_abb: "CZ",
    three_letter_code: "CZE",
    country_number_code: "420"
  },
  {
    name: "Democratic Republic of the Congo",
    country_abb: "CD",
    three_letter_code: "COD",
    country_number_code: "243"
  },
  {
    name: "Denmark",
    country_abb: "DK",
    three_letter_code: "DNK",
    country_number_code: "45"
  },
  {
    name: "Djibouti",
    country_abb: "DJ",
    three_letter_code: "DJI",
    country_number_code: "253"
  },
  {
    name: "Dominica",
    country_abb: "DM",
    three_letter_code: "DMA",
    country_number_code: "1-767"
  },
  {
    name: "Dominican Republic",
    country_abb: "DO",
    three_letter_code: "DOM",
    country_number_code: "1-809, 1-829, 1-849"
  },
  {
    name: "East Timor",
    country_abb: "TL",
    three_letter_code: "TLS",
    country_number_code: "670"
  },
  {
    name: "Ecuador",
    country_abb: "EC",
    three_letter_code: "ECU",
    country_number_code: "593"
  },
  {
    name: "Egypt",
    country_abb: "EG",
    three_letter_code: "EGY",
    country_number_code: "20"
  },
  {
    name: "El Salvador",
    country_abb: "SV",
    three_letter_code: "SLV",
    country_number_code: "503"
  },
  {
    name: "Equatorial Guinea",
    country_abb: "GQ",
    three_letter_code: "GNQ",
    country_number_code: "240"
  },
  {
    name: "Eritrea",
    country_abb: "ER",
    three_letter_code: "ERI",
    country_number_code: "291"
  },
  {
    name: "Estonia",
    country_abb: "EE",
    three_letter_code: "EST",
    country_number_code: "372"
  },
  {
    name: "Ethiopia",
    country_abb: "ET",
    three_letter_code: "ETH",
    country_number_code: "251"
  },
  {
    name: "Falkland Islands",
    country_abb: "FK",
    three_letter_code: "FLK",
    country_number_code: "500"
  },
  {
    name: "Faroe Islands",
    country_abb: "FO",
    three_letter_code: "FRO",
    country_number_code: "298"
  },
  {
    name: "Fiji",
    country_abb: "FJ",
    three_letter_code: "FJI",
    country_number_code: "679"
  },
  {
    name: "Finland",
    country_abb: "FI",
    three_letter_code: "FIN",
    country_number_code: "358"
  },
  {
    name: "France",
    country_abb: "FR",
    three_letter_code: "FRA",
    country_number_code: "33"
  },
  {
    name: "French Polynesia",
    country_abb: "PF",
    three_letter_code: "PYF",
    country_number_code: "689"
  },
  {
    name: "Gabon",
    country_abb: "GA",
    three_letter_code: "GAB",
    country_number_code: "241"
  },
  {
    name: "Gambia",
    country_abb: "GM",
    three_letter_code: "GMB",
    country_number_code: "220"
  },
  {
    name: "Georgia",
    country_abb: "GE",
    three_letter_code: "GEO",
    country_number_code: "995"
  },
  {
    name: "Germany",
    country_abb: "DE",
    three_letter_code: "DEU",
    country_number_code: "49"
  },
  {
    name: "Ghana",
    country_abb: "GH",
    three_letter_code: "GHA",
    country_number_code: "233"
  },
  {
    name: "Gibraltar",
    country_abb: "GI",
    three_letter_code: "GIB",
    country_number_code: "350"
  },
  {
    name: "Greece",
    country_abb: "GR",
    three_letter_code: "GRC",
    country_number_code: "30"
  },
  {
    name: "Greenland",
    country_abb: "GL",
    three_letter_code: "GRL",
    country_number_code: "299"
  },
  {
    name: "Grenada",
    country_abb: "GD",
    three_letter_code: "GRD",
    country_number_code: "1-473"
  },
  {
    name: "Guam",
    country_abb: "GU",
    three_letter_code: "GUM",
    country_number_code: "1-671"
  },
  {
    name: "Guatemala",
    country_abb: "GT",
    three_letter_code: "GTM",
    country_number_code: "502"
  },
  {
    name: "Guernsey",
    country_abb: "GG",
    three_letter_code: "GGY",
    country_number_code: "44-1481"
  },
  {
    name: "Guinea",
    country_abb: "GN",
    three_letter_code: "GIN",
    country_number_code: "224"
  },
  {
    name: "Guinea-Bissau",
    country_abb: "GW",
    three_letter_code: "GNB",
    country_number_code: "245"
  },
  {
    name: "Guyana",
    country_abb: "GY",
    three_letter_code: "GUY",
    country_number_code: "592"
  },
  {
    name: "Haiti",
    country_abb: "HT",
    three_letter_code: "HTI",
    country_number_code: "509"
  },
  {
    name: "Honduras",
    country_abb: "HN",
    three_letter_code: "HND",
    country_number_code: "504"
  },
  {
    name: "Hong Kong",
    country_abb: "HK",
    three_letter_code: "HKG",
    country_number_code: "852"
  },
  {
    name: "Hungary",
    country_abb: "HU",
    three_letter_code: "HUN",
    country_number_code: "36"
  },
  {
    name: "Iceland",
    country_abb: "IS",
    three_letter_code: "ISL",
    country_number_code: "354"
  },
  {
    name: "India",
    country_abb: "IN",
    three_letter_code: "IND",
    country_number_code: "91"
  },
  {
    name: "Indonesia",
    country_abb: "ID",
    three_letter_code: "IDN",
    country_number_code: "62"
  },
  {
    name: "Iran",
    country_abb: "IR",
    three_letter_code: "IRN",
    country_number_code: "98"
  },
  {
    name: "Iraq",
    country_abb: "IQ",
    three_letter_code: "IRQ",
    country_number_code: "964"
  },
  {
    name: "Ireland",
    country_abb: "IE",
    three_letter_code: "IRL",
    country_number_code: "353"
  },
  {
    name: "Isle of Man",
    country_abb: "IM",
    three_letter_code: "IMN",
    country_number_code: "44-1624"
  },
  {
    name: "Israel",
    country_abb: "IL",
    three_letter_code: "ISR",
    country_number_code: "972"
  },
  {
    name: "Italy",
    country_abb: "IT",
    three_letter_code: "ITA",
    country_number_code: "39"
  },
  {
    name: "Ivory Coast",
    country_abb: "CI",
    three_letter_code: "CIV",
    country_number_code: "225"
  },
  {
    name: "Jamaica",
    country_abb: "JM",
    three_letter_code: "JAM",
    country_number_code: "1-876"
  },
  {
    name: "Japan",
    country_abb: "JP",
    three_letter_code: "JPN",
    country_number_code: "81"
  },
  {
    name: "Jersey",
    country_abb: "JE",
    three_letter_code: "JEY",
    country_number_code: "44-1534"
  },
  {
    name: "Jordan",
    country_abb: "JO",
    three_letter_code: "JOR",
    country_number_code: "962"
  },
  {
    name: "Kazakhstan",
    country_abb: "KZ",
    three_letter_code: "KAZ",
    country_number_code: "7"
  },
  {
    name: "Kenya",
    country_abb: "KE",
    three_letter_code: "KEN",
    country_number_code: "254"
  },
  {
    name: "Kiribati",
    country_abb: "KI",
    three_letter_code: "KIR",
    country_number_code: "686"
  },
  {
    name: "Kosovo",
    country_abb: "XK",
    three_letter_code: "XKX",
    country_number_code: "383"
  },
  {
    name: "Kuwait",
    country_abb: "KW",
    three_letter_code: "KWT",
    country_number_code: "965"
  },
  {
    name: "Kyrgyzstan",
    country_abb: "KG",
    three_letter_code: "KGZ",
    country_number_code: "996"
  },
  {
    name: "Laos",
    country_abb: "LA",
    three_letter_code: "LAO",
    country_number_code: "856"
  },
  {
    name: "Latvia",
    country_abb: "LV",
    three_letter_code: "LVA",
    country_number_code: "371"
  },
  {
    name: "Lebanon",
    country_abb: "LB",
    three_letter_code: "LBN",
    country_number_code: "961"
  },
  {
    name: "Lesotho",
    country_abb: "LS",
    three_letter_code: "LSO",
    country_number_code: "266"
  },
  {
    name: "Liberia",
    country_abb: "LR",
    three_letter_code: "LBR",
    country_number_code: "231"
  },
  {
    name: "Libya",
    country_abb: "LY",
    three_letter_code: "LBY",
    country_number_code: "218"
  },
  {
    name: "Liechtenstein",
    country_abb: "LI",
    three_letter_code: "LIE",
    country_number_code: "423"
  },
  {
    name: "Lithuania",
    country_abb: "LT",
    three_letter_code: "LTU",
    country_number_code: "370"
  },
  {
    name: "Luxembourg",
    country_abb: "LU",
    three_letter_code: "LUX",
    country_number_code: "352"
  },
  {
    name: "Macau",
    country_abb: "MO",
    three_letter_code: "MAC",
    country_number_code: "853"
  },
  {
    name: "Macedonia",
    country_abb: "MK",
    three_letter_code: "MKD",
    country_number_code: "389"
  },
  {
    name: "Madagascar",
    country_abb: "MG",
    three_letter_code: "MDG",
    country_number_code: "261"
  },
  {
    name: "Malawi",
    country_abb: "MW",
    three_letter_code: "MWI",
    country_number_code: "265"
  },
  {
    name: "Malaysia",
    country_abb: "MY",
    three_letter_code: "MYS",
    country_number_code: "60"
  },
  {
    name: "Maldives",
    country_abb: "MV",
    three_letter_code: "MDV",
    country_number_code: "960"
  },
  {
    name: "Mali",
    country_abb: "ML",
    three_letter_code: "MLI",
    country_number_code: "223"
  },
  {
    name: "Malta",
    country_abb: "MT",
    three_letter_code: "MLT",
    country_number_code: "356"
  },
  {
    name: "Marshall Islands",
    country_abb: "MH",
    three_letter_code: "MHL",
    country_number_code: "692"
  },
  {
    name: "Mauritania",
    country_abb: "MR",
    three_letter_code: "MRT",
    country_number_code: "222"
  },
  {
    name: "Mauritius",
    country_abb: "MU",
    three_letter_code: "MUS",
    country_number_code: "230"
  },
  {
    name: "Mayotte",
    country_abb: "YT",
    three_letter_code: "MYT",
    country_number_code: "262"
  },
  {
    name: "Mexico",
    country_abb: "MX",
    three_letter_code: "MEX",
    country_number_code: "52"
  },
  {
    name: "Micronesia",
    country_abb: "FM",
    three_letter_code: "FSM",
    country_number_code: "691"
  },
  {
    name: "Moldova",
    country_abb: "MD",
    three_letter_code: "MDA",
    country_number_code: "373"
  },
  {
    name: "Monaco",
    country_abb: "MC",
    three_letter_code: "MCO",
    country_number_code: "377"
  },
  {
    name: "Mongolia",
    country_abb: "MN",
    three_letter_code: "MNG",
    country_number_code: "976"
  },
  {
    name: "Montenegro",
    country_abb: "ME",
    three_letter_code: "MNE",
    country_number_code: "382"
  },
  {
    name: "Montserrat",
    country_abb: "MS",
    three_letter_code: "MSR",
    country_number_code: "1-664"
  },
  {
    name: "Morocco",
    country_abb: "MA",
    three_letter_code: "MAR",
    country_number_code: "212"
  },
  {
    name: "Mozambique",
    country_abb: "MZ",
    three_letter_code: "MOZ",
    country_number_code: "258"
  },
  {
    name: "Myanmar",
    country_abb: "MM",
    three_letter_code: "MMR",
    country_number_code: "95"
  },
  {
    name: "Namibia",
    country_abb: "NA",
    three_letter_code: "NAM",
    country_number_code: "264"
  },
  {
    name: "Nauru",
    country_abb: "NR",
    three_letter_code: "NRU",
    country_number_code: "674"
  },
  {
    name: "Nepal",
    country_abb: "NP",
    three_letter_code: "NPL",
    country_number_code: "977"
  },
  {
    name: "Netherlands",
    country_abb: "NL",
    three_letter_code: "NLD",
    country_number_code: "31"
  },
  {
    name: "Netherlands Antilles",
    country_abb: "AN",
    three_letter_code: "ANT",
    country_number_code: "599"
  },
  {
    name: "New Caledonia",
    country_abb: "NC",
    three_letter_code: "NCL",
    country_number_code: "687"
  },
  {
    name: "New Zealand",
    country_abb: "NZ",
    three_letter_code: "NZL",
    country_number_code: "64"
  },
  {
    name: "Nicaragua",
    country_abb: "NI",
    three_letter_code: "NIC",
    country_number_code: "505"
  },
  {
    name: "Niger",
    country_abb: "NE",
    three_letter_code: "NER",
    country_number_code: "227"
  },
  {
    name: "Nigeria",
    country_abb: "NG",
    three_letter_code: "NGA",
    country_number_code: "234"
  },
  {
    name: "Niue",
    country_abb: "NU",
    three_letter_code: "NIU",
    country_number_code: "683"
  },
  {
    name: "North Korea",
    country_abb: "KP",
    three_letter_code: "PRK",
    country_number_code: "850"
  },
  {
    name: "Northern Mariana Islands",
    country_abb: "MP",
    three_letter_code: "MNP",
    country_number_code: "1-670"
  },
  {
    name: "Norway",
    country_abb: "NO",
    three_letter_code: "NOR",
    country_number_code: "47"
  },
  {
    name: "Oman",
    country_abb: "OM",
    three_letter_code: "OMN",
    country_number_code: "968"
  },
  {
    name: "Pakistan",
    country_abb: "PK",
    three_letter_code: "PAK",
    country_number_code: "92"
  },
  {
    name: "Palau",
    country_abb: "PW",
    three_letter_code: "PLW",
    country_number_code: "680"
  },
  {
    name: "Palestine",
    country_abb: "PS",
    three_letter_code: "PSE",
    country_number_code: "970"
  },
  {
    name: "Panama",
    country_abb: "PA",
    three_letter_code: "PAN",
    country_number_code: "507"
  },
  {
    name: "Papua New Guinea",
    country_abb: "PG",
    three_letter_code: "PNG",
    country_number_code: "675"
  },
  {
    name: "Paraguay",
    country_abb: "PY",
    three_letter_code: "PRY",
    country_number_code: "595"
  },
  {
    name: "Peru",
    country_abb: "PE",
    three_letter_code: "PER",
    country_number_code: "51"
  },
  {
    name: "Philippines",
    country_abb: "PH",
    three_letter_code: "PHL",
    country_number_code: "63"
  },
  {
    name: "Pitcairn",
    country_abb: "PN",
    three_letter_code: "PCN",
    country_number_code: "64"
  },
  {
    name: "Poland",
    country_abb: "PL",
    three_letter_code: "POL",
    country_number_code: "48"
  },
  {
    name: "Portugal",
    country_abb: "PT",
    three_letter_code: "PRT",
    country_number_code: "351"
  },
  {
    name: "Puerto Rico",
    country_abb: "PR",
    three_letter_code: "PRI",
    country_number_code: "1-787, 1-939"
  },
  {
    name: "Qatar",
    country_abb: "QA",
    three_letter_code: "QAT",
    country_number_code: "974"
  },
  {
    name: "Republic of the Congo",
    country_abb: "CG",
    three_letter_code: "COG",
    country_number_code: "242"
  },
  {
    name: "Reunion",
    country_abb: "RE",
    three_letter_code: "REU",
    country_number_code: "262"
  },
  {
    name: "Romania",
    country_abb: "RO",
    three_letter_code: "ROU",
    country_number_code: "40"
  },
  {
    name: "Russia",
    country_abb: "RU",
    three_letter_code: "RUS",
    country_number_code: "7"
  },
  {
    name: "Rwanda",
    country_abb: "RW",
    three_letter_code: "RWA",
    country_number_code: "250"
  },
  {
    name: "Saint Barthelemy",
    country_abb: "BL",
    three_letter_code: "BLM",
    country_number_code: "590"
  },
  {
    name: "Saint Helena",
    country_abb: "SH",
    three_letter_code: "SHN",
    country_number_code: "290"
  },
  {
    name: "Saint Kitts and Nevis",
    country_abb: "KN",
    three_letter_code: "KNA",
    country_number_code: "1-869"
  },
  {
    name: "Saint Lucia",
    country_abb: "LC",
    three_letter_code: "LCA",
    country_number_code: "1-758"
  },
  {
    name: "Saint Martin",
    country_abb: "MF",
    three_letter_code: "MAF",
    country_number_code: "590"
  },
  {
    name: "Saint Pierre and Miquelon",
    country_abb: "PM",
    three_letter_code: "SPM",
    country_number_code: "508"
  },
  {
    name: "Saint Vincent and the Grenadines",
    country_abb: "VC",
    three_letter_code: "VCT",
    country_number_code: "1-784"
  },
  {
    name: "Samoa",
    country_abb: "WS",
    three_letter_code: "WSM",
    country_number_code: "685"
  },
  {
    name: "San Marino",
    country_abb: "SM",
    three_letter_code: "SMR",
    country_number_code: "378"
  },
  {
    name: "Sao Tome and Principe",
    country_abb: "ST",
    three_letter_code: "STP",
    country_number_code: "239"
  },
  {
    name: "Saudi Arabia",
    country_abb: "SA",
    three_letter_code: "SAU",
    country_number_code: "966"
  },
  {
    name: "Senegal",
    country_abb: "SN",
    three_letter_code: "SEN",
    country_number_code: "221"
  },
  {
    name: "Serbia",
    country_abb: "RS",
    three_letter_code: "SRB",
    country_number_code: "381"
  },
  {
    name: "Seychelles",
    country_abb: "SC",
    three_letter_code: "SYC",
    country_number_code: "248"
  },
  {
    name: "Sierra Leone",
    country_abb: "SL",
    three_letter_code: "SLE",
    country_number_code: "232"
  },
  {
    name: "Singapore",
    country_abb: "SG",
    three_letter_code: "SGP",
    country_number_code: "65"
  },
  {
    name: "Sint Maarten",
    country_abb: "SX",
    three_letter_code: "SXM",
    country_number_code: "1-721"
  },
  {
    name: "Slovakia",
    country_abb: "SK",
    three_letter_code: "SVK",
    country_number_code: "421"
  },
  {
    name: "Slovenia",
    country_abb: "SI",
    three_letter_code: "SVN",
    country_number_code: "386"
  },
  {
    name: "Solomon Islands",
    country_abb: "SB",
    three_letter_code: "SLB",
    country_number_code: "677"
  },
  {
    name: "Somalia",
    country_abb: "SO",
    three_letter_code: "SOM",
    country_number_code: "252"
  },
  {
    name: "South Africa",
    country_abb: "ZA",
    three_letter_code: "ZAF",
    country_number_code: "27"
  },
  {
    name: "South Korea",
    country_abb: "KR",
    three_letter_code: "KOR",
    country_number_code: "82"
  },
  {
    name: "South Sudan",
    country_abb: "SS",
    three_letter_code: "SSD",
    country_number_code: "211"
  },
  {
    name: "Spain",
    country_abb: "ES",
    three_letter_code: "ESP",
    country_number_code: "34"
  },
  {
    name: "Sri Lanka",
    country_abb: "LK",
    three_letter_code: "LKA",
    country_number_code: "94"
  },
  {
    name: "Sudan",
    country_abb: "SD",
    three_letter_code: "SDN",
    country_number_code: "249"
  },
  {
    name: "Suriname",
    country_abb: "SR",
    three_letter_code: "SUR",
    country_number_code: "597"
  },
  {
    name: "Svalbard and Jan Mayen",
    country_abb: "SJ",
    three_letter_code: "SJM",
    country_number_code: "47"
  },
  {
    name: "Swaziland",
    country_abb: "SZ",
    three_letter_code: "SWZ",
    country_number_code: "268"
  },
  {
    name: "Sweden",
    country_abb: "SE",
    three_letter_code: "SWE",
    country_number_code: "46"
  },
  {
    name: "Switzerland",
    country_abb: "CH",
    three_letter_code: "CHE",
    country_number_code: "41"
  },
  {
    name: "Syria",
    country_abb: "SY",
    three_letter_code: "SYR",
    country_number_code: "963"
  },
  {
    name: "Taiwan",
    country_abb: "TW",
    three_letter_code: "TWN",
    country_number_code: "886"
  },
  {
    name: "Tajikistan",
    country_abb: "TJ",
    three_letter_code: "TJK",
    country_number_code: "992"
  },
  {
    name: "Tanzania",
    country_abb: "TZ",
    three_letter_code: "TZA",
    country_number_code: "255"
  },
  {
    name: "Thailand",
    country_abb: "TH",
    three_letter_code: "THA",
    country_number_code: "66"
  },
  {
    name: "Togo",
    country_abb: "TG",
    three_letter_code: "TGO",
    country_number_code: "228"
  },
  {
    name: "Tokelau",
    country_abb: "TK",
    three_letter_code: "TKL",
    country_number_code: "690"
  },
  {
    name: "Tonga",
    country_abb: "TO",
    three_letter_code: "TON",
    country_number_code: "676"
  },
  {
    name: "Trinidad and Tobago",
    country_abb: "TT",
    three_letter_code: "TTO",
    country_number_code: "1-868"
  },
  {
    name: "Tunisia",
    country_abb: "TN",
    three_letter_code: "TUN",
    country_number_code: "216"
  },
  {
    name: "Turkey",
    country_abb: "TR",
    three_letter_code: "TUR",
    country_number_code: "90"
  },
  {
    name: "Turkmenistan",
    country_abb: "TM",
    three_letter_code: "TKM",
    country_number_code: "993"
  },
  {
    name: "Turks and Caicos Islands",
    country_abb: "TC",
    three_letter_code: "TCA",
    country_number_code: "1-649"
  },
  {
    name: "Tuvalu",
    country_abb: "TV",
    three_letter_code: "TUV",
    country_number_code: "688"
  },
  {
    name: "U.S. Virgin Islands",
    country_abb: "VI",
    three_letter_code: "VIR",
    country_number_code: "1-340"
  },
  {
    name: "Uganda",
    country_abb: "UG",
    three_letter_code: "UGA",
    country_number_code: "256"
  },
  {
    name: "Ukraine",
    country_abb: "UA",
    three_letter_code: "UKR",
    country_number_code: "380"
  },
  {
    name: "United Arab Emirates",
    country_abb: "AE",
    three_letter_code: "ARE",
    country_number_code: "971"
  },
  {
    name: "United Kingdom",
    country_abb: "GB",
    three_letter_code: "GBR",
    country_number_code: "44"
  },
  {
    name: "United States",
    country_abb: "US",
    three_letter_code: "USA",
    country_number_code: "1"
  },
  {
    name: "Uruguay",
    country_abb: "UY",
    three_letter_code: "URY",
    country_number_code: "598"
  },
  {
    name: "Uzbekistan",
    country_abb: "UZ",
    three_letter_code: "UZB",
    country_number_code: "998"
  },
  {
    name: "Vanuatu",
    country_abb: "VU",
    three_letter_code: "VUT",
    country_number_code: "678"
  },
  {
    name: "Vatican",
    country_abb: "VA",
    three_letter_code: "VAT",
    country_number_code: "379"
  },
  {
    name: "Venezuela",
    country_abb: "VE",
    three_letter_code: "VEN",
    country_number_code: "58"
  },
  {
    name: "Vietnam",
    country_abb: "VN",
    three_letter_code: "VNM",
    country_number_code: "84"
  },
  {
    name: "Wallis and Futuna",
    country_abb: "WF",
    three_letter_code: "WLF",
    country_number_code: "681"
  },
  {
    name: "Western Sahara",
    country_abb: "EH",
    three_letter_code: "ESH",
    country_number_code: "212"
  },
  {
    name: "Yemen",
    country_abb: "YE",
    three_letter_code: "YEM",
    country_number_code: "967"
  },
  {
    name: "Zambia",
    country_abb: "ZM",
    three_letter_code: "ZMB",
    country_number_code: "260"
  },
  {
    name: "Zimbabwe",
    country_abb: "ZW",
    three_letter_code: "ZWE",
    country_number_code: "263"
  }
];

// Defaults to US
export const DefaultCountry: Country = {
  name: "United States",
  country_abb: "US",
  three_letter_code: "USA",
  country_number_code: "1",
  image: "/flags/4x3/us.svg"
};
export const DefaultCountryTemplate = "(XXX) XXX-XXXX";

// Add images to all Country Objects
Countries.forEach((c) => {
  c.image = `/flags/4x3/${c.country_abb.toLowerCase()}.svg`;
});

// This file is a workaround for a bug in web browsers' "native"
// ES6 importing system which is uncapable of importing "*.json" files.
// https://github.com/catamphetamine/libphonenumber-js/issues/239
export const ValidCountryNumbers = {
  "AC": "40123",
  "AD": "312345",
  "AE": "501234567",
  "AF": "701234567",
  "AG": "2684641234",
  "AI": "2642351234",
  "AL": "672123456",
  "AM": "77123456",
  "AO": "923123456",
  "AR": "91123456789",
  "AS": "6847331234",
  "AT": "664123456",
  "AU": "412345678",
  "AW": "5601234",
  "AX": "412345678",
  "AZ": "401234567",
  "BA": "61123456",
  "BB": "2462501234",
  "BD": "1812345678",
  "BE": "470123456",
  "BF": "70123456",
  "BG": "43012345",
  "BH": "36001234",
  "BI": "79561234",
  "BJ": "90011234",
  "BL": "690001234",
  "BM": "4413701234",
  "BN": "7123456",
  "BO": "71234567",
  "BQ": "3181234",
  "BR": "11961234567",
  "BS": "2423591234",
  "BT": "17123456",
  "BW": "71123456",
  "BY": "294911911",
  "BZ": "6221234",
  "CA": "5062345678",
  "CC": "412345678",
  "CD": "991234567",
  "CF": "70012345",
  "CG": "061234567",
  "CH": "781234567",
  "CI": "0123456789",
  "CK": "71234",
  "CL": "221234567",
  "CM": "671234567",
  "CN": "13123456789",
  "CO": "3211234567",
  "CR": "83123456",
  "CU": "51234567",
  "CV": "9911234",
  "CW": "95181234",
  "CX": "412345678",
  "CY": "96123456",
  "CZ": "601123456",
  "DE": "15123456789",
  "DJ": "77831001",
  "DK": "32123456",
  "DM": "7672251234",
  "DO": "8092345678",
  "DZ": "551234567",
  "EC": "991234567",
  "EE": "51234567",
  "EG": "1001234567",
  "EH": "650123456",
  "ER": "7123456",
  "ES": "612345678",
  "ET": "911234567",
  "FI": "412345678",
  "FJ": "7012345",
  "FK": "51234",
  "FM": "3501234",
  "FO": "211234",
  "FR": "612345678",
  "GA": "06031234",
  "GB": "7400123456",
  "GD": "4734031234",
  "GE": "555123456",
  "GF": "694201234",
  "GG": "7781123456",
  "GH": "231234567",
  "GI": "57123456",
  "GL": "221234",
  "GM": "3012345",
  "GN": "601123456",
  "GP": "690001234",
  "GQ": "222123456",
  "GR": "6912345678",
  "GT": "51234567",
  "GU": "6713001234",
  "GW": "955012345",
  "GY": "6091234",
  "HK": "51234567",
  "HN": "91234567",
  "HR": "921234567",
  "HT": "34101234",
  "HU": "201234567",
  "ID": "812345678",
  "IE": "850123456",
  "IL": "502345678",
  "IM": "7924123456",
  "IN": "8123456789",
  "IO": "3801234",
  "IQ": "7912345678",
  "IR": "9123456789",
  "IS": "6111234",
  "IT": "3123456789",
  "JE": "7797712345",
  "JM": "8762101234",
  "JO": "790123456",
  "JP": "9012345678",
  "KE": "712123456",
  "KG": "700123456",
  "KH": "91234567",
  "KI": "72001234",
  "KM": "3212345",
  "KN": "8697652917",
  "KP": "1921234567",
  "KR": "1020000000",
  "KW": "50012345",
  "KY": "3453231234",
  "KZ": "7710009998",
  "LA": "2023123456",
  "LB": "71123456",
  "LC": "7582845678",
  "LI": "660234567",
  "LK": "712345678",
  "LR": "770123456",
  "LS": "50123456",
  "LT": "61234567",
  "LU": "628123456",
  "LV": "21234567",
  "LY": "912345678",
  "MA": "650123456",
  "MC": "612345678",
  "MD": "62112345",
  "ME": "67622901",
  "MF": "690001234",
  "MG": "321234567",
  "MH": "2351234",
  "MK": "72345678",
  "ML": "65012345",
  "MM": "92123456",
  "MN": "88123456",
  "MO": "66123456",
  "MP": "6702345678",
  "MQ": "696201234",
  "MR": "22123456",
  "MS": "6644923456",
  "MT": "96961234",
  "MU": "52512345",
  "MV": "7712345",
  "MW": "991234567",
  "MX": "12221234567",
  "MY": "123456789",
  "MZ": "821234567",
  "NA": "811234567",
  "NC": "751234",
  "NE": "93123456",
  "NF": "381234",
  "NG": "8021234567",
  "NI": "81234567",
  "NL": "612345678",
  "NO": "40612345",
  "NP": "9841234567",
  "NR": "5551234",
  "NU": "8884012",
  "NZ": "211234567",
  "OM": "92123456",
  "PA": "61234567",
  "PE": "912345678",
  "PF": "87123456",
  "PG": "70123456",
  "PH": "9051234567",
  "PK": "3012345678",
  "PL": "512345678",
  "PM": "551234",
  "PR": "7872345678",
  "PS": "599123456",
  "PT": "912345678",
  "PW": "6201234",
  "PY": "961456789",
  "QA": "33123456",
  "RE": "692123456",
  "RO": "712034567",
  "RS": "601234567",
  "RU": "9123456789",
  "RW": "720123456",
  "SA": "512345678",
  "SB": "7421234",
  "SC": "2510123",
  "SD": "911231234",
  "SE": "701234567",
  "SG": "81234567",
  "SH": "51234",
  "SI": "31234567",
  "SJ": "41234567",
  "SK": "912123456",
  "SL": "25123456",
  "SM": "66661212",
  "SN": "701234567",
  "SO": "71123456",
  "SR": "7412345",
  "SS": "977123456",
  "ST": "9812345",
  "SV": "70123456",
  "SX": "7215205678",
  "SY": "944567890",
  "SZ": "76123456",
  "TA": "8999",
  "TC": "6492311234",
  "TD": "63012345",
  "TG": "90112345",
  "TH": "812345678",
  "TJ": "917123456",
  "TK": "7290",
  "TL": "77212345",
  "TM": "66123456",
  "TN": "20123456",
  "TO": "7715123",
  "TR": "5012345678",
  "TT": "8682911234",
  "TV": "901234",
  "TW": "912345678",
  "TZ": "621234567",
  "UA": "501234567",
  "UG": "712345678",
  "US": "2015550123",
  "UY": "94231234",
  "UZ": "912345678",
  "VA": "3123456789",
  "VC": "7844301234",
  "VE": "4121234567",
  "VG": "2843001234",
  "VI": "3406421234",
  "VN": "912345678",
  "VU": "5912345",
  "WF": "821234",
  "WS": "7212345",
  "XK": "43201234",
  "YE": "712345678",
  "YT": "639012345",
  "ZA": "711234567",
  "ZM": "955123456",
  "ZW": "712345678"
};
