import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OrgInfo } from "@preveil-api";
import { useAppSelector, Account, OrgUserRoles, DefaultRoutes } from "src/common";
import { EmailGateway, EmailGroup, AdminUsers, DataExport, AdminActivityLogs, TrustedCommunity, AdminSettings, ApprovalRequests, AdminApprovalGroups } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  page: "groups" | "gateway" | "users" | "dataExport" | "activityLogs" | "requests" | "settings" | "trustedCommunity" | "approvalGroups";
}

type RenderPageProps = {
  current_account: Account;
  org_info: OrgInfo;
} & AllProps;


function RenderPage({ page, current_account, org_info }: RenderPageProps) {
  const adminPage = {
    groups: <EmailGroup account={current_account} org_info={org_info} />,
    gateway: <EmailGateway account={current_account} org_info={org_info} />,
    users: <AdminUsers account={current_account} org_info={org_info} />,
    dataExport: <DataExport account={current_account} org_info={org_info} />,
    activityLogs: <AdminActivityLogs />,
    approvalGroups: <AdminApprovalGroups current_account={current_account} org_info={org_info} />,
    requests: <ApprovalRequests current_account={current_account} org_info={org_info} />,
    trustedCommunity: <TrustedCommunity />,
    settings: <AdminSettings />
  };
  return adminPage[page];
}

function AdminWrapper({ page }: AllProps) {
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const org_info = useAppSelector((state: RootState) => state.account.organization);
  const navigate = useNavigate();
  const [is_admin, setIsAdmin] = useState<boolean>(false);

  // Bounce out of the Admin module if its not admin user
  useEffect(() => {
    const is_admin = !!org_info && org_info.role === OrgUserRoles.admin;
    if (is_admin) {
      // Note: some of the calls to the API were still happening for some of the pages (Trusted Community, Activity Logs and Email Gateway)
      // and an error toaster would pop up while the user was being redirected to the mail page.
      // This was fixed by adding the check for is_admin in the useEffect hook.
      setIsAdmin(is_admin);
    } else if (!is_admin) {
      navigate(`${DefaultRoutes.mail_default}`);
    }
  }, [current_account]);

  return <>
    {
      (!!current_account && !!org_info && is_admin) &&
      <RenderPage page={page} current_account={current_account} org_info={org_info} />
    }
  </>;
}

export default AdminWrapper;
