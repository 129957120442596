import { ApproversList, CollectionServerUser, ExportShard, LogKey, RecoveryShard } from "@preveil-api";
import { FipsCrypto } from "pvcryptojs";
import { Account, AccountUserKey } from "../account/account.class";
import { PutUsersCollectionGrantApiArg } from "../api/collection/collection-rtk-api";
import { Helpers } from "../helpers/helpers.class";
import { KeyFactory } from "../keys/factory";
import { create } from "../keys/secret-sharer/secret-sharer";
import { AppUserKey, RemoteUserKey } from "../keys/user-keys";

export interface RekeyData {
  proposed_key: AppUserKey;
  wrapped_last_key: Uint8Array;
}

async function asymmetricSeal(key: Uint8Array, secret_shard: Uint8Array): Promise<Uint8Array> {
  const symm_key = await KeyFactory.newSymmKey({ key });
  return await symm_key.encrypt(secret_shard);
}

// Description: Takes a generated recovery shard and seals it with the recipients public key
async function wrappedRecoveryShard(
  secrets: string[] | undefined, approver_user: CollectionServerUser, sharer_key: AccountUserKey | RemoteUserKey, required: boolean
): Promise<RecoveryShard> {
  const secret = secrets?.pop();
  if (!secret) return await Promise.reject(new Error("Secrets list is empty"));
  const secret_data = Helpers.utf8Encode(secret);
  const public_user_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(approver_user.public_key));
  if (sharer_key.signing_key.protocol_version > 1 || public_user_key.protocol_version > 2) {
    const secret_map = {
      shard: Helpers.b64Encode(await public_user_key.public_key.seal(secret_data)),
      signature: Helpers.b64Encode(await sharer_key.signing_key.sign(secret_data))
    };
    return {
      user_id: approver_user.user_id,
      required,
      account_version: approver_user.account_version || 0,
      secret: JSON.stringify(secret_map),
      key_version: approver_user.key_version || 0,
      protocol_version: 2
    };
  }
  const private_key = sharer_key.encryption_key.private_key;
  if (!private_key) return await Promise.reject(new Error("Sharer private key is undefined"));
  const shared_key = await FipsCrypto.sharedSecret(private_key, public_user_key.public_key.public_key, false);
  const encrypted_shard = await asymmetricSeal(shared_key, secret_data);
  return {
    user_id: approver_user.user_id,
    required,
    account_version: approver_user.account_version || 0,
    secret: Helpers.b64Encode(encrypted_shard),
    key_version: approver_user.key_version || 0,
    protocol_version: 1
  };
}

// Description: Takes a generated export shard and seals it with the recipients public key
async function wrappedExportShard(
  approver_user: CollectionServerUser, sharer_key: AccountUserKey, wrapped_key_version: number, secrets: string[] | undefined
): Promise<ExportShard> {
  const secret = secrets?.pop();
  if (!secret) return await Promise.reject(new Error("Secrets list is empty"));
  const secret_data = Helpers.utf8Encode(secret);
  const public_user_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(approver_user.public_key));
  if (sharer_key.signing_key.protocol_version > 1 || public_user_key.protocol_version > 2) {
    const secret_map = {
      shard: Helpers.b64Encode(await public_user_key.public_key.seal(secret_data)),
      signature: Helpers.b64Encode(await sharer_key.signing_key.sign(secret_data))
    };
    return {
      user_id: approver_user.user_id,
      key_version: approver_user.account_version || 0,
      secret: JSON.stringify(secret_map),
      wrapped_key_version,
      sharder_key_version: sharer_key.key_version,
      protocol_version: 2
    };
  }
  const private_key = sharer_key.encryption_key.private_key;
  if (!private_key) return await Promise.reject(new Error("Sharer private key is undefined"));
  const shared_key = await FipsCrypto.sharedSecret(private_key, public_user_key.public_key.public_key, false);
  const encrypted_shard = await asymmetricSeal(shared_key, secret_data);
  return {
    user_id: approver_user.user_id,
    key_version: approver_user.account_version || 0,
    secret: Helpers.b64Encode(encrypted_shard),
    wrapped_key_version,
    sharder_key_version: sharer_key.key_version,
    protocol_version: 1
  };
}

// Description: Creates shards of the current user's user keys for each user in the provided recovery group 
export async function generateRecoverySecrets(user_key: AppUserKey, recovery_group: ApproversList, users: CollectionServerUser[]): Promise<RecoveryShard[]> {
  const users_by_id: { [key: string]: CollectionServerUser } = {};
  for (const user of users) {
    users_by_id[user.user_id.toLowerCase()] = user;
  }
  const optional_approvers = recovery_group.approvers.filter(approver => !approver.required);
  const required_approvers = recovery_group.approvers.filter(approver => approver.required);
  let required_count = required_approvers.length;
  if (recovery_group.optionals_required > 0) {
    required_count++;
  }
  const required_secrets = create(required_count, required_count, Helpers.b64Encode(user_key.serialize()));
  if (!required_secrets) return await Promise.reject(new Error("List of required secrets is undefined"));
  const raw_secret = required_secrets.pop();
  if (!raw_secret) return await Promise.reject(new Error("List of required secrets is empty"));
  const optional_secrets = create(recovery_group.optionals_required, optional_approvers.length, raw_secret);
  const recovery_shards: RecoveryShard[] = [];
  for (const approver of required_approvers) {
    recovery_shards.push(await wrappedRecoveryShard(required_secrets, users_by_id[approver.user_id.toLowerCase()], user_key, true));
  }
  for (const approver of optional_approvers) {
    recovery_shards.push(await wrappedRecoveryShard(optional_secrets, users_by_id[approver.user_id.toLowerCase()], user_key, false));
  }
  return recovery_shards;
}

// Description: Creates a proposed user key and wraps the old key with the new. To be used with the
// Key Storage Server rekey API
export async function rekeyData(user_key: AccountUserKey | RemoteUserKey): Promise<RekeyData> {
  const proposed_key = await KeyFactory.newUserKey({ key_version: user_key.key_version + 1 });
  const last_key = user_key.serialize();
  const wrapped_last_key = await proposed_key.public_user_key.public_key.seal(last_key);
  return { proposed_key, wrapped_last_key };
}

// Description: Creates shards of the current user's user keys for each user in the provided export group 
export async function createExportShards(user_keys: AccountUserKey[], users: CollectionServerUser[], export_group: ApproversList): Promise<ExportShard[]> {
  const users_by_id: { [key: string]: CollectionServerUser } = {};
  for (const user of users) {
    users_by_id[user.user_id.toLowerCase()] = user;
  }
  const optional_approvers = export_group.approvers.filter(approver => !approver.required);
  const required_approvers = export_group.approvers.filter(approver => approver.required);
  return await Promise.all(user_keys.map(async user_key => {
    const new_user_key = await KeyFactory.newUserKey({
      protocol_version: user_key.protocol_version || undefined,
      key_version: user_key.key_version,
      encryption_key: user_key.encryption_key
    });
    let required_count = required_approvers.length;
    if (export_group.optionals_required > 0) {
      required_count++;
    }
    const required_secrets = create(required_count, required_count, Helpers.b64Encode(new_user_key.serialize()));
    if (!required_secrets) return await Promise.reject(new Error("List of required secrets is undefined"));
    const raw_secret = required_secrets.pop();
    if (!raw_secret) return await Promise.reject(new Error("List of required secrets is empty"));
    const optional_secrets = create(export_group.optionals_required, optional_approvers.length, raw_secret);
    const export_shards: ExportShard[] = [];
    for (const approver of required_approvers) {
      export_shards.push(await wrappedExportShard(
        users_by_id[approver.user_id.toLowerCase()], user_keys[0], new_user_key.key_version, required_secrets));
    }
    for (const approver of optional_approvers) {
      export_shards.push(await wrappedExportShard(
        users_by_id[approver.user_id.toLowerCase()], user_keys[0], new_user_key.key_version, optional_secrets));
    }
    return export_shards;
  })).then(shards => shards.flat());
}

// Description: Takes the current user's collection log keys and seals them with the org's admin group key
export async function wrappedLogKeys(
  current_account: Account,
  serialized_admin_key: string,
  group_id: string,
  log_keys: LogKey[]
): Promise<Array<(PutUsersCollectionGrantApiArg | undefined)>> {
  const admin_key = await KeyFactory.deserializePublicUserKey(Helpers.b64Decode(serialized_admin_key));
  return await Promise.all(log_keys.map(async log_key => {
    const key = current_account.user_keys.find(k => k.key_version === log_key.wrapped_key.key_version);
    if (!key) return;
    const data = Helpers.b64Decode(log_key.wrapped_key.wrapped_key);
    const opened_log_key = await key.encryption_key.unseal(data);
    const rewrapped_log_key = await admin_key.public_key.seal(opened_log_key);
    const key_hash = await Helpers.sha256Checksum(rewrapped_log_key);
    const key_version = admin_key.key_version;
    const role = log_key.role.toUpperCase();
    const hash_hex = Helpers.hexEncode(key_hash).toLowerCase();
    const canonical_string = `${group_id},${key_version},${log_key.collection_id},${role},${log_key.version},${hash_hex}`;
    const signature = Helpers.b64Encode(await current_account.user_key.signing_key.sign(Helpers.utf8Encode(canonical_string)));
    return {
      body: {
        user_id: Account.getAccountIdentifiers(current_account).user_id,
        collection_id: log_key.collection_id,
        roles: [{
          role,
          version: log_key.version
        }],
        groups: [{
          group_id,
          key_version,
          role_info: [{
            role,
            signature,
            wrapped_key: Helpers.b64Encode(rewrapped_log_key)
          }]
        }]
      }
    };
  }));
}
