import React, { useEffect, useState, DragEvent } from "react";
import { useSearchParams, useParams } from "react-router-dom";
// import { IActionHandler, MessageDisplayType } from "@preveil-api";
import {
  useAppDispatch, QueryParamKeys, QueryParamMessages, Message, DriveConstants, useAppSelector, isRootPath, isTrashPath, isSharedPath, isInvitationPath,
  AppConfiguration, getRouterParams, DragEventType, DRIVE_MANAGER_STATE, DriveErrorMessages
  // GlobalErrorMessages, MessageAnchors, MessageHandlerDisplayType,

} from "src/common";
import { uiActions, driveActions } from "src/store";
import { RootState } from "src/store/configureStore";
import { CoverTemplate, Loading } from "src/components";
import { DriveManager, DriveInvitations, DriveShared, DriveTrash, EmptyMessage } from ".";

function DriveWrapperComponent() {
  const { collection_id, id, file_id, item_type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const is_web = AppConfiguration.buildForWeb();
  const current_account = useAppSelector((state: RootState) => state.account.current_account);
  const drive_state = useAppSelector((state: RootState) => state.drive.drive_state);
  const root_info = useAppSelector((state: RootState) => state.drive.root_info);
  const current_directory_name = useAppSelector((state: RootState) => state.drive.current_directory_name);
  const is_loading = useAppSelector((state: RootState) => state.drive.is_loading);
  const [drag_event, setDragEvent] = useState<{ type: string, e: DragEvent }>();
  const [legacy_file_id, setLegacyFileId] = useState<string | undefined>();
  const dispatch = useAppDispatch();

  // Description: load function to initialize drive (First onload event)
  useEffect(() => {
    handleInitDrive();
    return () => {
      dispatch(uiActions.handleMessageDismiss());
      dispatch(driveActions.resetCurrentDirectory());
    };
  }, []);

  useEffect(() => {
    dispatch(uiActions.handleSetDocumentTitle(setDriveTitle()));
  }, [collection_id, current_directory_name]);

  // Description: Handle Query Params on first load and initialize drive (get root info)
  function handleInitDrive() {
    // NOTE: Deal with Querystrings - returns the root_info and the initial collection_id call (entries): 
    if (!!searchParams.toString()) {
      const page_action_qs = searchParams.get(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
      if (!!page_action_qs) {
        _initAccountMessaging(page_action_qs);
        searchParams.delete(QueryParamKeys.PAGE_ACTION_QUERY_KEY);
      }

      // NOTE: Legacy (V1) - Remove name qs from url Legacy direct links
      !!searchParams.get(QueryParamKeys.PAGE_NAME_QUERY_KEY) && searchParams.delete(QueryParamKeys.PAGE_NAME_QUERY_KEY);

      //  NOTE: Legacy (V1) - For View (file_ids) querystrings convert to proper v2 url
      const page_view_qs = searchParams.get(QueryParamKeys.PAGE_VIEW_QUERY_KEY);
      if (!!page_view_qs) {
        setLegacyFileId(page_view_qs);
        searchParams.delete(QueryParamKeys.PAGE_VIEW_QUERY_KEY);
      }

      setSearchParams(searchParams);
    }
  }

  // Description: Get message depending on QS type
  function _initAccountMessaging(page_action_qs: string): void {
    if (Object.keys(QueryParamMessages).includes(page_action_qs)) {
      const msg = QueryParamMessages[page_action_qs];
      !!msg && dispatch(uiActions.handleSetMessage(new Message(msg)));
    } else {
      console.error(`NO MESSAGE FOR ${page_action_qs}`);
    }
  }

  // Description: set the dynamic title to store
  function setDriveTitle(): string {
    let dir_name = isTrashPath(collection_id) ? DriveConstants.TRASH_DRIVE :
      isSharedPath(collection_id) ? DriveConstants.SHARED_DRIVE :
        isInvitationPath(collection_id) ? DriveConstants.INVITATIONS_DRIVE :
          (!isRootPath(collection_id, id) && !!current_directory_name) ? current_directory_name : DriveConstants.ROOT_DIRECTORY;
    dir_name = `${dir_name} - ${current_account?.user_id}`;
    return dir_name;
  }

  // -------------------------------------------------------------------------------
  // Description: Handle all children component actions and store it
  // -------------------------------------------------------------------------------
  function RenderDrive() {
    if (!!current_account && !!collection_id && !!root_info) {
      return isTrashPath(collection_id) ? (
        <DriveTrash router_params={getRouterParams({ collection_id, id, file_id, item_type }, true)} current_account={current_account} />
      ) : isSharedPath(collection_id) ? (
        <DriveShared current_account={current_account} />
      ) : isInvitationPath(collection_id) ? (
        <DriveInvitations current_account={current_account} is_web={is_web} />
      ) : (
        <DriveManager
          router_params={getRouterParams({ collection_id, id, file_id: (file_id || legacy_file_id), item_type })}
          current_account={current_account}
          root_info={root_info}
          drag_event={drag_event}
          setLegacyFileId={setLegacyFileId}
        />
      );
    } else if (drive_state === DRIVE_MANAGER_STATE.ERROR) {
      return <div className="cover-content">
        <EmptyMessage message={DriveErrorMessages.default} />
      </div>;
    } else {
      return <Loading />;
    }
  }

  return <CoverTemplate
    className={`drive-component${is_loading ? " isloading" : ""}`}
    onDrop={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragEvent({ type: DragEventType.DROP, e });
    }}
    onDragOver={(e) => {
      e.preventDefault();
      e.stopPropagation();
      e.dataTransfer.types.includes("Files") && setDragEvent({ type: DragEventType.DRAG_OVER, e });
    }}
    onDragLeave={(e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragEvent({ type: DragEventType.DRAG_LEAVE, e });
    }}
  >
    {RenderDrive()}
  </CoverTemplate>;
}

export default React.memo(DriveWrapperComponent);