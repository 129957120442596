import React, { useState, useRef } from "react";
import { ParseError, ParseMeta } from "papaparse";
import { ActionHandlerFunction, IActionHandler } from "@preveil-api";
import { AdminUIActionTypes, AdminErrorMessages, useCsvDataValidation } from "src/common";
import { Icon, CsvSubmitData, DraggableContainer, AddressListModalPanel } from "src/components";
import { Card, Button, Modal, Tabs, Tab, Collapse } from "react-bootstrap";
import { IndividualForm } from ".";
import { EmailGatewayEntity } from "./helpers.class";
import _ from "lodash";

type AllProps = {
  handleActions: ActionHandlerFunction;
}

type PapaparseData = {
  data: {
    "Domain or User Address": string;
    "Display Name": string;
  }
}

type RefHashMap = {
  [key: string]: {
    ["Domain or User Address"]: string;
    ["Display Name"]: string;
  }
}

function AddToEmailGatewayComponent({ handleActions }: AllProps) {
  // Headers for Modal tabs.
  const tabEventKey = {
    individual: "Add Email Gateway Aliases",
    csvUpload: "Importing Email Gateway Aliases"
  };
  const emailGatewayColumnsName = ["Domain or User Address", "Display Name"]; // columns name that matches the document template provided to the user.
  const [tab_heading, setTabHeading] = useState<string>(tabEventKey.individual);
  const [open_collapse, setOpenCollapse] = useState<boolean>(false);
  const [show_create_list_addresses, setShowCreateListAddresses] = useState<boolean>(false);
  const csvDataRef = useRef<RefHashMap | any>();
  const { validAddresses, invalidAddresses, isDataDrop, handleValidateData, resetData } = useCsvDataValidation();

  const AddTrustedAddressRequests = {
    // Handle change for individual addresses/domain tab
    handleSubmitAddresses: (collection?: string[]) => {
      handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: collection });
    },
    // Handle change when using CSV Upload
    handleSubmitCsvData: () => {
      const formattedEmailGatewayAddresses = validAddresses.map((address) => {
        return csvDataRef.current.get(address).alias_format();
      });
      handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: formattedEmailGatewayAddresses });
      resetData();
    },
    handleCsvData: (csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) => {
      handleCsvFileData(csvFile);
    },
    handleReset: () => {
      resetData();
    },
    handleShowModal: () => {
      toggleModal();
      resetData();
    },
  };

  function handleCsvFileData(csvFile: any) {
    if (csvFile?.errors && csvFile?.errors.length >= 1) {
      // Paparse returns some errors when the CSV formatting is not correct
      // or the data is missing a delimiter in each row, but that doesn't mean the data will not be parse.
      // so we are just logging those errors.
      handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_upload_format, stack: csvFile.errors } });
    }
    const { data, meta } = csvFile;
    const { fields } = meta;
    const csvColumns = new Set(fields);
    // we need to verify if the columns name inside the csv file matches the ones in the csv template.
    const isValidColumns = csvColumns.has(emailGatewayColumnsName[0]) && csvColumns.has(emailGatewayColumnsName[1]);

    if (data.length === 0 || !isValidColumns) {
      if (data.length === 0) {
        handleActions({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_empty_gateway_file, stack: data } });
      } else if (!isValidColumns) {
        handleActions({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_wrong_heading, stack: meta.fields } });
      }
    } else {
      // any repeated address/domain from the csv file will be removed (only within the document).
      const addressColumn = emailGatewayColumnsName[0];
      const nameColumn = emailGatewayColumnsName[1];
      const csvDataMap = new Map<string, RefHashMap>(data.map((address: any) => {
        const key = address[addressColumn];
        const alias = address[addressColumn];
        const aliasName = address[nameColumn];
        return [key, new EmailGatewayEntity(alias, aliasName)];
      })) as any;
      csvDataRef.current = csvDataMap; // Keeping a map of all the aliases (address, name) in a ref to persist between states, so we can use as a reference when submitting the data.
      const addressesFromCsvFile = Array.from(csvDataMap.keys() as string);
      handleValidateData(addressesFromCsvFile);
    }
  }


  // Description: Handle delete of a single valid address
  function handleDeleteAction(id?: string) {
    if (!!id) {
      const data = [...validAddresses, ...invalidAddresses].filter((address: string) => address !== id);
      handleValidateData(data);
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AddTrustedAddressRequests as any)[callback] instanceof Function) {
      (AddTrustedAddressRequests as any)[callback](actionObj.params);
    }
  };

  // Description: Handling changing modal tabs and including the correct header.
  function handleTabSelect(eventKey: string | null) {
    const tab_heading = eventKey === "csvUpload" ? tabEventKey.csvUpload : tabEventKey.individual;
    setTabHeading(tab_heading);
  };

  // Toggle component's modal on/off.
  const toggleModal = () => {
    setShowCreateListAddresses(!show_create_list_addresses);
    resetData();
  };

  const showInvalidAreaContent = !(_.isEmpty(invalidAddresses));
  const isValid = !_.isEmpty(validAddresses);
  const showDivisor = isValid;
  return (
    <>
      <Card className="admin-card">
        <Icon className="email-gateway" />
        <Card.Body>
          <Card.Title as="p">Create a list of external addresses</Card.Title>
          <Card.Text as="p" className="create-list-text">
            <span className="text-muted">Learn More:</span>
            <a href="https://www.preveil.com">preveil.com/support</a>
          </Card.Text>
          <Button variant="primary" onClick={toggleModal}>
            <Icon className="icon-plus2" />
            Add to Email Gateway List
          </Button>
          <Modal
            size="lg"
            show={show_create_list_addresses}
            onHide={toggleModal}
            aria-labelledby="create email gateway group"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{tab_heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                defaultActiveKey="individual"
                id="add-to-email-gateway"
                onSelect={handleTabSelect}
              >
                <Tab eventKey="individual" title="Enter Addresses">
                  <IndividualForm handleActions={handlePageActions} />
                </Tab>
                <Tab eventKey="csvUpload" title="CSV Upload">
                  <CsvSubmitData handleAction={handlePageActions} isValid={isValid}>
                    {validAddresses.length >= 1 && isDataDrop && (
                      <AddressListModalPanel
                        validation_state="valid_gateway"
                        collection={validAddresses}
                        handleDeleteAction={handleDeleteAction}
                      />
                    )}
                    {showInvalidAreaContent && (
                      <div
                        className={`invalid-addresses-card ${showDivisor ? "show-divider" : ""
                          }`}
                      >
                        <header>
                          <h3 className="mb-2">
                            Addresses that will <i className="emphasis">not</i> be added
                          </h3>
                          <Button
                            variant="icon"
                            onClick={() => setOpenCollapse(!open_collapse)}
                            aria-controls="collapse-invalid-area"
                          >
                            <Icon
                              className={`${open_collapse ? "ficon-chevron-up" : "ficon-chevron-down"
                                }`}
                            />
                          </Button>
                        </header>
                        <Collapse in={open_collapse}>
                          <div id="collapse-invalid-area">
                            <div className="pt-2 pb-0">
                              {invalidAddresses.length >= 1 && isDataDrop && (
                                <AddressListModalPanel
                                  validation_state="invalid"
                                  collection={invalidAddresses}
                                  handleDeleteAction={handleDeleteAction}
                                />
                              )}
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    )}
                    {!isDataDrop && (
                      <>
                        <p className="download-csv-legend">
                          Download and use the upload template to accurately add multiple users at
                          once
                          <a
                            href="/templates/PreVeil-Email-Gateway-Upload-Template.csv"
                            target="_blank"
                          >
                            <Icon className="ficon-download" />
                            Preveil-Email-Gateway-Upload-Template.csv
                          </a>
                        </p>
                        <DraggableContainer handleAction={handlePageActions} />
                      </>
                    )}
                  </CsvSubmitData>
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Card>
    </>
  );
}

export default React.memo(AddToEmailGatewayComponent);
