import React, { FocusEvent } from "react";
import { Col, Row, Badge } from "react-bootstrap";
import { ActionHandlerFunction, AccountType } from "@preveil-api";
import { CheckboxStatesTypes, AdminUIActionTypes, OrgUserStatus, account_types } from "src/common";
import { Checkbox, Icon } from "src/components";
import _ from "lodash";

type AllProps = {
  accountType?: AccountType;
  status: string;
  userName: string;
  userEmail: string;
  department: string;
  updatesPending?: number;
  recoveryGroup: {
    groupId: string;
    name: string;
  } | null;
  isChecked: CheckboxStatesTypes;
  handleActions: ActionHandlerFunction;
};

function UserListRow({ accountType, status, userName, userEmail, department, updatesPending, recoveryGroup, isChecked, handleActions }: AllProps) {
  const recoveryGroupName = !!recoveryGroup ? recoveryGroup.name : "Not Assigned";
  const iconTooltip = accountType === account_types.full ? "Full Account User" : accountType === account_types.express ? "Express User" : "User has not accepted the invitation to join";
  const pendingRequests = updatesPending && updatesPending >= 1 ? updatesPending : null;
  const label = pendingRequests && pendingRequests === 1 ? "Update" : "Updates";
  const _status = _.capitalize(status);
  return (
    <Row
      className="admin-item-list"
      onClick={() => handleActions({ actionType: AdminUIActionTypes.EditUser, params: userEmail })}
    >
      <div className="user-row-checkbox">
        <Checkbox
          className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.SelectUser, params: { e, user: { userName, userEmail, recoveryGroup } } })}
          value={userEmail}
          selected={isChecked}
          label="Select User"
        />
      </div>
      <Col sm={3} className="m-0 p-0">
        <div className="user-row-name">
          <Icon
            tooltip={iconTooltip}
            className={`account-type-icon ${accountType === account_types.full ? "icon-user-plus" : accountType === account_types.express ? "icon-user" : "icon-user-plus inactive"}`} />
          <p>{userName}</p>
        </div>
      </Col>
      <Col sm={3} className="user-row-field">
        <p>{userEmail}</p>
      </Col>
      <Col sm={2} className="user-row-field">
        <p>{department}</p>
      </Col>
      {
        status === OrgUserStatus.active ?
          <Col xs={4} className={`user-row-field ${pendingRequests ? "recovery-group-column" : ""}`}>
            <p className={`${!recoveryGroup ? "no-recovery-group" : ""}`}>{recoveryGroupName}</p>
            {
              pendingRequests && (
                <div className="pending-button-container">
                  <Badge bg="warning" aria-label="Status">{pendingRequests} {label}</Badge>
                </div>
              )}
          </Col> :
          <Col sm={4} className="user-row-field pending-status">
            <div className="pending-button-container">
              <Badge bg="info" aria-label="Status"> {_status}</Badge>
            </div>
          </Col>
      }
    </Row>
  );
}

export default React.memo(UserListRow);
