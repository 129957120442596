import React, { useState, ReactNode, MouseEvent, DragEvent } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import { TreeItem, ActionHandlerFunction } from "@preveil-api";
import { MailTreeIcon, UIManagerActionTypes, useAppSelector, DragEventTypes, DragEventType } from "src/common";
import { Icon, Loading } from "src/components";


type AllProps = {
  folder: TreeItem;
  children: ReactNode;
  readonly?: boolean;
  active?: boolean;
  fetching_children?: boolean;
  drive?: boolean;
  drag_events?: DragEventTypes[];
  handleAction: ActionHandlerFunction;
};

function TreeFolder(props: AllProps) {
  const { folder, active, children, readonly, handleAction, fetching_children, drive, drag_events } = props;
  const expanded = useAppSelector((state) => state.mail.expanded);
  const [open, setOpen] = useState<boolean>(expanded.includes(folder?.id || "") || false);

  // Description: Conditionally add drag events to the tree node
  const drag_props = !!drag_events ? {
    onDragOver: (event: DragEvent) => (drag_events.includes(DragEventType.DRAG_OVER) && handleAction({ actionType: UIManagerActionTypes.OnDragOver, params: { event, folder } })),
    onDrop: (event: DragEvent) => (drag_events.includes(DragEventType.DROP) && handleAction({ actionType: UIManagerActionTypes.OnDrop, params: { event, folder } })),
    onDragLeave: (event: DragEvent) => (drag_events.includes(DragEventType.DRAG_LEAVE) && handleAction({ actionType: UIManagerActionTypes.OnDragLeave, params: { event, folder } }))
    // NOTE: Add more DragEvent types as needed
    // onDragEnd: (event: DragEvent) => (drag_events.includes(DragEventType.DRAG_END) && handleAction({ actionType: UIManagerActionTypes.OnDragEnd, params: { event, folder } }))
  } : undefined;

  // Description: Handles click to icon
  function handleOnClickFolder(e: MouseEvent<HTMLSpanElement>) {
    e.stopPropagation();
    e.preventDefault();
    // NOTE: If no children redirect bubble parent click to handle action TreeNodeActiveState
    if (!!folder.children) {
      setOpen(!open);
      !drive && handleAction({ actionType: UIManagerActionTypes.TreeNodeActiveState, params: { id: folder.id, expanded: !open } });
    } else if (folder.children_loading) {
      setOpen(!open);
      handleAction({ actionType: UIManagerActionTypes.FetchTreeNodeChildren, params: folder });
    } else {
      handleAction({ actionType: UIManagerActionTypes.TreeNodeClick, params: folder });
    }
  }

  function FolderActions() {
    return <Dropdown as="span" className="folder-actions">
      <Dropdown.Toggle variant="light" size="sm" >
        <Icon className="ficon-more-vertical" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => {
          // Open parent
          !open && setOpen(true);
          handleAction({ actionType: UIManagerActionTypes.TreeNodeActiveState, params: { id: folder.id, expanded: !open } });
          handleAction({ actionType: UIManagerActionTypes.TreeNodeAddChild, params: folder });
        }}>
          <Icon className="pv-icon-new-folder" />
          Create Subfolder
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleAction({ actionType: UIManagerActionTypes.TreeNodeRename, params: folder })}>
          <Icon className="pv-icon-compose" />
          Rename
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleAction({ actionType: UIManagerActionTypes.TreeNodeDelete, params: folder })}>
          <Icon className="pv-icon-trash" />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>;
  }

  return <div className={`folder-item${folder.disabled ? " disabled" : ""}`}>
    <div id={folder.id}
      {...drag_props}
      className={`folder-label ${folder.active || active ? " active" : ""}`}>
      <span className={`folder-icon${!folder.children && !drive ? " no-children" : ""}`}
        onClick={handleOnClickFolder}>
        {!folder.children && !folder.children_loading && !!drive ? <Icon tooltip="There are no eligible subfolders under this folder" className="ficon-folder" /> :
          <Icon className={open ? MailTreeIcon.opened : MailTreeIcon.closed} />}
      </span>
      <span className="folder-name" onClick={() => handleAction({ actionType: UIManagerActionTypes.TreeNodeClick, params: folder })}>{folder.name}</span>
      {
        !readonly && <FolderActions />
      }
    </div>
    {folder.children_loading && open && fetching_children && <Loading className="in-place" noLogo={true} />}
    {
      !!folder.children &&
      <Collapse in={open} className="folder-children">
        <div>
          {children}
        </div>
      </Collapse>
    }
  </div >;
}

export default React.memo(TreeFolder);
