import React, { useState } from "react";
import { ActionHandlerFunction, IActionHandler } from "@preveil-api";
import { AdminUIActionTypes, AdminErrorMessages, useCsvDataValidation } from "src/common";
import { CsvSubmitData, DraggableContainer, AddressListModalPanel, Icon } from "src/components";
import { EnterAddressesForm } from "..";
import { Modal, Tab, Tabs, Collapse, Button } from "react-bootstrap";
import _ from "lodash";
import { ParseError, ParseMeta } from "papaparse";

type AllProps = {
  show_modal: boolean;
  handleActions: ActionHandlerFunction;
  saved_addresses: string[];
}

type PapaparseData = {
  data: {
    "Domain or User Address": string
  }
}

function AddTrustedAddressModal({ show_modal, handleActions, saved_addresses }: AllProps) {
  // Headers for Modal tabs.
  const tabEventKey = {
    individual: "Add Trusted Addresses",
    csvUpload: "Importing Trusted Community Addresses"
  };
  const csvTrustedCommunityColumnName = "Domain or User Address"; // column name that matches the document template provided to the user.
  const [tabHeading, setTabHeading] = useState<string>(tabEventKey.individual);
  const [openCollapse, setOpenCollapse] = useState<boolean>(false);
  const { validAddresses, invalidAddresses, duplicateAddresses, isDataDrop, handleValidateData, resetData } = useCsvDataValidation(saved_addresses);

  const AddTrustedAddressRequests = {
    // Handle change for individual addresses/domain tab
    handleSubmitAddresses: (collection?: string[]) => {
      handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: collection });
    },
    // Handle change when using CSV Upload
    handleSubmitCsvData: () => {
      resetData();
      handleActions({ actionType: AdminUIActionTypes.SubmitAddresses, params: validAddresses });
    },
    handleReset: () => {
      resetData();
    },
    handleShowModal: () => {
      handleActions({ actionType: AdminUIActionTypes.ShowModal });
      resetData();
    },
    handleCsvData: (csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) => {
      handleCsvFileData(csvFile);
    },

    // Handle error from Draggable component (wrong file extension).
    handlePageError: (params: { message: string, stack: string }) => {
      const { message, stack } = params;
      handleActions({ actionType: AdminUIActionTypes.PageError, params: { message, stack } });
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AddTrustedAddressRequests as any)[callback] instanceof Function) {
      (AddTrustedAddressRequests as any)[callback](actionObj.params);
    }
  }

  // handling csv data.
  function handleCsvFileData(csvFile: { data: PapaparseData[], errors: ParseError[], meta: ParseMeta }) {
    if (csvFile?.errors && csvFile?.errors.length >= 1) {
      // Papaparse returns some errors when the CSV formatting is not correct
      // or the data is missing a delimiter in each row, but that doesn't mean the data will not be parse.
      // so we are just logging those errors.
      handleActions({ actionType: AdminUIActionTypes.PageErrorMessage, params: { message: AdminErrorMessages.error_csv_upload_format, stack: csvFile.errors } });
    }
    const { data, meta } = csvFile;
    // we need to verify if the columns name inside the csv file matches the one on the csv template.
    const isValidColumns = meta.fields?.includes(csvTrustedCommunityColumnName);

    if (data.length === 0 || !isValidColumns) {
      if (data.length === 0) {
        handleActions({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_empty_file, stack: data } });
      } else if (!isValidColumns) {
        handleActions({ actionType: AdminUIActionTypes.PageError, params: { message: AdminErrorMessages.error_csv_wrong_heading, stack: meta.fields } });
      }
    } else {
      // any repeated address/domain from the csv file will be removed (only within the document).
      const addressesFromCsvFile: string[] = _.uniq(data.map((dataRow: any) => dataRow[csvTrustedCommunityColumnName].trim().toLowerCase()));
      handleValidateData(addressesFromCsvFile);
    }
  };

  // Description: Handle delete of a single valid address
  function handleDeleteAction(id: string | undefined) {
    if (!!id) {
      const data = [...validAddresses, ...invalidAddresses, ...duplicateAddresses].filter((address: string) => address !== id);
      handleValidateData(data);
    }
  };

  // Description: Handling changing modal tabs and including the correct header.
  function handleTabSelect(eventKey: string | null) {
    const tabHeading = eventKey === "csvUpload" ? tabEventKey.csvUpload : tabEventKey.individual;
    setTabHeading(tabHeading);
  }

  const showInvalidAreaContent = !(_.isEmpty(invalidAddresses) && _.isEmpty(duplicateAddresses));
  const isValid = !_.isEmpty(validAddresses);
  const showDivisor = isValid;
  return (
    <Modal
      size="lg"
      show={show_modal}
      onHide={() => handlePageActions({ actionType: AdminUIActionTypes.ShowModal })}
      aria-labelledby="trusted community form"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{tabHeading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="individual" id="add-users-tabs" onSelect={handleTabSelect}>
          <Tab eventKey="individual" title="Individual">
            <EnterAddressesForm handleAction={handlePageActions} />
          </Tab>
          <Tab eventKey="csvUpload" title="CSV Upload">
            <CsvSubmitData handleAction={handlePageActions} isValid={isValid}>
              {validAddresses.length >= 1 && isDataDrop && (
                <AddressListModalPanel
                  validation_state="valid_trusted"
                  collection={validAddresses}
                  handleDeleteAction={handleDeleteAction}
                />
              )}
              {showInvalidAreaContent && (
                <div className={`invalid-addresses-card ${showDivisor ? "show-divider" : ""}`}>
                  <header>
                    <h3 className="mb-2">
                      Addresses that will <i className="emphasis">not</i> be added
                    </h3>
                    <Button
                      variant="icon"
                      onClick={() => setOpenCollapse(!openCollapse)}
                      aria-controls="collapse-invalid-area"
                    >
                      <Icon
                        className={`${openCollapse ? "ficon-chevron-up" : "ficon-chevron-down"}`}
                      />
                    </Button>
                  </header>
                  <Collapse in={openCollapse}>
                    <div id="collapse-invalid-area">
                      <div className="pt-2 pb-0">
                        {duplicateAddresses.length >= 1 && isDataDrop && (
                          <AddressListModalPanel
                            validation_state="duplicate"
                            collection={duplicateAddresses}
                            handleDeleteAction={handleDeleteAction}
                          />
                        )}
                        {invalidAddresses.length >= 1 && isDataDrop && (
                          <AddressListModalPanel
                            validation_state="invalid"
                            collection={invalidAddresses}
                            handleDeleteAction={handleDeleteAction}
                          />
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>
              )}
              {!isDataDrop && (
                <>
                  <p className="download-csv-legend">
                    Download and use the upload template to accurately add multiple users at once
                    <a
                      href="/templates/PreVeil-Trusted-Community-Upload-Template.csv"
                      target="_blank"
                    >
                      <Icon className="ficon-download" />
                      PreVeil-Trusted-Community-Upload-Template.csv
                    </a>
                  </p>
                  <DraggableContainer handleAction={handlePageActions} />
                </>
              )}
            </CsvSubmitData>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(AddTrustedAddressModal);
