import React, { useState, FocusEvent, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler, AdminUserEntity, Sort } from "@preveil-api";
import {
  AdminUIActionTypes, GlobalErrorMessages, MessageAnchors, Message, MessageHandlerDisplayType, useAppDispatch, CheckboxStatesTypes
} from "src/common";
import { Checkbox, Icon } from "src/components";
import { EditUserSidePanel, ListView } from ".";
import { AdminRecoveryGroup, UpdateUser } from "./helpers.class";
import { uiActions } from "src/store";

type SingleUserList = {
  userName: string;
  userEmail: string;
}

type AllProps = {
  sort: Sort<keyof AdminUserEntity>;
  allSelectState: CheckboxStatesTypes;
  filtered_users_list: AdminUserEntity[];
  selected_users_list: SingleUserList[];
  recovery_groups: AdminRecoveryGroup[];
  handleActions: ActionHandlerFunction;
  allow_add_devices?: boolean;
  add_device_overrides: string[];
}

function AdminUsersListPanel({ sort, allSelectState, filtered_users_list, selected_users_list, recovery_groups, handleActions, allow_add_devices, add_device_overrides }: AllProps) {
  const dispatch = useAppDispatch();
  const [currentSelectedUser, setCurrentSelectedUser] = useState<AdminUserEntity | undefined>();
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
  const recoveryGroupsHash = Object.fromEntries(recovery_groups.map(group => [group.groupId, group]));

  useEffect(() => {
    if (showSidePanel && !!currentSelectedUser) {
      const findCurrentSelectedUser = filtered_users_list.filter((user) => user.userEmail === currentSelectedUser.userEmail)[0];
      if (findCurrentSelectedUser && findCurrentSelectedUser?.updatesPending !== currentSelectedUser?.updatesPending) {
        setCurrentSelectedUser(findCurrentSelectedUser);
      }
    }
  }, [filtered_users_list]);

  const AdminUsersPanelRequests = {
    handleEditUser: (userId: string) => {
      // When user clicks on a single user to show the offcanvas/sidepanel
      const selectedUserToEdit = filtered_users_list.filter((user) => user.userEmail === userId)[0];
      setShowSidePanel(true);
      setCurrentSelectedUser(selectedUserToEdit);
    },
    unHandleErrorMessage: (params: { message: string, stack?: any }) => {
      const { message, stack } = params;
      dispatch(uiActions.handleRequestErrors(new Message(message, MessageHandlerDisplayType.logger), stack));
    },
    handleShowSideNavMenu: () => {
      setShowSidePanel(!showSidePanel);
    },
    handleSelectUser: (params: { e: FocusEvent<HTMLInputElement>, user: SingleUserList }) => {
      handleActions({ actionType: AdminUIActionTypes.SingleSelect, params });
    },
    handleUpdateUser: (params: UpdateUser) => {
      handleActions({ actionType: AdminUIActionTypes.Update, params });
    }
  };

  function setSort(field: string) {
    handleActions({
      actionType: AdminUIActionTypes.SetSort,
      params: field,
    });
  }

  /* Descriptions: Shareable react fragment - for each field returns the header with sort icon
  if applicable */
  function headerWithSortIcon(name: string, field: string, style?: string) {
    const _style = !!style ? style : "";
    return (
      <div className="d-flex align-items-center">
        <h5 className={`${_style}`}>{name}</h5>
        {!!sort && sort.field === field && filtered_users_list.length > 1 && (
          <Icon
            className={`${sort.direction === "desc" ? "ficon-arrow-down" : "ficon-arrow-up"
              } small-icon ps-1 d-none d-sm-inline`}
          />
        )}
      </div>
    );
  }

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((AdminUsersPanelRequests as any)[callback] instanceof Function) {
      (AdminUsersPanelRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      AdminUsersPanelRequests.unHandleErrorMessage({ message, stack: actionObj });
    }
  }

  const currentRecoveryGroup = !!currentSelectedUser && !!currentSelectedUser.recoveryGroup ? recoveryGroupsHash[currentSelectedUser.recoveryGroup] : null;

  return (
    <>
      <div className="cover-content list-group content-dynamic">
        <>
          <header>
            <Checkbox
              className="sr-only me-1"
              onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({ actionType: AdminUIActionTypes.MultiSelect, params: e })}
              value="all"
              selected={allSelectState}
              label="Select All"
            />
            <Row className="users-columns-header">
              <Col sm={3} onClick={() => setSort("userName")}>
                {headerWithSortIcon("Name", "userName")}
              </Col>
              <Col sm={3} onClick={() => setSort("userEmail")}>
                {headerWithSortIcon("Email", "userEmail", "ms-0")}
              </Col>
              <Col sm={2} onClick={() => setSort("department")}>
                {headerWithSortIcon("Department", "department")}
              </Col>
              <Col sm={4} onClick={() => setSort("recoveryGroup")}>
                {headerWithSortIcon("Recovery Group", "recoveryGroup")}
              </Col>
            </Row>
          </header>
          <ListView
            filtered_users_list={filtered_users_list}
            selected_users_list={selected_users_list}
            recoveryGroupsHash={recoveryGroupsHash}
            handleActions={handlePageActions} />
          {currentSelectedUser && <EditUserSidePanel show={showSidePanel} currentRecoveryGroup={currentRecoveryGroup} currentSelectedUser={currentSelectedUser} recovery_groups={recovery_groups} handleActions={handlePageActions} allow_add_devices={allow_add_devices} add_device_overrides={add_device_overrides} />}
        </>
      </div>
    </>
  );
}

export default React.memo(AdminUsersListPanel);
