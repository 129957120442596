import React from "react";
import { Icon } from "src/components";
import { dataExportRequestStatus } from "./helpers.class";

type AllProps = {
  status: "not_init" | "not_started" | "init" | "pending" | "started" | "running" | "complete" | "cancelled";
}

function DataExportRequestProgress({ status }: AllProps) {
  const approvalStep = 
    dataExportRequestStatus[status] === "init" || dataExportRequestStatus[status] === "complete" || dataExportRequestStatus[status] === "running" || dataExportRequestStatus[status] === "started";
  return (
    <div className="export-multistep-progress">
      <div className="export-single-step">
        {dataExportRequestStatus[status] ? <Icon className="icon-checkmark" /> : <span className="request-step-number">1</span>}
        <p className={`${status === "init" || status === "pending" || status === "complete" ? "step-complete-color" : ""}`}>Request</p>
      </div>
      <div className="export-single-step">
        <hr className={`${dataExportRequestStatus[status] ? "complete" : ""}`}/>
        {approvalStep ? <Icon className="icon-checkmark" /> : <span className="request-step-number">2</span>}
        <p className={`${status === "init" || status === "complete" ? "step-complete-color" : ""}`}>Approval</p>
        <hr className={`${dataExportRequestStatus[status] === "complete" ? "complete" : ""}`}/>
      </div>
      <div className="export-single-step">
        {dataExportRequestStatus[status] === "complete" ? <Icon className="icon-checkmark" /> : <span className="request-step-number">3</span>}
        <p className={`${status === "complete" ? "step-complete-color" : ""}`}>Export</p>
      </div>
    </div>
  );
}

export default React.memo(DataExportRequestProgress);