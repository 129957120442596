import React, { useRef, FocusEvent, MouseEvent } from "react";
// import { Form } from "react-bootstrap";
// import { ActionHandlerFunction } from "@preveil-api";
import { CheckboxStates, CheckboxStatesTypes } from "src/common";

type AllProps = {
  className?: string;
  selected: CheckboxStatesTypes;
  value: any; // Pass the id or value representing this checkbox
  onChange: (e: FocusEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean
}

function CheckBox(props: AllProps) {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { onChange, label, selected, className, disabled } = props;

  React.useEffect(() => {
    if (!!checkboxRef.current) {
      if (selected === CheckboxStates.checked) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (selected === CheckboxStates.empty) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (selected === CheckboxStates.indeterminate) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }
  }, [selected]);

  return <div
    onClick={(e: MouseEvent<HTMLInputElement>) => { e.stopPropagation(); }}
    className={`form-check ${!!className ? className : ""}`}>
    <input type="checkbox"
      ref={checkboxRef}
      className="form-check-input"
      onChange={onChange}
      disabled={disabled}
    />
    <label title={!!label ? label : "Select"} className="form-check-label">{!!label ? label : ""}</label>
  </div>;

}

export default React.memo(CheckBox);