import * as React from "react";
import { TreeItem, ActionHandlerFunction } from "@preveil-api";
import { DragEventTypes } from "src/common";
import TreeFolder from "./TreeFolder.component";
import TreeFile from "./TreeFile.component";

type AllProps = {
  data: TreeItem[];
  readonly?: boolean;
  active?: string;
  className?: string;
  fetching_children?: boolean;
  drive?: boolean;
  drag_events?: DragEventTypes[];
  handleAction: ActionHandlerFunction;
};

function TreeRecursive(props: AllProps) {
  const { active, data, readonly, handleAction, fetching_children, drive, drag_events } = props;

  return <>
    {
      !!data &&
      data.map((item: TreeItem, i: number) => {
        // if its a file render <TreeFile /> -> TO DO If needed
        if (item.type === "file") {
          return <TreeFile key={`file_${i}_${item.id}`} file={item}
            handleAction={handleAction} />;
        } else {
          return <TreeFolder key={`folder_${i}_${item.id}`} folder={item}
            active={active === item.id}
            handleAction={handleAction} readonly={readonly} fetching_children={fetching_children} drive={drive}
            drag_events={drag_events} >
            {
              !!item.children &&
              <TreeRecursive
                data={item.children}
                handleAction={handleAction}
                readonly={readonly}
                active={active}
                fetching_children={fetching_children}
                drive={drive}
                drag_events={drag_events} />
            }
          </TreeFolder>;
        }
      })
    }
  </>;
};

function Tree(props: AllProps) {
  const { data, readonly, active, handleAction, className, fetching_children, drive, drag_events } = props;
  return <div className={`tree${!!className ? ` ${className}` : ""}`}>
    <TreeRecursive
      data={data}
      handleAction={handleAction}
      readonly={readonly}
      active={active}
      fetching_children={fetching_children}
      drive={drive}
      drag_events={drag_events} />
  </div>;
}

export default React.memo(Tree);
