import * as React from "react";
import { Routes, Route, Params, Navigate } from "react-router-dom";
import { NavigationPath, PrivateRoutes, AppConfiguration } from "src/common";
import {
  AdminWrapper,
  Wrapper,
} from "src/components";

function AdminPage() {
  return (
    <Wrapper type={NavigationPath.AdminNavigation}>
      <AdminPagesRoutes />
    </Wrapper>
  );
}

export function AdminPagesRoutes(props: Readonly<Params<string>>) {
  // Description: Only allow navigation if not web build
  return <Routes>
    {
      AppConfiguration.buildForWeb() ?
        <Route path="*" element={<Navigate to={`/${PrivateRoutes.mail_route}`} replace />} /> :
        <>
          <Route path={PrivateRoutes.users_route} element={<AdminWrapper page="users" />} />
          <Route path={PrivateRoutes.approval_groups_route} element={<AdminWrapper page="approvalGroups" />} />
          <Route path={PrivateRoutes.trusted_community_route} element={<AdminWrapper page="trustedCommunity" />} />
          <Route path={PrivateRoutes.groups_route} element={<AdminWrapper page="groups" />} />
          <Route path={`${PrivateRoutes.requests_route}/:request_id`} element={<AdminWrapper page="requests" />} />
          <Route path={PrivateRoutes.requests_route} element={<AdminWrapper page="requests" />} />
          <Route path={PrivateRoutes.activity_logs_route} element={<AdminWrapper page="activityLogs" />} />
          <Route path={PrivateRoutes.data_export_route} element={<AdminWrapper page="dataExport" />} />
          <Route path={PrivateRoutes.email_gateway_route} element={<AdminWrapper page="gateway" />} />
          <Route path={PrivateRoutes.settings_route} element={<AdminWrapper page="settings" />} />
          <Route path="*" element={<Navigate to={`/${PrivateRoutes.mail_route}`} replace />} />
        </>
    }
  </Routes>;
}

export default React.memo(AdminPage);
