import { randomBytes } from "pvcryptojs";
import { FSWrappedKey, FSMessage, FSType, FSRequest, FSBlockType, } from "src/common/keys/protos/collections_pb";
import {
  Account, Helpers, UUID, AppUserKey, DriveErrorMessages, PermissionSetType, getDirSymmKey, getNewWrappedDirKey, FSTypeMapType,
  BulkUpdateParams
} from "src/common";
import _ from "lodash";

export interface IndexedSnapshot {
  dirs: Map<string, FSMessage.Dir>;
  files: Map<string, FSMessage.File>;
  links: Map<string, FSMessage.Link>;
}

export class Snapshot {
  readonly dirsMap: Map<string, FSMessage.Dir>;
  readonly filesMap: Map<string, FSMessage.File>;
  public snapNodeMap: Map<string, SnapshotNode>;
  public linksList: Map<string, FSMessage.Link>;
  constructor(
    public snapshot: IndexedSnapshot,
    public default_collection_id: UUID,
    public share_name: Uint8Array
  ) {
    this.snapshot = snapshot;
    this.dirsMap = snapshot.dirs;
    this.filesMap = snapshot.files;
    this.linksList = snapshot.links;
    this.snapNodeMap = new Map();
    this.default_collection_id = default_collection_id;
    this.share_name = share_name;
  }

  public containsLink(): Boolean {
    return this.linksList.size > 0;
  }

  public async buildSnapNodes(reader_key: AppUserKey, old_reader_key: AppUserKey, dir: FSMessage.Dir, parent_id: string, parent_version: string) {
    for (const entry of dir.getEntriesList()) {
      if (this.snapNodeMap.has(entry.getId_asB64())) {
        continue;
      }
      switch (entry.getType()) {
        case FSType.DIR:
          const entry_id = entry.getId_asB64();
          const directory = this.dirsMap.get(entry_id);
          if (!!directory) {
            const new_id = new UUID().B64();
            const new_version = Helpers.b64Encode(randomBytes(32));
            const wrapped_dir_key = directory.getWrappedDirKey();
            this.snapNodeMap.set(
              new_id,
              new SnapshotNode(
                new_id,
                new_version,
                entry.getName_asU8(),
                parent_id,
                parent_version,
                true,
                false,
                wrapped_dir_key,
                undefined,
                dir.getLastLocalFsUpdate()
              )
            );
            await this.buildSnapNodes(reader_key, old_reader_key, directory, new_id, new_version);
          }
          break;
        case FSType.FILE:
          const file_entry_id = entry.getId_asB64();
          const file = this.filesMap.get(file_entry_id);
          if (!!file) {
            const new_id = new UUID().B64();
            const blocks = await this.getDataListBlocks(file, old_reader_key, reader_key);
            this.snapNodeMap.set(
              new_id,
              new SnapshotNode(
                new_id,
                Helpers.b64Encode(randomBytes(32)),
                entry.getName_asU8(),
                parent_id,
                parent_version,
                false,
                false,
                undefined,
                blocks,
                file.getLastLocalFsUpdate(),
              ),
            );
          }
          break;
      }
    }
  }

  // Description: Get Files Blocks
  private async getDataListBlocks(file: FSMessage.File, old_reader_key: AppUserKey, reader_key: AppUserKey) {
    const blocks = await Promise.all(_.map(file.getDataList(), async (data_block: FSMessage.Block) => {
      const block = new FSRequest.BulkUpdate.Block();
      const wrapped_key = data_block.getWrappedKey();
      const unwrapped_key = !!wrapped_key ? await old_reader_key.encryption_key.unseal(wrapped_key.getWrappedKey_asU8()) : null;
      const rewrapped_key = !!unwrapped_key ? await reader_key.encryption_key.seal(unwrapped_key) : null;
      if (!!rewrapped_key) {
        const fswrappedkey = new FSWrappedKey();
        fswrappedkey.setWrappedKey(rewrapped_key);
        fswrappedkey.setKeyVersion(reader_key.key_version);
        block.setWrappedKey(fswrappedkey);
      } else {
        console.error(DriveErrorMessages.error_wrapping_block_key);
      }
      block.setBlockSize(data_block.getBlockSize() || 0);
      block.setHash(data_block.getHash());
      const id = `${Helpers.hexEncode(this.default_collection_id.Bytes())}/${data_block.getId()}`; // const id = Helpers.hexEncode(this.default_collection_id.Bytes()) + "/" + data_block.getId();
      block.setBlockType(FSBlockType.POINTER);
      const encrypted_id = await reader_key.public_user_key.public_key.seal(Helpers.utf8Encode(id));
      block.setId(Helpers.hexEncode(encrypted_id));
      return block;
    }));
    return blocks;
  }

  // Description: Build the FSRequest.Create[], FSRequest.BulkUpdate.Block[] and FSRequest.Update[] fro BulkUpdate
  public async buildUpdateMessage(current_account: Account, default_permissions: PermissionSetType, new_acl_reader: AppUserKey):
    Promise<BulkUpdateParams> {
    let blocks: FSRequest.BulkUpdate.Block[] = [];
    const create_dir: FSRequest.Create[] = [];
    const create_file: FSRequest.Create[] = [];
    const updates: FSRequest.Update[] = [];
    await Promise.all(_.map(Array.from(this.snapNodeMap.keys()), async (id: string) => {
      const value = this.snapNodeMap.get(id);
      if (!!value) {
        if (value.is_directory) {
          if (!value.is_root) { // NOTE: Do not include the root directory in BULK UPDATE
            const symm_key = !!value.wrappedDirKey ? await getDirSymmKey(value.wrappedDirKey, current_account, default_permissions) : null;
            const new_wrapped_key = !!symm_key ? await getNewWrappedDirKey(symm_key, current_account, new_acl_reader) : null;
            !!new_wrapped_key ? create_dir.push(this._buildNewCreate(id, value, FSType.DIR, new_wrapped_key)) : console.error(DriveErrorMessages.error_getting_wrapped_key);
          }
        } else {
          if (!!value.blocks) {
            // NOTE: Handle FSRequest.Create, FSRequest.BulkUpdate.Block[], FSRequest.Update
            create_file.push(this._buildNewCreate(id, value, FSType.FILE));
            // Add To FSRequest.BulkUpdate.Block[]
            const block_ids = _.compact(_.map(value.blocks, (block: FSRequest.BulkUpdate.Block) => block.getId()));
            blocks = blocks.concat(value.blocks);
            !!block_ids && block_ids.length > 0 ?
              updates.push(this._buildUpdate(id, value, block_ids)) :
              console.error(DriveErrorMessages.error_getting_file_blocks);
          } else {
            console.error(DriveErrorMessages.error_getting_file_blocks);
          }
        }
      }

      return value;
    }));

    // NOTE: Create Directories first to avoid Parent not found error
    return {
      create: create_dir.concat(create_file),
      updates,
      blocks
    };
  }

  // Description: Build the FSRequest.Create object
  private _buildNewCreate(id: string, value: SnapshotNode, type: FSTypeMapType, fs_wrapped_key?: FSWrappedKey): FSRequest.Create {
    const create = new FSRequest.Create();
    create.setParentId(value.parent_id);
    create.setParentVer(value.parent_version);
    create.setId(id);
    create.setVersion(value.version);
    create.setName(value.name);
    create.setType(type);
    !!value.last_local_fs_update && create.setLastLocalFsUpdate(value.last_local_fs_update);
    !!fs_wrapped_key && create.setWrappedDirKey(fs_wrapped_key); // NOT FOR FILES
    return create;
  }

  // Description: Build the FSRequest.Update object
  private _buildUpdate(id: string, value: SnapshotNode, block_ids: string[]): FSRequest.Update {
    const change = new FSRequest.Update.Change();
    change.setOp(FSRequest.Update.Op.ALTER);
    change.setDataList(block_ids);
    const update = new FSRequest.Update();
    update.setId(id);
    update.setOldVersion(value.version);
    update.setNewVersion(randomBytes(32));
    update.setChangesList([change]);
    !!value.last_local_fs_update && update.setLastLocalFsUpdate(value.last_local_fs_update);
    return update;
  }

  // Description: In a multi-page snapshot, directory children might be split across pages, so we are combining the entries for the directories
  // with the same id so that all entries are together.
  static getIndexedSnapshot(snapshot: FSMessage.Snapshot): IndexedSnapshot {
    const dirs = new Map<string, FSMessage.Dir>();
    snapshot.getDirsList().forEach((value) => {
      const id = value.getId_asB64();
      const exisiting_dir = dirs.get(id);
      if (dirs.has(value.getId_asB64()) && !!exisiting_dir) {
        exisiting_dir.setEntriesList(exisiting_dir.getEntriesList().concat(value.getEntriesList()));
      } else {
        dirs.set(value.getId_asB64(), value);
      }
    });
    const files = new Map();
    snapshot.getFilesList().forEach((value) => {
      files.set(value.getId_asB64(), value);
    });
    const links = new Map();
    snapshot.getLinksList().forEach((value) => {
      links.set(value.getId_asB64(), value);
    });
    return { dirs, files, links };
  }
}

export class SnapshotNode {
  constructor(
    public id: string | Uint8Array,
    public version: string | Uint8Array,
    public name: Uint8Array,
    public parent_id: string | Uint8Array,
    public parent_version: string | Uint8Array,
    public is_directory: boolean,
    public is_root: boolean = false,
    public wrappedDirKey?: FSWrappedKey,
    public blocks?: FSRequest.BulkUpdate.Block[], // Array<Promise<FSRequest.BulkUpdate.Block>>,
    public last_local_fs_update?: string
  ) { }


}
