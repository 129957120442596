import React from "react";
import { RegexHelper } from "src/common";
import { EmailGatewayEntity, EmailGateway } from "src/components/admin/email-gateway/helpers.class";
import { AddressListRow } from "src/components/shared/address-list";
import _ from "lodash";

type AddressListModalPanelProps = {
  validation_state: "duplicate" | "invalid" | "valid_trusted" | "valid_gateway" | "valid_group" | "non_org_user";
  collection: string[] | EmailGateway[];
  handleDeleteAction: (id: string | undefined) => void;
}

const statusHeading = {
  duplicate: "Duplicate addresses",
  non_org_user: "Users are not active members of the organization",
  invalid: "Invalid Format",
  valid_trusted: "New addresses to be added to Trusted Community.",
  valid_gateway: "New addresses to be added to Email Gateway.",
  valid_group: "New addresses to be added to Admin Group."
};

function AddressListModalPanel({ validation_state, collection, handleDeleteAction }: AddressListModalPanelProps) {
  const headingText = statusHeading[validation_state];
  const invalidColor = validation_state === "duplicate" || validation_state === "invalid" || validation_state === "non_org_user" ? "invalid" : "";
  const totalAddresses = collection.length;
  const heading = totalAddresses === 1 ? headingText.replace("addresses", "address") : headingText;
  return (
    <section className="csv-upload-card">
      <div>
        <header className="d-flex align-items-center pt-2 pb-2">
          <h5 className="m-0">{`${totalAddresses} ${heading}`}</h5>
        </header>
        <div>
          {collection.map((param: string | EmailGateway) => {
            if (param instanceof EmailGatewayEntity) {
              const formattedAddress = param.email_gateway_address_format();
              const { id, address, displayName, type } = formattedAddress;
              const icon = type === "individual" ? "ficon-user" : "ficon-globe";
              const subTitle = type === "individual" ? displayName : undefined;
              return (
                <AddressListRow
                  key={id}
                  sub_title={subTitle}
                  icon={icon}
                  id={address}
                  handleDeleteAction={handleDeleteAction}
                  selectable={false}
                  validation_state={validation_state}
                />
              );
            }
            const address = param as string;
            const key = _.uniqueId(address);
            const icon = validation_state ===  "invalid" ? "ficon-x-circle" : RegexHelper.testEmailAddress(address) ? "ficon-user" : "ficon-globe";
            return (
              <AddressListRow
                key={key}
                icon={icon}
                id={address}
                handleDeleteAction={handleDeleteAction}
                selectable={false}
                validation_state={validation_state}
                color={invalidColor}
              />
            );
          })}
        </div>
      </div>
    </section>
  ); 
};

export default React.memo(AddressListModalPanel);
