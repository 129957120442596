import { useState, useCallback, useEffect } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import {
  Account, useAppDispatch, Message, MessageHandlerDisplayType, useSendRequestMutation, CollectionFilesyncAPI, DriveErrorMessages, AppUserKey,
  useGetGrants, useAppSelector, KeyFactory, Helpers, DriveRequest, UUID, GrantSetType, GrantSet, DownloadFileApiResponse, useDownloadFileMutation,
  getEnumKey, ErrorStackItem
} from "src/common";
import { DriveCallbackAsyncFunction, uiActions } from "src/store";
import _ from "lodash";
import { FSBlockType, FSMessage, FSRole, FSStatus, FSRequest } from "src/common/keys/protos/collections_pb";
import { PermissionSet, PermissionSetType } from "src/common/drive/permissions.class";
import { RootState } from "src/store/configureStore";
import { EncryptionStyle } from "src/common/keys/encryption";

type FileBlock = {
  index: number;
  data: Uint8Array;
}

/* Description: This is the download file hook that returns the file as a Blob. It is used for file viewer as well as downloading a file as an isolate operation */
export function useDownloadFile(current_account: Account, use_filesync: boolean = false, include_deleted: boolean = false) {
  const default_permissions = useAppSelector((state: RootState) => state.drive.default_permissions);
  const default_grants = useAppSelector((state: RootState) => state.drive.default_grants);
  const [file, setFile] = useState<Blob>();
  const [file_name, setFileName] = useState<string>();
  const [fs_message_file, setFSMessageFile] = useState<FSMessage.File>();
  const [error, setError] = useState<boolean>(false);
  const [collection_id, setCollectionId] = useState<string>("");
  const [blocks, setBlocks] = useState<FileBlock[]>([]);
  const [done_downloading, setDoneDownloading] = useState<boolean>(false);
  const [sendRequest] = useSendRequestMutation();
  const [download_file] = useDownloadFileMutation();
  const { grants, getGrants } = useGetGrants(current_account, include_deleted);
  const dispatch = useAppDispatch();
  const [for_viewer, setForViewer] = useState(false);

  // Description: This block will only be hit if the file has a maintainer id and we call the getGrants, it will then call downloadBlockData with the appropriate user key so that it can decrypt the block's wrapped key. 
  useEffect(() => {
    if (!!grants) {
      downloadBlockDataWithGrants(grants);
    }
  }, [grants]);

  // Description: Once all blocks are done downloading, they are sorted and then set as a Blob that is returned from this hook.
  useEffect(() => {
    if (!!done_downloading) {
      const sorted_blocks = blocks.sort((a, b) => a.index - b.index);
      const data = sorted_blocks.map((b) => b.data);
      setFile(new Blob(data));
    };
  }, [done_downloading]);

  async function downloadBlockDataWithGrants(grants: GrantSetType) {
    if (!!fs_message_file && fs_message_file?.getDataList().length > 0) {
      const wrapped_key = fs_message_file?.getDataList()[0].getWrappedKey();
      const grant = !!wrapped_key ? GrantSet.getGrantbyRole(grants, wrapped_key.getKeyVersion()) : null;
      const key = !!grant ? await grant.key(current_account) : null;
      !!key && downloadBlocksData(collection_id, fs_message_file.getDataList(), undefined, for_viewer, key);
    }
  }

  // Description: Gets the file and goes through the data list, downloading each block. 
  async function downloadFileWeb(_collection_id: string, file_id: string, for_viewer: boolean) {
    const permissions = _.find(default_permissions, (set: PermissionSetType) => _collection_id === set.collection_id.B64());
    const drive_request = !!permissions ? await CollectionFilesyncAPI.fetchFile(current_account, _collection_id, file_id, permissions, include_deleted) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const file = message.getFile();
        if (!!file && !!permissions && file.getDataList().length > 0) {
          setFSMessageFile(file);
          if (file.hasMaintainerId()) {
            getGrants(file.getMaintainerId_asB64(), _collection_id, default_permissions, default_grants);
          } else {
            downloadBlocksData(_collection_id, file.getDataList(), permissions, for_viewer);
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_file }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_file);
  }

  // Description: Downloads the blocks of the file
  async function downloadBlocksData(_collection_id: string, file_blocks: FSMessage.Block[], permissions?: PermissionSetType, for_viewer?: boolean, key?: AppUserKey) {
    const block_ids: string[] = [];
    let __collection_id = _collection_id;
    file_blocks.forEach(async (block, index) => {
      const block_type = block.getBlockType();
      const block_id = block.getId();
      switch (block_type) {
        case FSBlockType.BASIC: // NOTE: Basic block is a block of a file that was uploaded into a directory after it was already sharedxs
          !!block_id && block_ids.push(block_id);
          index === file_blocks.length - 1 && downloadBlocks(__collection_id, block_ids, file_blocks, permissions, for_viewer, key);
          break;
        case FSBlockType.POINTER: // NOTE: Pointer block is a block of a file that was already existing in the directory after sharing
          const wrapped_key = block.getWrappedKey();
          const set = !!permissions ? PermissionSet.init(permissions) : null;
          const version = !!wrapped_key ? wrapped_key?.getKeyVersion() : 0;
          const user_key = !!key ? key : !!set ? await set.permission(FSRole.READER, version)?.key(current_account) : null;   
          if (!!user_key && !!block_id) {
            const decoded: Uint8Array = Helpers.hexDecode(block_id);
            const secret = await user_key.encryption_key.unseal(decoded);
            const data = Helpers.utf8Decode(secret).split("/");
            if (data.length !== 2) {
              handlePageErrorMessage(DriveErrorMessages.default, {
                stack_message: DriveErrorMessages.block_incorrect_format,
              });
              break;
            }
            block_ids.push(data[1]);
            __collection_id = new UUID({ bytes: Helpers.hexDecode(data[0]) }).B64(); // NOTE: data[0] is a hex string so I am converting it to a UUID so we can get the B64() to get permissions/send the request
            index === file_blocks.length - 1 && downloadBlocks(__collection_id, block_ids, file_blocks, permissions, for_viewer, key);
          } else {
            handlePageErrorMessage(DriveErrorMessages.default, {
              stack_message: DriveErrorMessages.undefined_key,
            });
          }
          break;
        default:
          handlePageErrorMessage(DriveErrorMessages.default, {
            stack_message: DriveErrorMessages.invalid_block_type,
          });
          break;
      }  
    });
  }

  // Description: DOWNLOAD_BLOCK is deprecated so we switched to use DOWNLOAD_BLOCKS to be able to send
  // the access type flag to backend. In the future we may want to implement DOWNLOAD_BLOCKS_DIRECT 
  async function downloadBlocks(_collection_id: string, block_ids: string[], file_blocks: FSMessage.Block[], permissions?: PermissionSetType, for_viewer?: boolean, key?: AppUserKey) {
    const access_type = for_viewer ? FSRequest.DownloadBlocks.BlockAccessType.VIEW : FSRequest.DownloadBlocks.BlockAccessType.DOWNLOAD_TO_BROWSER;
    const drive_request = await CollectionFilesyncAPI.downloadBlocks(current_account, _collection_id, block_ids, access_type);
    async function callback(message: FSMessage) {
      const status = message.getStatus();
      const data_blocks = message.getDataBlocks()?.getBlocksList();
      if (status === FSStatus.OK && !!data_blocks) {
        data_blocks.forEach(async (block_data, index) => {
          const wrapped_key = file_blocks[index].getWrappedKey();
          const set = !!permissions ? PermissionSet.init(permissions) : null;
          const version = !!wrapped_key ? wrapped_key?.getKeyVersion() : 0;
          const user_key = !!key ? key : !!set ? await set.permission(FSRole.READER, version)?.key(current_account) : null;
          const block_id = block_data.getId();
          if (!!user_key && !!wrapped_key && !!block_id) {
            const unwrapped_block_key = await user_key.encryption_key.unseal(wrapped_key?.getWrappedKey_asU8());
            const block_key = KeyFactory.deserializeSymmKey(unwrapped_block_key, EncryptionStyle.DRIVE);
            const decryptedData = await block_key.decrypt(block_data.getInlineData_asU8());
            blocks.push({ data: decryptedData, index });
            setBlocks(blocks);
            ((data_blocks.length - 1) === index) && setDoneDownloading(true);
          } else {
            handlePageErrorMessage(DriveErrorMessages.default, {
              stack_message: DriveErrorMessages.undefined_key,
            });
          }
        });
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_downloading_block }, message);
      }
    }
    handleSendRequest(drive_request, callback, DriveErrorMessages.error_downloading_block);
  }

  async function downloadFileForApp(collection_id: string, file_id: string, file_name: string, for_viewer: boolean) {
    await download_file({
      user_id: current_account.user_id,
      file_id,
      collection_id,
      file_name,
      for_viewer
    })
      .unwrap()
      .then((blob: DownloadFileApiResponse) => {
        setFile(blob);
      })
      .catch((stack_error: unknown) => {
        handlePageErrorMessage(DriveErrorMessages.default, {
          stack_message: DriveErrorMessages.error_downloading,
          stack_error
        });
      });
  }

  // Description: handles error when the request object is null (this happens when permissions are undefined)
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }

  // Description: Logs Errors and sets Error to true
  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.logger) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useDownloadFile Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Callback for download file
  const downloadFile = useCallback((_collection_id: string, file_id: string, file_name: string, for_viewer: boolean = false) => {
    setCollectionId(_collection_id);
    setFileName(file_name);
    setForViewer(for_viewer);
    if (use_filesync) {
      downloadFileForApp(_collection_id, file_id, file_name, for_viewer);
    } else {
      downloadFileWeb(_collection_id, file_id, for_viewer);
    }
  }, []);

  // Description: Callback for download file
  const resetDownload = useCallback(() => {
    setFile(undefined);
    setFSMessageFile(undefined);
    setError(false);
    setCollectionId("");
    setBlocks([]);
    setDoneDownloading(false);
    setForViewer(false);
  }, []);

  return {
    file,
    downloadFile,
    error,
    resetDownload,
    file_name
  };
}