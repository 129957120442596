
import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useAppDispatch, NavigationPath, useAppSelector } from "src/common";
import { uiActions } from "src/store";
import { Icon } from "src/components";
import { RootState } from "src/store/configureStore";

type AllProps = {
  type?: string;
  className?: string;
}

function NotificationsButtonComponent(props: AllProps) {
  const { type, className } = props;
  const upload_request = useAppSelector((state: RootState) => state.drive.upload_request);
  const upload_folder_ids = useAppSelector((state: RootState) => state.ui.upload_folder_ids);
  const upload_progress_hidden = useAppSelector((state: RootState) => state.ui.upload_progress_hidden);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (!upload_request && upload_folder_ids.length > 0) && dispatch(uiActions.setUploadFolderIds([]));
  }, [upload_request]);

  function handleProgressClick() {
    dispatch(uiActions.setUploadProgressHidden(!!upload_request && !upload_progress_hidden));
  }

  return <Nav.Link title={upload_progress_hidden ? "View Progress" : "Hide Progress"}
    className={`dot${upload_folder_ids.length > 0 ? "" : " disabled"}${!!className ? ` ${className}` : ""}`} onClick={handleProgressClick}>
    {/* Add Icons as needed by NavigationPath or disable it */}
    <Icon className={type === NavigationPath.DriveNavigation ? "ficon-bell" : "ficon-bell"} />
  </Nav.Link>;
}

export default React.memo(NotificationsButtonComponent);
