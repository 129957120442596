import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { UserProfile, ActionHandlerFunction, IActionHandler, CollectionServerUser, CollectionServerFindUsers } from "@preveil-api";
import { Account, GlobalErrorMessages, MessageAnchors, Contacts, isSameProfile, useContacts, SettingsUIActionTypes, isSameUser } from "src/common";
import { UserSearchForm, UserBadges } from ".";
import { Loading } from "src/components";
import _ from "lodash";

type AllProps = {
  current_account: Account;
  user_ids?: string[];
  id?: string;
  focus?: boolean;
  hide_badges?: boolean;
  org_users?: CollectionServerUser[];
  exclude_self?: boolean;
  exclude_users?: string[];
  show_external_users?: boolean;
  single_user?: boolean;
  handleAction: ActionHandlerFunction;
};

const UserListComponent = forwardRef(function UserListComponent(props: AllProps, ref) {
  const { user_ids, current_account, id, focus, handleAction, hide_badges, org_users, exclude_self, single_user, exclude_users, show_external_users } = props;
  const [_user_ids, setUserIds] = useState<string[] | undefined>(user_ids);
  const [_exclude_users, setExcluded] = useState<string[] | undefined>(user_ids);
  const [contacts] = useState<Contacts>(new Contacts(current_account.user_id, null, org_users, exclude_self, exclude_users));
  const [current_contacts, setCurrentContacts] = useState<UserProfile[]>([]);
  const { data, isLoading } = useContacts(current_account, _user_ids);
  useImperativeHandle(ref, () => ({ handlePageActions }));

  useEffect(() => {
    (!!_user_ids && _user_ids.length > 0) && setUserIds(_user_ids);
  }, [_user_ids]);

  useEffect(() => {
    !!data && handleUseContactsData(data);
  }, [data]);

  useEffect(() => {
    // NOTE: Pass _exclude_users to reset messaging on component
    handleAction({ actionType: SettingsUIActionTypes.UpdateContacts, params: { current_contacts, id, contacts_data: contacts.data, exclude_users: _exclude_users } });
  }, [current_contacts]);

  // Description: Find users success call
  function handleUseContactsData(data: CollectionServerFindUsers) {
    const { users, groups, aliases, errors } = data;
    const _users: CollectionServerUser[] = !!users && users.length > 0 ? users : []; // begin with the user response
    contacts.updateCsProfiles(_users, _user_ids);
    contacts.updateCsProps(aliases, groups, errors);
    setCurrentContacts(contacts.current_contacts);
  }

  const UserListRequests = {
    // Description: Find if selected_profile is already in contacts.profiles 
    handleSetUserProfile: (user_profiles: UserProfile[]) => {
      const valid_users: string[] = [];
      const excluded_contacts: string[] = [];
      _.forEach(user_profiles, (user_profile: UserProfile) => {
        // NOTE: If user is self and exclude_self is on then return current so we can show error 
        // If user is in the exclude_users optional array does not allow it in 
        const exclude_user = (!!exclude_self && isSameUser(user_profile.address, current_account.user_id)) ||
          (!!exclude_users && exclude_users.includes(user_profile.address));
        if (exclude_user) {
          excluded_contacts.push(user_profile.address.toLowerCase());
        } else {
          const selected_profile_id = !!user_profile.external_email ? user_profile.external_email : user_profile.address;
          valid_users.push(selected_profile_id);
        }
      });

      (excluded_contacts.length > 0) && handleAction({
        actionType: SettingsUIActionTypes.UpdateContactsExcludeUserWarning,
        params: { id, current_contacts, contacts_data: contacts.data, exclude_users: _.uniq(excluded_contacts) }
      });
      setExcluded(excluded_contacts);

      valid_users.length > 0 && setUserIds(valid_users);
    },
    // Description: Resets current contacts
    handleReset: () => {
      setUserIds([]);
      setCurrentContacts([]);
      contacts.reset();
    },
    // Description: remove profiles from the selected list
    handleRemoveProfile: (_user_profile: UserProfile) => {
      if (!!contacts) {
        const existing_contact = _.find(contacts.current_contacts, (profile: UserProfile) => isSameProfile(profile, _user_profile));
        if (!!existing_contact) {
          contacts.removeCurrentContact(existing_contact);
          setCurrentContacts(contacts.current_contacts);
        }
      } else { // Reset
        setCurrentContacts([]);
      }
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      console.error(params);
    },
    handleCancel: (params: { user_id: string }) => {
      // Note: just being use in Admin Gateway, if user wants to cancel
      // Lookup for a new gateway user, clicking cancel button should
      // default to current gateway user id.
      let current_contacts;
      let profileToRemove = {};

      if (contacts.current_contacts.length > 1) {
        // Note: if gatewayId is invalid we want to filter/reset to previous valid one.
        // Note Update: now we are filtering out non-org users for email gateway, so it shouldn't return this error.
        // but keeping it just in case, this condition will only trigger if there more than one current_contact when invalid.
        current_contacts = contacts.current_contacts.filter((contact, i) => {
          if (contact.address !== params.user_id) {
            profileToRemove = contact;
          }
          return contact.address === params.user_id;
        });
        contacts.removeCurrentContact(profileToRemove as UserProfile);
        setCurrentContacts(current_contacts);
      }
      setUserIds([params.user_id]);
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((UserListRequests as any)[callback] instanceof Function) {
      (UserListRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      UserListRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <>
    {
      (!!current_contacts && !hide_badges) &&
      <UserBadges current_contacts={current_contacts} handleAction={UserListRequests.handleRemoveProfile} show_external_users={show_external_users} />
    }
    {
      isLoading ?
        <Loading noLogo className="in-place" /> :
        <UserSearchForm
          focus={focus}
          single_user={single_user}
          profiles={!!contacts ? contacts.filterSelected(current_contacts, exclude_users) : []}
          org_users={org_users}
          handleAction={handlePageActions} />
    }
  </>;
});

export default React.memo(UserListComponent);
