import { ChangeAdminStatusRequest, ChangeOrgApprovalGroupRequest, CollectionServerUser, DeleteUserRequest, MemberRekeyAndSetRecoveryRequest, SubsumeRequest } from "@preveil-api";
import { useCallback, useState } from "react";
import {
  Account,
  RequestTypes,
  usePostUsersFindMutation,
  UserRequest,
  ApprovalGroup,
} from "src/common";
import _ from "lodash";

export function useGetPopulatedRequests(current_account: Account) {
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<{ requests: UserRequest[], invalid_requests_count: number }>();
  const [findUsers] = usePostUsersFindMutation();

  async function getPopulatedRequests(
    reqs: UserRequest[],
    org_users: CollectionServerUser[],
    approval_group: ApprovalGroup[],
  ) {
    let users: UserRequest[] | undefined;
    let user_ids = Array<string>();
    reqs.forEach((request) => {
      try {
        if (!!request.requester_id) {
          user_ids = user_ids.concat(request.requester_id);
        }
        const request_info = populateRequestInfo(request, org_users, approval_group);
        user_ids = user_ids.concat(request_info.external_user_ids);
        return request;
      } catch (error) {
        setError(error);
        request.invalid = true;
      }
    });
    user_ids = _.uniqBy(user_ids, (id) => id);
    if (user_ids.length > 0) {
      users = await getUsers(
        user_ids,
        reqs.filter((request) => !request.invalid),
      );
    } else {
      users = reqs.filter((request) => !request.invalid);
    }
    const invalid_requests_count = reqs.filter((request) => !!request.invalid).length;
    if (!!users) {
      setData({ requests: users, invalid_requests_count });
    }
  }

  /* Description: Gets user objects from the backend given a list of user ids and populates the request objects
  with those users. */
  async function getUsers(
    user_ids: string[],
    user_requests: UserRequest[],
  ): Promise<UserRequest[] | undefined> {
    if (!!current_account) {
      return await findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: user_ids.map((id) => ({ user_id: id, key_version: -1 })) },
      })
        .unwrap()
        .then(({ users }) => {
          const updated_admin_requests = user_requests.map((req) => {
            if (!!req.parsed_payload.for_users) {
              req.for_users = req.parsed_payload.for_users.map((for_user) => {
              const u = users.find((u) => u.user_id === for_user.toLowerCase());
              return { name: u?.display_name || null, address: u?.user_id || "" };
              });
            }
            return req;
          });
          return updated_admin_requests;
        })
        .catch((msg: string) => {
          const updated_response = user_requests.map((request) => {
            if (!!request.parsed_payload.approvers) {
              request.approvers = request.parsed_payload.approvers.map(
                (approver: { user_id: any }) => ({ name: null, address: approver.user_id }),
              );
            }
            return request;
          });
          return updated_response;
        });
    }
    return undefined;
  }

  /* Description: Used to populate the request object depending on the request type. */
  function populateRequestInfo(request: UserRequest, org_users: CollectionServerUser[],
      approval_group: ApprovalGroup[]): { request: UserRequest; external_user_ids: string[] } {
    let external_user_ids = Array<string>();
    let user: CollectionServerUser | undefined;
    let new_group_id: string;
    const u = org_users.find(
      (org_user) => org_user.user_id.toLowerCase() === request.requester_id.toLowerCase(),
    );
    request.requester = { name: u?.display_name || null, address: u?.user_id || "" };
    switch (request.type) {
      case RequestTypes.member_rekey_and_set_approval_group:
      case RequestTypes.set_member_approval_group:
        (request.metadata as MemberRekeyAndSetRecoveryRequest).events.forEach((event) => {
          try {
            const event_payload = JSON.parse(event.payload);
            new_group_id = event_payload.data.id;
            const user = org_users.find(
              (org_user) =>
                org_user.user_id.toLowerCase() === event_payload.for_user_id.toLowerCase(),
            );
            if (!!user) {
              request.for_users.push({ address: user.user_id, name: user.display_name });
            }
          } catch {
            setError("error parsing event payload");
          }
        });
        if (!!request.for_users && request.for_users.length > 0) {
          // request.current_group = request.for_users[0].recovery_group;
        } else {
          request.invalid = true;
        }
        break;
      case RequestTypes.change_org_approval_group_role:
        new_group_id = (request.metadata as ChangeOrgApprovalGroupRequest).group_id;
        request.current_group = approval_group.find(
          (ag) => ag.approval_group_id === request.current_group_id,
        );
        break;
      case RequestTypes.change_admin_status:
        const change_admin_status = request.parsed_payload.data as ChangeAdminStatusRequest;
        user = org_users.find(
          (org_user) =>
            org_user.user_id.toLowerCase() === change_admin_status.user_id.toLowerCase(),
        );
        if (!!user) {
          request.updated_role = change_admin_status.role;
          request.for_users.push({ address: user.user_id, name: user.display_name });
        } else {
          request.invalid = true;
        }
        break;
      case RequestTypes.subsume_account:
        const subsume = request.parsed_payload.data as SubsumeRequest;
        external_user_ids = external_user_ids.concat(subsume.subsume_user_id);
        request.parsed_payload.for_users = [subsume.subsume_user_id];
        request.setOrgDetails({
          loaded: true,
          org_id: current_account?.org_info?.org_id || "",
          org_name: current_account?.org_info?.org_name || "",
        });
        break;
      case RequestTypes.delete_user:
        const delete_user = request.parsed_payload.data as DeleteUserRequest;
        user = org_users.find(
          (org_user) =>
            org_user.user_id.toLowerCase() === delete_user.user_id.toLowerCase(),
        );
        if (!!user) {
          request.for_users.push({ address: user.user_id, name: user.display_name });
        } else {
          request.invalid = true;
        }
        break;
    }
    const associated_ag = approval_group.find(
      (ag) => ag.approval_group_id === new_group_id,
    );
    if (associated_ag) {
      request.approval_group_name = associated_ag.name;
      request.optionals_required = associated_ag.required_approvers;
      request.approvers = associated_ag.approvers;
    }
    return { request, external_user_ids };
  }

  const refreshPopulatedRequests = useCallback(
    (reqs: UserRequest[], org_users: CollectionServerUser[], approval_group: ApprovalGroup[]) => {
      getPopulatedRequests(reqs, org_users, approval_group);
    },[]);

  return {
    data,
    error,
    refreshPopulatedRequests
  };
}

