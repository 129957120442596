import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { IActionHandler, CreateUserData, MessageDisplayType } from "@preveil-api";
import {
  QueryParamKeys, QueryParamValues, normalizeQueryUserId, useAppDispatch, useAppSelector, account_types, MessageHandlerDisplayType,
  GlobalErrorMessages, CreateAccountSteps, Message, DefaultRoutes, MessageAnchors, AccountErrorMessages, StatusCode
} from "src/common";
import { uiActions, accountActions } from "src/store";
import { Loading, useAuthContext, ErrorMessage } from "src/components";
import { EmailForm, VerificationForm, UserInformationForm } from ".";

function CreateAccountComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginComplete } = useAuthContext();
  const current_account = useAppSelector((state) => state.account.current_account);
  const step = useAppSelector((state) => state.account.status);
  const [user_id, setUserId] = useState<string>();
  const [expires, setExpires] = useState<string>("");
  const [verification_error_count, setVerificationErrorCount] = useState<number>(0);
  const [create_user_data, setCreateUserData] = useState<CreateUserData>();
  const [org_name, setOrgName] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  // Description: Initialize query string data
  function handleInitCreateAccount() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }

    dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_EMAIL));
  }

  // Description: Handle all children component actions and store it
  const CreateAccountRequests = {
    // Description: Already validated email 
    handleSubmitEmail: (params: { user_id: string; expires: string; status: string; }) => {
      setUserId(params.user_id);
      setExpires(params.expires);
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_VERIFICATION_CODE));
    },
    handleSubmitVerificationCode: (_create_user_data: CreateUserData) => {
      if (!!_create_user_data) {
        setOrgName(!!_create_user_data.org_name ? _create_user_data.org_name : undefined);
        setCreateUserData(_create_user_data);
        dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_USER_INFORMATION));
      } else {
        CreateAccountRequests.handlePageError({ message: AccountErrorMessages.create_account_step2_failed });
      }
    },
    handleSubmitUserInformation: (display_name: string) => {
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.LOADING));
      dispatch(accountActions.setNewLocalAccount(Object.assign({}, create_user_data, { display_name })));
    },
    handleSubmitVerificationCodeError: (status: number) => {
      if (status === StatusCode.FORBIDDEN_DOMAIN && verification_error_count === 4) {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_code_reset)));
        setVerificationErrorCount(0);
        setExpires("");
        dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_EMAIL));
      } else {
        setVerificationErrorCount(verification_error_count + 1);
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_verification_code)));
      }
    },
    // Description: Reset all form states and go back to first step
    handleResetForms: () => {
      setUserId(undefined);
      setOrgName(undefined);
      setCreateUserData(undefined);
      setExpires("");
      dispatch(accountActions.setComponentStatus(CreateAccountSteps.SUBMIT_EMAIL));
    },
    handlePageError: (params: { message: string, stack?: any, displayType?: MessageDisplayType }) => {
      CreateAccountRequests.handleResetForms();
      const displayType = params.displayType || MessageHandlerDisplayType.toastr;
      dispatch(uiActions.handleRequestErrors(new Message(params.message, displayType), params.stack));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((CreateAccountRequests as any)[callback] instanceof Function) {
      (CreateAccountRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      CreateAccountRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  //  Description: Create Account 3 Steps and Forms 
  function CreateAccountForms() {
    switch (step) {
      case CreateAccountSteps.SUBMIT_EMAIL: // 1 
      case CreateAccountSteps.SUBMIT_ERROR:
        return <EmailForm handleAction={handlePageActions} user_id={user_id}>
          <>
            <h2 className="border-bottom">Welcome to PreVeil!</h2>
            <p>
              We use your <b>regular email address</b> as your <b>PreVeil User ID.</b> The email address will also be used to verify your identity during account activation.</p>
            <p>If you have been invited to join PreVeil by your IT organization, use the same work email address provided in your invitation. Otherwise, individual users can choose an email address that you can access from the computer or smartphone where the
              PreVeil application is installed.</p>
          </>
        </EmailForm>;
      case CreateAccountSteps.SUBMIT_VERIFICATION_CODE: // 2
        return !!user_id ? <VerificationForm handleAction={handlePageActions} user_id={user_id} expires={expires} /> :
          <ErrorMessage message={GlobalErrorMessages.default} />;
      case CreateAccountSteps.SUBMIT_USER_INFORMATION:
        return <UserInformationForm create_user_data={create_user_data} org_name={org_name} handleAction={handlePageActions} />;
      default:
        return <Loading className="in-place" />;
    }
  }

  // Description: load accounts and current account from crypto (First onload event)
  useEffect(() => {
    handleInitCreateAccount();
    return () => { // On unmount
      dispatch(uiActions.handleMessageDismiss());
      dispatch(accountActions.destroyAccountFormStates());
    };
  }, []);

  // Description: Update the login status when current account is set in state
  useEffect(() => {
    (!!current_account) &&
      loginComplete(current_account, account_types.full,
        `${DefaultRoutes.mail_default}?${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.new}`);
  }, [current_account]);

  return step > 0 ? <Row className="justify-content-center">
    <Col md={8}>
      <h1 className="main-header">Create New Account <small>{step} of 3</small></h1>
      <Card className="pv-card">
        <CreateAccountForms />
      </Card>
    </Col>
  </Row> : <Loading />;
}

export default React.memo(CreateAccountComponent);
