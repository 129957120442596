import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionHandlerFunction } from "@preveil-api";
import { DefaultCollectionName, DefaultRoutes, PathInfo, CollectionEntity } from "src/common";
import { Breadcrumb } from "react-bootstrap";
import _ from "lodash";

type AllProps = {
  id?: string;
  paths?: PathInfo[];
  collection_info?: CollectionEntity;
  handleAction?: ActionHandlerFunction;
  trash?: boolean;
}

function BreadcrumbsComponent(props: AllProps) {
  const { paths, id, collection_info, trash } = props;
  const [effective_root, setEffectiveRoot] = useState<PathInfo | undefined>();
  const route = trash ? DefaultRoutes.drive_trash : DefaultRoutes.drive_root;

  useEffect(() => {
    (!!paths && paths.length > 0 && !!collection_info) &&
      buildEffectiveRootNode(paths[0], collection_info);
  }, [paths, collection_info]);

  // Description: Perform all checks to build the ACL Node Effective root
  function buildEffectiveRootNode(info: PathInfo, _collection_info: CollectionEntity) {
    const id = info.id;
    const maintainer_id = _collection_info.maintainer_id;
    // NOTE: Need to check this collection_info is NOT root so it wont duplicate node on owner accts
    if (!!id && !!maintainer_id && id === maintainer_id && _collection_info.collection_name !== info.path) {
      setEffectiveRoot({
        id,
        path: _collection_info.collection_name,
        url: DefaultRoutes.drive_root
        // TO DO: Need to figure the Effective root URL from link_id and collection_id (root_info collection_id)
      });
    } else {
      setEffectiveRoot(undefined);
    }
  }

  return <Breadcrumb className="drive-breadcrumbs">
    <Breadcrumb.Item className="h1" linkProps={{ to: route }} linkAs={Link}>{trash ? "Trash" : "My PreVeil"}</Breadcrumb.Item>
    {
      (!!paths && paths.length > 0) &&
      <>
        {
          !!effective_root &&
          <Breadcrumb.Item
            className="effective_root"
            active={false}
            linkProps={{ to: effective_root.url || DefaultRoutes.drive_root }}>{effective_root.path}</Breadcrumb.Item>
        }
        {
          _.map(paths, (node: PathInfo, index: number) => {
            // NOTE: Skip defaultCollection (root)
            return node.path !== DefaultCollectionName &&
              <Breadcrumb.Item
                key={`path_${index}`}
                // NOTE: Check to see if this is the last node which is current node and make active
                active={node.id === id || (index + 1 >= paths.length)}
                linkAs={Link}
                linkProps={{ to: node.url || DefaultRoutes.drive_root }}>
                {node.path}
              </Breadcrumb.Item>;
          })}
      </>
    }
  </Breadcrumb >;
}

export default React.memo(BreadcrumbsComponent);
