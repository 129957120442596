import React, { useState, useEffect, FocusEvent } from "react";
import { Link } from "react-router-dom";
import { Col, Button, Form } from "react-bootstrap";
import { AccountType, ActionHandlerFunction } from "@preveil-api";
import {
  PublicRoutes, usePostUsersTypeMutation, RegexHelper, account_types, AccountUIActionTypes, useGetUsersApproversSecretQuery, AccountErrorMessages
} from "src/common";
import { Icon, Loading } from "src/components";

type AllProps = {
  user_id?: string;
  handleAction: ActionHandlerFunction;
}

function DefaultPanelComponent(props: AllProps) {
  const { user_id, handleAction } = props;
  const [validated, setValidated] = useState(false);
  const [_user_id, setUserId] = useState<string>(user_id || "");
  const [skip, setSkip] = useState(true);
  const { data, error, isLoading } = useGetUsersApproversSecretQuery({ userId: _user_id || "" }, { skip });
  const [getAccountType] = usePostUsersTypeMutation();

  // Description: Handle Data and errors from get Users Approvers Secret call
  useEffect(() => {
    if (!!data) {
      setSkip(false);
      handleAction({ actionType: AccountUIActionTypes.SubmitUserId, params: _user_id });
    } else if (!!error) {
      handleError(AccountErrorMessages.default, true, error);
    }
  }, [data, error, isLoading]);


  // Description: Submit Backup Recovery code
  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    e.stopPropagation();
    const userId = e.currentTarget.user_id.value.trim().toLowerCase();
    if (isValid(userId)) { // Validate email in form
      getAccountType({ userId }).unwrap()
        .then((params: { account_type: AccountType }) => {
          (params.account_type === account_types.full) ? setSkip(false) :
            handleError(AccountErrorMessages.account_user_id_error, false);
        })
        .catch((stack: unknown) => {
          handleError(AccountErrorMessages.account_user_id_error, true, stack);
        });
      setValidated(true);
    } else {
      handleError(AccountErrorMessages.account_user_id_error);
    }
  };

  // Description: is form valid
  function isValid(email: string): boolean {
    return RegexHelper.testEmailAddress(email);
  };

  // Description: Send error up and reset form
  function handleError(message: string, error_panel: boolean = true, stack?: any) {
    setUserId("");
    handleAction({
      actionType: AccountUIActionTypes.PageError,
      params: { message, stack, error_panel }
    });
  };

  return isLoading ? <Loading className="in-place" /> :
    <>
      <h4>Enter your PreVeil ID to begin the recovery process. You'll be asked to contact people in your recovery group.</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
        <Form.Group as={Col} controlId="validationConnecting">
          <Form.Label>PreVeil ID (your email address)</Form.Label>
          <div className="input-icon-prepend">
            <Icon className="ficon-user" />
            <Form.Control
              required
              type="email"
              placeholder="Enter your email address"
              name="user_id"
              autoComplete="off"
              autoFocus={true}
              value={_user_id}
              onChange={(e: FocusEvent<HTMLInputElement>) => setUserId(e.currentTarget.value)} />
          </div>
        </Form.Group>
        <div className="btn-panel my-3">
          <Button type="submit" disabled={!_user_id || !isValid(_user_id)}>Continue</Button>
          <Link className="btn btn-outline-primary" to={`/${PublicRoutes.add_account_route}`}>Cancel</Link>
        </div>
        <p className="d-flex">
          <Icon className="pv-icon-question" />
          <span>Can you access your PreVeil account from another device?&nbsp;<Link to={`/${PublicRoutes.copy_account_route}`}
            title="Copy your private key instead" >Copy your private key instead.</Link></span>
        </p>
      </Form>
    </>;
}

export default React.memo(DefaultPanelComponent);
