import * as React from "react";
import { Offcanvas } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, EntryItem, DriveUIActionTypes, DriveMessages, PanelStateType, PanelState, getEntityIcon, CollectionEntity, DriveEntryType,
  Feature, DriveFeature
} from "src/common";
import { Icon, Loading } from "src/components";
import { ItemDetails, RenameItemForm, FileDetails, FolderDetails } from "..";
import { EmptyMessage } from "../..";

type AllProps = {
  current_account: Account;
  action?: string;
  entry?: EntryItem;
  collection_info?: CollectionEntity;
  collection_info_error?: string;
  panel_state: PanelStateType;
  handleAction: ActionHandlerFunction;
  under_downloads: boolean;
};

function DriveSidePanelComponent(props: AllProps) {
  const { current_account, entry, action, panel_state, collection_info, collection_info_error, handleAction, under_downloads } = props;

  // Description: Close the offcanvas and reset current entry - send undefined for entry to reset parent state
  function handleClose() {
    handleAction({ actionType: DriveUIActionTypes.ToggleSidePanel, params: PanelState.off });
  }

  return (
    <Offcanvas
      className="sidebar drive-details"
      backdrop={false}
      show={panel_state === PanelState.on}
      onHide={handleClose}
      placement="end" >
      <Offcanvas.Header closeButton >
        {
          !!entry &&
          <Offcanvas.Title className="rename-div">
            <Icon className={entry.type_class || getEntityIcon(entry.type)} />
            {
              Feature.hidden(DriveFeature.Rename, collection_info?.permissions) ?
                <p>{entry.name}</p> :
                <RenameItemForm
                  entry={entry}
                  action={action}
                  handleAction={handleAction} />
            }
          </Offcanvas.Title>
        }
      </Offcanvas.Header>
      <Offcanvas.Body>
        {
          !!entry ?
            <>
              <ItemDetails
                entry={entry}
                under_downloads={under_downloads}
                permissions={collection_info?.permissions}
                handleAction={handleAction} />
              {
                !!collection_info ?
                  entry.type === DriveEntryType.FILE ?
                    <FileDetails
                      current_account={current_account}
                      entry={entry}
                      action={action}
                      collection_info={collection_info}
                      handleAction={handleAction} /> :
                    <FolderDetails
                      current_account={current_account}
                      entry={entry}
                      action={action}
                      collection_info={collection_info}
                      handleAction={handleAction}
                      under_downloads={under_downloads} /> :
                  !!collection_info_error ?
                    <EmptyMessage message={DriveMessages.problem_with_fetching_collection_info} /> :
                    <Loading className="in-place" />
              }
            </> :
            <EmptyMessage message={DriveMessages.no_entry_selected} />
        }
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(DriveSidePanelComponent);
