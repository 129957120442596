import React, { useState, useEffect, FocusEvent, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Dropdown } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  DriveUIActionTypes, CheckboxStates, buildNavigationLink, EntryItem, getEntityIcon, CollectionEntity, NodePermissionSet, PanelState, DriveEntryType,
  DirectoryEntity, viewerUnsupported, DriveMessages, parseValidDate, Feature, DriveFeature, useAppDispatch, Message, DriveErrorMessages,
  MessageToastTypes, MessageHandlerDisplayType, useAppSelector, isUploadInProgres
} from "src/common";
import { Checkbox, Icon } from "src/components";
import { DriveDropdown } from "./";
import { uiActions } from "src/store";
import { RootState } from "src/store/configureStore";

type AllProps = {
  entry: EntryItem;
  collection_info?: CollectionEntity;
  current_directory: DirectoryEntity;
  is_loading: boolean;
  selected: boolean;
  dragging_items: string[];
  handleAction: ActionHandlerFunction;
  under_downloads: boolean;
}

function ListRowComponent(props: AllProps) {
  const { entry, selected, collection_info, dragging_items, current_directory, is_loading, handleAction, under_downloads } = props;
  const navigate = useNavigate();
  const [_selected, setSelected] = useState<boolean>(selected);
  const [node_permissions, setNodePermissions] = useState<NodePermissionSet | undefined>(
    (!!collection_info && collection_info.id === entry.id) ? collection_info.permissions : undefined);
  const upload_folder_ids = useAppSelector((state: RootState) => state.ui.upload_folder_ids);
  const uploading_in_progress = isUploadInProgres(entry, upload_folder_ids);
  const [class_name, setClassName] = useState<string>("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dragging_items.includes(entry.id) ? setClassName("dragging-item") : setClassName("");
  }, [dragging_items]);

  useEffect(() => {
    (!!collection_info && collection_info.id === entry.id) &&
      setNodePermissions(collection_info.permissions);
    // NOTE: Reset Node Permissions on navigation
    (!collection_info && !!node_permissions) && setNodePermissions(undefined);
  }, [collection_info]);

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  // Description: Handle the click of checkbox and changes of checkbox state 
  function handleCheckboxChange(e: FocusEvent<HTMLInputElement>) {
    const new_selected = !_selected;
    setSelected(new_selected);
    handleAction({ actionType: DriveUIActionTypes.Select, params: { selected: e.target.checked, entry } });
  }

  // Description: Build the url and navigate 
  function handleRowClick() {
    if (is_loading) {
      return false;
    }
    if (entry.type === DriveEntryType.FILE && !!viewerUnsupported(entry)) {
      dispatch(uiActions.handleSetMessage(new Message(DriveErrorMessages.file_not_supported, MessageHandlerDisplayType.toastr, MessageToastTypes.warning)));
      return false;
    }
    const parent_id = entry.type === DriveEntryType.FILE && !!current_directory ? current_directory.id : undefined;
    const url = !!parent_id ? buildNavigationLink(entry.collection_id, parent_id, entry.type, entry.id) :
      buildNavigationLink(entry.collection_id, entry.id, entry.type);
    handleAction({ actionType: DriveUIActionTypes.ToggleSidePanel, params: PanelState.off });
    navigate(url);
    entry.type === DriveEntryType.FILE && handleAction({ actionType: DriveUIActionTypes.DownloadAndView, params: { entry, node_permissions } });
    return false;
  }

  function unsupportedTooltip(): string {
    return entry.type === DriveEntryType.FILE && !!viewerUnsupported(entry)
      ? DriveMessages.not_supported
      : "";
  }

  function uploadingTooltip(): string {
    return uploading_in_progress ? DriveMessages.uploading_in_progress : "";
  }

  // Description: Build the icon for the row depending on lock or uploading state
  function ListRowIcon() {
    return uploading_in_progress ?
      <span data-tooltip-id="pv-tooltip" data-tooltip-content={uploadingTooltip()}>
        <Icon className="ficon-loader" />
      </span> :
      !!entry.is_locked ?
        <Icon className="ficon-lock" /> :
        <Checkbox className="sr-only"
          onChange={(e: FocusEvent<HTMLInputElement>) => { handleCheckboxChange(e); }}
          selected={_selected ? CheckboxStates.checked : CheckboxStates.empty}
          value="all" label="Select All" disabled={upload_folder_ids.includes(entry.id)} />;
  }

  return <Row onClick={handleRowClick}
    className={`${selected ? "active " : ""}${class_name}${!!entry.is_locked || uploading_in_progress ? " locked" : ""}`}
    draggable={!Feature.hidden(DriveFeature.Move, node_permissions) && !uploading_in_progress}
    onDragStart={(e) => { !uploading_in_progress && handleAction({ actionType: DriveUIActionTypes.OnDrag, params: { e, entry } }); }}
    onDragOver={(e) => handleAction({ actionType: DriveUIActionTypes.DragOver, params: { e } })}
    onDrop={(e) => handleAction({ actionType: DriveUIActionTypes.OnDrop, params: { e } })}
    onDragLeave={(e) => handleAction({ actionType: DriveUIActionTypes.OnDragLeave, params: { e } })}
    onDragEnd={() => handleAction({ actionType: DriveUIActionTypes.OnDragEnd })}
    id={`entity-item~${!!entry.maintainer_id && entry.maintainer_id === entry.id
      ? DriveEntryType.ACL_NODE
      : entry.type
      }~${entry.id}`}>
    <Col md="1">
      {ListRowIcon()}
    </Col>
    <Col md="5" className="entry-name col">
      <span data-tooltip-id="pv-tooltip" data-tooltip-content={unsupportedTooltip()}>
        <Icon className={entry.type_class || getEntityIcon(entry.type)} />{entry.name}
      </span>
    </Col>
    <Col md="2" className="order-3">
      {entry.type_label}
    </Col>
    <Col md="3" className="order-4">
      {parseValidDate(entry.lastModificationDate)}
    </Col>
    <Col md="1" className="order-md-5 order-2">
      <Dropdown as="button"
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          document.body.click();
          handleAction({ actionType: DriveUIActionTypes.Details, params: { entry, action: DriveUIActionTypes.ToggleSidePanel } });
        }}
        className="btn btn-icon btn-sm" title="More actions..." disabled={is_loading}>
        <Dropdown.Toggle as="span">
          <Icon className="ficon-more-vertical" />
        </Dropdown.Toggle>
        <DriveDropdown
          entry={entry}
          permissions={node_permissions}
          handleAction={handleAction}
          under_downloads={under_downloads}
          all_actions />
      </Dropdown>
    </Col>
  </Row>;
}

export default React.memo(ListRowComponent);