import React, { useState, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import { LoginWebUIActionTypes, GlobalConstants, dayjs } from "src/common";
import { CountdownDisplay, VerificationCode } from "src/components";

type AllProps = {
  handleAction: ActionHandlerFunction;
  forgot_password?: boolean;
}

function LoginSMSCodeVerificationFormComponent(props: AllProps) {
  const expires = dayjs().add(GlobalConstants.LOGIN_VERIFICATION_CODE_VALID, "minutes").format("YYYY-MM-DD HH:mm:ss");
  const { handleAction, forgot_password } = props;
  const [expired, setExpired] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  let secret = "";
  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    const params = secret;
    handleAction({ actionType: LoginWebUIActionTypes.SubmitSMSVerificationCode, params });
  }

  // Description: Validate and set secret code on finished
  function handleSetSecretCode(code: string) {
    secret = code;
    const button = buttonRef.current;
    const isValid = code.length === GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX;
    if (!!button) {
      button.disabled = !isValid;
      isValid && setTimeout(() => button.focus());
    }
  }

  // Description: Expiration Message Panel
  function VerificationCodeExpired() {
    return <>
      <p>
        The verification code that was sent to your phone has expired. Please click <b>Back</b> to restart the process.
      </p>
      <div className="btn-panel">
        <Button variant="outline-secondary" type="button"
          onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Back</Button>
      </div>
    </>;
  }

  // Description: Render the enter code form
  function VerificationForm() {
    return <>
      <p><b>Check your phone!</b> We sent you an SMS message with a verification code.</p>
      <p>This code can only be used once and expires in <b><CountdownDisplay expires={expires} handleTimeExpired={() => setExpired(true)} /></b>.</p>
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group className="mb-3">
          <Form.Label>Verification Code</Form.Label>
          <VerificationCode count={GlobalConstants.CREATE_ACCOUNT_VERIFICATION_CODE_MAX} handleAction={handleSetSecretCode} />
          <Form.Text>Did not get an SMS message with your code? <Button type="button" variant="link" size="sm" className="fs-6 px-1"
            onClick={() => handleAction({ actionType: LoginWebUIActionTypes.SubmitPassword })}>Resend code</Button>
          </Form.Text>
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" ref={buttonRef} disabled={true}>{forgot_password ? "Continue" : "Sign in"}</Button>
          <Button variant="outline-secondary" type="button"
            onClick={() => handleAction({ actionType: LoginWebUIActionTypes.ResetLogin })}>Cancel</Button>
        </div>
      </Form>
    </>;
  }

  return expired ? <VerificationCodeExpired /> : <VerificationForm />;
}

export default React.memo(LoginSMSCodeVerificationFormComponent);
