import { useState, useCallback } from "react";
import { MessageDisplayType, ErrorStackDataType } from "@preveil-api";
import { Node, FSMessage, FSStatus } from "src/common/keys/protos/collections_pb";
import {
  Account, useAppDispatch, PermissionSetType, GrantSet, Message, MessageHandlerDisplayType, GrantSetType, DriveErrorMessages, useSendRequestMutation,
  CollectionFilesyncAPI, DriveRequest, getEnumKey, ErrorStackItem
} from "src/common";
import { driveActions, uiActions, DriveCallbackAsyncFunction } from "src/store";
import _ from "lodash";

export function useGetGrants(current_account: Account, include_deleted: boolean = false) {
  const [grants, setGrants] = useState<GrantSetType>(); // setGrants
  const [error, setError] = useState<boolean>(false);
  const [maintainer_node, setMaintainerNode] = useState<FSMessage.Dir>();
  const [sendRequest] = useSendRequestMutation();
  const dispatch = useAppDispatch();

  // Description: Get ACL List from a maintainer directory using FETCH_DIR with Limit of 1 
  // NOTE: there is no direct endpoint for getAclList
  async function getAclList(id: string, collection_id: string, default_permissions: PermissionSetType[], default_grants: GrantSetType[]) {
    const permissions = _.find(default_permissions, (set: PermissionSetType) => collection_id === set.collection_id.B64());
    const _request = await CollectionFilesyncAPI.getDirectoryInformationRequest(collection_id, id, include_deleted);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(_request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const maintainer_dir = message.getDir();
        !!maintainer_dir && setMaintainerNode(maintainer_dir);
        const acl_list = maintainer_dir?.getAclList();
        if (!!acl_list && !!permissions && !!default_grants) {
          // const grant_sets = acl_list?.length > 0 ?
          const current_grants = GrantSet.getCurrentUsersGrantSets(collection_id, id, acl_list, current_account.user_id);
          const grant_set = !!current_grants ? GrantSet.init(current_grants) : null;
          const grant = !!grant_set ? grant_set.grant(Node.ACLRole.READER) : null;
          if (!!grant) {
            (grant.role_key_version > 0 && !!grant_set) ? getAclKeyHistory(id, collection_id, default_grants, grant_set, permissions) :
              handleFetchGrantsSuccess(default_grants, current_grants);
          } else {
            handlePageErrorMessage(DriveErrorMessages.default, {
              stack_message: DriveErrorMessages.error_fetching_acl_history,
              stack_error: "grants are undefined or null"
            });
          }
        } else {
          handlePageErrorMessage(DriveErrorMessages.default, {
            stack_message: DriveErrorMessages.error_fetching_acl_history,
            stack_error: "acl_list, permissions or default_grants are undefined or null"
          });
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_history }, message);
      }
    };

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_history);
  }

  // Description: Get ACL Key History if needed
  async function getAclKeyHistory(id: string, collection_id: string, default_grants: GrantSetType[], grant_set: GrantSet, permissions: PermissionSetType) {
    const role = Node.ACLRole.READER;
    const _request = await CollectionFilesyncAPI.getAclKeyHistoryRequest(collection_id, id, role);
    const drive_request = !!permissions ? await DriveRequest.initializeRequest(_request, current_account, permissions) : null;
    async function callback(message: FSMessage) {
      if (message.getStatus() === FSStatus.OK) {
        const key_history = message.getKeyHistoryList().sort((a: FSMessage.KeyHistoryEntry, b: FSMessage.KeyHistoryEntry) =>
          ((a.getVersion() || 0) - (b.getVersion() || 0)));
        try {
          const current_grants = await GrantSet.unwrapAclKeyHistory(collection_id, id, key_history, role, grant_set, current_account);
          handleFetchGrantsSuccess(default_grants, current_grants);
        } catch (stack_error: unknown) {  // NOTE: Catches errors in unwrapping and decrypting keys
          handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_history, stack_error }, message);
        }
      } else {
        handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_history }, message);
      }
    };

    handleSendRequest(drive_request, callback, DriveErrorMessages.error_fetching_acl_history);
  }

  // Description: On success
  function handleFetchGrantsSuccess(default_grants: GrantSetType[], current_grants?: GrantSetType) {
    if (!!current_grants) {
      setGrants(current_grants);
      dispatch(driveActions.setDefaultGrants(GrantSet.updateDefaultGrantSets(current_grants, default_grants)));
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_message: DriveErrorMessages.error_fetching_acl_history });
    }
  }

  // Description: handles error when the request object is null
  function handleSendRequest<T>(request: DriveRequest | null, callback: DriveCallbackAsyncFunction<T>, stack_error: string = DriveErrorMessages.error_sending_request) {
    if (!!request) {
      request.setCallback(callback);
      sendRequest(request);
    } else {
      handlePageErrorMessage(DriveErrorMessages.default, { stack_error, stack_message: DriveErrorMessages.error_sending_request });
    }
  }


  // Description: Handle error message and send error to store
  function handlePageErrorMessage(message: string, stack_data?: ErrorStackDataType, fsmessage?: FSMessage | null,
    display_type: MessageDisplayType = MessageHandlerDisplayType.toastr) {
    const status = !!fsmessage?.getStatus() ? getEnumKey(FSStatus, Number(fsmessage.getStatus())) : "empty";
    const stack = new ErrorStackItem("[useGetGrants Hook]", fsmessage?.getErrorMessage() || message, status || "error", stack_data).info;
    dispatch(uiActions.handleRequestErrors(new Message(message, display_type), stack));
    setError(true);
  }

  // Description: Call get grants from acl list and acl key history
  const getGrants = useCallback((id: string, collection_id: string, default_permissions: PermissionSetType[], default_grants: GrantSetType[]) => {
    getAclList(id, collection_id, default_permissions, default_grants);
  }, []);

  // Description: Grab Grants from default_grants object directly (by passing fetch)
  // Useful for when grants were just grabbed on another hook for the same id and collection_id
  const getStoredGrants = useCallback((id: string, collection_id: string, default_grants: GrantSetType[]) => {
    const current_grants = GrantSet.getActive(default_grants, collection_id, id, current_account.user_id);
    setGrants(current_grants);
  }, []);

  // Description: Reset Grant state
  const destroyGrants = useCallback(() => {
    setGrants(undefined);
    setError(false);
    setMaintainerNode(undefined);
  }, []);

  return {
    grants,
    error,
    getGrants,
    getStoredGrants,
    destroyGrants,
    maintainer_node
  };
}
