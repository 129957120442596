import React, { useState, useEffect } from "react";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, AppConfiguration, useAppSelector, DriveUIActionTypes, EntryItem, CollectionEntity, Feature, DriveFeature, NodePermissionType, COLLECTION_PROTOCOL_VERSIONS,
  DriveEntryType, PreveilDownloads, isUploadInProgres, SELECTIVE_SYNC_STATES
} from "src/common";
import { RootState } from "src/store/configureStore";
import { CollapseWrapper, SyncDetails, ShareDetails } from "..";
import { PeopleWithAccess } from "../entry-actions/directories/share";
import { UnsyncedParentAlert, UploadingAlert, V1ChildAlert } from "./side-panel-alerts";

type AllProps = {
  current_account: Account;
  entry: EntryItem;
  action?: string;
  collection_info: CollectionEntity;
  handleAction: ActionHandlerFunction;
  under_downloads: boolean;
}

function FolderDetailsComponent(props: AllProps) {
  const { current_account, entry, action, collection_info, handleAction, under_downloads } = props;
  const upload_folder_ids = useAppSelector((state: RootState) => state.ui.upload_folder_ids);
  const uploading_in_progress = isUploadInProgres(entry, upload_folder_ids);
  const [share_disabled, setSharedDisabled] = useState<boolean>(isShareDisabled());

  useEffect(() => {
    !!collection_info &&
      setSharedDisabled(isShareDisabled());
  }, [collection_info]);

  // Description: get the shared enabling status from regular permissions, directory name (exclude PreveilDownloads) and uploading_in_progress
  function isShareDisabled(): boolean {
    return Feature.hidden(DriveFeature.Share, collection_info.permissions, under_downloads ? PreveilDownloads : entry.name) || uploading_in_progress;
  }

  // Description: get the sync enabling status from regular permissions, uploading_in_progress and
  //             synced status exclude for SELECTIVE_SYNC_STATES.InheritedOFF
  function isSyncDisabled(): boolean {
    return Feature.hidden(DriveFeature.Sync, collection_info.permissions) || uploading_in_progress;
  }

  // Description: Filter conditions to show the V1 collection Directory Alert
  function showV1DirectoryAlert(): boolean {
    return collection_info.collection_protocol_version === COLLECTION_PROTOCOL_VERSIONS.V1 && entry.type === DriveEntryType.DIR &&
      entry.name !== PreveilDownloads && !under_downloads && collection_info.permissions.type === NodePermissionType.edit_and_share;
  }

  return <>
    {/* Sync Component */}
    {
      AppConfiguration.driveSyncV1Enabled() &&
      <>
        {
          entry.localSyncStatus !== SELECTIVE_SYNC_STATES.InheritedOFF ?
            <CollapseWrapper
              hidden={isSyncDisabled()}
              handleAction={handleAction}
              title="Sync to this device"
              open={action === DriveUIActionTypes.Sync || share_disabled}>
              <SyncDetails
                current_account={current_account}
                entry={entry}
                handleAction={handleAction} />
            </CollapseWrapper> : <UnsyncedParentAlert />
        }
      </>
    }
    {/* Share Component - default opened */}
    {
      <CollapseWrapper
        handleAction={handleAction}
        hidden={share_disabled}
        title="Shared Details"
        open={action === DriveUIActionTypes.Share || action === DriveUIActionTypes.Details}>
        <ShareDetails
          current_account={current_account}
          entry={entry}
          collection_info={collection_info}
          action={action}
          handleAction={handleAction} />
      </CollapseWrapper>
    }
    {
      share_disabled &&
      <>
        {
          // NOTE: This section is for diplaying people with access Read Only mode (edit permissions for recipients)
          (collection_info.permissions.type === NodePermissionType.edit && collection_info.shared_with.length > 0) &&
          <CollapseWrapper
            handleAction={handleAction}
            title={`People with Access (${collection_info.shared_with.length})`}
            className="pb-0"
            open={action === DriveUIActionTypes.Share || action === DriveUIActionTypes.Details}>
            <PeopleWithAccess shared_with={collection_info.shared_with} />
          </CollapseWrapper>
        }
        {
          showV1DirectoryAlert() && <V1ChildAlert />
        }
      </>
    }
    {
      uploading_in_progress && <UploadingAlert />
    }
  </>;
}

export default FolderDetailsComponent;
