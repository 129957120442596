import React, { useState } from "react";
import { Account, AccountUIActionTypes, AccounWStSteps } from "src/common";
import { Icon, DataExportAwaitConnection, Loading } from "src/components";
import { UserProfile, ActionHandlerFunction, IActionHandler, CollectionServerUser } from "@preveil-api";
import { DataExportApproversRow, DataExportActiveConnection, DataExportErrorConnection, DataExportApproversPanel } from ".";

type User = UserProfile & CollectionServerUser;

type AllProps = {
  requester_name: string | null;
  request_timeframe?: string;
  request_expiration: string | number | undefined;
  requested_content_label?: string;
  pin: string | undefined;
  wsStep: number;
  current_account: Account;
  approvers: User[];
  handleActions: ActionHandlerFunction;
  currentUsersApproved: UserProfile[];
  requiredApprovers: number | undefined;
  requested_users: CollectionServerUser[] | undefined;
}

function DataExportApproveStep({ requester_name, request_timeframe, request_expiration, requested_content_label, requested_users, pin, wsStep, current_account, approvers, handleActions, currentUsersApproved, requiredApprovers }: AllProps) {
  const [userToRequestApprove, setUserToRequestApprove] = useState<UserProfile | null>(null);
  const requester_found = currentUsersApproved.length === 0 && approvers.filter((user) => (user.address === current_account.user_id));
  const current_users_approved = Array.isArray(requester_found) && requester_found.length > 0 ? [...currentUsersApproved, ...requester_found] : [...currentUsersApproved];
  const DataExportApproveRequest = {
    handleStart: (params: { subsumeUser: string }) => {
      const { subsumeUser: userId } = params;
      const approver = approvers.find((user) => (user.address === userId));
      !!approver && setUserToRequestApprove(approver);
      handleActions({ actionType: AccountUIActionTypes.InitWebsocketConnection, params: { subsumeUser: userId } });
    },
    handleReset: () => {
      handleActions({ actionType: AccountUIActionTypes.Destroy });
    }
  };

  function handlePageActions(actionObj: IActionHandler) {
    const callback = `handle${actionObj.actionType}`;
    if ((DataExportApproveRequest as any)[callback] instanceof Function) {
      (DataExportApproveRequest as any)[callback](actionObj.params);
    }
  }

  const uiPanels = () => {
    switch (wsStep) {
      case AccounWStSteps.DISCONNECTED:
      case AccounWStSteps.GET_KEY_TRANSFER_COMPLETE:
        return <DataExportApproversPanel approvers={approvers} current_users_approved={current_users_approved} handleActions={handlePageActions} required_approvers={requiredApprovers} />;
      case AccounWStSteps.INITIALIZE_CONNECTION:
      case AccounWStSteps.GET_ACCEPTED_INIT_OK:
        return <DataExportAwaitConnection approver={userToRequestApprove} handleActions={handlePageActions} />;
      case AccounWStSteps.GET_CONNECTION_ESTABLISHED:
        return !!pin ? <DataExportActiveConnection pin={pin} /> : <Loading />;
      case AccounWStSteps.ERROR:
        return <DataExportErrorConnection handleActions={handlePageActions} approver={userToRequestApprove} />;
      default:
        return null;
    }
  };

  return (
    <main className="data-export-main bg-white px-4">
      <section className="export-left-section">
        {uiPanels()}
      </section>
      <section className="export-right-section pt-1">
        <div className="data-export-details-container">
          <header>
            <Icon className="ficon-download" />
            <div className="header-details-label">
              <h4>Export Details</h4>
              <p>Download</p>
            </div>
          </header>
          <div className="export-details-body">
            <div className="single-detail">
              <h5>Requester</h5>
              <hr />
              <p>{requester_name}</p>
            </div>
            <div className="single-detail">
              <h5>Timeframe</h5>
              <hr />
              <p>{request_timeframe}</p>
            </div>
            <div className="single-detail">
              <h5>Content</h5>
              <hr />
              <p>{requested_content_label}</p>
            </div>
            <div className="single-detail">
              <h5>Expires</h5>
              <hr />
              <p>{request_expiration}</p>
            </div>
            <div className="single-detail">
              <div className="recovery-groups-card mb-0">
                <header>
                  <p className="m-0">{`Users: ${requested_users?.length}`}</p>
                </header>
                <div className="data-request-users-container">
                  {
                    requested_users && requested_users.map((user) => {
                      return <DataExportApproversRow key={user.user_id} is_read_only user={user} />;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default React.memo(DataExportApproveStep);