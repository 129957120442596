import * as React from "react";
import { Alert } from "react-bootstrap";
import { Icon } from "src/components";

function UnsyncedParentAlertComponent() {
  return <Alert
    variant="warning"
    className="details-alert">
    <Alert.Heading>
      <Icon className="ficon-alert-circle" />
      Syncing is disabled
    </Alert.Heading>
    <p>This folder cannot be synced until the folder that contains it is synced.</p>
    <p>Sync the containing folder(s) to enable syncing.</p>
  </Alert>;
}

export default UnsyncedParentAlertComponent;