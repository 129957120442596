import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { ActionHandlerFunction } from "@preveil-api";
import { EntryItem, NodePermissionSet, DriveEntryType } from "src/common";
import { LinkMenu, DirMenu, FileMenu } from ".";

export type DropdownProps = {
  entry: EntryItem;
  all_actions?: boolean;
  permissions?: NodePermissionSet;
  handleAction: ActionHandlerFunction;
  under_downloads: boolean;
};

function DriveDropdownComponent(props: DropdownProps) {
  const { entry } = props;
  return <Dropdown.Menu
    className="mt-1"
    align="end">
    {
      entry.type === DriveEntryType.LINK &&
      <LinkMenu  {...props} />
    }
    {
      entry.type === DriveEntryType.DIR &&
      <DirMenu  {...props} />
    }
    {
      entry.type === DriveEntryType.FILE &&
      <FileMenu  {...props} />
    }
    {/* Need its own Tooltip for closing issues */}
    <Tooltip
      id="dd-tooltip"
      disableStyleInjection="core"
      closeEvents={{
        mouseleave: true,
        blur: true,
        click: true,
        dblclick: true,
        mouseup: true,
      }} />
  </Dropdown.Menu>;
}
export default React.memo(DriveDropdownComponent);