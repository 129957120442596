import React, { FocusEvent } from "react";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import { Checkbox } from "src/components";
import { CheckboxStates, AdminUIActionTypes } from "src/common";
import { NotMatchesFoundView } from "src/components/shared/address-list";
import { ActionHandlerFunction, CollectionServerUser } from "@preveil-api";
import { DataExportUserListRow } from ".";

type AllProps = {
  filteredUsersList: CollectionServerUser[];
  selectAllCheckboxState: "empty" | "indeterminate" | "checked";
  selectedUsersList: CollectionServerUser[];
  handleActions: ActionHandlerFunction;
}

type DataExportGroupUsers = {
  filteredUsersList: CollectionServerUser[];
  selectedUsersList: CollectionServerUser[];
  handleActions: ActionHandlerFunction;
}

function RenderDataExportGroupUsers({ filteredUsersList, selectedUsersList, handleActions }: DataExportGroupUsers) { 
  if (_.isEmpty(filteredUsersList)) {
    return <NotMatchesFoundView />;
  }

  return (
    <div className="export-users-container">
      {filteredUsersList.map((user: CollectionServerUser) => {
        const key = _.uniqueId(user.user_id);
        const isUserSelected = selectedUsersList.filter((selectedUser: CollectionServerUser) => selectedUser.user_id === user.user_id).length > 0 ? CheckboxStates.checked : CheckboxStates.empty;
        return (
          <DataExportUserListRow
            key={key}
            user={user}
            handleActions={handleActions}
            isChecked={isUserSelected}
          />
        );
      })}
    </div>
  );
}

function DataExportGroupUsersPanel({ filteredUsersList, selectAllCheckboxState, selectedUsersList, handleActions }: AllProps) {
  return (
    <>
      <div className="cover-content list-group content-dynamic-width">
        <>
          <Row className="export-users-header m-0 flex-nowrap">
            <div className="user-row-checkbox">
              <Checkbox
                className="sr-only"
                onChange={(e: FocusEvent<HTMLInputElement>) => handleActions({
                  actionType: AdminUIActionTypes.MultiSelect,
                  params: { e },
                })}
                value="all"
                selected={selectAllCheckboxState}
                label="Select All"
              />
            </div>
            <div className="data-export-header-label">
              <Col sm={4}>
                <h5>Name</h5>
              </Col>
              <Col sm={4}>
                <h5>Email</h5>
              </Col>
              <Col sm={4}>
                <h5>Department</h5>
              </Col>
            </div>
          </Row>
          <RenderDataExportGroupUsers filteredUsersList={filteredUsersList} selectedUsersList={selectedUsersList} handleActions={handleActions} />
        </>
      </div>
    </>
  );
}

export default React.memo(DataExportGroupUsersPanel);