import React, { useState } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { ActionHandlerFunction, CreateUserData } from "@preveil-api";
import { CreateAccountUIActionTypes } from "src/common";

type AllProps = {
  create_user_data?: CreateUserData;
  org_name?: string;
  handleAction: ActionHandlerFunction;
}

// Description: Checks if First Name and Last Name values are empty or only contain white spaces.
const isEmpty = (value: string) => {
  return !value || !value.trim();
};

// Description: Handle Login submit send to parent component
function UserInformationFormComponent(props: AllProps) {
  const { create_user_data, org_name, handleAction } = props;
  const [first_name, setFirstName] = useState<string>("");
  const [last_name, setLastName] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false);
  const isFormValid = isChecked && !isEmpty(first_name) && !isEmpty(last_name);

  // Description: Handle form submit
  function handleSubmit(e: React.FocusEvent<HTMLFormElement>) {
    e.stopPropagation();
    e.preventDefault();
    const current_first = e.currentTarget?.first_name?.value || first_name;
    const current_last = e.currentTarget?.last_name?.value || last_name;
    handleAction({
      actionType: CreateAccountUIActionTypes.SubmitUserInformation,
      params: `${current_first} ${current_last}`
    });
  }

  return <>
    <Card.Body>
      <h2 className="border-bottom">
        {
          (!!org_name && !!create_user_data?.display_name) ?
            `${create_user_data.display_name}, you are almost done!` : "Choose your name"
        }
      </h2>
      <p dangerouslySetInnerHTML={{
        __html:
          !!org_name ?
            `Please review our license before <b>joining ${create_user_data?.org_name} organization</b> and start collaborating with your team.` :
            "At PreVeil we are very careful about user identity and spoofing. Therefore, you will not be able to change your name later."
      }} />
      <Form onSubmit={handleSubmit} >
        <Row className="mb-3">
          <Form.Group as={Col} controlId="first_name_cntrl">
            <Form.Label className="fs-6">First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              onChange={(e: React.FocusEvent<HTMLInputElement>) => setFirstName(e.currentTarget.value)}
              value={first_name}
              autoFocus={true}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="last_name_cntrl">
            <Form.Label className="fs-6">Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              onChange={(e: React.FocusEvent<HTMLInputElement>) => setLastName(e.currentTarget.value)}
              value={last_name}
            />
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
          <input type="checkbox" className="form-check-input me-2" id="accept_terms"
            defaultChecked={isChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.checked)} />
          <Form.Label className="fs-5" htmlFor="accept_terms">I accept the&nbsp;
            <a target="_blank" href="https://www.preveil.com/terms-of-use/" rel="noreferrer">PreVeil Terms of Service</a>
            &nbsp;and&nbsp;
            <a target="_blank" href="https://www.preveil.com/privacy-policy/" rel="noreferrer">Privacy Policy</a></Form.Label>
        </Form.Group>
        <div className="btn-panel">
          <Button type="submit" disabled={!isFormValid}>Create Account</Button>
          <Button variant="outline-secondary" type="button"
            onClick={() => handleAction({ actionType: CreateAccountUIActionTypes.ResetForms })}>Cancel</Button>
        </div>
      </Form>
    </Card.Body>
  </>;
}
export default React.memo(UserInformationFormComponent);
