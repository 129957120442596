import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ActionHandlerFunction, IActionHandler, MailboxItem, TreeItem } from "@preveil-api";
import { useAppSelector, MailUIActionTypes, buildCustomMailboxTree, DefaultMailboxes, MailErrorMessages } from "src/common";
import { Tree } from "src/components";
import _ from "lodash";

type AllProps = {
  current_mailbox: MailboxItem;
  count: number;
  handleAction: ActionHandlerFunction;
}

function MoveModalComponent(props: AllProps) {
  const { count, current_mailbox, handleAction } = props;
  const custom_mailboxes = useAppSelector((state) => state.mail.custom_mailboxes);
  const default_mailboxes = useAppSelector((state) => state.mail.default_mailboxes);
  const [destination, setDestination] = useState<MailboxItem>();
  const [active, setActive] = useState<string | undefined>();
  const [tree, setTree] = useState<TreeItem[]>();

  // Description: Build tree structure
  useEffect(() => {
    if (!!custom_mailboxes) {
      const _tree = buildCustomMailboxTree(custom_mailboxes, [current_mailbox.mailbox_id]);
      !!_tree && setTree(_tree);
    };
  }, [custom_mailboxes]);

  // Description: Handle states of modal. If is a close, dismiss the message too
  function handleHideModal() {
    handleAction({ actionType: MailUIActionTypes.MoveModal, params: false });
  };

  // Description: Set Destination tree
  function formatDestinationTreeItem(actionObj: IActionHandler) {
    const item = actionObj.params as TreeItem;
    if (!!item.type && !item.disabled) {
      !!item.id ? setActive(item.id) : setActive(undefined);
      const mailbox_item = _.find(custom_mailboxes, (mailbox: MailboxItem) => (item.id === mailbox.mailbox_id));
      !!mailbox_item ? setDestination(mailbox_item) : handleAction({
        actionType: MailUIActionTypes.PageErrorMessage, params: {
          message: MailErrorMessages.error_finding_destination_mailbox,
          stack: destination,
          stack_message: "Tree Item in move modal is not an active Custom Mailbox"
        }
      });
    }
  }

  // Description: Set the default mailboxes for selection
  function formatDefaultMailboxes(role: string) {
    const mailbox_item = _.find(default_mailboxes, (mailbox: MailboxItem) => (role === mailbox.role));
    const cleaned = role === DefaultMailboxes.deleted ? { name: "Trash" } : { name: "Inbox" };
    setActive(mailbox_item?.mailbox_id);
    !!mailbox_item ? setDestination({ ...mailbox_item, ...cleaned }) :
      handleAction({
        actionType: MailUIActionTypes.PageErrorMessage, params: {
          message: MailErrorMessages.error_finding_destination_mailbox,
          stack: destination,
          stack_message: "Tree Item in move modal is not an active Custom Mailbox"
        }
      });
  }

  // Description: Send selected to move 
  function sendMoveAction() {
    if (!!destination) {
      handleAction({ actionType: MailUIActionTypes.Move, params: destination });
      handleHideModal();
    }
  }

  // Description: Add the 2 default mailboxes available for move: Inbox and Trash
  function DefaultMailbox() {
    return <div className="tree mail-tree">
      {
        (!!default_mailboxes && current_mailbox?.role !== DefaultMailboxes.inbox) &&
        <div className="folder-item"
          onClick={() => { formatDefaultMailboxes(DefaultMailboxes.inbox); }}>
          <div className={`folder-label ${destination?.name === "Inbox" ? " active" : ""}`}>
            <span className="folder-icon no-children">
              <i className="ficon-mail"></i>
            </span>
            <span className="folder-name">Inbox</span>
          </div>
        </div>
      }
      {
        (!!default_mailboxes && current_mailbox?.role !== DefaultMailboxes.deleted) &&
        <div className="folder-item"
          onClick={() => { formatDefaultMailboxes(DefaultMailboxes.deleted); }}>
          <div className={`folder-label ${destination?.name === "Trash" ? " active" : ""}`}>
            <span className="folder-icon no-children">
              <i className="ficon-trash-2"></i>
            </span>
            <span className="folder-name">Trash</span>
          </div>
        </div>
      }
    </div>;
  }
  function prettyBreadcrumbs(destination_name: string) {
    return destination_name.replaceAll("/", "<i class='ficon-chevron-right'></i>");
  }

  return <Modal
    size="lg"
    dialogClassName="move-modal"
    show={true}
    onHide={() => handleHideModal()}
    aria-labelledby="Move Modal">
    <Modal.Header className="align-items-start" closeButton>
      <p>
        Move <b>{`${count} Selected Messages${count > 1 ? "s" : ""}`}</b> {
          !!destination?.name && <>to: <span className="text-link" dangerouslySetInnerHTML={{ __html: prettyBreadcrumbs(destination.name) }} /></>
        }
      </p>
    </Modal.Header>
    <Modal.Body>
      <div className="move-folders">
        <DefaultMailbox />
        {
          !!tree && <Tree
            readonly
            active={active}
            className="mail-tree"
            data={tree}
            handleAction={formatDestinationTreeItem} />
        }
      </div>
    </Modal.Body>
    <Modal.Footer className="justify-content-center">
      <Button onClick={sendMoveAction} disabled={!destination}>Move</Button>
      <Button variant="no-outline-primary" onClick={handleHideModal}>Cancel</Button>
    </Modal.Footer>
  </Modal>;
}

export default React.memo(MoveModalComponent);
