import { CountryCode, getCountries, getExampleNumber, AsYouType, isValidPhoneNumber } from "libphonenumber-js";
import { Countries, DefaultCountry, DefaultCountryTemplate, SupportedCountryCodes, ValidCountryNumbers } from "src/common";
import _ from "lodash";

// // two letter country code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2:
type MissingCountryAbbCodes = "PN" | "AN" | "AQ";
export type CountryAbbCode = CountryCode | MissingCountryAbbCodes;

export interface Country {
  name: string;
  country_abb: CountryAbbCode;
  three_letter_code: string;
  country_number_code: string;
  image?: string;
}

export class LocationService {
  _defaultCode: CountryAbbCode = DefaultCountry.country_abb;
  _country_number_code: string = DefaultCountry.country_number_code;
  _phoneTemplate: string = DefaultCountryTemplate;
  _selectedCountry: Country = DefaultCountry;
  CountryCodes: CountryAbbCode[] = SupportedCountryCodes;

  constructor() {
    this.getAllCountryAbbreviations();
    this.resetDefault();
  }

  // Description: Set to a different selected country
  public setSelectedCountryByAbb(country_code: string): string {
    const country: Country = this._findCountryCode(country_code as CountryAbbCode);
    if (!!country) {
      this._country_number_code = country.country_number_code;
      this._selectedCountry = country;
      this._phoneTemplate = this._getPlaceholder();
    } else {
      this.resetDefault();
    }
    return this._country_number_code;
  }

  public get country_number_code(): string {
    return this._country_number_code;
  }

  // Descriptions: Gets the country Two letter code or abbreviation
  public get selectedCountryAbb(): CountryAbbCode {
    return this._selectedCountry.country_abb;
  }

  // Descriptions: Gets input placeholder template by country - example: (XXX) XXX-XXXX for US
  public get phonePlaceholder(): string {
    return this._phoneTemplate;
  }

  // Descriptions: get an array of ALL country codes example ["US", "CA", ...]
  public getAllCountryAbbreviations(): CountryAbbCode[] {
    return getCountries();
  }
  // Description: Format phone number in a E164 Format
  // Param: pass probably incomplete phone_number 
  public phoneNumberE164format(phone_input: string): string {
    return `+${this._selectedCountry.country_number_code}${phone_input.trim().replace(/\D/g, "")}`;
  }

  // Description: Validate number 
  // Param: pass probably incomplete phone_number 
  public isPhoneNumberValid(phone_input: string): boolean {
    const phoneNumberE164format: string = this.phoneNumberE164format(phone_input);
    return isValidPhoneNumber(phoneNumberE164format, this._selectedCountry.country_abb as CountryCode);
  }

  // Description: Create phone mask
  public phoneMaskChange(value: string, backspace: boolean = false): string {
    const code = this.selectedCountryAbb as CountryCode;
    const newVal = value.replace(/\D/g, "");
    const asYouType: AsYouType = new AsYouType(code);
    return asYouType ? asYouType.input(newVal) : newVal;
  }

  // Description: Reset selected country to default
  private resetDefault() {
    const country: Country = this._findCountryCode(this._defaultCode);
    if (!!country) {
      this._country_number_code = country.country_number_code;
      this._selectedCountry = country;
      this._phoneTemplate = this._getPlaceholder();
    }
  }

  // Description: Get the placeholder template for the input
  private _getPlaceholder(): string {
    const cc = this.selectedCountryAbb as CountryCode;
    const example = getExampleNumber(cc, ValidCountryNumbers);
    const asYouType: AsYouType = new AsYouType(cc);
    let template = "";
    if (example) {
      asYouType.input(example.nationalNumber);
      template = asYouType.getTemplate();
    }
    return template;
  }

  // Description: get country object by country_abb (2 letter abbreviation)
  private _findCountryCode(country_code: CountryAbbCode): Country {
    let country = _.find(Countries, (country: Country) => country.country_abb === country_code);
    if (!country) {
      country = _.find(Countries, (country: Country) => country.country_abb === this._defaultCode);
    }
    return _.find(Countries, (country: Country) => country.country_abb === country_code) || DefaultCountry;
  }
}
