import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { IActionHandler } from "@preveil-api";
import {
  GlobalErrorMessages, QueryParamKeys, useAppDispatch, useAppSelector, normalizeQueryUserId,
  account_types, LoginWebSteps, AccountErrorMessages, MessageHandlerDisplayType, Message, MessageAnchors, DefaultRoutes, QueryParamValues, StatusCode
} from "src/common";
import { uiActions, accountActions } from "src/store";
import { Loading, useAuthContext, ErrorMessage } from "..";
import { LoginEmailForm, LoginPasswordForm, LoginSMSCodeVerificationForm, LoginTOTPCodeVerificationForm } from "./login";

function UpgradeAccountComponent() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user_id, setUserId] = useState<string>();
  const [password, setPassword] = useState<string>();
  const dispatch = useAppDispatch();
  const { loginComplete } = useAuthContext();
  const current_account = useAppSelector((state) => state.account.current_account);
  const account_ids = useAppSelector((state) => state.account.account_ids);
  const redirect_url = useAppSelector((state) => state.ui.redirect_url);
  const step = useAppSelector((state) => state.account.status);
  const [verification_error_count, setVerificationErrorCount] = useState<number>(0);

  // Description:  Initialize upgrade account by login in first
  function handleInitUpgradeAccount() {
    const query = searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY);
    const query_user_id = normalizeQueryUserId(query);
    !!query_user_id && setUserId(query_user_id);
    if (!!query) {
      searchParams.delete(QueryParamKeys.USER_ID_QUERY_KEY);
      setSearchParams(searchParams);
    }
    dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_EMAIL));
  }

  // Description: Handle all children component actions and store it
  const UpgradeAccountRequests = {
    // Description: Already validated email 
    handleSubmitEmail: (user_email: string) => {
      setUserId(user_email);
      dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_PASSWORD));
    },
    // Description: Set password or Resend from Verification form
    // Note: for Resend SMS verification click  (pass = undefined)
    handleSubmitPassword: (pass: string) => {
      const _password = !!pass ? pass : password;
      dispatch(accountActions.setComponentStatus(LoginWebSteps.LOADING));
      if (!!_password && !!user_id) {
        dispatch(accountActions.getKSSUser({ user_id, password: _password, is_upgrade: true }));
        setPassword(_password);
      } else {
        UpgradeAccountRequests.handlePageError(AccountErrorMessages.login_submit_password);
      }
    },
    handleSubmitSMSVerificationCode: (sms_code: string) => {
      dispatch(accountActions.setComponentStatus(LoginWebSteps.LOADING));
      if (!!user_id && !!password && !!sms_code) {
        dispatch(accountActions.getKSSUser({ user_id, password, sms_code, is_upgrade: true }));
      } else {
        UpgradeAccountRequests.handlePageError(AccountErrorMessages.bad_sms_code);
      }
    },
    handleSubmitTOTPVerificationCode: (totp_code: string) => {
      dispatch(accountActions.setComponentStatus(LoginWebSteps.LOADING));
      if (!!user_id && !!password && !!totp_code) {
        dispatch(accountActions.getKSSUser({ user_id, password, totp_code, is_upgrade: true }));
      } else {
        UpgradeAccountRequests.handlePageError(AccountErrorMessages.bad_totp_code);
      }
    },
    handleSubmitVerificationCodeError: (status: number) => {
      if (status === StatusCode.FORBIDDEN_DOMAIN && verification_error_count === 4) {
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_code_reset)));
        setVerificationErrorCount(0);
        setPassword(undefined);
        dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_EMAIL));
      } else {
        setVerificationErrorCount(verification_error_count + 1);
        dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.bad_verification_code)));
      }
    },
    // Description: Reset all form states and go back to first step
    handleResetLogin: () => {
      setUserId(undefined);
      setPassword(undefined);
      dispatch(accountActions.setComponentStatus(LoginWebSteps.SUBMIT_EMAIL));
    },
    handlePageError: (message: string) => {
      console.error("Login Express Account - handlePageError: ", message);
      UpgradeAccountRequests.handleResetLogin();
      dispatch(uiActions.handleRequestErrors(new Message(message)));
    },
    handlePageErrorMessage: (params: { message: string, stack?: any }) => {
      dispatch(uiActions.handleRequestErrors(new Message(params.message, MessageHandlerDisplayType.logger), params.stack));
    }
  };

  //  Description: Handle all actions from Children forms
  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((UpgradeAccountRequests as any)[callback] instanceof Function) {
      (UpgradeAccountRequests as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(MessageAnchors.actionType, actionObj.actionType);
      UpgradeAccountRequests.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  // Description: Render the correct form
  function LoginForms() {
    switch (step) {
      case 1:
        return <LoginEmailForm handleAction={handlePageActions} user_id={user_id}>
          <p>Please enter your <b>PreVeil Express user ID</b>, this is also your regular email address.</p>
        </LoginEmailForm>;
      case 2:
        return !!user_id ?
          <LoginPasswordForm handleAction={handlePageActions} user_id={user_id} >
            <>
              <h6 className="text-primary fw-bold">You are about to upgrade your account</h6>
              <p>The full installed version of PreVeil includes additional security and many advanced features. If you can
                install software, we suggest the full version to get the most out of your PreVeil experience.</p>
              <p className="fw-bold"> Once you upgrade, your password and PreVeil Express login will no longer be used.</p>
            </>
          </LoginPasswordForm> :
          <ErrorMessage message={GlobalErrorMessages.default} />;
      case 3:
        return <LoginSMSCodeVerificationForm handleAction={handlePageActions} />;
      case 4:
        return <LoginTOTPCodeVerificationForm handleAction={handlePageActions} />;
      default:
        return <Loading className="in-place" />;
    }
  }

  // Description: load LoginEmailForm (First onload event)
  useEffect(() => {
    handleInitUpgradeAccount();
    return () => { // On unmount destroy the KSS store info
      dispatch(accountActions.destroyAccountFormStates());
      dispatch(uiActions.handleMessageDismiss());
    };
  }, []);

  // Description: Complete upgrade step after KSS User creds
  useEffect(() => {
    !!account_ids && dispatch(accountActions.upgradeExpressAccount(account_ids));
  }, [account_ids]);


  // Description: Update the login status when current account is set in state
  useEffect(() => {
    !!current_account &&
      loginComplete(current_account, account_types.full,
        `${DefaultRoutes.mail_default}?${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.new}`);

  }, [current_account, redirect_url]);

  const stepDisplayNumbers: { [key: number]: number } = {
    [LoginWebSteps.SUBMIT_EMAIL]: 1,
    [LoginWebSteps.SUBMIT_PASSWORD]: 2,
    [LoginWebSteps.SUBMIT_SMS_CODE]: 3,
    [LoginWebSteps.SUBMIT_TOTP_CODE]: 3,
  };

  return step > 0 ? <Row className="justify-content-center mt-5">
    <Col md={7}>
      <h1 className="main-header">Upgrade PreVeil Express <small>{stepDisplayNumbers[step]} of 3</small></h1>
      <Card className="upgrade-account box-shadow-sm">
        <Card.Body>
          <LoginForms />
        </Card.Body>
      </Card>
    </Col>
  </Row> : <Loading />;
}

export default React.memo(UpgradeAccountComponent);
