import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const collectionServerAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_SERVER}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("accept-version", process.env.REACT_APP_COLLECTION_API_VERSION || "v8");
      headers.set("Content-Type", "application/json");
      return headers;
    }
  }),
  reducerPath: "collectionApi",
  tagTypes: ["collection", "mailbox", "threads", "keyval", "gateway", "groups"],
  endpoints: () => ({}),
});

export const filesyncSocketAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BACKEND_WEBSOCKET_SERVER}/` }),
  reducerPath: "filesyncSocketApi",
  tagTypes: ["collection_ws"],
  endpoints: () => ({}),
});

// This will be saved for future development of an open API Config for crypto server
export const cryptoServerAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_CRYPTO_SERVER}/` }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  reducerPath: "cryptoApi",
  tagTypes: ["crypto"],
  endpoints: () => ({}),
});

export const filesyncServerAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_FILESYNC_SERVER}/` }),
  reducerPath: "filesyncApi",
  tagTypes: ["filesync", "download"],
  endpoints: () => ({}),
});


// This will be saved for future development of an open API Config for crypto server
export const keyStorageServerAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_KEYSTORAGE_SERVER}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("accept-version", process.env.REACT_APP_KEYSTORAGE_API_VERSION || "v0");
      headers.set("Content-Type", "application/json");
      return headers;
    }
  }),
  reducerPath: "keystorageApi",
  tagTypes: ["kss"],
  endpoints: () => ({}),
});
