import React, { useState, useEffect, useRef } from "react";
import { Form, Button, DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import { IActionHandler, UserProfile, ActionHandlerFunction, CollectionServerUser } from "@preveil-api";
import { AdminUIActionTypes, ApprovalGroup, useAppSelector, UserListActionTypes, account_types } from "src/common";
import { Icon, UserList } from "src/components";
import * as Yup from "yup";
import { useFormik } from "formik";
import _ from "lodash";

type AllProps = {
  saved_approval_groups: ApprovalGroup[];
  setCreateApprovalGroupShow: React.Dispatch<React.SetStateAction<boolean>>;
  copiedApprovalGroup?: ApprovalGroup;
  org_users: CollectionServerUser[];
  handleAction: ActionHandlerFunction;
  setCopiedApprovalGroup: React.Dispatch<React.SetStateAction<ApprovalGroup | undefined>>;
};

function CreateApprovalGroupFormComponent(props: AllProps) {
  const duplicateApprovalGroupName = "This Approval Group Name already exists";
  const { setCreateApprovalGroupShow, copiedApprovalGroup, handleAction, setCopiedApprovalGroup, org_users, saved_approval_groups } = props;
  const [editingUsers, setEditingUsers] = useState<UserProfile[]>(!!copiedApprovalGroup ? copiedApprovalGroup.approvers : []);
  const [groupName, setGroupName] = useState<string | undefined>(!!copiedApprovalGroup ? copiedApprovalGroup.name : "");
  const [requiredApprovals, setRequiredApprovals] = useState<number>(2);
  const [full_users, setFullUsers] = useState<CollectionServerUser[]>();
  const userListRef = useRef<{ handlePageActions: ActionHandlerFunction }>();
  const current_account = useAppSelector((state) => state.account.current_account);
  const groupNameRef = useRef<HTMLInputElement>(null);

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      groupName: Yup
        .string()
        .required("The Approval Group Name is required")
        .test("group-name", duplicateApprovalGroupName, (value: string | undefined) => {
          if (value) {
            return !saved_approval_groups.some((group) => group.name === value.trim());
          }
          return !!value;
        })
    }),
    initialValues: {
      groupName,
    },
    onSubmit: () => { }
  });

  useEffect(() => {
    if (!!copiedApprovalGroup) {
      setEditingUsers(copiedApprovalGroup.approvers);
      setGroupName(copiedApprovalGroup.name);
      setCopiedApprovalGroup(undefined);
    }

    if (!!org_users && org_users.length > 0) {
      const _full_users = _.filter(org_users, ((_users: CollectionServerUser) => _users.account_type === account_types.full));
      setFullUsers(_full_users);
    }

    if (groupNameRef && groupNameRef.current && !!groupNameRef.current.value) {
      // Note: if the group is copied from another group, we want to make sure to remind the user the group name is already taken.
      const { value } = groupNameRef.current;
      const currentGroupName = value.trim();
      const isGroupNameFound = saved_approval_groups.some((group) => group.name === currentGroupName);
      if (isGroupNameFound) {
        formik.setErrors({ groupName: duplicateApprovalGroupName });
      }
    }
  }, []);

  function handleUpdateUsers(actionObj: IActionHandler) {
    const params = actionObj.params;
    setEditingUsers(params.current_contacts);
  }

  function maxOptionals(): number[] {
    const numbers: number[] = editingUsers.map((user, index) => index + 2);
    if (numbers.length > 1) {
      numbers.splice(numbers.length - 2);
    }
    return numbers;
  }

  function reset() {
    if (!!userListRef.current) {
      userListRef.current.handlePageActions({
        actionType: UserListActionTypes.Reset,
      });
    }
    setGroupName("");
  }

  function removeButton(user: UserProfile) {
    return (
      <Button
        type="submit"
        variant="icon"
        className="text-muted-light float-end"
        onClick={() => {
          if (!!userListRef.current) {
            userListRef.current.handlePageActions({
              actionType: UserListActionTypes.RemoveProfile,
              params: user,
            });
          }
        }}
      >
        <Icon className="ficon-x" />
      </Button>
    );
  }

  function handleSubmit() {
    if (!!formik.errors.groupName || !formik.values.groupName) {
      return;
    }
    const currentGroupName = formik.values.groupName.trim();
    handleAction({
      actionType: AdminUIActionTypes.Create,
      params: { groupName: currentGroupName, approvers: editingUsers, requiredApprovals },
    });
    setCreateApprovalGroupShow(false);
  }

  const isGroupNameInvalid = !!formik.errors.groupName;
  return (
    <>
      <Form.Group controlId="groupName">
        <Form.Label>Name this Group</Form.Label>
        <Form.Control
          ref={groupNameRef}
          type="text"
          name="group-name"
          placeholder="Enter Required Group Name"
          value={formik.values.groupName}
          aria-describedby="approval group name"
          onChange={formik.handleChange("groupName")}
          onBlur={formik.handleBlur("groupName")}
          autoFocus={true}
          isInvalid={!!formik.errors.groupName}
        />
        {!!formik.errors.groupName ? (
          <small className="error">{formik.errors.groupName}</small>
        ) : null}
      </Form.Group>
      <h4 className="mt-4">Add Approvers</h4>
      <p>
        Please add <u>three</u> or more people to your list of approvers.
      </p>
      {
        (!!current_account && !!full_users) &&
        < UserList
          ref={userListRef}
          current_account={current_account}
          handleAction={handleUpdateUsers}
          hide_badges={true}
          org_users={full_users}
          user_ids={editingUsers.map((e) => e.address)} />
      }
      {editingUsers.length > 0 && (
        <div className="admin-table mt-3 mb-3">
          <Row className="table-header">
            <Col sm={5} className="ps-1">User</Col>
            <Col sm={6}>Role</Col>
          </Row>
          {_.map(editingUsers, (user_profile: UserProfile, i: number) => {
            const key = _.uniqueId(user_profile.address);
            return (
              <Row key={key} className="p-0">
                <Col xs={12} sm={5} className="d-block d-sm-none">
                  {user_profile.name}
                  {removeButton(user_profile)}
                </Col>
                <Col xs={12} sm={5} className="d-none d-sm-block">
                  {user_profile.name}
                </Col>
                <Col sm={4} className="with_cap">
                  {user_profile.role}
                </Col>
                <Col className="d-none d-sm-block">{removeButton(user_profile)}</Col>
              </Row>
            );
          })}
        </div>
      )}
      {editingUsers.length > 2 && (
        <div className="d-flex align-items-center">
          How many approvals are required for recovery?
          <DropdownButton
            variant="outline-secondary"
            size="sm"
            className="ms-4 pv-dropdown"
            title={requiredApprovals}
          >
            {_.map(maxOptionals(), (number: number, i: number) => (
              <Dropdown.Item key={i} onClick={() => setRequiredApprovals(number)}>
                {number}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      )}
      <div className="mt-4">
        <Button
          onClick={handleSubmit}
          className="me-2"
          disabled={editingUsers.length < 3 || isGroupNameInvalid}>
          Create
        </Button>
        <Button
          onClick={() => reset()}
          disabled={editingUsers.length < 1}
          className="me-2"
          variant="no-outline-primary">
          Reset
        </Button>
        <Button onClick={() => setCreateApprovalGroupShow(false)} variant="no-outline-primary">
          Close
        </Button>
      </div>
    </>
  );
}

export default React.memo(CreateApprovalGroupFormComponent);

