import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ActionHandlerFunction, PaginationItem } from "@preveil-api";
import { Account, DriveUIActionTypes, DirectoryEntity, NodePermissionSet, Feature, DriveFeature } from "src/common";
import { Icon, Pagination, PaginationDisplay } from "src/components";

type AllProps = {
  current_account: Account;
  is_loading: boolean;
  pagination?: PaginationItem;
  selected: string[];
  current_directory?: DirectoryEntity;
  node_permissions?: NodePermissionSet;
  handleAction: ActionHandlerFunction;
}

function ToolbarComponent(props: AllProps) {
  const { is_loading, pagination, selected, current_directory, node_permissions, handleAction } = props;
  const [btn_disabled, setButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    (!!pagination && pagination.totalRows > 0) ?
      setButtonDisabled(selected.length <= 0) : setButtonDisabled(true);
  }, [selected, pagination]);

  return <Row className="toolbar">
    {
      !!current_directory &&
      <>
        <Col className="order-1">
          {
            !!node_permissions &&
            <>
              <Button variant="icon" size="sm"
                hidden={Feature.hidden(DriveFeature.Upload, node_permissions)}
                disabled={is_loading}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.SelectFiles })}
                aria-label="Upload Files"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Upload Files">
                <Icon className="ficon-upload" />
              </Button>
              <Button variant="icon" size="sm"
                hidden={Feature.hidden(DriveFeature.CreateFolder, node_permissions)}
                disabled={is_loading}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.CreateFolder })}
                aria-label="Create New Folder"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Create New Folder">
                <Icon className="ficon-folder-plus" />
              </Button>
              <Button variant="icon" size="sm"
                disabled={btn_disabled}
                hidden={Feature.hidden(DriveFeature.Move, node_permissions)}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.MoveModal, params: { show: true } })}
                aria-label="Move Selected"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Move Selected Items">
                <Icon className="pv-icon-move" />
              </Button>
              <Button variant="icon" size="sm"
                disabled={btn_disabled}
                hidden={Feature.hidden(DriveFeature.Delete, node_permissions)}
                onClick={() => handleAction({ actionType: DriveUIActionTypes.Delete })}
                aria-label="Delete Selected"
                data-tooltip-id="pv-tooltip" data-tooltip-content="Delete Selected Items">
                <Icon className="ficon-trash-2" />
              </Button>
            </>
          }
        </Col>
        <Col xs="auto" className="order-sm-2 order-3 ms-auto">
          {
            !!pagination &&
            <Pagination
              className="mb-0 justify-content-end"
              pagination={pagination}
              handleAction={handleAction} />
          }
        </Col>
        <Col xs="auto" className="order-sm-3 order-4 me-2">
          {!!pagination && <PaginationDisplay pagination={pagination} />}
        </Col>
        <Col className="divider order-sm-3 order-1"></Col>
        <Col xs="auto" className="p-0 order-sm-3 order-4 ms-1 refresh" >
          <Button variant="icon" size="sm"
            onClick={() => handleAction({ actionType: DriveUIActionTypes.Refresh })}
            title="Refresh Current Directory">
            {
              is_loading ?
                <i className="spinner"></i> :
                <Icon className="ficon-rotate-cw" />
            }
          </Button>
        </Col>
      </>
    }
  </Row >;
}

export default React.memo(ToolbarComponent);