import * as React from "react";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { NodePermissionType, NodePermissionLabels, NodePermissionTypes, NodePermissionTooltips } from "src/common";

type AllProps = {
  permission_type?: NodePermissionTypes;
  unshare_disabled?: boolean;
  handleAction: (type: NodePermissionTypes) => void;
}

function PermissionsMenuComponent(props: AllProps) {
  const { permission_type, unshare_disabled, handleAction } = props;

  return <>
    <Dropdown>
      <Dropdown.Toggle variant="no-outline-primary" className="btn btn-sm">
        {!!permission_type ? NodePermissionLabels[permission_type] : NodePermissionLabels.edit_and_share}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0" align="end">
        <Dropdown.Item
          active={permission_type === NodePermissionType.edit_and_share}
          onClick={() => handleAction(NodePermissionType.edit_and_share)}
          data-tooltip-id="permissions-tooltip"
          data-tooltip-content={NodePermissionTooltips.edit_and_share}
          data-tooltip-place="right">
          {NodePermissionLabels.edit_and_share}
        </Dropdown.Item>
        <Dropdown.Item
          active={permission_type === NodePermissionType.edit}
          onClick={() => handleAction(NodePermissionType.edit)}
          data-tooltip-id="permissions-tooltip"
          data-tooltip-content={NodePermissionTooltips.edit}
          data-tooltip-place="right">{NodePermissionLabels.edit}</Dropdown.Item>
        <Dropdown.Item
          active={permission_type === NodePermissionType.read_only}
          onClick={() => handleAction(NodePermissionType.read_only)}
          data-tooltip-id="permissions-tooltip"
          data-tooltip-content={NodePermissionTooltips.read_only}
          data-tooltip-place="right">
          {NodePermissionLabels.read_only}
        </Dropdown.Item>
        <Dropdown.Item
          active={permission_type === NodePermissionType.view_only}
          onClick={() => handleAction(NodePermissionType.view_only)}
          data-tooltip-id="permissions-tooltip"
          data-tooltip-content={NodePermissionTooltips.view_only}
          data-tooltip-place="right">
          {NodePermissionLabels.view_only}
        </Dropdown.Item>
        {
          !unshare_disabled &&
          <Dropdown.Item onClick={() => handleAction(NodePermissionType.unshare)}>
            {NodePermissionLabels.unshare}
          </Dropdown.Item>
        }
        {/* Need its own Tooltip for closing issues */}
        <Tooltip
          id="permissions-tooltip"
          disableStyleInjection="core"
          closeEvents={{
            mouseleave: true,
            blur: true,
            click: true,
            dblclick: true,
            mouseup: true,
          }} />
      </Dropdown.Menu>
    </Dropdown>
  </>;
}

export default React.memo(PermissionsMenuComponent);