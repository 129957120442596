import * as React from "react";
import { Outlet, Route, Navigate } from "react-router-dom";
import { Dashboard, Sandbox, UIGuides } from "../components/developer";
import { Wrapper } from "src/components";
import { PrivateRoutes, AppConfiguration } from "src/common";

function DeveloperPage() {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
}

export function DeveloperRoutes() {
  // Description: Only allow navigation if this environment is NOT production
  return !AppConfiguration.isDevelopmentRunMode() ?
    <Route path="*" element={<Navigate to="/" replace />} /> :
    <>
      <Route path={""} element={<Dashboard />} />
      <Route path={`${PrivateRoutes.ui_guide_route}/*`} element={<UIGuides />} />
      <Route path={PrivateRoutes.sandbox_route} element={<Sandbox />} />
    </>;

}

export default React.memo(DeveloperPage);
