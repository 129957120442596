import React, { useEffect } from "react";
import { useSearchParams, useNavigate, Navigate } from "react-router-dom";
import { uiActions, accountActions } from "src/store";
import {
  QueryParamKeys, useAppDispatch, PublicRoutes, Message, AccountErrorMessages,
  useAppSelector, DefaultRoutes, QueryParamValues, account_types, CreateAccountSteps, normalizeQueryUserId
} from "src/common";
import { Loading, useAuthContext } from "src/components";

function ClaimAccountComponent() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loginComplete } = useAuthContext();
  const current_account = useAppSelector((state) => state.account.current_account);
  const step = useAppSelector((state) => state.account.status);

  function handleClaimAccount() {
    const user_id = normalizeQueryUserId(searchParams.get(QueryParamKeys.USER_ID_QUERY_KEY));
    const secret = searchParams.get(QueryParamKeys.SECRET_QUERY_KEY)?.trim();
    const key_version = searchParams.get(QueryParamKeys.KEY_VERSION_KEY)?.trim();
    if (!!user_id && !!secret && key_version) {
      dispatch(accountActions.setNewLocalAccount({ user_id, secret, key_version: Number(key_version) }));
    } else {
      dispatch(uiActions.handleRequestErrors(new Message(AccountErrorMessages.claim_account_missing_params),
        {
          stack_meassage: "The link to claim account is missing data",
          stack_error: { user_id, secret, key_version }
        }));
      navigate(`/${PublicRoutes.get_started_route}`);
    }
  }

  // Description: load accounts and current account from crypto (First onload event)
  useEffect(() => {
    handleClaimAccount();
  }, []);

  // Description: Update the login status when current account is set in state
  useEffect(() => {
    (!!current_account) &&
      loginComplete(current_account, account_types.full,
        `${DefaultRoutes.mail_default}?${QueryParamKeys.PAGE_ACTION_QUERY_KEY}=${QueryParamValues.new}`);
  }, [current_account]);
  return step !== CreateAccountSteps.SUBMIT_ERROR ? <Loading /> :
    <Navigate to={`/${PublicRoutes.get_started_route}`} />;
}

export default React.memo(ClaimAccountComponent);
