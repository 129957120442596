import * as React from "react";
import { TreeItem, ActionHandlerFunction } from "@preveil-api";
import { UIManagerActionTypes } from "src/common";
import { Icon } from "src/components";

type AllProps = {
  file: TreeItem;
  handleAction: ActionHandlerFunction
}

function TreeFile(props: AllProps) {
  const { file, handleAction } = props;
  return <div className={`file-item${file.disabled ? " disabled" : ""}${file.active ? " active" : ""}`}
    onClick={() => handleAction({ actionType: UIManagerActionTypes.TreeNodeClick, params: file })}>
    <Icon className="ficon-file-text" />
    <span>{file.name}</span>
  </div>;
}

export default React.memo(TreeFile);
