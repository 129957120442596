import React, { useEffect, useState, FocusEvent } from "react";
import { Row, Col, Form, ProgressBar, Button, Toast } from "react-bootstrap";
import { ActionHandlerFunction } from "@preveil-api";
import {
  Account, useAppDispatch, EntryItem, SELECTIVE_SYNC_STATES, useSetSyncStateMutation, Message, DriveErrorMessages, MessageHandlerDisplayType,
  DriveSuccessMessages, MessageAnchors, DriveEntryType, DriveUIActionTypes, UUID, useFSProcessNotification, AsyncJobTypeMap, AsyncJobStatusMap
} from "src/common";
import { uiActions } from "src/store";

type AllProps = {
  current_account: Account;
  entry: EntryItem;
  handleAction: ActionHandlerFunction;
}

const ProgressValues = {
  initial: 0,
  beforeCall: 25,
  working: 50,
  success: 100,
  error: 0
};

function SyncDetailsComponent(props: AllProps) {
  const { current_account, entry, handleAction } = props;
  const [show_progress, setShowProgress] = useState<boolean>(false);
  const [is_synced, setIsSynced] = useState<boolean>(isSynced(entry.localSyncStatus));
  const [disabled, setDisabled] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(ProgressValues.initial);
  const [watch_id, setWatchRequestId] = useState<string | undefined>();
  const { new_notification } = useFSProcessNotification(AsyncJobTypeMap.SYNC, watch_id);
  const [setSyncState] = useSetSyncStateMutation();
  const dispatch = useAppDispatch();

  // Description: Set Dirty Warning based on loading
  useEffect(() => {
    dispatch(uiActions.handleSetDirtyMessage(show_progress));
  }, [show_progress]);

  // Description: Update UI when FILESYNC Notifications come in
  useEffect(() => {
    if (!!new_notification && new_notification.request_id === watch_id && show_progress) {
      (new_notification.status === AsyncJobStatusMap.COMPLETED) && handleSuccess(true, false);
      (new_notification.status === AsyncJobStatusMap.FAILED || new_notification.status === AsyncJobStatusMap.HARD_FAILED) &&
        handleError(new_notification);
    };
  }, [new_notification]);

  // Description: Set is_synced state
  function isSynced(localsyncstatus: SELECTIVE_SYNC_STATES): boolean {
    return localsyncstatus === SELECTIVE_SYNC_STATES.ON;
    // TO DO: Activate this on Selective sync enabled !AppConfiguration.driveSyncV1Enabled()
    //  return localsyncstatus === SELECTIVE_SYNC_STATES.ON || localsyncstatus === SELECTIVE_SYNC_STATES.InheritedON;
  }

  // Description: handle checkbox change
  function handleChange(event: FocusEvent<HTMLInputElement>) {
    setProgress(ProgressValues.beforeCall);
    setShowProgress(!show_progress);
    setDisabled(true);
    handleSyncUnsync(event.target.checked);
  }

  // Description: cancels Only ongoing sync progress -> Trigger stop
  function cancel() {
    dispatch(uiActions.handleMessageDismiss());
    handleSyncUnsync(false, true);
  }

  // Description: Clean up UI and reset to initial state
  function reset() {
    setShowProgress(false);
    setProgress(ProgressValues.initial);
    setDisabled(false);
    setWatchRequestId(undefined);
  }

  // Description: call sync or unsync (start or stop)
  // NOTE: Filesync expects the linked_collection_id for both identifiers (collection_id and directory_id) for LINK type entries
  async function handleSyncUnsync(sync_state: boolean, is_cancel: boolean = false) {
    // NOTE: Only subsribe for async calls start (Sync turn on)
    const request_id = sync_state ? new UUID().String() : undefined;
    setWatchRequestId(request_id);
    // NOTE: If this is an ACL Node then the directory_id should also be the  entry.linked_collection_id  ***** WORKING
    const params = {
      user_id: current_account.user_id,
      collection_id: entry.type === DriveEntryType.LINK ? entry.linked_collection_id : entry.collection_id,
      directory_id: entry.type === DriveEntryType.LINK ? entry.linked_collection_id : entry.id,
      sync_state,
      request_id
    };

    setSyncState(params)
      .unwrap()
      .then((response: string) => {
        if (response === "OK") {
          (!request_id || !sync_state) && handleSuccess(sync_state, is_cancel);
        } else {
          handleError({ stack_message: "Error sending the setSyncState", stack_error: params });
        }
      })
      .catch((error: unknown) => {
        handleError({ stack_error: { error, params } });
      });
  }

  // Description: Handle cancel and clean up
  function handleSuccess(sync_state: boolean, is_cancel: boolean) {
    setProgress(ProgressValues.success);
    handleAction({ actionType: DriveUIActionTypes.Refresh });
    const message = is_cancel ? DriveSuccessMessages.success_cancelled_sync :
      DriveSuccessMessages.success_sync_unsync.replace(MessageAnchors.actionType, sync_state ? "Synced" : "Unsynced").
        replace(MessageAnchors.folder_name, entry.name);
    dispatch(uiActions.handleSetMessage(new Message(message, MessageHandlerDisplayType.toastr)));
    // NOTE: Just a tiny lag so we can see the success progress
    setTimeout(() => {
      reset();
      setIsSynced(sync_state);
    }, 1000);
  }

  // Description: Handle call error or fs ws notification
  function handleError(stack_error?: unknown) {
    dispatch(uiActions.handleRequestErrors(new Message(DriveErrorMessages.default, MessageHandlerDisplayType.toastr), { stack_error }));
    reset();
  }

  return (
    <>
      <div className={`switch-preveil d-flex p-3 ps-0${disabled ? " disabled" : ""}`}>
        <Form.Check
          type="switch"
          id="sync-switch"
          className="me-2"
          checked={is_synced}
          disabled={disabled}
          onChange={handleChange} />
        <span className="property-name">{!!is_synced ? "Synced" : "Unsynced"}</span>
      </div>
      {!!show_progress && (
        <>
          <Row className="align-items-center">
            <Col sm={9} xs={8}>
              <ProgressBar now={progress} label={`${progress}% completed`} visuallyHidden />
            </Col>
            <Col xs={1} className="mb-0 ps-1">
              {progress}%
            </Col>
            {
              // NOTE: Cancel only appears with Syncing (start); then it should trigger stop
              !is_synced &&
              <Col className="ps-0">
                <Button
                  className="ms-auto float-end"
                  size="sm"
                  variant="no-outline-primary"
                  aria-label="Close"
                  onClick={cancel}>
                  Cancel
                </Button>
              </Col>
            }
          </Row>
          <Toast bg="warning" className="d-inline-block mb-3 mt-2">
            <Toast.Body>
              {
                is_synced ?
                  <span>Secure Unsyncing in Progress. If there is an interruption, please restart the process.</span> :
                  <span>Secure Syncing in Progress. Cancel to Stop.</span>
              }
            </Toast.Body>
          </Toast>
        </>
      )}
    </>
  );
}

export default React.memo(SyncDetailsComponent);
