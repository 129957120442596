import React, { useState, useEffect } from "react";
import {
  IActionHandler,
  MessageDisplayType,
  DataExportRequest,
  ChangeAdminStatusRequest,
  RekeyAndChangeRecoveryRequest,
  MemberRekeyAndSetRecoveryRequest,
  DeleteUserRequest,
  SubsumeRequest,
  Sort,
  JSONOrgInfo
} from "@preveil-api";
import {
  Account, UserRequest, GetUserApprovalsApiResponse, GlobalErrorMessages, Message, MessageAnchors, MessageHandlerDisplayType,
  OrgUserRoles, GlobalConstants, RequestTab, RequestTypes, SimplePaginationAction, SimplePaginationActionType, useAppDispatch,
  useAppSelector, useGetUserApprovalsMutation, usePostUsersFindMutation, useRespondToAdminApprovalMutation, useRespondToUserApprovalMutation,
  dayjs, ApprovalRequestStatus, ApprovalRequestStatusType, getOppositeSortDirection, sortBy, SORT_DIRECTION, Pagination, collectionApi, AdminErrorMessages
} from "src/common";
import { ApprovalRequestsParent } from "src/components";
import { accountActions, settingsActions, uiActions } from "src/store";
import _ from "lodash";

function ApprovalsComponent() {
  const dispatch = useAppDispatch();
  const [pending_requests, setPendingRequests] = useState<UserRequest[]>([]);
  const [history_requests, setHistoryRequests] = useState<UserRequest[]>([]);
  const current_account = useAppSelector((state) => state.account.current_account);
  const current_org = useAppSelector((state) => state.account.organization);
  const [filter_status, setFilterStatus] = useState<ApprovalRequestStatusType>(ApprovalRequestStatus.approved);
  const pagination = useAppSelector((state) => state.settings.pagination);
  const [page, setPage] = useState<number>(0);
  const [getApprovals, { isLoading: loading }] = useGetUserApprovalsMutation();
  const [findUsers] = usePostUsersFindMutation();
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [respondToUserRequest] = useRespondToUserApprovalMutation();
  const [respondToAdminRequest] = useRespondToAdminApprovalMutation();
  const [sort, setSort] = useState<Sort<keyof UserRequest>>({ field: "timestamp", direction: "asc" });
  const [getOrgInfo] = collectionApi.endpoints.getUsersOrgsByEntityId.useLazyQuery();

  /* Description: On Initial load, get pending user approvals
   Clean up/on exit => reset pagination, because we do not need to save that
   in the store when we leave the component. */
  useEffect(() => {
    getUserApprovals(false, "pending", true);
    return () => {
      dispatch(settingsActions.resetPagination());
    };
  }, []);

  /* Description: Used to populate the request object depending on the request type. */
  function populateRequestInfo(request: UserRequest): { request: UserRequest, user_ids: string[] } {
    let user_ids = Array<string>();
    switch (request.type) {
      case RequestTypes.change_approval_group:
      case RequestTypes.rekey_and_change_approval_group:
        const change_recovery = request.parsed_payload.data as RekeyAndChangeRecoveryRequest;
        request.is_admin = false;
        if (!!change_recovery.group) {
          user_ids = change_recovery.group.map(
            (approver: { user_id: any }) => approver.user_id,
          );
        }
        request.parsed_payload.approvers = change_recovery.group;
        request.parsed_payload.for_users = [request.requester_id];
        break;
      case RequestTypes.set_member_approval_group:
      case RequestTypes.member_rekey_and_set_approval_group:
        const set_recovery = request.parsed_payload.data as MemberRekeyAndSetRecoveryRequest;
        request.is_admin = true;
        let group_found = false;
        request.parsed_payload.for_users = [];
        if (!!set_recovery.events) {
          set_recovery.events.forEach((event: { payload: string }) => {
            const event_payload = JSON.parse(event.payload);
            if (!!request.parsed_payload.for_users) {
              request.parsed_payload.for_users = request.parsed_payload.for_users.concat(
                event_payload.for_user_id,
              );
            }
            user_ids = event_payload.for_user_id;
            if (!group_found) {
              request.parsed_payload.approvers = event_payload.data.approvers;
              user_ids = event_payload.data.approvers.map(
                (approver: { user_id: any }) => approver.user_id,
              );
              group_found = true;
            }
          });
        }
        break;
      case RequestTypes.change_org_approval_group_role:
        request.is_admin = true;
        break;
      case RequestTypes.export:
        const export_req = request.parsed_payload.data as DataExportRequest;
        try {
          const from = dayjs.utc(export_req.from).local().format("l");
          const until = dayjs.utc(export_req.until).local().format("l");
          const include_email = export_req.include_emails;
          const include_drive = export_req.include_files;
          const include_log = export_req.include_logs;
          const include_org_acl_report = export_req.include_org_acl_report;
          request.export_content = {
            from,
            until,
            include_email,
            include_drive,
            include_log,
            include_org_acl_report,
          };
        } catch (e) {
          dispatch(
            uiActions.handleRequestErrors(new Message("Error setting export content", MessageHandlerDisplayType.logger), e));
        }
        break;
      case RequestTypes.change_admin_status:
        const change_admin_status = request.parsed_payload.data as ChangeAdminStatusRequest;
        request.is_admin = true;
        if (!!change_admin_status.user_id) {
          request.parsed_payload.for_users = [change_admin_status.user_id];
          request.updated_role = change_admin_status.role;
          user_ids = [change_admin_status.user_id];
        }
        break;
      case RequestTypes.delete_user:
        const delete_user = request.parsed_payload.data as DeleteUserRequest;
        request.is_admin = true;
        if (delete_user.user_id) {
          request.parsed_payload.for_users = [delete_user.user_id];
          user_ids = [delete_user.user_id];
        }
        break;
      case RequestTypes.subsume_account:
        request.is_admin = true;
        const subsume = request.parsed_payload.data as SubsumeRequest;
        const local_request = pending_requests.find((l_request) => l_request.uid === request.uid);
        if (!!local_request && !!local_request.org_details) {
          request.setOrgDetails(local_request.org_details);
        } else {
          getOrganizationInfo(subsume.entity_id, request);
        }
        if (!!subsume.subsume_user_id) {
          request.parsed_payload.for_users = [subsume.subsume_user_id];
          user_ids = [subsume.subsume_user_id];
        }
        break;
      default:
        dispatch(
          uiActions.handleRequestErrors(
            new Message(`Unmapped request.type: ${request.type}`, MessageHandlerDisplayType.logger),
          ),
        );
        break;
    }
    return { request, user_ids };
  };

  async function getOrganizationInfo(entity_id: string, request: UserRequest) {
    if (!!current_account) {
      const account_ids = Account.getAccountIdentifiers(current_account);
      const params = {
        account_ids,
        body: {
          entity_id,
        },
      };
      getOrgInfo(params)
        .unwrap()
        .then((response: JSONOrgInfo) => {
          request.org_details = {
            loaded: true,
            org_id: response.admin_group_id, 
            org_name: response.display_name,
            roled_approval_groups: response.roled_approval_groups
          };
        })
        .catch((error: any) => {
          dispatch(uiActions.handleRequestErrors(new Message(AdminErrorMessages.error_fetching_org_info, MessageHandlerDisplayType.logger), error));
          request.org_details = { loaded: false, org_id: entity_id || "" };
        });
    }
  }

  /* Description: Gets user objects from the backend given a list of user ids and populates the request objects
  with those users. */
  async function getUsers(
    user_ids: string[],
    user_requests: UserRequest[],
  ): Promise<UserRequest[] | undefined> {
    if (!!current_account) {
      return await findUsers({
        account_ids: Account.getAccountIdentifiers(current_account),
        body: { spec: user_ids.map((id) => ({ user_id: id, key_version: -1 })) },
      }).unwrap().then(({ users }) => {
        const updated_user_approvals = user_requests.map((approval) => {
          const u = users.find((u) => u.user_id === approval.requester_id.toLowerCase());
          if (!!approval.requester_id) {
            approval.requester = !!u
              ? { name: u.display_name, address: u.user_id, external_email: u.external_email }
              : null;
          }
          if (!!approval.parsed_payload.approvers) {
            approval.approvers = approval.parsed_payload.approvers.map(
              (approver: { user_id: string }) => {
                const u = users.find((u) => u.user_id === approver.user_id.toLowerCase());
                return { name: u?.display_name || null, address: u?.user_id || "" };
              },
            );
          }
          if (!!approval.parsed_payload.for_users) {
            approval.for_users = approval.parsed_payload.for_users.map(
              (approver: string) => {
                const u = users.find((u) => u.user_id === approver.toLowerCase());
                return { name: u?.display_name || null, address: u?.user_id || "" };
              },
            );
          }
          return approval;
        });
        return updated_user_approvals;
      })
        .catch((msg: string) => {
          dispatch(uiActions.handleRequestErrors(new Message(msg, MessageHandlerDisplayType.logger)));
          const updated_response = user_requests.map((request) => {
            if (!!request.parsed_payload.approvers) {
              request.approvers = request.parsed_payload.approvers.map(
                (approver: { user_id: any }) => ({ name: null, address: approver.user_id }),
              );
            }
            return request;
          });
          return updated_response;
        });
    }
    return undefined;
  }

  /* Description: Gets the user approvals from the backend, populates the user request objects and sets it in the store. */
  async function getUserApprovals(history: boolean, status?: string, hideExpired?: boolean, response?: string, limit?: number, offset?: number) {
    if (!!current_account) {
      await getApprovals({ userId: current_account.user_id, status, hideExpired, response, limit, offset })
        .unwrap()
        .then(async (response: GetUserApprovalsApiResponse) => {
          const approvals = response.approvals.map(approval => {
            const parsed_payload = JSON.parse(approval.payload);
            return new UserRequest(
              approval.request_id,
              null,
              approval.payload,
              !!history ? approval.status : approval.response,
              parsed_payload.user_id,
              [],
              null,
              null,
              !history && approval.status === ApprovalRequestStatus.pending && approval.response !== ApprovalRequestStatus.pending
            );
          });
          let users: UserRequest[] | undefined;
          let user_ids = Array<string>();
          approvals.forEach(request => {
            try {
              if (!!request.requester_id) {
                user_ids = user_ids.concat(request.requester_id);
              }
              const request_info = populateRequestInfo(request);
              user_ids = user_ids.concat(request_info.user_ids);
              return request;
            } catch (error) {
              request.invalid = true;
            }
          });
          user_ids = _.uniqBy(user_ids, (id) => id);
          if (user_ids.length > 0) {
            users = await getUsers(user_ids, approvals);
          } else {
            users = approvals;
          }
          if (!!users) {
            if (history) {
              users.forEach(request => {
                if (!!request.approvers && request.approvers.length === 0) {
                  request.invalid = true;
                }
                switch (request.type) {
                  case RequestTypes.member_rekey_and_set_approval_group:
                  case RequestTypes.set_member_approval_group:
                  case RequestTypes.change_admin_status:
                  case RequestTypes.delete_user:
                    if (!request.for_users || request.for_users.length === 0) {
                      request.invalid = true;
                    }
                    break;
                  case RequestTypes.subsume_account:
                    request.invalid = !(request.for_users.length === 1 && !!request.for_users[0]);
                    break;
                }
              });
              users = users.filter((request) => !request.invalid);
            }
            const invalid_request_count = approvals.filter((request) => !!request.invalid).length;
            if (history) {
              setHistoryRequests(users);
            } else {
              setPendingRequests(users);
              dispatch(settingsActions.setNotifications({ recoveryGroupPending: undefined, recoveryCodePending: undefined, approvals: users.length }));
            }
            const totalPageRows = users.length;
            const totalRows = response.total_rows - invalid_request_count || users.length;
            if (totalPageRows > 0 && totalRows > 0) {
              const paginationItem = new Pagination(
                page,
                totalPageRows,
                totalRows,
                GlobalConstants.REQUESTS_PER_PAGE_COUNT,
              ).pagination_item;
              !!paginationItem && dispatch(settingsActions.setPagination(paginationItem));
            } else {
              dispatch(settingsActions.resetPagination());
              setPage(0);
            }
          }
          setIsLoading(false);
        })
        .catch((msg) => {
          dispatch(uiActions.handleRequestErrors(
            new Message(msg, MessageHandlerDisplayType.logger)));
        });
    }
  }

  /* Description: Handle all children component actions and store it */
  const ApprovalsCalls = {
    handleSetSort: async (
      params: { tab: string; field: "mapped_action" | "requester_id" | "expiration" | "timestamp" },
    ) => {
      const { tab, field } = params;
      const _requests = tab === RequestTab.history ? history_requests : pending_requests;
      const direction =
        sort.field === field ? getOppositeSortDirection(sort.direction) : SORT_DIRECTION.ascending;
      const sorted_requests = sortBy(_requests, { field, direction });
      setSort({ field, direction });
      tab === RequestTab.history ? setHistoryRequests(sorted_requests) : setPendingRequests(sorted_requests);
    },
    handleSimplePaging: (action: SimplePaginationActionType) => {
      if (!!action) {
        setIsLoading(true);
        const new_page = action === SimplePaginationAction.next ? page + 1 : page - 1;
        const offset = new_page * GlobalConstants.REQUESTS_PER_PAGE_COUNT;
        setPage(new_page);
        getUserApprovals(
          true,
          filter_status,
          false,
          undefined,
          GlobalConstants.REQUESTS_PER_PAGE_COUNT,
          offset
        );
      }
    },
    handleUpdateFilterStatus: (params: any) => {
      dispatch(settingsActions.resetPagination());
      setFilterStatus(params);
      setIsLoading(true);
      getUserApprovals(true, params, false, undefined, GlobalConstants.REQUESTS_PER_PAGE_COUNT);
    },
    handleRefresh: (tab: string) => {
      if (tab === RequestTab.pending) {
        getUserApprovals(false, "pending", true);
      } else {
        setIsLoading(true);
        getUserApprovals(
          true,
          filter_status,
          false,
          undefined,
          GlobalConstants.REQUESTS_PER_PAGE_COUNT,
          page * GlobalConstants.REQUESTS_PER_PAGE_COUNT,
        );
      }
    },
    handleRespondToRequest: async (params: { request: UserRequest; approved: boolean }) => {
      if (!!current_account) {
        setIsLoading(true);
        let query;
        if (!!params.request.is_admin) {
          let org_id: string | undefined;
          if (!!current_account) {
            org_id = Account.getAccountProfile(current_account).org_id || current_org?.org_id;
          }
          if (params.request.type === RequestTypes.subsume_account) {
            const parsed = params.request.parsed_payload.data as SubsumeRequest;
            org_id = parsed.entity_id;
          }
          if (!org_id) {
            dispatch(
              uiActions.handleRequestErrors(
                new Message("Missing Org Id", MessageHandlerDisplayType.logger),
              ),
            );
            return;
          }
          query = respondToAdminRequest({
            userId: current_account?.user_id,
            orgId: org_id,
            response: params.approved,
            requestId: params.request.request_id,
            request: params.request.payload,
          });
        } else {
          query = respondToUserRequest({
            userId: current_account?.user_id,
            response: params.approved,
            requestId: params.request.request_id,
            request: params.request.payload,
          });
        }
        query
          .unwrap()
          .then(async () => {
            const updated_approval_index = pending_requests.findIndex(
              (approval) => approval.request_id === params.request.request_id,
            );
            pending_requests[updated_approval_index].status = params.approved
              ? ApprovalRequestStatus.approved
              : ApprovalRequestStatus.denied;
            if (
              !!params.approved &&
              params.request.type === RequestTypes.subsume_account &&
              !!params.request.org_details
            ) {
              const parsed = params.request.parsed_payload.data as SubsumeRequest;
              // TO DO: test this again when working on admin.
              dispatch(
                accountActions.setCurrentAccountOrganizationSuccess({
                  org_id: parsed.entity_id,
                  org_name: params.request.org_details.org_name || "",
                  dept_name: parsed.department,
                  role: OrgUserRoles.standard,
                  show_mua_prompt: false,
                  status: undefined,
                  approval_group_id: undefined,
                  approval_group_version: undefined,
                }),
              );
            }
            dispatch(
              uiActions.handleSetMessage(
                new Message(
                  `Successfully ${params.approved ? ApprovalRequestStatus.approved : "voted no to"
                  } the request`,
                ),
              ),
            );
            setIsLoading(true);
            getUserApprovals(false, "pending", true);
          })
          .catch((msg) => {
            let message;
            if (msg.status === 409) {
              message = "You have already responded to this request";
            } else {
              message = `Error ${params.approved ? "approving" : "voting no to"} this request`;
            }
            dispatch(uiActions.handleRequestErrors(new Message(message), msg));
          });
      }
    },
    handlePageErrorMessage: (params: {
      message: string;
      stack?: any;
      display_type?: MessageDisplayType;
    }) => {
      const display_type = !!params.display_type
        ? params.display_type
        : MessageHandlerDisplayType.logger;
      dispatch(
        uiActions.handleRequestErrors(new Message(params.message, display_type), params.stack),
      );
    },
  };

  function handlePageActions(actionObj: IActionHandler) {
    dispatch(uiActions.handleMessageDismiss());
    const callback = `handle${actionObj.actionType}`;
    // Handle local calls:
    if ((ApprovalsCalls as any)[callback] instanceof Function) {
      (ApprovalsCalls as any)[callback](actionObj.params);
    } else {
      const message = GlobalErrorMessages.no_handler_found.replace(
        MessageAnchors.actionType,
        actionObj.actionType,
      );
      ApprovalsCalls.handlePageErrorMessage({ message, stack: actionObj });
    }
  }

  return <ApprovalRequestsParent
    handleAction={handlePageActions}
    is_approval={true}
    approval_requests={pending_requests}
    history_requests={history_requests}
    current_sort={sort}
    loading={!!loading || !!is_loading}
    can_select={false}
    pagination={pagination}
    is_details_loading={false}
    get_responses={false}
  ></ApprovalRequestsParent>;
}

export default React.memo(ApprovalsComponent);
