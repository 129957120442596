import React, { Dispatch, SetStateAction } from "react";
import { Card, Alert, Button } from "react-bootstrap";
import {
  Account,
  DeleteUsersBackupApiResponse,
  Message,
  SettingsErrorMessages,
  SettingsSuccessMessages,
  useAppDispatch,
  useAppSelector,
  useDeleteUsersBackupMutation,
} from "src/common";
import { uiActions } from "src/store";

type AllProps = {
  backup_id: string;
  setBackupId: Dispatch<SetStateAction<string | undefined>>;
};

function ExistingBackupComponent(props: AllProps) {
  const { backup_id, setBackupId } = props;
  const backup_prefix = backup_id.substring(0, 6);
  const dispatch = useAppDispatch();
  const current_account = useAppSelector((state) => state.account.current_account);
  const [deleteAccountBackup] = useDeleteUsersBackupMutation();

  /* Deletes the users current account backup. */
  function handleDelete() {
    if (!!current_account) {
      const account_ids = Account.getAccountIdentifiers(current_account);
      const params = {
        account_ids,
        body: {
          user_id: account_ids.user_id,
          backup_id,
        },
      };
      deleteAccountBackup(params)
        .unwrap()
        .then((response: DeleteUsersBackupApiResponse) => {
          !!response.errors
            ? dispatch(
              uiActions.handleRequestErrors(
                new Message(SettingsErrorMessages.error_deleting_backup),
                response,
              ),
            )
            : setBackupId(undefined);
          dispatch(
            uiActions.handleSetMessage(new Message(SettingsSuccessMessages.account_backup_deleted)),
          );
        })
        .catch((stack) => {
          dispatch(
            uiActions.handleRequestErrors(
              new Message(SettingsErrorMessages.error_deleting_backup),
              stack,
            ),
          );
        });
    }
  };

  return <Card.Body>
    <Alert variant="primary" className="mb-4 max-800">
      <p>
        If you have lost your PreVeil QR code and key recovery file or someone else has gained
        access to it, you can delete it and then create a new one.
      </p>
      <p>
        Print your new recovery code and keep it somewhere safe or store the recovery file
        somewhere safe (not on your computer).
      </p>
      <p>
        In the future, if you lose access to all your PreVeil devices, you can recover your
        account and its data by using this information on a new device.
      </p>
    </Alert>
    <h4>Backup</h4>
    <div className="existing-backup">
      <p>{backup_prefix}</p>
      <Button variant="outline-primary" onClick={handleDelete}>
        Delete
      </Button>
    </div>
  </Card.Body>;
}

export default React.memo(ExistingBackupComponent);
