/*
*  File:            drive/slice.ts
*  Description:     Holds types and constants for Drive module
*  Author:          PreVeil
*/
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationItem, Sort } from "@preveil-api";
import {
  InitialPaginationState, PermissionSetType, RootInfo, DirectoryEntity, DriveLimits, DirectoryPaginationInfo, PathInfo, CollectionInfo,
  GrantSetType, LinkEntity, DefaultCollectionName, DriveConstants, SortType, SORT_DIRECTION, CollectionEntity, DRIVE_MANAGER_STATE,
  DriveNotifications, ACK, UploadRequest, EntryUpdate, FilesyncLocalNotificationsMap
} from "src/common";

// ----------------------------------------------------------------------
// Types
// ----------------------------------------------------------------------
export interface DriveState {
  is_loading: boolean;
  pagination: PaginationItem,
  sort: Sort<keyof SortType>,
  selected: string[];
  default_permissions: PermissionSetType[];
  drive_state: DRIVE_MANAGER_STATE;
  default_grants: GrantSetType[];
  root_info?: RootInfo;
  current_directory?: DirectoryEntity;
  current_directory_name?: string;
  current_collection?: CollectionEntity;
  collections: CollectionInfo;
  pagination_info?: DirectoryPaginationInfo;
  breadcrumbs?: PathInfo[];
  link?: LinkEntity;
  notifications: DriveNotifications;
  fs_notifications: FilesyncLocalNotificationsMap;
  ack_notification?: ACK;
  upload_request?: UploadRequest;
  entry_update?: EntryUpdate;
}

// ----------------------------------------------------------------------
// Initial State and Slices 
// ----------------------------------------------------------------------
const initialState: DriveState = {
  drive_state: DRIVE_MANAGER_STATE.NOT_INITIALIZED,
  is_loading: false,
  pagination: Object.assign({}, InitialPaginationState, { pageSize: DriveLimits.DRIVE_PAGINATION_PAGE_SIZE }),
  sort: { field: "name", direction: SORT_DIRECTION.ascending },
  selected: [],
  default_permissions: [],
  default_grants: [],
  collections: {},
  notifications: {},
  fs_notifications: {}
};

export const driveSlice = createSlice({
  name: "drive",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.is_loading = action.payload;
    },
    // NOTE: Only use this field for cross page corrupted issues like No permissions in db - will redirect to an error component
    setDriveState: (state, action: PayloadAction<DRIVE_MANAGER_STATE>) => {
      state.drive_state = action.payload;
    },
    setDefaultPermissions: (state, action: PayloadAction<PermissionSetType[]>) => {
      state.default_permissions = action.payload;
    },
    setDefaultGrants: (state, action: PayloadAction<GrantSetType[]>) => {
      state.default_grants = action.payload;
    },
    setRootInfoSuccess: (state, action: PayloadAction<RootInfo>) => {
      state.root_info = action.payload;
    },
    browseDirectorySuccess: (state, action: PayloadAction<DirectoryEntity>) => {
      state.current_directory = action.payload;
      state.breadcrumbs = action.payload.path;
    },
    setCurrentDirectorySuccess: (state, action: PayloadAction<DirectoryEntity>) => {
      state.current_directory = action.payload;
    },
    resetCurrentDirectory: (state) => {
      state.current_directory = undefined;
      state.breadcrumbs = undefined;
      state.link = undefined;
      state.selected = [];
      state.is_loading = false;
      state.pagination = initialState.pagination;
      state.pagination_info = undefined;
    },
    setLinkEntity: (state, action: PayloadAction<LinkEntity | undefined>) => {
      state.link = action.payload;
    },
    setBreadcrumbsPaths: (state, action: PayloadAction<PathInfo[]>) => {
      state.breadcrumbs = action.payload;
      const path = (action.payload.length > 0) ? action.payload[action.payload.length - 1].path : undefined;
      state.current_directory_name = path === DefaultCollectionName ? DriveConstants.ROOT_DIRECTORY : path;
    },
    setPaginationInfo: (state, action: PayloadAction<DirectoryPaginationInfo>) => {
      state.pagination_info = action.payload;
    },
    setCurrentCollection: (state, action: PayloadAction<CollectionEntity | undefined>) => {
      state.current_collection = action.payload;
    },
    setCollections: (state, action: PayloadAction<CollectionInfo>) => {
      state.collections = action.payload;
    },
    setSelected: (state, action: PayloadAction<string[]>) => {
      state.selected = action.payload;
    },
    setPagination: (state, action: PayloadAction<PaginationItem>) => {
      state.pagination = action.payload;
      state.pagination_info = undefined;
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
      state.pagination_info = undefined;
    },
    resetBreadcrumbs: (state) => {
      state.breadcrumbs = undefined;
    },
    setSort: (state, action: PayloadAction<Sort<keyof SortType>>) => {
      state.sort = action.payload;
    },
    resetSort: (state) => {
      state.sort = initialState.sort;
    },
    setNotification: (state, action: PayloadAction<DriveNotifications>) => {
      state.notifications = action.payload;
    },
    setAck: (state, action: PayloadAction<ACK | undefined>) => {
      state.ack_notification = action.payload;
    },
    // Description: Local filesync notifications 
    setLocalFSNotification: (state, action: PayloadAction<FilesyncLocalNotificationsMap>) => {
      state.fs_notifications = action.payload;
    },
    setFileUploadRequest: (state, action: PayloadAction<UploadRequest | undefined>) => {
      state.upload_request = action.payload;
    },
    setEntryUpdate: (state, action: PayloadAction<EntryUpdate | undefined>) => {
      state.entry_update = action.payload;
    },
    destroyDrive: () => {
      return initialState;
    },
  },
});

// ----------------------------------------------------------------------
// Global exports
// ----------------------------------------------------------------------
export const driveActions = driveSlice.actions;
export default driveSlice.reducer;


